/* eslint-disable no-console */

import { register } from 'register-service-worker'

// Behöver ha dessa metoderna för att webbläsarna ska godkänna appen som PWA
// Skriver just nu bara ut, men kanske kan visa mer i UI framöver
// Ska aldrig köras i testmiljö för det slår ut hot-reload
// Paketet har automatisk rensning av serviceworkers i testmiljö
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'Appen serveras från cachen av en service worker.\n' +
        'För mer information, besök https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker har registrerats.')
    },
    cached () {
      console.log('Innehållet har cachats för offline-användning.')
    },
    updatefound () {
      console.log('Nytt innehåll laddas ner.')
    },
    updated () {
      console.log('Nytt innehåll är tillgängligt; vänligen uppdatera.')
    },
    offline () {
      console.log('Ingen internetanslutning hittades. Appen körs i offline-läge.')
    },
    error (error) {
      console.error('Fel under registrering av service worker:', error)
    }
  })
}
