<template>
  <div class="d-flex flex-column">
    <v-slide-y-reverse-transition group>
      <common-snackbar
        v-for="(snackbar, i) in snackbars" :key="snackbar.id"
        :color="snackbar.color"
        :text="snackbar.text"
        :id="snackbar.id"
        :style="`margin-bottom: ${i * 58}px;`"
      ></common-snackbar>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommonSnackbar from './Snackbar.vue'

export default {
  name: 'SnackbarCollection',
  components: {
    CommonSnackbar
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        snackbars: state => state.snackbars.snackbars
      }
    )
  },
  methods: {},
  watch: {},
  mounted () {}
}
</script>
