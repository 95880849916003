<template>
  <div class="category-picker">
    <v-chip-group
      v-model="selectedCategoryChip"
      center-active
      show-arrows
      :column="isMobile"
      color="secondary"
    >
      <v-chip
        v-for="(category, i) in categories"
        :key="i"
        outlined
        :value="category.id"
      >
        <v-icon left>
          {{ category.icon_class_name }}
        </v-icon>
        {{ category.name }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryPicker',
  props: {
    selectedCategory: Object
  },
  data: () => ({}),
  computed: {
    ...mapGetters(
      {
        categories: 'position/activeCategories'
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    selectedCategoryChip: {
      get: function () {
        return this.selectedCategory ? this.selectedCategory.id : 0
      },
      set: function (id) {
        if (id !== undefined) {
          const category = this.categories.find(category => category.id === id)
          if (category) {
            this.$emit('set-category', category)
          } else {
            this.$emit('set-category', null)
          }
        } else {
          this.$emit('set-category', null)
        }
      }
    }
  }
}
</script>
