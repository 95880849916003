function convertSingleDate (dt) {
  if (dt != null) {
    return window.dayjs(dt)
  }
  return null
}

function toArray (val) {
  let arr = []
  if ((typeof val) === 'object') {
    if (Array.isArray(val)) {
      arr = val
    } else {
      arr.push(val)
    }
  } else {
    console.error('toArray: Unexpected datatype')
  }
  return arr
}

const convertDates = function (className, val) {
  if (className === 'Booking') {
    const bookings = toArray(val)
    bookings.forEach(booking => {
      booking.check_in = convertSingleDate(booking.check_in)
      booking.check_out = convertSingleDate(booking.check_out)
      booking.checked_in_at = convertSingleDate(booking.checked_in_at)
      booking.checked_out_at = convertSingleDate(booking.checked_out_at)
      booking.auto_checkout_at = convertSingleDate(booking.auto_checkout_at)
      booking.created_at = convertSingleDate(booking.created_at)
      booking.updated_at = convertSingleDate(booking.updated_at)
      booking.deleted_at = convertSingleDate(booking.deleted_at)
      booking.confirmed_at = convertSingleDate(booking.confirmed_at)
      booking.draft_expires_at = convertSingleDate(booking.draft_expires_at)
    })
  } else if (className === 'Report') {
    const reports = toArray(val)
    reports.forEach(report => {
      report.opened_at = convertSingleDate(report.opened_at)
      report.closed_at = convertSingleDate(report.closed_at)
      if (report.reconciliation) {
        report.reconciliation.completed_at = convertSingleDate(report.reconciliation.completed_at)
      }
    })
  } else if (className === 'ScbExportMonth') {
    const scbExportMonths = toArray(val)
    scbExportMonths.forEach(scbExportMonth => {
      scbExportMonth.month = convertSingleDate(scbExportMonth.month)
      if (scbExportMonth.export) {
        scbExportMonth.export.month = convertSingleDate(scbExportMonth.export.month)
        scbExportMonth.export.confirmed_by_user_at = convertSingleDate(scbExportMonth.export.confirmed_by_user_at)
      }
    })
  } else if (className === 'ScbExport') {
    const scbExports = toArray(val)
    scbExports.forEach(scbExport => {
      scbExport.month = convertSingleDate(scbExport.month)
      scbExport.confirmed_by_user_at = convertSingleDate(scbExport.confirmed_by_user_at)
    })
  } else if (className === 'Invoice') {
    const invoices = toArray(val)
    invoices.forEach(invoice => {
      invoice.invoice_date = convertSingleDate(invoice.invoice_date)
      invoice.created_at = convertSingleDate(invoice.created_at)
      invoice.due_date = convertSingleDate(invoice.due_date)
      invoice.deposit_due_date = convertSingleDate(invoice.deposit_due_date)
    })
  } else if (className === 'Receipt') {
    const receipts = toArray(val)
    receipts.forEach(receipt => {
      receipt.created_at = convertSingleDate(receipt.created_at)
    })
  } else if (className === 'ParkedOrder') {
    const orders = toArray(val)
    orders.forEach(order => {
      order.created_at = convertSingleDate(order.created_at)
      order.updated_at = convertSingleDate(order.updated_at)
    })
  } else if (className === 'Charge') {
    const charges = toArray(val)
    charges.forEach(charge => {
      charge.start = convertSingleDate(charge.start)
      charge.end = convertSingleDate(charge.end)
      charge.created_at = convertSingleDate(charge.created_at)
      charge.updated_at = convertSingleDate(charge.updated_at)
    })
  } else if (className === 'Email') {
    const emails = toArray(val)
    emails.forEach(email => {
      email.opened_at = convertSingleDate(email.opened_at)
      email.sent_at = convertSingleDate(email.sent_at)
    })
  } else if (className === 'CalendarItem') {
    const calendarItems = toArray(val)
    calendarItems.forEach(calendarItem => {
      if (calendarItem.accommodation_charge) {
        calendarItem.accommodation_charge.start = convertSingleDate(calendarItem.accommodation_charge.start)
        calendarItem.accommodation_charge.end = convertSingleDate(calendarItem.accommodation_charge.end)
      }
      if (calendarItem.suggested_accommodation_charge) {
        calendarItem.suggested_accommodation_charge.start = convertSingleDate(calendarItem.suggested_accommodation_charge.start)
        calendarItem.suggested_accommodation_charge.end = convertSingleDate(calendarItem.suggested_accommodation_charge.end)
      }
    })
  } else if (className === 'PositionBlock') {
    const positionBlocks = toArray(val)
    positionBlocks.forEach(positionBlocks => {
      positionBlocks.opening_date = convertSingleDate(positionBlocks.opening_date)
      positionBlocks.closing_date = convertSingleDate(positionBlocks.closing_date)
    })
  } else if (className === 'SalesReport') {
    const salesReports = toArray(val)
    salesReports.forEach(salesReport => {
      salesReport.period_date = convertSingleDate(salesReport.period_date)
      salesReport.last_downloaded_at = convertSingleDate(salesReport.last_downloaded_at)
    })
  }
}
const toHumanReadableDate = function (date, longFormat = false, capitalized = true) {
  const monthCode = longFormat ? 'MMMM' : 'MMM'
  const dayCode = longFormat ? 'dddd' : 'ddd'
  let str = ''
  if (date.isSame(date.startOf('day'))) {
    // Klockslag 00:00:00.000 - är date istället för datetime = vill inte visa klockslaget
    if (date.isToday()) {
      str = 'idag'
    } else if (date.isYesterday()) {
      str = 'igår'
    } else if (date.isTomorrow()) {
      str = 'imorgon'
    } else {
      if (date.isSame(window.dayjs(), 'year')) {
        str = date.format(`${dayCode} D ${monthCode}`)
      } else {
        str = date.format(`${dayCode} D ${monthCode} YYYY`)
      }
    }
  } else {
    if (date.isToday()) {
      str = `idag kl ${date.format('HH:mm')}`
    } else if (date.isYesterday()) {
      str = `igår kl ${date.format('HH:mm')}`
    } else if (date.isTomorrow()) {
      str = `imorgon kl ${date.format('HH:mm')}`
    } else {
      if (date.isSame(window.dayjs(), 'year')) {
        str = date.format(`${dayCode} D ${monthCode} HH:mm`)
      } else {
        str = date.format(`${dayCode} D ${monthCode} YYYY`)
      }
    }
  }
  if (capitalized) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } else {
    return str
  }
}

const diffInDays = function (firstDate, secondDate) {
  // Returnerar ett naturligt sätt att ange skillnaden i månader/veckor/dagar mellan två datum
  let days = secondDate.startOf('day').diff(firstDate.startOf('day'), 'days')
  days = Math.abs(days)
  let months = secondDate.startOf('day').diff(firstDate.startOf('day'), 'months')
  months = Math.abs(months)
  const weeks = Math.floor(days / 7)
  let additionalDays = 0

  if (days === 1) {
    return '1 natt'
  } else if (days === 7) {
    return '1 vecka'
  } else if (days < 14) {
    return days + ' nätter'
  } else if (firstDate.date() === secondDate.date() && months !== 0) {
    // samma datum, olika månad
    if (months === 1) {
      return '1 månad'
    } else {
      return months + ' månader'
    }
  } else if (months < 2) {
    if (days % 7 === 0) {
      return weeks + ' veckor'
    } else {
      const weekEndDate = firstDate.add(weeks * 7, 'days')
      additionalDays = secondDate.diff(weekEndDate, 'days')
      if (additionalDays === 1) {
        return `${weeks} veckor och 1 natt`
      }
      return `${weeks} veckor och ${additionalDays} nätter`
    }
  } else {
    /* if (firstDate.date() <= secondDate.date()) {
      days = secondDate.date() - firstDate.date()
    } else { */
    const monthEndDate = firstDate.add(months, 'months')
    additionalDays = secondDate.diff(monthEndDate, 'days')
    /* } */
    if (additionalDays === 0) {
      return `${months} månader`
    }
    if (additionalDays === 1) {
      return `${months} månader och 1 natt`
    }
    return `${months} månader och ${additionalDays} nätter`
  }
}

const toHumanReadableAmount = function (amount, minimumFractionDigits = 'auto') {
  // andra parametern kan sättas som en siffra som "minsta antal ören" att visa
  if (minimumFractionDigits === 'auto') {
    const hasDecimals = amount !== Math.round(amount)
    if (hasDecimals) {
      minimumFractionDigits = 2 // gör så visar två decimaler även om det hade "räckt" med en - för att "1,50 kr" ser bättre ut än "1,5 kr"
    } else {
      minimumFractionDigits = 0
    }
  }
  const formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits
  })
  return formatter.format(amount)
}

const hexToRgb = function (hex, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const slugify = function (str, separator = '-') {
  return str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
}

const fuseHighlight = (fuseSearchResult, highlightClassName = 'highlight') => {
  // från https://github.com/krisk/Fuse/issues/6#issuecomment-455813098
  const set = (obj, path, value) => {
    const pathValue = path.split('.')
    let i

    for (i = 0; i < pathValue.length - 1; i++) {
      obj = obj[pathValue[i]]
    }

    obj[pathValue[i]] = value
  }

  const generateHighlightedText = (inputText, regions = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0
    regions = regions.filter(i => {
      return i[1] + 1 - i[0] >= 3
    })

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<span class="${highlightClassName}">`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</span>'
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }

  return fuseSearchResult
    .filter(({ matches }) => matches && matches.length)
    .map(({ item, matches }) => {
      const highlightedItem = { ...item }

      matches.forEach((match) => {
        set(highlightedItem, match.key, generateHighlightedText(match.value, match.indices))
      })

      return highlightedItem
    })
}

export default {
  convertDates,
  toHumanReadableDate,
  diffInDays,
  toHumanReadableAmount,
  slugify,
  hexToRgb,
  fuseHighlight
}
