<template>
  <div class="">
    <template v-if="icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!openAsLink"
            :color="color"
            icon
            @click="downloadFile"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-cloud-download
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            :href="url"
            @click="clickedLink"
            target="_blank"
            icon
            :color="color"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </template>
        {{ buttonText }}
      </v-tooltip>
    </template>

    <template v-else>
      <v-btn
        v-if="!openAsLink"
        :color="color"
        @click="downloadFile"
      >
        {{ buttonText }}
        <v-icon
          right
        >
          mdi-cloud-download
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        :href="url"
        @click="clickedLink"
        target="_blank"
        :color="color"
      >
        {{ buttonText }}
        <v-icon
          right
        >
          mdi-cloud-download
        </v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DownloadButton',
  props: {
    url: String,
    buttonText: String, // buttonText visas som en tooltip om icon
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: Boolean,
      default: false
    },
    openInline: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isElectron: state => state.isElectron
      }
    ),
    openAsLink: function () {
      return !this.isElectron || this.openInline
    }
  },
  methods: {
    downloadFile: function () {
      window.shell.openExternalLink(this.url)
      this.$emit('downloaded')
    },
    clickedLink: function () {
      this.$emit('downloaded')
    }
  },
  watch: {}
}
</script>
