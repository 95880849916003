import API from './API.js'

export default {
  getAccommodationCharges ({ checkIn, checkOut, cancellationPolicyId, categoryId, occupancy, dogs }) {
    return API.httpClient.get('rates/accommodation-charges', {
      params: {
        check_in: checkIn.format('YYYY-MM-DD'),
        check_out: checkOut.format('YYYY-MM-DD'),
        cancellation_policy_id: cancellationPolicyId,
        category_id: categoryId,
        occupancy,
        dogs
      }
    })
  },
  getRebookingPreview ({ bookingId, checkIn, checkOut, cancellationPolicyId, categoryId, occupancy, dogs }) {
    return API.httpClient.get('rates/rebooking-charges', {
      params: {
        booking_id: bookingId,
        check_in: checkIn.format('YYYY-MM-DD'),
        check_out: checkOut.format('YYYY-MM-DD'),
        cancellation_policy_id: cancellationPolicyId,
        category_id: categoryId,
        occupancy,
        dogs
      }
    })
  }
}
