<template>
  <div
    class="accommodation-charges-calendar"
    :class="{
      'single-column': singleColumn,
      'has-warnings': suggestedAccommodationCharges.length > 0,
      'four-weeks': weeksInMonth === 4,
      'five-weeks': weeksInMonth === 5,
      'six-weeks': weeksInMonth === 6
    }">
    <v-row class="my-0">
      <v-col :cols="singleColumn ? 12 : 7" class="py-0 flex-shrink-0 flex-grow-1" style="align-items: stretch;">
        <v-card :loading="loading ? 'secondary' : false" class="calendar-card">
          <v-card-title v-if="singleColumn" class="pb-0 pt-2 text-center d-block">
            Platsavgiftskalender
          </v-card-title>
          <v-sheet>
            <v-toolbar
              flat
              dense
            >
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prevMonth"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                {{ monthFormatted }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="nextMonth"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-toolbar>
          </v-sheet>
          <v-calendar
            v-model="calendarDateInput"
            color="primary"
            show-week
            locale-first-day-of-year="4"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            @click:day="dayClicked"
            @mousedown:day="onmousedownDay"
            @mouseup:day="onmouseupDay"
            @mouseenter:day="onmouseenterDay"
          >
            <template v-slot:day-label="{ day, date, present, past }">
              <div class="day-label-container d-flex flex-column fill-height">
                <template v-if="!hasCalendarItem(date)">
                  <div class="day-label">{{ day }}</div>
                  <div style="pointer-events: none;" class="notmidbooking-day"></div>
                  <div></div>
                </template>

                <template v-else>
                  <div :class="{ 'day-label': true, 'inside-booking-period': getCalendarItem(date).is_mid_booking_inclusive || getCalendarItem(date).is_checked_in_day || getCalendarItem(date).is_checked_out_day }">{{ day }}</div>

                  <template v-if="getCalendarItem(date).is_checkin_day">
                    <div class="checkin-day"></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_checkout_day">
                    <div class="checkout-day"></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_mid_booking">
                    <div class="midbooking-day"></div>
                  </template>
                  <template v-else>
                    <div class="notmidbooking-day"></div>
                  </template>

                  <template v-if="getCalendarItem(date).is_checked_in_day && getCalendarItem(date).is_checked_out_day">
                    <div class="checked-in-and-checked-out-day"><span></span><span></span></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_checked_in_day">
                    <div class="checked-in-day"><span></span></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_checked_out_day">
                    <div class="checked-out-day"><span></span></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_mid_stay && (present || past)">
                    <div class="midstay-day"></div>
                  </template>
                  <template v-else-if="getCalendarItem(date).is_checkout_day && present">
                    <div class="checkout-day-not-checked-out-today"></div>
                  </template>
                </template>
              </div>
            </template>

            <template v-slot:day="{ date }">
              <v-tooltip bottom :disabled="dateTooltip(date) === ''" color="#555">
                <template v-slot:activator="{ on, attrs }">
                  <div :class="{ 'calendar-day': true, 'is-preselect': preselectedDates.includes(date), 'is-selected': selectedDates.includes(date), 'has-hover': hoveringDates.includes(date), 'first-day-of-many': getCalendarItem(date)?.accommodation_charge?.nights > 1 && isFirstChargeDayOfWeek(date) }" v-bind="attrs" v-on="on">

                    <template v-if="savingDates.includes(date) || dismissingDates.includes(date)">
                      <div class="day-loader">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          class="ma-auto"
                          color="white"
                        ></v-progress-circular>
                      </div>
                    </template>

                    <template v-if="!hasCalendarItem(date)">
                      <div></div>
                    </template>
                    <template v-else>
                      <template v-if="dayHasWarning(date)">
                        <div class="day-has-warning">
                          <v-icon class="ma-auto" size="38">
                            mdi-alert
                          </v-icon>
                        </div>
                      </template>

                      <template v-if="!!getCalendarItem(date).accommodation_charge">
                        <!-- Har accommodation charge -->
                        <template v-if="getCalendarItem(date).accommodation_charge.nights === 1">
                          <div :class="{ 'day-content text-body-2 mt-2': true, 'has-warning': dayHasWarning(date) }">
                            {{ formatPrice(getCalendarItem(date).accommodation_charge.price) }}
                          </div>
                        </template>
                        <template v-else>
                          <!-- Charge har flera nätter -->
                          <template v-if="isFirstChargeDayOfWeek(date)">
                            <div :class="{ 'day-content text-body-2 multi-night-charge mt-2': true, 'has-charges-previous-week': hasChargesPreviousWeek(date), 'has-charges-next-week': hasChargesNextWeek(date), 'has-warning': dayHasWarning(date) }" :style="`width:${chargeWidth(date)}`">
                              {{ formatPrice(getCalendarItem(date).accommodation_charge.price) }}
                            </div>
                          </template>
                          <template v-else>
                            <div class="day-content mt-2">&nbsp;</div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <!-- CalendarItem utan accommodation charge -->
                        <template v-if="isCheckoutDay(date)">
                          <!-- Checkout day är normalt att det finns calendarItem men inte accommodation charge -->
                          <div class="day-content text-body-2 mt-2">&nbsp;</div>
                        </template>
                        <template v-else>
                          <div class="day-content text-body-2 mt-2 has-warning">
                            0kr
                          </div>
                        </template>
                      </template>
                    </template>

                  </div>
                </template>
                <div v-html="dateTooltip(date)"></div>
              </v-tooltip>
            </template>
          </v-calendar>

          <div v-if="singleColumn" class="accommodation-charge-overlay-outer-container d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none;">
            <div @click="closeAccommodationChargeOverlay" class="overlay-padder" :class="{ 'show': isShowAccommodationChargeOverlay }"></div>
            <div :class="{ 'flex-grow-1 accommodation-charge-overlay': true, 'show': isShowAccommodationChargeOverlay}" style="pointer-events: auto;">
              <accommodation-charge-view
                overlay
                :loading="loading"
                :booking="booking"
                :calendar-items="calendarItems"
                :selected-dates="selectedDates"
                :is-open="isShowAccommodationChargeOverlay"
                @close="closeAccommodationChargeOverlay"
                @unselect-dates="selectDateRange([])"
                @set-hovering-dates="setHoveringDates"
                @set-saving-dates="setSavingDates"
                @set-dismissing-dates="setDismissingDates"
                @header-clicked="openAccommodationChargeOverlay"
              ></accommodation-charge-view>
            </div>
          </div>

        </v-card>
      </v-col>

      <template v-if="!singleColumn">
        <v-col cols="5" class="py-0">
          <accommodation-charge-view
            :loading="loading"
            :booking="booking"
            :calendar-items="calendarItems"
            :selected-dates="selectedDates"
            @unselect-dates="selectDateRange([])"
            @set-hovering-dates="setHoveringDates"
            @set-saving-dates="setSavingDates"
            @set-dismissing-dates="setDismissingDates"
          ></accommodation-charge-view>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import AccommodationChargeView from './AccommodationChargeView.vue'

export default {
  name: 'AccommodationChargesCalendar',
  components: {
    AccommodationChargeView
  },
  props: {
    booking: Object,
    calendarItems: Array,
    loading: Boolean,
    isOpen: Boolean,
    singleColumn: Boolean
  },
  data: () => ({
    calendarDate: window.dayjs().startOf('month'),
    mousedownDay: null,
    isMouseDown: false,
    preselectedDates: [],
    selectedDates: [],
    hoveringDates: [],
    savingDates: [],
    shouldRefreshStartingMonth: true,
    dismissingDates: [],
    isShowAccommodationChargeOverlay: false // endast applicerbart på singleColumn
  }),
  computed: {
    calendarDateInput: {
      get: function () {
        return this.calendarDate.format('YYYY-MM-DD')
      },
      set: function (val) {
        this.calendarDate = window.dayjs(val)
      }
    },
    weeksInMonth: function () {
      return this.calendarDate.weeksInMonth()
    },
    monthFormatted: function () {
      return this.calendarDate.format('MMMM YYYY')
    },
    suggestedAccommodationCharges: function () {
      return this.calendarItems.map(ci => ci.suggested_accommodation_charge).filter(ci => !!ci)
    },
    allAccommodationCharges: function () {
      return this.calendarItems.map(ci => ci.accommodation_charge).filter(ci => !!ci)
    }
  },
  methods: {
    prevMonth: function () {
      this.calendarDate = this.calendarDate.subtract(1, 'month')
    },
    nextMonth: function () {
      this.calendarDate = this.calendarDate.add(1, 'month')
    },
    setHoveringDates: function (val) {
      this.hoveringDates = val
    },
    setSavingDates: function (val) {
      this.savingDates = val
    },
    setDismissingDates: function (val) {
      this.dismissingDates = val
    },
    closeAccommodationChargeOverlay: function () {
      this.isShowAccommodationChargeOverlay = false
      this.selectDateRange([])
    },
    openAccommodationChargeOverlay: function () {
      this.isShowAccommodationChargeOverlay = true
    },
    hasCalendarItem: function (dt) {
      return !!this.getCalendarItem(dt)
    },
    getCalendarItem: function (dt) {
      return this.calendarItems.find(ci => ci.date === dt)
    },
    isFirstChargeDayOfWeek: function (dt) {
      const calendarItem = this.getCalendarItem(dt)
      const calendarItemsWithSameCharge = this.calendarItems.filter(ci => ci.accommodation_charge?.id === calendarItem.accommodation_charge.id)
      const calendarItemsEarlierSameWeek = calendarItemsWithSameCharge.filter(ci => ci.dayJsDate.isSame(calendarItem.dayJsDate, 'week') && ci.dayJsDate.isBefore(calendarItem.dayJsDate))
      return calendarItemsEarlierSameWeek.length === 0
    },
    chargeWidth: function (dt) {
      const calendarItem = this.getCalendarItem(dt)
      const calendarItemsWithSameCharge = this.calendarItems.filter(ci => ci.accommodation_charge?.id === calendarItem.accommodation_charge.id)
      const calendarItemsLaterSameWeek = calendarItemsWithSameCharge.filter(ci => ci.dayJsDate.isSame(calendarItem.dayJsDate, 'week') && ci.dayJsDate.isAfter(calendarItem.dayJsDate))

      const daysAfter = calendarItemsLaterSameWeek.length
      const hasDaysNextWeek = this.hasChargesNextWeek(dt)

      let percent = 100 + (100 * daysAfter)
      if (!hasDaysNextWeek) {
        percent = percent - 5
      }

      return `${percent}%`
    },
    hasChargesNextWeek: function (dt) {
      const calendarItem = this.getCalendarItem(dt)
      const calendarItemsWithSameCharge = this.calendarItems.filter(ci => ci.accommodation_charge?.id === calendarItem.accommodation_charge.id)
      const calendarItemsLaterNotSameWeek = calendarItemsWithSameCharge.filter(ci => !ci.dayJsDate.isSame(calendarItem.dayJsDate, 'week') && ci.dayJsDate.isAfter(calendarItem.dayJsDate))
      return calendarItemsLaterNotSameWeek.length > 0
    },
    hasChargesPreviousWeek: function (dt) {
      const calendarItem = this.getCalendarItem(dt)
      const calendarItemsWithSameCharge = this.calendarItems.filter(ci => ci.accommodation_charge?.id === calendarItem.accommodation_charge.id)
      const calendarItemsEarlierNotSameWeek = calendarItemsWithSameCharge.filter(ci => !ci.dayJsDate.isSame(calendarItem.dayJsDate, 'week') && ci.dayJsDate.isBefore(calendarItem.dayJsDate))
      return calendarItemsEarlierNotSameWeek.length > 0
    },
    updateCalendarStartDate: function () {
      if (this.calendarItems.length > 0) {
        const firstDate = window.dayjs(this.calendarItems[0].date)
        this.calendarDate = firstDate.startOf('month')
      }
      this.selectedDates = []
    },
    dayHasWarning: function (dt) {
      const date = window.dayjs(dt)
      return this.suggestedAccommodationCharges.findIndex(ac => ac.start.isSameOrBefore(date) && ac.end.isAfter(date)) >= 0
    },
    isCheckoutDay: function (dt) {
      if (!this.booking) {
        return false
      }
      const calendarItem = this.getCalendarItem(dt)
      const today = window.dayjs().startOf('day')
      if (this.booking.checked_out_at) {
        // utcheckad bokning
        return calendarItem.is_checked_out_day
      } else if (this.booking.deleted_at || !this.booking.checked_in_at || this.booking.check_out.isSameOrAfter(today)) {
        // avbokad, ej incheckad, eller incheckad men inte försenad
        return calendarItem.is_checkout_day
      } else {
        // Försenad bokning
        return dt === today.format('YYYY-MM-DD')
      }
    },
    formatPrice: function (price) {
      const roundedPrice = Math.round(price / 100)
      return `${roundedPrice}kr`
    },
    dateTooltip: function (dt) {
      let tooltip = ''
      const calendarItem = this.getCalendarItem(dt)

      dt = window.dayjs(dt)
      const isPresent = window.dayjs().isSame(dt, 'date')
      const isPast = window.dayjs().isAfter(dt)
      if (isPresent) {
        tooltip += 'Dagens datum<br>'
      }
      if (!calendarItem) {
        return tooltip
      }
      if (calendarItem.is_checked_in_day) {
        tooltip += 'Bokningen checkades in<br>'
      } else if (calendarItem.is_checkin_day) {
        tooltip += 'Incheckningsdagen<br>'
      }
      if (calendarItem.is_mid_stay && (isPresent || isPast)) {
        tooltip += 'Incheckad<br>'
      }
      if (calendarItem.is_checked_out_day) {
        tooltip += 'Bokningen checkades ut<br>'
      } else if (calendarItem.is_checkout_day) {
        tooltip += 'Utcheckningsdagen<br>'
      }

      if (calendarItem.accommodation_charge) {
        if (calendarItem.accommodation_charge.note !== null) {
          tooltip += `${calendarItem.accommodation_charge.note} `
        }
        if (calendarItem.accommodation_charge.nights === 1) {
          tooltip += `${calendarItem.accommodation_charge.price / 100}kr<br>`
        } else {
          tooltip += `${calendarItem.accommodation_charge.price / 100}kr, ${calendarItem.accommodation_charge.nights} nätter<br>`
        }
      }
      return tooltip
    },
    dayClicked: function (dt) {
      if (this.selectedDates.length === 1 && this.selectedDates[0] === dt.date) {
        // Om valt datum redan är det enda valda datumet så väljs den bort istället
        this.selectDateRange([])
      } else {
        this.selectDateRange([dt.date])
      }
    },
    onmousedownDay: function (dt) {
      // this.selectDateRange([])
      this.isMouseDown = true
      this.mousedownDay = window.dayjs(dt.date)
    },
    onmouseupDay: function (dt) {
      this.isMouseDown = false
      if (this.preselectedDates.length > 0) {
        this.selectDateRange(this.preselectedDates)
        this.preselectedDates = []
      }
    },
    onmouseenterDay: function (dt) {
      if (this.isMouseDown) {
        dt = window.dayjs(dt.date)
        let iteratedDate
        let endDate
        if (dt.isBefore(this.mousedownDay)) {
          iteratedDate = dt.clone()
          endDate = this.mousedownDay.clone()
        } else {
          iteratedDate = this.mousedownDay.clone()
          endDate = dt.clone()
        }

        const preselectedDates = []
        while (iteratedDate.isSameOrBefore(endDate)) {
          preselectedDates.push(iteratedDate.format('YYYY-MM-DD'))
          iteratedDate = iteratedDate.add(1, 'day')
        }
        this.preselectedDates = preselectedDates.slice()
      }
    },
    selectDateRange: function (dates) {
      const selectedDates = dates

      // Om man väljer en dag med en accommodationcharge som innehåller fler dagar, så väljs alla dagar
      const addDates = []
      selectedDates.forEach(selectedDate => {
        selectedDate = window.dayjs(selectedDate)
        const accommodationCharge = this.allAccommodationCharges.find(ac => ac.start.isSameOrBefore(selectedDate) && ac.end.isAfter(selectedDate))
        if (accommodationCharge && accommodationCharge.nights > 1) {
          let iteratedDate = accommodationCharge.start.clone()
          while (iteratedDate.isBefore(accommodationCharge.end)) {
            addDates.push(iteratedDate.format('YYYY-MM-DD'))
            iteratedDate = iteratedDate.add(1, 'days')
          }
        }
      })
      addDates.forEach(dt => {
        if (!selectedDates.includes(dt)) {
          selectedDates.push(dt)
        }
      })

      selectedDates.sort((a, b) => {
        // b före a = 1, a före b = -1
        return window.dayjs(a).isBefore(window.dayjs(b)) ? -1 : 1
      })

      this.selectedDates = selectedDates

      if (this.selectedDates.length > 0) {
        this.isShowAccommodationChargeOverlay = true
      }
    }
  },
  watch: {
    calendarItems: function () {
      if (this.selectedDates.length === 0 && this.shouldRefreshStartingMonth) {
        this.updateCalendarStartDate()
        this.shouldRefreshStartingMonth = false
      }
    },
    isOpen: function () {
      this.shouldRefreshStartingMonth = true
      if (!this.isOpen) {
        this.selectDateRange([])
      }
    }
  },
  mounted () {
    this.updateCalendarStartDate()
  }
}
</script>
