import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import sv from 'vuetify/es5/locale/sv'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.blueGrey.lighten1,
        secondary: '#21C2EC', /* Den blå färgen i CampingOnline loggan */
        /* #21c2ec RGB(33, 194, 236) secondary darken-2 */
        error: '#FF5252',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    }
  },
  lang: {
    locales: { sv },
    current: 'sv'
  }
})
