<template>
  <div class="booking-cancellation-manual-refund full-height">
    <div class="card-inner-container pa-0 full-height">
      <div class="d-flex flex-column full-height">
        <div class="d-flex pt-1">
          <v-switch
            v-if="!isMobile"
            v-model="inputModeAmount"
            color="secondary"
            label="Ange återbetalning i kronor"
            hide-details
            class="mb-4 mt-auto"
            style="margin-left: 240px;"
          ></v-switch>
          <div class="text-subtitle-2 ml-auto mr-8 mb-4 mt-auto">Återbetalning</div>
        </div>
        <div class="overflow-y-auto px-4" style="max-height: calc(100% - 38px);">
          <template v-if="loading">
            <div v-for="i in 3" :key="i">
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(productCategory, i) in data"
              :key="i"
            >
              <v-divider></v-divider>
              <div class="d-flex highlight-on-hover py-4 px-4 rounded" :class="{ 'flex-column': isMobile }">
                <div class="d-flex flex-column justify-center" style="max-width: 200px; min-width: 200px;">
                  <div class="text-subtitle-1">{{ productCategory.title }}</div>
                  <div class="text-h5">{{ formatAmount(productCategory.total) }}</div>
                </div>
                <div key="0" class="d-flex flex-grow-1 my-auto mt-4 mt-lg-auto">
                  <refund-input
                    :input-mode-amount="inputModeAmount && productCategory.product_category === 'accommodation'"
                    :product-category="productCategory"
                    @set-refund-percent="setRefundPercent"
                    @set-refund-amount="setRefundAmount"
                  ></refund-input>
                </div>
                <div class="my-auto d-flex flex-column mt-4 mt-lg-auto" style="max-width: 120px; min-width: 120px;">
                  <div class="text-subtitle-2 ml-lg-auto">{{ productCategory.refund_percent }}%</div>
                  <div class="text-subtitle-2 ml-lg-auto">{{ formatAmount(productCategory.refund_amount) }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RefundInput from '@/components/cancellation-policy/RefundInput.vue'

export default {
  name: 'BookingCancellationManualRefund',
  components: {
    RefundInput
  },
  props: {
    loading: Boolean,
    data: Array
  },
  data: () => ({
    inputModeAmount: false
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    setRefundPercent: function ({ productCategory, val }) {
      if (val > 100) {
        val = 100
      }
      if (val < 0) {
        val = 0
      }
      const refundPercent = val
      const refundAmount = Math.round(productCategory.total * val / 100)
      productCategory.refund_percent = refundPercent
      productCategory.refund_amount = refundAmount
    },
    setRefundAmount: function ({ productCategory, val }) {
      val = Math.round(val)
      if (val > productCategory.total) {
        val = productCategory.total
      }
      if (val < 0) {
        val = 0
      }

      const refundPercent = Math.round(val / productCategory.total * 100)
      const refundAmount = val
      productCategory.refund_percent = refundPercent
      if (productCategory.refund_amount === refundAmount) {
        // Ser konstigt ut, det är för att vuetify komponenten med beloppet ska uppdateras korrekt när beloppet ändras i denna metod t.ex. pga att det är för högt belopp
        // OBS: kan inte ange belopp med decimaler pga detta (om man skriver in kommat i slutet av stängen), men tror inte vi behöver stödja ören
        productCategory.refund_amount = 0
        this.$nextTick(() => {
          productCategory.refund_amount = refundAmount
        })
      } else {
        productCategory.refund_amount = refundAmount
      }
    },

    formatAmount: function (val) {
      return window.conversion.toHumanReadableAmount(val / 100)
    }
  },
  watch: {
    loading: function () {
      if (this.loading) {
        // återställer till procent input när går in på tab igen
        this.inputModeAmount = false
      }
    }
  }
}
</script>
