<template>
  <div class="booking-cancellation-completed">
    <div class="card-inner-container px-4 pb-4">
      <div>
        <div class="text-subtitle-1 font-weight-medium py-4 ml-4">{{ cancellationPolicy?.name }}</div>
        <cancellation-policy-timeline
          :horizontal="!isMobile"
          :is-open="show && !!bookingCancellation"
          :booking-id="bookingCancellation?.booking.id"
          :policy-id="cancellationPolicy?.id"
        ></cancellation-policy-timeline>
      </div>

      <div v-show="!!selectedOptionText" class="d-flex px-8 mt-4">
        <div class="d-flex flex-column flex-grow-1">
          <div class="text-h5">{{ selectedOptionText }}</div>
        </div>
      </div>

      <div class="d-flex flex-column mt-2" style="height: calc(100% - 204px - 48px - 158px);">
        <div class="text-subtitle-2 ml-auto mr-8">Återbetalning</div>
        <div class="overflow-y-auto px-4" style="max-height: calc(100% - 22px);">
          <div
            v-for="(productCategory, i) in finalSpecification"
            :key="i"
            class="d-flex highlight-on-hover py-2 px-4 rounded"
          >
            <div class="d-flex flex-column flex-grow-1">
              <div class="text-subtitle-1">{{ productCategory.title }}</div>
              <div class="text-h5">{{ formatAmount(productCategory.total) }}</div>
            </div>
            <div class="my-auto d-flex flex-column">
              <template v-if="productCategory.refund_percent !== null">
                <div class="text-subtitle-1 text-right">{{ productCategory.refund_percent }}%</div>
                <div class="text-subtitle-1 text-right">{{ refundedAmount(productCategory) }}</div>
              </template>
              <template v-else>
                <div class="text-subtitle-1 text-right">{{ formatAmount(productCategory.refund_amount) }}</div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="px-8 mt-8">
        <v-alert text type="info" :class="{ 'alert-center-icon': !isMobile }">
          <div class="d-flex flex-column flex-lg-row">
            <div class="flex-grow-1 my-auto pr-4">
              <div v-html="changeDescription"></div>
            </div>
          </div>
        </v-alert>
      </div>

    </div>
  </div>
</template>

<script>
import CancellationPolicyTimeline from '@/components/cancellation-policy/CancellationPolicyTimeline.vue'

export default {
  name: 'BookingCancellationCompleted',
  components: {
    CancellationPolicyTimeline
  },
  props: {
    show: Boolean,
    bookingCancellation: Object
  },
  data: () => ({}),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    cancellationPolicy: function () {
      return this.bookingCancellation?.booking.cancellation_policy
    },
    selectedOptionText: function () {
      return this.bookingCancellation?.completed_page?.selected_option_text
    },
    changeDescription: function () {
      return this.bookingCancellation?.completed_page?.change_description
    },
    finalSpecification: function () {
      if (!this.bookingCancellation?.completed_page?.final_specification) {
        return []
      }
      return this.bookingCancellation.completed_page.final_specification
    }
  },
  methods: {
    formatAmount: function (amount) {
      if (amount === null || amount === undefined) {
        return 'N/A'
      }
      return window.conversion.toHumanReadableAmount(amount / 100)
    },
    refundedAmount: function (productCategory) {
      if (!productCategory.refund_percent) {
        return this.formatAmount(0)
      }
      let amount = productCategory.total * (productCategory.refund_percent / 100)
      amount = Math.round(amount)
      return this.formatAmount(amount)
    }
  }
}
</script>
