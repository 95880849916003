<template>
  <div class="booking-cancellation-document-request">
    <div class="card-inner-container px-8 pb-4 pt-2">
      <h4>Alternativet "förmildrande omständigheter föreligger" har valts</h4>
      <p>
        Detta alternativet kräver att gästen styrker omständigheterna med intyg från t.ex. läkare, myndighet eller försäkringsbolag.
      </p>
      <p>
        Du kan maila en intygsförfrågan till gästen med instruktioner om att skicka in intyget med email, och sen avsluta avbokningen när detta är gjort. Du hittar avbokningar som inväntar intyg på sidan "Avbokningar".
      </p>
      <v-btn
        @click="sendDocumentRequest"
        :loading="isSendingRequest"
        :disabled="isApproving || isDenying"
        outlined
        block
        large
        color="secondary"
        class="flex-grow-1 mb-4"
        :class="{ 'btn-two-rows': isMobile }"
      >
        Maila instruktioner att skicka in intyg
      </v-btn>
      <div class="d-flex mt-4" :class="{ 'flex-column': isMobile }">
        <v-btn
          @click="close"
          :disabled="isApproving || isDenying || isSendingRequest"
          text
          large
          color="primary"
          class="flex-grow-1"
          :class="{ 'mt-2': isMobile, 'mr-2': !isMobile }"
        >
          Invänta intyg
        </v-btn>
        <v-btn
          @click="denyDocumentRequest"
          :loading="isDenying"
          :disabled="isApproving || isSendingRequest"
          text
          large
          color="error"
          class="flex-grow-1"
          :class="{ 'mt-2': isMobile, 'ml-2': !isMobile }"
        >
          Neka intyg
        </v-btn>
        <v-btn
          @click="approveDocumentRequest"
          :loading="isApproving"
          :disabled="isDenying || isSendingRequest"
          text
          large
          color="success"
          class="flex-grow-1 ml-2"
          :class="{ 'mt-2': isMobile, 'ml-2': !isMobile }"
        >
          Godkänn intyg
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CancellationPolicyService from '@/services/CancellationPolicyService'

export default {
  name: 'BookingCancellationPreview',
  components: {},
  props: {
    bookingCancellation: Object
  },
  data: () => ({
    isSendingRequest: false,
    isApproving: false,
    isDenying: false
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    sendDocumentRequest: function () {
      this.isSendingRequest = true
      CancellationPolicyService.sendDocumentRequest({ bookingCancellationId: this.bookingCancellation.id })
        .then(({ data }) => {
          if (data.status === 'success') {
            this.$store.commit('booking/SET_DIALOG_OPEN_TAB', { tabIndex: 'emails' })
            this.$store.commit('booking/SET_IS_AWAITING_EMAIL_SAVED_BOOKING_ID', { bookingId: this.bookingCancellation.booking_id, val: true })
            this.isSendingRequest = false
            this.$emit('close')
          }
        })
        .finally(() => {
          this.isSendingRequest = false
        })
    },
    approveDocumentRequest: function () {
      this.isApproving = true
      this.$store.dispatch('cancellationPolicy/confirmDocumentRequest', {
        bookingCancellationId: this.bookingCancellation.id,
        hasValidDocument: true
      })
        .finally(() => {
          this.isApproving = false
          this.$emit('confirmed')
        })
    },
    denyDocumentRequest: function () {
      this.isDenying = true
      this.$store.dispatch('cancellationPolicy/confirmDocumentRequest', {
        bookingCancellationId: this.bookingCancellation.id,
        hasValidDocument: false
      })
        .finally(() => {
          this.isDenying = false
          this.$emit('confirmed')
        })
    },
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
