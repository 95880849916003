<template>
  <div class="access-card-icon">
    <svg :class="{ 'active': active }" :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path class="beam top-beam" d="M6.34 6.575L4.93 5.165C8.84 1.245 15.17 1.245 19.07 5.165L17.66 6.575C14.53 3.445 9.46 3.445 6.34 6.575Z"/>
      <path class="beam bottom-beam" d="M9.17 9.74L7.76 8.33C10.1 5.99 13.9 5.99 16.24 8.33L14.83 9.74C13.26 8.18 10.73 8.18 9.17 9.74Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 11.74C6.53043 11.74 18 11.74 18 11.74C18.5304 11.74 19.0391 11.9507 19.4142 12.3258C19.7893 12.7009 20 13.2096 20 13.74V21.74C20 22.2704 19.7893 22.7791 19.4142 23.1542C19.0391 23.5293 18.5304 23.74 18 23.74H6C5.46957 23.74 4.96086 23.5293 4.58579 23.1542C4.21071 22.7791 4 22.2704 4 21.74C4 21.74 4 14.2704 4 13.74C4 13.2096 4.21071 12.7009 4.58579 12.3258C4.96086 11.9507 5.46957 11.74 6 11.74ZM6 21.74H9H12H18V20.24V18.74V17.74V15.74V14.74V13.74H6V14.74V15.74V17.74V18.74V21.74Z"/>
      <path d="M9.91667 18.5733C9.45833 18.5733 9.08333 18.1983 9.08333 17.74C9.08333 17.2817 9.45833 16.9067 9.91667 16.9067C10.375 16.9067 10.75 17.2817 10.75 17.74C10.75 18.1983 10.375 18.5733 9.91667 18.5733ZM12.25 16.9067C11.9167 15.9483 11 15.24 9.91667 15.24C8.54167 15.24 7.41667 16.365 7.41667 17.74C7.41667 19.115 8.54167 20.24 9.91667 20.24C11 20.24 11.9167 19.5317 12.25 18.5733H13.6667V20.24H15.3333V18.5733H16.5833V16.9067H12.25Z"/>
    </svg>
  </div>
</template>

<style lang="scss">
  .access-card-icon {
    svg {
      fill: rgba(0, 0, 0, 0.54);
      &.active {
        path {
          fill: var(--v-success-base) !important;
        }
        @keyframes hideshow1 {
          0% { opacity: 0; }
          25% { opacity: 0; }
          50% { opacity: 1; }
          75% { opacity: 1; }
          100% { opacity: 1; }
        }
        @keyframes hideshow2 {
          0% { opacity: 0; }
          25% { opacity: 0; }
          50% { opacity: 0; }
          75% { opacity: 1; }
          100% { opacity: 1; }
        }
        .bottom-beam {
          animation: hideshow1 2s ease infinite;
        }
        .top-beam {
          animation: hideshow2 2s ease infinite;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'AccessCardIcon',
  props: {
    active: Boolean,
    size: {
      type: String,
      default: function () { return '60' }
    }
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>
