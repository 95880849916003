<template>
  <v-form
    @submit.prevent
    ref="formOrgNumber"
  >
    <v-text-field
      label="Organisationsnummer*"
      v-model.trim="orgNumber"
      :error-messages="errorMessages"
      :loading="validation.isLoading"
      :class="{
        'sub-errors-for-warning warning--text': validation.hasWarning
      }"
    ></v-text-field>
  </v-form>
</template>

<script>
import ValidationService from '@/services/ValidationService.js'

export default {
  name: 'CustomerInputOrgNumber',
  components: {},
  props: {
    value: String,
    forceValidation: Number,
    customerId: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    originalOrgNumber: ''
  }),
  computed: {
    orgNumber: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    errorMessages: function () {
      if (this.validation.errorMessage) {
        return [this.validation.errorMessage]
      } else {
        return []
      }
    }
  },
  methods: {
    resetValidation: function () {
      this.validation.hasChanges = false
      this.validation.isLoading = false
      this.validation.isValidated = false
      this.validation.hasError = false
      this.validation.hasWarning = false
      this.validation.errorMessage = ''
      this.validation.suggestions = []
    },
    validateOrgNumber: function () {
      const orgNumber = this.orgNumber
      this.resetValidation()

      if (orgNumber == null || orgNumber === '') {
        this.validation.errorMessage = 'Fyll i organisationsnummer'
        this.validation.isValidated = true
        this.validation.hasError = true
      } else if (orgNumber.length > 20) {
        this.validation.errorMessage = 'Organisationsnummer kan vara max 20 tecken'
        this.validation.isValidated = true
        this.validation.hasError = true
      } else {
        this.validation.isLoading = true
        ValidationService.validateOrgNumber(orgNumber)
          .then(({ data }) => {
            if (this.orgNumber === orgNumber) {
              if (data.status === 'error') {
                // orgnummer finns redan i databasen
                const customer = data.data.suggestions[0]
                if (customer.id !== this.customerId) {
                  this.validation.hasError = true
                  this.validation.suggestions.push({
                    customer,
                    reason: 'Samma organisationsnummer'
                  })
                  this.validation.errorMessage = 'Organisationsnumret används av: ' + customer.full_name
                }
              }
            }
          }).finally(() => {
            if (this.orgNumber === orgNumber) {
              this.validation.isValidated = true
              this.validation.isLoading = false
              this.validation.hasChanges = (this.originalOrgNumber === orgNumber)
              this.$emit('set-validation', this.validation)
            }
          })
      }
      this.$emit('set-validation', this.validation)
    }
  },
  watch: {
    orgNumber: function () {
      this.validateOrgNumber()
    },
    forceValidation: function () {
      this.validateOrgNumber()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  mounted () {
    this.originalOrgNumber = this.orgNumber
  }
}
</script>
