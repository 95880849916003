import ProductService from '@/services/ProductService.js'
export const namespaced = true

export const state = {
  products: [],
  productGroups: [],
  productLayoutTabs: [],
  isFetchingProductLayoutTabs: false,
  isFetchingProducts: false,
  isFetchingProductGroups: false,
  deletingProductIds: [],
  deletingProductGroupIds: [],
  scannerInput: '',
  isLoadingUpdateBarcodeProductIds: [],
  isLoadingUpdatePriceProductIds: [],
  isLoadingUpdateColorProductIds: [],
  isLoadingStoreProductProductIds: [],
  isLoadingSaveLayoutButtonButtons: [],
  availableProductColors: ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey'], // detta är en enum i databasen
  showFormDialog: false,
  editedProduct: null
}

export const mutations = {
  SET_PRODUCTS (state, val) {
    state.products = val
  },
  SET_IS_FETCHING_PRODUCTS (state, val) {
    state.isFetchingProducts = val
  },
  SET_IS_FETCHING_PRODUCT_GROUPS (state, val) {
    state.isFetchingProductGroups = val
  },
  SET_SHOW_FORM_DIALOG (state, val) {
    state.showFormDialog = val
  },
  REPLACE_OR_INSERT_PRODUCT (state, product) {
    let i = state.products.findIndex(arrayProduct => arrayProduct.id === product.id)
    if (i >= 0) {
      state.products.splice(i, 1, product)
    } else {
      state.products.push(product)
    }
    // Ersätter produkten i productGroup.products också, obs fungerar bara om har samma product group som tidigare, tror inte product group får ändras utan att product får ny id
    const productGroup = state.productGroups.find(arrayProductGroup => arrayProductGroup.id === product.product_group_id)
    if (productGroup) {
      i = productGroup.products.findIndex(arrayProduct => arrayProduct.id === product.id)
      if (i >= 0) {
        productGroup.products.splice(i, 1, product)
      } else {
        productGroup.products.push(product)
      }
    }

    // Uppdaterar product layouter eftersom de är berikade med produkterna, och de uppdateras inte för att produkten byts ut i en array
    window.enrich.enrichProductLayoutTab(state.productLayoutTabs)
  },
  REMOVE_PRODUCT (state, product) {
    let i = state.products.findIndex(arrayProduct => arrayProduct.id === product.id)
    if (i >= 0) {
      state.products.splice(i, 1)
    }
    // Tar bort produkten i productGroup.products också, obs fungerar bara om har samma product group som tidigare, tror inte product group får ändras utan att product får ny id
    const productGroup = state.productGroups.find(arrayProductGroup => arrayProductGroup.id === product.product_group_id)
    if (productGroup) {
      i = productGroup.products.findIndex(arrayProduct => arrayProduct.id === product.id)
      if (i >= 0) {
        productGroup.products.splice(i, 1)
      }
    }
  },
  SET_PRODUCT_GROUPS (state, val) {
    state.productGroups = val
  },
  SET_DELETING_PRODUCT (state, val) {
    state.deletingProductIds.push(val)
  },
  SET_DELETING_PRODUCT_GROUP (state, val) {
    state.deletingProductGroupIds.push(val)
  },
  SET_SCANNER_INPUT (state, val) {
    state.scannerInput = val
  },
  REMOVE_DELETING_PRODUCT (state, val) {
    state.deletingProductIds.splice(state.deletingProductIds.indexOf(val), 1)
  },
  REMOVE_DELETING_PRODUCT_GROUP (state, val) {
    state.deletingProductGroupIds.splice(state.deletingProductGroupIds.indexOf(val), 1)
  },
  SET_IS_LOADING_UPDATE_BARCODE (state, payload) {
    if (payload.val) {
      const i = state.isLoadingUpdateBarcodeProductIds.findIndex(id => id === payload.productId)
      if (i < 0) {
        state.isLoadingUpdateBarcodeProductIds.push(payload.productId)
      }
    } else {
      const i = state.isLoadingUpdateBarcodeProductIds.findIndex(id => id === payload.productId)
      state.isLoadingUpdateBarcodeProductIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_UPDATE_PRICE (state, payload) {
    if (payload.val) {
      const i = state.isLoadingUpdatePriceProductIds.findIndex(id => id === payload.productId)
      if (i < 0) {
        state.isLoadingUpdatePriceProductIds.push(payload.productId)
      }
    } else {
      const i = state.isLoadingUpdatePriceProductIds.findIndex(id => id === payload.productId)
      state.isLoadingUpdatePriceProductIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_UPDATE_COLOR (state, payload) {
    if (payload.val) {
      const i = state.isLoadingUpdateColorProductIds.findIndex(id => id === payload.productId)
      if (i < 0) {
        state.isLoadingUpdateColorProductIds.push(payload.productId)
      }
    } else {
      const i = state.isLoadingUpdateColorProductIds.findIndex(id => id === payload.productId)
      state.isLoadingUpdateColorProductIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_STORE_PRODUCT (state, payload) {
    if (payload.val) {
      const i = state.isLoadingStoreProductProductIds.findIndex(id => id === payload.productId)
      if (i < 0) {
        state.isLoadingStoreProductProductIds.push(payload.productId)
      }
    } else {
      const i = state.isLoadingStoreProductProductIds.findIndex(id => id === payload.productId)
      state.isLoadingStoreProductProductIds.splice(i, 1)
    }
  },
  SET_IS_LOADING_SAVE_LAYOUT_BUTTON (state, payload) {
    if (payload.val) {
      const i = state.isLoadingSaveLayoutButtonButtons.findIndex(btn => btn.id === payload.button.id && btn.product_layout_tab_id === payload.button.product_layout_tab_id && btn.row === payload.button.row && btn.column === payload.button.column)
      if (i < 0) {
        state.isLoadingSaveLayoutButtonButtons.push(payload.button)
      }
    } else {
      const i = state.isLoadingSaveLayoutButtonButtons.findIndex(btn => btn.id === payload.button.id && btn.product_layout_tab_id === payload.button.product_layout_tab_id && btn.row === payload.button.row && btn.column === payload.button.column)
      state.isLoadingSaveLayoutButtonButtons.splice(i, 1)
    }
  },
  REPLACE_OR_INSERT_LAYOUT_BUTTON (state, button) {
    const productLayoutTab = state.productLayoutTabs.find(productLayoutTab => productLayoutTab.id === button.product_layout_tab_id)
    if (productLayoutTab) {
      const i = productLayoutTab.product_layout_buttons.findIndex(productLayoutButton => productLayoutButton.id === button.id)
      if (i >= 0) {
        productLayoutTab.product_layout_buttons.splice(i, 1, button)
      } else {
        productLayoutTab.product_layout_buttons.push(button)
      }
    }
  },
  REMOVE_LAYOUT_BUTTON (state, button) {
    const productLayoutTab = state.productLayoutTabs.find(productLayoutTab => productLayoutTab.id === button.product_layout_tab_id)
    if (productLayoutTab) {
      const i = productLayoutTab.product_layout_buttons.findIndex(productLayoutButton => productLayoutButton.id === button.id)
      if (i >= 0) {
        productLayoutTab.product_layout_buttons.splice(i, 1)
      }
    }
  },
  SET_EDITED_PRODUCT (state, val) {
    state.editedProduct = val
  },
  SET_PRODUCT_LAYOUT_TABS (state, val) {
    state.productLayoutTabs = val
  },
  SET_IS_FETCHING_PRODUCT_LAYOUT_TABS (state, val) {
    state.isFetchingProductLayoutTabs = val
  }
}

export const actions = {
  scannedBarcode ({ state, dispatch }, barcode) {
    const product = state.products.find(prod => prod.barcode === barcode)
    if (product) {
      // Hittade en sparad product med skannad barcode
      dispatch('posCart/addToCart', product, { root: true })
    } else {
      // Hittade ingen product
      dispatch('openFormDialogWithBarcode', barcode)
    }
  },
  openFormDialogWithBarcode ({ commit }, barcode) {
    const newProduct = {
      id: 0,
      barcode,
      deposit_price: null,
      gui_color: 'blue',
      is_bulk: 0,
      name: '',
      price: 0,
      product_group_id: null,
      unit_id: 1
    }
    commit('SET_EDITED_PRODUCT', newProduct)
    commit('SET_SHOW_FORM_DIALOG', true)
  },
  openFormDialogWithProductGroupId ({ commit }, productGroupId) {
    const newProduct = {
      id: 0,
      barcode: null,
      deposit_price: null,
      gui_color: 'blue',
      is_bulk: 0,
      name: '',
      price: 0,
      product_group_id: productGroupId,
      unit_id: 1
    }
    commit('SET_EDITED_PRODUCT', newProduct)
    commit('SET_SHOW_FORM_DIALOG', true)
  },
  barcodeInputRequest ({ commit }, { input }) {
    commit('SET_SCANNER_INPUT', input)
  },
  reloadProducts ({ commit, dispatch }) {
    // Hämtar om products och tillhörande, product groups och product layout tabs
    commit('SET_IS_LOADING_PAGE', true, { root: true })
    return Promise.allSettled([
      dispatch('fetchProducts'),
      dispatch('fetchProductGroups'),
      dispatch('fetchProductLayoutTabs')
    ])
      .then(() => {
        return Promise.allSettled([
          dispatch('enrichProducts'),
          dispatch('enrichProductGroups'),
          dispatch('enrichProductLayoutTabs')
        ])
          .finally(() => {
            commit('SET_IS_LOADING_PAGE', false, { root: true })
          })
      })
      .catch(() => {
        commit('SET_IS_LOADING_PAGE', false, { root: true })
      })
  },
  fetchProducts ({ commit }) {
    commit('SET_IS_FETCHING_PRODUCTS', true)
    return ProductService.getProducts()
      .then(({ data }) => {
        commit('SET_PRODUCTS', data.data.products)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_PRODUCTS', false)
      })
  },
  enrichProducts ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichProduct(state.products)
      resolve(true)
    })
  },
  fetchProductGroups ({ commit }) {
    commit('SET_IS_FETCHING_PRODUCT_GROUPS', true)
    return ProductService.getProductGroups()
      .then(({ data }) => {
        commit('SET_PRODUCT_GROUPS', data.data.product_groups)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_PRODUCT_GROUPS', false)
      })
  },
  enrichProductGroups ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichProductGroup(state.productGroups)
      resolve(true)
    })
  },
  fetchProductLayoutTabs ({ commit }) {
    commit('SET_IS_FETCHING_PRODUCT_LAYOUT_TABS', true)
    return ProductService.getProductLayoutTabs()
      .then(({ data }) => {
        commit('SET_PRODUCT_LAYOUT_TABS', data.data.product_layout_tabs)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_PRODUCT_LAYOUT_TABS', false)
      })
  },
  enrichProductLayoutTabs ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichProductLayoutTab(state.productLayoutTabs)
      resolve(true)
    })
  },
  saveLayoutButton ({ state, commit, dispatch }, button) {
    const productLayoutTab = state.productLayoutTabs.find(productLayoutTab => productLayoutTab.id === button.product_layout_tab_id)
    commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: true })
    window.enrich.enrichProductLayoutTab(productLayoutTab)
    ProductService.saveLayoutButton({
      button
    })
      .then(({ data }) => {
        console.log('ProductService saveLayoutButton then', data)
        if (data.status === 'success') {
          commit('REPLACE_OR_INSERT_LAYOUT_BUTTON', data.data)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: button.id === 0 ? 'Knappen har skapats' : 'Knappen har uppdaterats'
          }, { root: true })
          commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: false }) // För att det ska bli rätt när layout tabben berikas i nästa steg
          window.enrich.enrichProductLayoutTab(productLayoutTab)
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: false })
      })
  },
  deleteLayoutButton ({ state, commit, dispatch }, button) {
    const productLayoutTab = state.productLayoutTabs.find(productLayoutTab => productLayoutTab.id === button.product_layout_tab_id)
    commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: true })
    window.enrich.enrichProductLayoutTab(productLayoutTab)
    ProductService.deleteLayoutButton({
      buttonId: button.id
    })
      .then(({ data }) => {
        console.log('ProductService deleteLayoutButton then', data)
        if (data.status === 'success') {
          commit('REMOVE_LAYOUT_BUTTON', button)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Knappen har tagits bort'
          }, { root: true })
          commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: false }) // För att det ska bli rätt när layout tabben berikas i nästa steg
          window.enrich.enrichProductLayoutTab(productLayoutTab)
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_SAVE_LAYOUT_BUTTON', { button, val: false })
      })
  },
  updateBarcode ({ commit, dispatch }, { product, barcode }) {
    commit('SET_IS_LOADING_UPDATE_BARCODE', { productId: product.id, val: true })
    ProductService.updateBarcode({
      productId: product.id,
      barcode
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichProduct(data.data.product)
          commit('REPLACE_OR_INSERT_PRODUCT', data.data.product)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Streckkoden har uppdaterats'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_UPDATE_BARCODE', { productId: product.id, val: false })
      })
  },
  updatePrice ({ commit, dispatch }, { product, price }) {
    commit('SET_IS_LOADING_UPDATE_PRICE', { productId: product.id, val: true })
    ProductService.updatePrice({
      productId: product.id,
      price
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichProduct(data.data.product)
          commit('REPLACE_OR_INSERT_PRODUCT', data.data.product)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Priset har uppdaterats'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_UPDATE_PRICE', { productId: product.id, val: false })
      })
  },
  updateColor ({ commit, dispatch }, { product, color }) {
    commit('SET_IS_LOADING_UPDATE_COLOR', { productId: product.id, val: true })
    ProductService.updateColor({
      productId: product.id,
      color
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichProduct(data.data.product)
          commit('REPLACE_OR_INSERT_PRODUCT', data.data.product)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Produktfärgen har uppdaterats'
          }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_UPDATE_COLOR', { productId: product.id, val: false })
      })
  },
  storeProduct ({ state, commit, dispatch }) {
    const editedProduct = state.editedProduct
    commit('SET_IS_LOADING_STORE_PRODUCT', { productId: editedProduct.id, val: true })
    ProductService.storeProduct(editedProduct)
      .then(({ data }) => {
        console.log('storeProduct then', data)
        if (data.status === 'success') {
          window.enrich.enrichProduct(data.data)
          commit('REPLACE_OR_INSERT_PRODUCT', data.data)
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Produkten har skapats'
          }, { root: true })
          commit('SET_SHOW_FORM_DIALOG', false)
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_STORE_PRODUCT', { productId: editedProduct.id, val: false })
      })
  },
  deleteProduct ({ commit, dispatch }, product) {
    commit('SET_DELETING_PRODUCT', product.id)
    console.log('deleteProduct', product)
    ProductService.deleteProduct(product)
      .then(({ data }) => {
        console.log('deleteProduct then', data)
        if (data.status === 'success') {
          dispatch('snackbars/createSnackbar', {
            color: 'success',
            text: 'Produkten har flyttats till papperskorgen'
          }, { root: true })
          if (data.data.refresh_layout) {
            // Produkten fanns med i en layout, tillhörande knappar har tagits bort serverside, uppdatera layouts lokalt också
            commit('SET_PRODUCT_LAYOUT_TABS', data.data.layout_tabs)
          }
          commit('REMOVE_PRODUCT', product)
        }
      })
      .finally(() => {
        commit('REMOVE_DELETING_PRODUCT', product.id)
      })
  },
  deleteProductGroup ({ commit, dispatch }, productGroup) {
    // Disablat knapp till denna tillsvidare ännu 11/4-22
    console.log('deleteProductGroup', productGroup)
    commit('SET_DELETING_PRODUCT_GROUP', productGroup.id)
    ProductService.deleteProductGroup(productGroup)
      .then(({ data }) => {
        console.log('deleteProductGroup then', data)
        dispatch('snackbars/createSnackbar', {
          color: 'success',
          text: 'Produktgruppen och tillhörande produkter har flyttats till papperskorgen'
        }, { root: true })
      })
      .finally(() => {
        commit('REMOVE_DELETING_PRODUCT_GROUP', productGroup.id)
      })
  }
}
