<template>
  <v-dialog
    v-model="show"
    max-width="420"
  >
    <v-card>
      <v-card-title class="text-h5" style="word-break: keep-all;">
        Skicka kvitto till email
      </v-card-title>
      <v-card-text class="mt-2">
        <v-form @submit.prevent="send">
          <input-email
            v-model="emailAddress"
            :customer-id="0"
            @set-validation="setEmailValidation"
            :force-validation="forceEmailValidation"
            :force-reset-validation="forceResetEmailValidation"
            mandatory
          ></input-email>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn
          @click="show = false"
          :disabled="isSending"
          color="error"
          text
        >
          Skicka inte
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="send"
          :loading="isSending"
          color="success"
        >
          <span>
            Skicka
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import ReceiptService from '@/services/ReceiptService.js'

import InputEmail from '@/components/customer/InputEmail.vue'

export default {
  name: 'SendReceiptDialog',
  components: {
    InputEmail
  },
  data: () => ({
    emailAddress: '',
    isSending: false,
    forceEmailValidation: 0,
    forceResetEmailValidation: 0,
    saveAfterValidation: false,
    emailValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    }
  }),
  computed: {
    ...mapState(
      {
        sendReceiptDialog: state => state.posReceipt.sendReceiptDialog
      }
    ),
    show: {
      get () {
        return this.sendReceiptDialog.show
      },
      set (value) {
        if (!value) {
          this.$store.commit('posReceipt/SET_CLOSE_SEND_RECEIPT_DIALOG')
        }
      }
    }
  },
  methods: {
    setEmailValidation (validation) {
      this.emailValidation = validation
      if (this.saveAfterValidation && validation.isValidated) {
        this.send()
      }
    },
    send: function () {
      const receiptId = this.sendReceiptDialog.receiptId
      const emailAddress = this.emailAddress
      if (this.isSending) {
        return false
      }
      if (!this.emailValidation.isValidated) {
        this.saveAfterValidation = true
        this.forceEmailValidation++
        return false
      }
      this.saveAfterValidation = false
      if (this.emailValidation.hasError) {
        return false
      }

      this.isSending = true
      ReceiptService.sendReceipt({ receiptId, emailAddress })
        .then(({ data }) => {
          if (data.status === 'error' && data.error_code === 'invalid_email') {
            // klarade inte serverside validering, låter dialogen vara kvar öppen
            // Uppdaterar emailValidation, kommer ingen snackbar
            this.emailValidation.hasError = true
            this.emailValidation.errorMessage = data.error_message
          } else {
            this.forceResetEmailValidation++
            this.show = false
          }
        })
        .finally(() => {
          this.isSending = false
        })
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        this.emailAddress = this.sendReceiptDialog.defaultEmailAddress
      } else {
        this.emailAddress = ''
      }
    }
  },
  mounted () {}
}
</script>
