<template>
<div class="customer-selector" :class="{ 'has-customer-form-button': !hideCustomerFormButton, 'has-selection': !!customer }">
  <div class="d-flex customer-selector-inner-container">
    <div class="d-flex pb-0 flex-grow-1 customer-selector-search-field-container">
      <v-autocomplete
        v-model="customer"
        :items="selectorItems"
        :loading="isLoading"
        :search-input.sync="searchValue"
        no-filter
        hide-no-data
        :hide-details="hideCustomerFormButton"
        :label="label"
        return-object
        clearable
        :allow-overflow="false"
        append-icon=""
        :outlined="outlined"
        :disabled="disabled"
        :class="{ 'rounded-r-0': !hideCustomerFormButton, 'sub-errors-for-warning': hasValidationWarning }"
        :error-messages="errorMessages"
      >
        <template v-slot:selection="data">
          <customer-avatar
            :customer="data.item"
            list-item
          ></customer-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.full_name"></v-list-item-title>
            <v-list-item-subtitle v-text="data.item.shortAddress"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:item="data">
          <customer-avatar
            :customer="data.item"
            list-item
          ></customer-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.full_name"></v-list-item-title>
            <v-list-item-subtitle v-text="data.item.shortAddress"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div v-if="!isMobile && !hideCustomerFormButton" class="d-flex flex-grow-0 customer-selector-edit-button-container">
      <v-btn
        @click="openCustomerForm"
        outlined
        class="rounded-l-0 px-3"
        height="56"
        color="secondary"
        style="border-left: 0;"
        :disabled="disabled"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </div>
  <div v-if="isMobile && !hideCustomerFormButton" class="d-flex width-100">
    <v-btn
      @click="openCustomerForm"
      outlined
      class="px-3 ml-auto"
      color="secondary"
      :disabled="disabled"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</div>
</template>

<script>
import SearchService from '@/services/SearchService.js'

import CustomerAvatar from '@/components/customer/Avatar.vue'

export default {
  name: 'CustomerSelector',
  components: {
    CustomerAvatar
  },
  props: {
    preselectedCustomer: Object,
    outlined: Boolean,
    validation: Object,
    isMobile: Boolean,
    disabled: Boolean,
    hideCustomerFormButton: Boolean,
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchValue: null,
    searchResults: [],
    isLoading: false
  }),
  computed: {
    customer: {
      get () {
        return this.preselectedCustomer
      },
      set (customer) {
        this.$emit('set-customer', customer)
      }
    },
    hasCustomer: function () {
      return !!this.preselectedCustomer
    },
    buttonText: function () {
      if (this.hasCustomer) {
        return 'Redigera Kund'
      } else {
        return 'Ny Kund'
      }
    },
    selectorItems: function () {
      let arr = []
      if (this.hasCustomer) {
        arr.push(this.customer)
      }
      arr = arr.concat(this.searchResults)
      return arr
    },
    errorMessages: function () {
      if (!this.validation || !this.customer) {
        return []
      }
      if (this.validation.hasError || this.validation.hasWarning) {
        return [this.validation.errorMessage]
      }
      return []
    },
    hasValidationWarning: function () {
      return this.validation && this.validation.hasWarning
    },
    label: function () {
      if (this.mandatory) {
        return 'Kund*'
      }
      return 'Kund'
    }
  },
  methods: {
    openCustomerForm: function () {
      this.$emit('open-customer-form')
    }
  },
  watch: {
    searchValue: function () {
      const v = this.searchValue
      if (v === '' || v == null) {
        this.searchResults = []
        this.customer = null
        this.isLoading = false
        return false
      }

      this.isLoading = true
      SearchService.searchCustomer(v)
        .then(({ data }) => {
          if (v === this.searchValue) {
            window.enrich.enrichCustomer(data.data.customers)
            this.searchResults = data.data.customers
          }
        })
        .finally(() => {
          if (v === this.searchValue) {
            this.isLoading = false
          }
        })
    }
  }
}
</script>
