<template>
  <div class="p-relative" style="min-height: 240px;">
    <v-overlay
      absolute
      :value="isFetching"
    >
      <v-progress-circular
        indeterminate
        :size="70"
        color="white"
      ></v-progress-circular>
    </v-overlay>
    <bar-chart :data="chartData" :options="chartOptions"></bar-chart>
  </div>
</template>

<script>

import ProductService from '@/services/ProductService.js'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from 'chart.js'
import { Bar as BarChart } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
  name: 'ProductSalesHistoryChart',
  components: {
    BarChart
  },
  props: {
    productId: Number,
    prefetchedData: Array
  },
  data: () => ({
    isFetching: false,
    hasError: false,
    fetchedData: null,
    formatter: new Intl.NumberFormat('sv-SE', { minimumFractionDigits: 0 })
  }),
  computed: {
    data: function () {
      return this.prefetchedData ?? this.fetchedData?.sales_history
    },
    labels: function () {
      if (!this.data) {
        const today = window.dayjs()
        let date = window.dayjs().startOf('month').subtract(23, 'months')
        const months = []
        while (date.isBefore(today)) {
          months.push(date.format('MMM-YY'))
          date = date.add(1, 'month')
        }
        return months
      }
      return this.data.map(d => d.month.format('MMM-YY'))
    },
    chartData: function () {
      if (!this.data) {
        return {
          labels: this.labels,
          datasets: [
            {
              label: '',
              backgroundColor: 'rgba(0, 0, 0, .26)',
              data: [0, 0, 10, 20, 40, 80, 80, 40, 20, 10, 0, 0, 0, 0, 10, 20, 40, 80, 80, 40, 20, 10, 0, 0],
              order: 1,
              yAxisID: 'yLeft'
            },
            {
              label: '',
              backgroundColor: 'rgba(0, 0, 0, .26)',
              data: [0, 0, 10, 20, 40, 80, 80, 40, 20, 10, 0, 0, 0, 0, 10, 20, 40, 80, 80, 40, 20, 10, 0, 0],
              type: 'line',
              order: 0,
              yAxisID: 'yRight'
            }
          ]
        }
      }
      return {
        labels: this.labels,
        datasets: [
          {
            label: 'Omsättning',
            backgroundColor: 'RGB(33, 194, 236)', // secondary
            data: this.data.map(d => Math.round(d.total_sales / 100)),
            order: 1,
            yAxisID: 'yLeft'
          },
          {
            label: 'Sålda enheter',
            data: this.data.map(d => Math.round(d.total_quantity / 1000)),
            borderColor: '#607D8B',
            backgroundColor: '#607D8B',
            type: 'line',
            order: 0,
            yAxisID: 'yRight'
          }
        ]
      }
    },
    largestBarValue: function () {
      if (!this.data) {
        return 0
      }
      const salesValues = this.data.map(d => d.total_sales / 100)
      const maxSalesValue = Math.max.apply(null, salesValues)
      return maxSalesValue
    },
    chartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yLeft: {
            min: 0,
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              callback: this.handleTickLabelAmounts
            }
          },
          yRight: {
            min: 0,
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false // only want the grid lines for one axis to show up
            },
            ticks: {
              callback: this.handleTickLabelQuantity
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 30
            }
          },
          tooltip: {
            enabled: !!this.data && !this.isFetching,
            callbacks: {
              label: this.handleTooltipValue
            }
          }
        }
      }
    }
  },
  methods: {
    getData: function () {
      this.isFetching = true
      this.hasError = false
      ProductService.getProductSalesHistory(this.productId)
        .then(({ data }) => {
          if (data.status === 'success') {
            data.data.sales_history.forEach(salesObject => {
              salesObject.month = window.dayjs(salesObject.month)
            })
            this.fetchedData = data.data
          } else {
            this.hasError = true
          }
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    handleTickLabelAmounts: function (value, index, ticks) {
      if (this.largestBarValue > 1000000) {
        const amount = this.formatter.format(value / 1000000)
        return `${amount} mkr`
      } else if (this.largestBarValue > 200000) {
        const amount = this.formatter.format(value / 1000)
        return `${amount} tkr`
      } else {
        const amount = this.formatter.format(value)
        return `${amount} kr`
      }
    },
    handleTickLabelQuantity: function (value, index, ticks) {
      const quantity = this.formatter.format(value)
      return `${quantity} st`
    },
    handleTooltipValue: function (tooltipItem) {
      console.log('tooltipItem', tooltipItem)
      if (tooltipItem.dataset.type === 'line') {
        return this.formatter.format(tooltipItem.raw) + ' st'
      } else {
        return this.formatter.format(tooltipItem.raw) + ' kr'
      }
    }
  },
  watch: {
    productId: function () {
      if (!this.prefetchedData) {
        this.getData()
      }
    }
  },
  mounted () {
    if (this.productId && !this.prefetchedData) {
      this.getData()
    }
  }
}
</script>
