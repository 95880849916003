import API from './API.js'

export default {
  //
  // GET
  //
  getProducts () {
    return API.httpClient.get('products')
  },
  getProductGroups () {
    return API.httpClient.get('product-groups')
  },
  getProductLayoutTabs () {
    return API.httpClient.get('layouts/tabs')
  },
  getProductSalesHistory (productId) {
    return API.httpClient.get('products/get-sales-history', {
      params: {
        product_id: productId
      }
    })
  },

  //
  // POST
  //
  updateBarcode ({ productId, barcode }) {
    return API.httpClient.post('products/updatebarcode', {
      product_id: productId,
      barcode
    })
  },
  updatePrice ({ productId, price }) {
    return API.httpClient.post('products/updateprice', {
      product_id: productId,
      price
    })
  },
  updateColor ({ productId, color }) {
    return API.httpClient.post('products/updatecolor', {
      product_id: productId,
      color
    })
  },
  storeProduct (product) {
    return API.httpClient.post('products/create', {
      product
    })
  },
  saveLayoutButton ({ button }) {
    const productIds = button.products.map(p => p.id)
    return API.httpClient.post('layouts/button', {
      button_id: button.id,
      product_layout_tab_id: button.product_layout_tab_id,
      button_color: button.button_color,
      button_text: button.button_text,
      column: button.column,
      row: button.row,
      type: button.type,
      product_ids: productIds
    })
  },
  moveLayoutButton ({ buttonId, row, column }) {
    return API.httpClient.post('layouts/button/move', {
      button_id: buttonId,
      row,
      column
    })
  },
  setTabOrder ({ tabIds }) {
    return API.httpClient.post('layouts/tab/move', {
      ordered_tab_ids: tabIds
    })
  },
  setTabPin ({ tabId, workstationId, togglePin }) {
    return API.httpClient.post('layouts/tab/pin', {
      tab_id: tabId,
      workstation_id: workstationId,
      toggle_pin: togglePin
    })
  },

  //
  // DELETE
  //
  deleteProduct (product) {
    return API.httpClient.delete('products/' + product.id)
  },
  deleteProductGroup (productGroup) {
    return API.httpClient.delete('product-groups/' + productGroup.id)
  },
  deleteLayoutButton ({ buttonId }) {
    return API.httpClient.delete('layouts/button', {
      params: {
        button_id: buttonId
      }
    })
  }
}
