<template>
<div class="d-flex full-width payment-methods" style="min-width: 0; min-height: 0" onkeydown="return false;">
  <template v-if="activePaymentMethods.length === 0 && !showInvoicingMethod">
    <v-btn
      outlined
      color="primary"
      dark
      class="px-5 mx-3 my-auto btn-payment-method-settings"
      @click="openSettings"
    >
      <v-icon left size="25">mdi-tune-variant</v-icon>
      Betalningsmetoder
    </v-btn>
  </template>

  <template v-else>
    <!-- Visade betalningsknappar -->
    <div class="d-flex flex-shrink-1 overflow-auto" style="min-width: 0; min-height: 0">
      <template v-if="activePaymentMethods.length === 0">
        <v-btn
          v-if="showInvoicingMethod"
          x-large
          :color="invoicingPaymentMethod.gui_color"
          :dark="!isLoading"
          class="px-5 mx-3 btn-payment-method"
          :loading="isCreatingInvoice"
          :disabled="isLoading"
          @click="selectPaymentMethod(invoicingPaymentMethod)"
        >
          Fakturera
        </v-btn>
      </template>
      <template v-else>
        <div
          v-for="paymentMethod in activePaymentMethods.slice(0, buttonsShown)"
          :key="paymentMethod.id"
        >
          <v-btn
            x-large
            :color="paymentMethod.gui_color ?? paymentMethod.default_gui_color"
            :dark="!((disabled && paymentMethod.gateway !== 'invoicing') || (isLoading && !isLoadingPaymentMethod(paymentMethod.id)))"
            class="px-5 mx-3 btn-payment-method"
            :loading="isLoadingPaymentMethod(paymentMethod.id)"
            :disabled="(disabled && paymentMethod.gateway !== 'invoicing') || (isLoading && !isLoadingPaymentMethod(paymentMethod.id))"
            @click="selectPaymentMethod(paymentMethod)"
          >
            <template v-if="paymentMethod.gateway === 'stripe' && stripePaymentMethod.stripe_payment_method_type === 'card'">
              <v-icon left size="25">mdi-credit-card-check-outline</v-icon>
              <span>{{ stripePaymentMethod.stripe_payment_method_brand_short }} *{{ stripePaymentMethod.stripe_payment_method_last_four }}</span>
            </template>
            <template v-else-if="paymentMethod.gateway === 'stripe' && stripePaymentMethod.stripe_payment_method_type !== 'card'">
              <v-icon left size="25">{{ paymentMethod.icon_class_name }}</v-icon>
              <span>Link</span>
            </template>
            <template v-else>
              <v-icon v-if="paymentMethod.icon_class_name" left size="25">{{ paymentMethod.icon_class_name }}</v-icon>
              <span>{{ paymentMethod.button_text ?? paymentMethod.name }}</span>
            </template>
          </v-btn>
        </div>
      </template>
    </div>

    <!-- Dropdown meny -->
    <div class="">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-large
            v-bind="attrs"
            v-on="on"
            class="ml-2 mr-2"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-4 payment-methods-menu" onkeydown="return false;">
          <v-list-item
            v-for="paymentMethod in activePaymentMethods.slice(buttonsShown)"
            :key="paymentMethod.id"
            class="pa-1"
          >
            <v-btn
              x-large
              :color="paymentMethod.gui_color ?? paymentMethod.default_gui_color"
              :dark="!((disabled && paymentMethod.gateway !== 'invoicing') || (isLoading && !isLoadingPaymentMethod(paymentMethod.id)))"
              class="px-5 btn-payment-method"
              :loading="isLoadingPaymentMethod(paymentMethod.id)"
              :disabled="(disabled && paymentMethod.gateway !== 'invoicing') || (isLoading && !isLoadingPaymentMethod(paymentMethod.id))"
              @click="selectPaymentMethod(paymentMethod)"
            >
              <v-icon v-if="paymentMethod.icon_class_name" left size="25">{{ paymentMethod.icon_class_name }}</v-icon>
              <span>{{ paymentMethod.button_text ?? paymentMethod.name }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item
            v-if="activePaymentMethods.length >= 1 && showInvoicingMethod"
            class="pa-1"
          >
            <v-btn
              x-large
              :color="invoicingPaymentMethod.gui_color"
              :dark="!isLoading"
              class="px-5 btn-payment-method"
              :loading="isCreatingInvoice"
              :disabled="isLoading"
              @click="selectPaymentMethod(invoicingPaymentMethod)"
            >
              Fakturera
            </v-btn>
          </v-list-item>
          <slot name="additional-menu-items"></slot>
          <v-list-item
            v-if="activePaymentMethods.length >= 1 || showInvoicingMethod"
            class="pa-1"
          >
            <v-btn
              x-large
              color="primary"
              dark
              outlined
              class="px-5 btn-payment-method-settings"
              @click="openSettings"
            >
              <v-icon left size="25">mdi-tune-variant</v-icon>
              Inställningar
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>

  <v-dialog v-model="showSettings" max-width="500">
    <payment-method-settings></payment-method-settings>
  </v-dialog>
</div>
</template>

<script>
import { mapState } from 'vuex'

import PaymentMethodSettings from './PaymentMethodSettings.vue'

export default {
  name: 'PaymentMethods',
  components: {
    PaymentMethodSettings
  },
  props: {
    value: Object,
    buttonsShown: Number,
    disabled: Boolean,
    isLoading: Boolean,
    showInvoicingMethod: Boolean,
    showInvoicingOnly: Boolean,
    stripePaymentMethod: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    showSettings: false,
    invoicingPaymentMethod: {
      id: 0,
      gateway: 'invoicing',
      name: 'Fakturera',
      icon_class_name: null,
      gui_color: 'purple'
    }
  }),
  computed: {
    ...mapState(
      {
        workstation: state => state.workstation,
        isCreatingInvoice: state => state.invoice.isCreatingInvoice,
        paymentMethods: state => state.posCheckout.paymentMethods
      }
    ),
    selectedPaymentMethod: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    showStripeMethod: function () {
      return this.stripePaymentMethod !== null
    },
    activePaymentMethods: function () {
      if (!this.workstation) {
        return []
      }

      // TODO: använda display_order för sortering
      const activePaymentMethods = this.paymentMethods.filter(pm => pm.workstation_ids.includes(this.workstation.id))
      if (this.showStripeMethod) {
        activePaymentMethods.unshift(this.paymentMethods.find(pm => pm.gateway === 'stripe')) // Lägger till Stripe först när den är tillgänglig (beroende på bokning, ej workstation)
      }
      return activePaymentMethods
    }
  },
  methods: {
    isLoadingPaymentMethod: function (paymentMethodId) {
      return this.isLoading && this.selectedPaymentMethod && this.selectedPaymentMethod.id === paymentMethodId
    },
    selectPaymentMethod: function (paymentMethod) {
      this.selectedPaymentMethod = paymentMethod
      this.$emit('payment-method-selected')
    },
    openSettings: function () {
      this.showSettings = true
    }
  }
}
</script>
