// använt https://github.com/Marwelln/Holiday/blob/master/src/Holiday.php för inspiration
export default (o, c, d) => {
  // true för alla svenska bankhelgdagar
  // kontrollerar inte påskdagen (alltid söndag), pingstdagen (alltid söndag), midsommardagen (alltid lördag), allahelgona (alltid lördag)
  c.prototype.isBankHoliday = function () {
    return this.day() === 0 || /* 0 för söndag, 6 för lördag */
      this.day() === 6 ||
      isNewYearsEve(this) ||
      isNewYearsDay(this) ||
      isEpiphany(this) ||
      isGoodFriday(this) ||
      isEasterMonday(this) ||
      isAscensionDay(this) ||
      isMayDay(this) ||
      isSwedishNationalDay(this) ||
      isMidsummerEve(this) ||
      isChristmasEve(this) ||
      isChristmasDay(this) ||
      isBoxingDay(this)
  }
}

// nyårsafton: alltid 31 december
function isNewYearsEve (d) {
  return d.format('MM-DD') === '12-31'
}

// nyårsdagen: alltid 1 januari
function isNewYearsDay (d) {
  return d.format('MM-DD') === '01-01'
}

// trettondag jul: alltid 6 januari
function isEpiphany (d) {
  return d.format('MM-DD') === '01-06'
}

// påskdagen: första söndagen efter fullånen som inträffar närmast 21 mars
/* function isEaster (d) {
  return d.isSame(gaussEaster(d.year()))
} */

// långfredag: fredagen före påsk
function isGoodFriday (d) {
  return d.isSame(gaussEaster(d.year()).subtract(2, 'days'))
}

// annandag påsk: dagen efter påsk
function isEasterMonday (d) {
  return d.isSame(gaussEaster(d.year()).add(1, 'days'))
}

// kristhimmelfärd: sjätte torsdagen efter påsk
function isAscensionDay (d) {
  return d.isSame(gaussEaster(d.year()).add((4 + 5 * 7), 'days'))
}

// pingstdagen: sjunde söndagen efter påsk
/* function isPentecostDay (d) {
  return d.isSame(gaussEaster(d.year()).add((7 * 7), 'days'))
} */

// första maj: alltid 1 maj
function isMayDay (d) {
  return d.format('MM-DD') === '05-01'
}

// svenska nationaldagen: alltid 6 juni
function isSwedishNationalDay (d) {
  return d.format('MM-DD') === '06-06'
}

// midsommardagen: lördagen mellan 20 och 26 juni
/* function isMidsummerDay (d) {
  return getMidsummerDay(d.year()).isSame(d)
} */

// midsommarafton: fredagen före midsommar
function isMidsummerEve (d) {
  return getMidsummerDay(d.year()).subtract(1, 'days').isSame(d)
}

// alla helgons dag: lördagen mellan 31 oktober och 7 november
/* function isAllSaintsDay (d) {
  return getAllSaintsDay(d.year()).isSame(d)
} */

// julafton: alltid 24 december
function isChristmasEve (d) {
  return d.format('MM-DD') === '12-24'
}

// juldagen: alltid 25 december
function isChristmasDay (d) {
  return d.format('MM-DD') === '12-25'
}

// annandag jul: alltid 26 december
function isBoxingDay (d) {
  return d.format('MM-DD') === '12-26'
}

// lördagen mellan 31 oktober och 7 november
/* function getAllSaintsDay (y) {
  let dt = window.dayjs([y, 10, 31])
  for (let i = 0; i <= 6; ++i) {
    dt = dt.add(1, 'days')
    if (dt.day() === 6) { // 6 = lördag (0  = söndag)
      return dt
    }
  }
} */

// lördagen mellan 20 och 26 juni
function getMidsummerDay (y) {
  let dt = window.dayjs([y, 6, 20])
  for (let i = 0; i <= 6; ++i) {
    dt = dt.add(1, 'days')
    if (dt.day() === 6) { // 6 = lördag (0  = söndag)
      return dt
    }
  }
}

function gaussEaster (Y) {
  // algoritm från https://www.geeksforgeeks.org/how-to-calculate-the-easter-date-for-a-given-year-using-gauss-algorithm/
  // All calculations done on the basis of Gauss Easter Algorithm
  const A = Y % 19
  const B = Y % 4
  const C = Y % 7
  const P = Math.floor(Y / 100.0)
  const Q = Math.floor((13 + 8 * P) / 25.0)
  const M = Math.floor(15 - Q + P - Math.floor(P / 4)) % 30
  const N = Math.floor(4 + P - Math.floor(P / 4)) % 7
  const D = Math.floor(19 * A + M) % 30
  const E = Math.floor(2 * B + 4 * C + 6 * D + N) % 7

  const days = Math.floor(22 + D + E)

  if ((D === 29) && (E === 6)) {
    // A corner case,
    // when D is 29
    return window.dayjs([Y, 4, 19]) // 19 april
  } else if ((D === 28) && (E === 6)) {
    // Another corner case,
    // when D is 28
    return window.dayjs([Y, 4, 18]) // 18 april
  } else {
    if (days > 31) {
      // If days > 31, move to April
      return window.dayjs([Y, 4, (days - 31)]) // april
    } else {
      // Otherwise, stay on March
      return window.dayjs([Y, 3, days]) // mars
    }
  }
}
