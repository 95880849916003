import API from './API.js'

export default {
  getPaymentTerms () {
    return API.httpClient.get('invoices/payment-terms')
  },
  getInvoices ({ requestId, offset, visibleInvoices, sortedColumn, sortedDesc, filterCreatedAt, searchString, filterStatus }) {
    return API.httpClient.get('invoices', {
      params: {
        request_id: requestId,
        offset,
        visible_invoices: visibleInvoices,
        sorted_column: sortedColumn,
        sorted_desc: sortedDesc,
        filter_created_at: filterCreatedAt,
        search_string: searchString,
        filter_status: filterStatus
      }
    })
  },
  getInvoice (id) {
    return API.httpClient.get(`invoices/${id}`)
  },
  createInvoice ({ customer, bookingId, paymentTerm, orderReference, dueDate, charges = null, customerCreditAmount, accommodationChargesAmount, isCashInvoice, paymentMethod, shouldCheckOut, shouldSendEmail = true }) {
    let cart = null
    if (charges !== null) {
      cart = charges.map((charge) => {
        const item = Object.assign({}, charge)
        item.position_id = charge.position ? charge.position.id : null
        item.product_id = charge.product.id
        item.position = null
        item.product = null
        return item
      })
    }
    return API.httpClient.post('invoices/create', {
      customer_id: customer ? customer.id : null,
      customer_credit_amount: customerCreditAmount,
      customer,
      booking_id: bookingId,
      payment_term_id: paymentTerm ? paymentTerm.id : 2,
      due_date: dueDate ? dueDate.format('YYYY-MM-DD') : null,
      order_reference: orderReference,
      cart,
      accommodation_charges_amount: accommodationChargesAmount,
      is_cash_invoice: isCashInvoice,
      payment_method_id: paymentMethod ? paymentMethod.id : null,
      should_check_out: shouldCheckOut,
      should_send_email: shouldSendEmail
    }).catch((error) => {
      console.log('error', error)
      return API.httpClient.post('invoices/wait-for-create', {
      })
    })
  },
  createCreditInvoice ({ creditSalesItems, originalInvoiceId }) {
    return API.httpClient.post('invoices/create-credit-invoice', {
      original_invoice_id: originalInvoiceId,
      items: creditSalesItems
    })
  },
  registerInvoicePayment ({ invoiceId, paymentMethodId, accountId, date, amount }) {
    return API.httpClient.post('invoices/register-payment', {
      invoice_id: invoiceId,
      payment_method_id: paymentMethodId,
      account_id: accountId,
      date,
      amount
    })
  },
  checkout ({ invoiceId, paymentMethodId, workstationId, amount, customerCreditAmount }) {
    return API.httpClient.post('invoices/checkout', {
      invoice_id: invoiceId,
      customer_credit_amount: customerCreditAmount,
      payment_method_id: paymentMethodId,
      workstation_id: workstationId,
      amount
    }).catch((error) => {
      console.log('error', error)
      return API.httpClient.post('invoices/wait-for-checkout', {
      })
    })
  },
  reversePayment ({ paymentId, invoiceId = null }) {
    return API.httpClient.post('invoices/reverse-payment', {
      payment_id: paymentId,
      invoice_id: invoiceId
    })
  },
  reverseInvoicePaymentApplication ({ paymentApplicationId }) {
    return API.httpClient.post('invoices/reverse-payment-application', {
      payment_application_id: paymentApplicationId
    })
  },
  resendInvoice ({ invoiceId }) {
    return API.httpClient.post('invoices/resend', {
      invoice_id: invoiceId
    })
  },
  convertToCredit ({ invoiceId }) {
    return API.httpClient.post('invoices/convert-to-credit', {
      invoice_id: invoiceId
    })
  }
}
