<template>
  <v-card class="invoice-list" :flat="flat">
    <v-card-title v-if="showTitle" :class="{ 'py-2': shrinkWithoutInvoices && !hasInvoices, 'pb-0 pt-2': denseHeader && !(shrinkWithoutInvoices && !hasInvoices) }">
      <div class="card-title">Fakturor</div>
      <template v-if="loading">
        <v-progress-circular
          indeterminate
          size="40"
          class="ml-4"
          color="primary"
        ></v-progress-circular>
      </template>
      <template v-else>
        <v-alert
          v-show="shrinkWithoutInvoices && !hasInvoices"
          dense
          text
          color="info"
          class="ma-0 ml-4 py-1"
        >
          Det finns inga fakturor
        </v-alert>
      </template>
    </v-card-title>
    <v-data-table
      v-show="hasInvoices || !shrinkWithoutInvoices"
      :headers="headers"
      fixed-header
      :items="items"
      :loading="loading"
      hide-default-footer
      disable-filtering
      disable-pagination
    >
      <template v-slot:body="{ items }">
        <tbody>
          <div class="tbody-inner-container" :style="`height: ${scrollableTableHeight}; min-height: ${scrollableTableHeight}; max-height: ${scrollableTableHeight}`">
            <template v-if="loading">
              <v-simple-table class="skeleton-loader-table">
                <tr v-for="i in 2" :key="i">
                  <td v-for="(header, x) in headers" :key="x" :class="{ 'text-left': header.align === 'start', 'text-right': header.align === 'end' }">
                    <v-skeleton-loader
                      style=""
                      type="table-cell"
                      class=""
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </v-simple-table>
            </template>
            <template v-else-if="items.length === 0">
              <v-alert
                dense
                text
                color="info"
                class="ma-10 text-center"
              >
                Det finns inga fakturor att visa
              </v-alert>
            </template>

            <tr
              v-for="invoice in items"
              :key="invoice.id"
              @click="openInvoice(invoice)"
              :class="{ 'warning-highlight': highlightUnpaidInvoices && invoice.remaining_amount > 0 }"
            >
              <td v-if="displayedColumns.includes('invoice_number')">
                {{ invoice.invoice_number }}
              </td>
              <td v-if="displayedColumns.includes('ocr')">
                {{ invoice.ocr }}
              </td>
              <td v-if="displayedColumns.includes('customer_name')">
                {{ invoice.customer_name }}
              </td>
              <td v-if="displayedColumns.includes('total')">
                {{ invoice.totalFormatted }}
              </td>
              <td v-if="displayedColumns.includes('remaining_amount')" :class="{ 'font-weight-black red--text': highlightUnpaidAmount && invoice.remaining_amount !== 0 }">
                {{ invoice.remainingAmountFormatted }}
              </td>
              <td v-if="displayedColumns.includes('invoice_date')">
                {{ invoice.createdAtFormatted }}
              </td>
              <td v-if="displayedColumns.includes('due_date')">
                {{ invoice.dueDateFormatted }}
              </td>
              <td v-if="displayedColumns.includes('status')">
                <v-chip
                  class="justify-center"
                  small
                  :color="invoice.statusColor"
                  text-color="white"
                  style="min-width: 88px;"
                >
                  {{ invoice.statusText }}
                </v-chip>
              </td>
            </tr>
          </div>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {
  name: 'InvoiceList',
  components: {},
  props: {
    invoices: Array,
    loading: Boolean,
    showTitle: Boolean,
    denseHeader: Boolean,
    flat: Boolean,
    shrinkWithoutInvoices: Boolean,
    highlightUnpaidAmount: Boolean,
    displayedItems: { // antal fakturor som visas innan det blir scroll, 0 för obegränsat
      type: Number,
      default: function () { return 0 }
    },
    displayedColumns: {
      type: Array,
      default: function () { return ['invoice_number', 'invoice_date'] } // möjliga värden: invoice_number, ocr, customer_name, total, remaining_amount, invoice_date, due_date, status
    }
  },
  data: () => ({
    highlightUnpaidInvoices: false
  }),
  computed: {
    triggerUnpaidInvoiceAlert: function () {
      return this.$store.state.posCart.triggerUnpaidInvoiceAlert
    },
    headers: function () {
      const columns = []
      if (this.displayedColumns.includes('invoice_number')) {
        columns.push({ text: 'Fakturanummer', align: 'start', sortable: false })
      }
      if (this.displayedColumns.includes('ocr')) {
        columns.push({ text: 'OCR', align: 'start', sortable: false })
      }
      if (this.displayedColumns.includes('customer_name')) {
        columns.push({ text: 'Kund', align: 'start', sortable: false })
      }
      if (this.displayedColumns.includes('total')) {
        columns.push({ text: 'Fakturabelopp', sortable: false })
      }
      if (this.displayedColumns.includes('remaining_amount')) {
        columns.push({ text: 'Återstående', sortable: false })
      }
      if (this.displayedColumns.includes('invoice_date')) {
        columns.push({ text: 'Fakturadatum', sortable: false })
      }
      if (this.displayedColumns.includes('due_date')) {
        columns.push({ text: 'Förfallodatum', sortable: false })
      }
      if (this.displayedColumns.includes('status')) {
        columns.push({ text: 'Status', sortable: false })
      }
      return columns
    },
    items: function () {
      return this.invoices.map(invoice => {
        invoice.totalFormatted = invoice.total_amount / 100 + ' kr'
        invoice.remainingAmountFormatted = invoice.remaining_amount / 100 + ' kr'
        invoice.createdAtFormatted = invoice.created_at.format('D MMM YYYY')
        invoice.dueDateFormatted = invoice.due_date.format('D MMM YYYY')
        return invoice
      })
    },
    hasInvoices: function () {
      return this.invoices.length > 0
    },
    scrollableTableHeight: function () {
      if (this.displayedItems === 0) {
        return 'auto'
      }
      const requiredHeight = this.invoices.length * 48
      const maxHeight = this.displayedItems * 48
      let height = Math.min(requiredHeight, maxHeight)
      if (requiredHeight > maxHeight) {
        height += 24 // lägger till en halv rad så man tydligt ser att det finns fler fakturor att scrolla ner till
      }
      return `${height}px`
    }
  },
  methods: {
    openInvoice: function (invoice) {
      this.$store.commit('invoice/SET_SHOW_DIALOG', false)
      this.$nextTick(() => {
        this.$store.dispatch('invoice/openDialogById', { invoiceId: invoice.id })
      })
    }
  },
  watch: {
    triggerUnpaidInvoiceAlert: function () {
      this.highlightUnpaidInvoices = true
      setTimeout(() => {
        this.highlightUnpaidInvoices = false
      }, 2000)
    }
  }
}
</script>
