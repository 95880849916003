<template>
  <div class="position-map" :style="`height: ${height};`">
    <div ref="bgImage" class="bg-image" :style="`background-image: url(${mapPath})`"></div>
    <div style="position: relative; height: 100%;" @click="mapClicked">
      <div v-if="showZoomButtons" class="zoom-buttons-group d-flex flex-column">
        <v-btn
          v-if="!isMobile"
          fab
          class="mb-2"
          @click="zoomIn"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="!isMobile"
          fab
          class="mb-2"
          @click="zoomOut"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn
          fab
          class="mb-2"
          @click="resetZoom"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
      <svg
        id="zoom"
        ref="zoomSvg"
        :viewBox="`0 0 ${originalImageWidth} ${originalImageHeight}`"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        :class="{ 'cursor-auto': !isDragging, 'cursor-grabbing': isDragging }"
      >
        <g
          class="g"
          @pointerdown="pointerdown"
          @pointermove="pointermove"
          @pointerup="pointerup"
          :class="mapClassList"
        >
          <image :href="mapPath" class="map-image" width="100%"/>
          <template v-if="pageReady">
            <map-position
              v-for="position in positions"
              :key="position.id"
              :position="position"
              :availability="availability"
              :checked-in-bookings="checkedInBookings"
              :selected-position-id="selectedPosition?.id"
              :selected-category-id="selectedCategoryId"
              :show-availability="showAvailability"
              @select-position="selectPosition(position)"
              :map-image-aspect-ratio="mapImageAspectRatio"
            >
            </map-position>
          </template>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as d3 from 'd3'

import MapPosition from './MapPosition.vue'

export default {
  name: 'PositionMap',
  components: {
    MapPosition
  },
  props: {
    height: String,
    showZoomButtons: Boolean,
    selectedPosition: Object,
    selectedCategoryId: Number,
    checkIn: Object,
    checkOut: Object,
    occupancy: Number,
    dogs: Number,
    showAvailability: Boolean,
    alwaysShowCategoryAvailability: Boolean,
    showOccupied: Boolean,
    hasAvailabilityError: Boolean,
    isLoadingAvailability: Boolean,
    bookingId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    transform: null,
    k: 1,
    g: null,
    bg: null,
    svg: null,
    imageWidth: 1,
    imageHeight: 1,
    originalImageWidth: 1000,
    mapImageAspectRatio: parseFloat(localStorage.getItem('CACHE_MAP_IMAGE_ASPECT_RATIO')) ?? 1,
    pointerDown: false,
    isDragging: false,
    pageReady: false
  }),
  computed: {
    ...mapState(
      {
        isFetchingPositions: state => state.position.isFetchingPositions,
        fetchedAvailabilities: state => state.position.fetchedAvailabilities,
        triggerPositionBlocksUpdated: state => state.position.triggerPositionBlocksUpdated,
        triggerBookingUpdated: state => state.booking.triggerBookingUpdated,
        mapPath: state => state.position.mapPath
      }
    ),
    ...mapGetters(
      {
        positions: 'position/activePositions',
        checkedInBookings: 'booking/checkedInBookings'
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    originalImageHeight: function () {
      return this.originalImageWidth * this.mapImageAspectRatio
    },
    mapClassList: function () {
      return {
        'show-availability': this.showAvailability,
        'has-error': this.hasAvailabilityError,
        'is-loading': this.isLoadingAvailability,
        'show-occupied': this.showOccupied,
        'always-show-category-availability': this.alwaysShowCategoryAvailability
      }
    },
    availability: function () {
      const fetchedAvailability = this.fetchedAvailabilities.find(ra => ra.checkIn.isSame(this.checkIn) && ra.checkOut.isSame(this.checkOut) && ra.bookingId === this.bookingId && ra.occupancy === this.occupancy && ra.dogs === this.dogs)
      if (!fetchedAvailability) {
        return []
      }
      const indexedArray = []
      fetchedAvailability.data.forEach(d => {
        indexedArray[d.position_id] = {
          is_available: d.is_available,
          status: d.status
        }
      })
      return indexedArray
    }
  },
  methods: {
    selectPosition: function (pos) {
      this.$emit('select-position', pos)
    },
    unselectPosition: function () {
      this.$emit('unselect-position')
    },
    pointerdown: function () {
      this.pointerDown = true
    },
    pointermove: function () {
      this.isDragging = this.pointerDown
    },
    pointerup: function () {
      this.pointerDown = false
    },
    mapClicked: function () {
      this.unselectPosition()
      if (this.selectedCategoryId !== null) {
        this.$emit('unselect-category-id')
      }
    },
    zoomed: function (event) {
      const { transform } = event
      if (isNaN(transform.k)) {
        return false
      }

      this.g.attr('transform', transform)
      this.g.attr('stroke-width', 1 / transform.k)
      this.k = transform.k
    },
    zoomIn: function () {
      this.transform.scaleBy(this.svg.transition().duration(200), 1.25)
    },
    zoomOut: function () {
      this.transform.scaleBy(this.svg.transition().duration(200), 0.8)
    },
    resetZoom: function () {
      this.transform.scaleTo(this.svg, 1)
      const x = this.imageWidth / 2
      const y = this.imageHeight / 2
      this.transform.translateTo(this.svg.transition().duration(400), x, y)
    },
    windowResize: function () {
      const image = this.$refs.zoomSvg.getElementsByClassName('g')[0].getBBox()
      this.imageHeight = Math.max(image.height, 1)
      this.imageWidth = Math.max(image.width, 1)
    },
    zoomToPosition: function (coordinates) {
      const x = coordinates.x * this.originalImageWidth
      const y = coordinates.y * this.originalImageHeight
      if (this.k <= 3.5 || this.k >= 6) {
        this.k = 3.5
        const t = {}
        t.k = this.k
        t.x = x
        t.y = y
      }
      setTimeout(() => {
        this.transform.scaleTo(this.svg, this.k)
        this.transform.translateTo(this.svg.transition().duration(400), x, y)
      }, !this.transform ? 200 : 0)
    },
    zoomToCategory: function (id) {
      if (id) {
        const positions = this.positions.filter(position => position.category.id === id)
        const top = Math.min.apply(Math, positions.map(function (position) { return position.map_y }))
        const bottom = Math.max.apply(Math, positions.map(function (position) { return position.map_y }))
        const left = Math.min.apply(Math, positions.map(function (position) { return position.map_x }))
        const right = Math.max.apply(Math, positions.map(function (position) { return position.map_x }))
        this.zoomToArea({ top, bottom, left, right })
      }
    },
    zoomToArea: function (boundaries) {
      const w = boundaries.right - boundaries.left
      const h = boundaries.bottom - boundaries.top
      let k = 1 / Math.max(w, h)
      k = Math.min(k, 4)

      setTimeout(() => {
        this.transform.scaleTo(this.svg, k)
        const x = (boundaries.right + boundaries.left) / 2 * this.originalImageWidth
        const y = (boundaries.bottom + boundaries.top) / 2 * this.originalImageHeight
        this.transform.translateTo(this.svg.transition().duration(600), x, y)
      }, !this.transform ? 200 : 0)
    },
    fetchAvailability: function () {
      if (this.showAvailability) {
        this.$store.dispatch('position/fetchAvailability', { checkIn: this.checkIn, checkOut: this.checkOut, bookingId: 0, occupancy: this.occupancy, dogs: this.dogs })
      }
    }
  },
  watch: {
    checkIn: function () {
      this.fetchAvailability()
    },
    checkOut: function () {
      this.fetchAvailability()
    },
    occupancy: function () {
      this.fetchAvailability()
    },
    dogs: function () {
      this.fetchAvailability()
    },
    showAvailability: function () {
      this.fetchAvailability()
    },
    triggerPositionBlocksUpdated: function () {
      this.fetchAvailability()
    },
    triggerBookingUpdated: function () {
      this.fetchAvailability()
    }
  },
  mounted () {
    this.fetchAvailability()

    const mapImageElement = document.getElementsByClassName('map-image')[0]
    mapImageElement.addEventListener('load', (event) => {
      const h = mapImageElement.getBBox().height
      const w = mapImageElement.getBBox().width
      this.imageHeight = Math.max(h, 1)
      this.imageWidth = Math.max(w, 1)
      if (w !== 0) {
        this.mapImageAspectRatio = h / w
        this.$emit('set-map-image-aspect-ratio', this.mapImageAspectRatio)
        localStorage.setItem('CACHE_MAP_IMAGE_ASPECT_RATIO', this.mapImageAspectRatio)
      }
    })

    this.$nextTick(() => {
      this.transform = d3.zoom()
        .scaleExtent([0.4, 8])
        .on('zoom', this.zoomed)
      const zoomElement = this.$el.querySelector('#zoom')
      const gElement = this.$el.querySelector('.g')
      this.svg = d3.select(zoomElement)
      this.g = d3.select(gElement)
      this.svg.call(this.transform)
      this.pageReady = true
    })
    window.addEventListener('resize', this.windowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  }
}
</script>
