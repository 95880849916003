import API from './API.js'

export default {
  search (input) {
    return API.httpClient.get('search', {
      params: {
        input
      }
    })
  },
  getSearchResultPreviewData ({ type, id }) {
    return API.httpClient.get('search/search-results-preview-data', {
      params: {
        type,
        id
      }
    })
  },
  searchCustomer (input) {
    return API.httpClient.get('search/customer', {
      params: {
        input
      }
    })
  }
}
