<template>
  <v-dialog v-model="show" :max-width="isSmaller ? '720px' : '850px'" :width="isSmaller ? '720px' : '850px'" :fullscreen="isMobile">
    <div :class="{ 'customer-dialog': true, 'smaller': isSmaller }">
      <v-card tile :loading="isLoading ? 'secondary' : false" class="customer-dialog-card">
        <v-toolbar
          style="height: auto;"
          elevation="0"
          class="py-3"
          color="white"
        >
          <template v-if="isFetchingCustomer">
            <v-skeleton-loader
              type="avatar"
              style="width: 48px; height: 48px;"
            ></v-skeleton-loader>
            <div>
              <v-toolbar-title class="pl-5 mt-1 flex-shrink-1">
                <v-skeleton-loader
                  type="heading"
                  style="width: 170px; max-width: 170px;"
                ></v-skeleton-loader>
              </v-toolbar-title>
              <v-toolbar-title class="toolbar-subheader pl-5 mt-2 flex-shrink-1">
                <v-skeleton-loader
                  type="text"
                  style="width: 120px; max-width: 120px;"
                ></v-skeleton-loader>
              </v-toolbar-title>
            </div>
          </template>
          <template v-else>
            <customer-avatar :customer="customer" large></customer-avatar>
            <div class="dialog-title-container">
              <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
              <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
            </div>
          </template>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="isFetchingCustomer"
                @click="openCustomerForm"
                class="mr-2"
              >
                <v-icon>mdi-pen</v-icon>
              </v-btn>
            </template>
            <div>
              Redigera kund
            </div>
          </v-tooltip>
          <v-btn icon v-if="isMobile" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tabs
          v-model="tabIndex"
          color="secondary"
          show-arrows
          grow
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab href="#customer-information">
            <v-icon v-if="isMobile">mdi-account</v-icon>
            <span v-else>Kundinformation</span>
          </v-tab>
          <v-tab href="#customer-history">
            <v-icon v-if="isMobile">mdi-book-clock-outline</v-icon>
            <span v-else>Historik</span>
          </v-tab>
          <v-tab href="#bookings">
            <v-badge
              :value="bookingsCount !== ''"
              color="secondary"
              :content="bookingsCount"
              offset-x="-2"
              offset-y="8"
            >
              <v-icon v-if="isMobile">mdi-book-marker-outline</v-icon>
              <span v-else>Bokningar</span>
            </v-badge>
          </v-tab>
          <v-tab href="#invoices-receipts">
            <v-badge
              :value="receiptsAndInvoicesCount !== ''"
              color="secondary"
              :content="receiptsAndInvoicesCount"
              offset-x="-2"
              offset-y="8"
            >
              <v-icon v-if="isMobile">mdi-receipt-text</v-icon>
              <span v-else>Kvitton och fakturor</span>
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-slide-y-transition leave-absolute>
          <v-tabs
            v-show="tabIndex === 'invoices-receipts'"
            v-model="subTabIndex"
            centered
            slider-color="secondary"
          >
            <v-tab
              href="#receipts"
              class="px-8"
            >
              <v-badge
                :value="receiptCount !== ''"
                color="secondary"
                :content="receiptCount"
                offset-x="-2"
                offset-y="8"
              >
                Kvitton
              </v-badge>
            </v-tab>
            <v-tab
              href="#invoices"
              class="px-8"
            >
              <v-badge
                :value="invoiceCount !== ''"
                color="secondary"
                :content="invoiceCount"
                offset-x="-2"
                offset-y="8"
              >
                Fakturor
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-slide-y-transition>
        <v-divider v-show="tabIndex === 'invoices-receipts'"></v-divider>
        <v-tabs-items v-model="tabIndex">
          <v-tab-item value="customer-information">
            <v-sheet class="overflow-y-auto pa-3 full-height">
              <v-row class="ma-0 full-height">
                <v-col class="py-0 full-height">
                  <customer-data-list
                    :is-loading="isFetchingCustomer"
                    :customer="customer"
                    :hidden-sections="['name']"
                  ></customer-data-list>
                </v-col>
                <v-col class="pb-0 pt-6 full-height">
                  <customer-credit-timeline
                    :customer="customer"
                    :customer-credit-timeline="customerCreditTimeline"
                    :loading="isFetchingCustomerCreditTimeline"
                  ></customer-credit-timeline>
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="customer-history">
            <v-sheet class="overflow-y-auto pa-3 full-height">
              <editing-record-timeline
                :editing-records="editingRecords"
                :loading="fetchingChunks.includes('editing-records')"
              ></editing-record-timeline>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="bookings">
            <v-sheet
              class="full-height overflow-y-auto px-3"
            >
              <bookings-list
                :bookings="bookings"
                :loading="fetchingChunks.includes('bookings')"
                @booking-clicked="showBooking"
                @close="close"
              ></bookings-list>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="invoices-receipts">
            <v-tabs-items v-model="subTabIndex">
              <v-tab-item value="receipts">
                <v-sheet
                  class="pa-3 full-height"
                >
                  <receipt-list
                    class="full-height"
                    flat
                    :loading="fetchingChunks.includes('receipts')"
                    :receipts="receipts"
                    :displayedColumns="['receipt_number', 'total', 'created_at']"
                  ></receipt-list>
                </v-sheet>
              </v-tab-item>
              <v-tab-item value="invoices">
                <v-sheet
                  class="pa-3 full-height"
                >
                  <invoice-list
                    class="full-height"
                    flat
                    :loading="fetchingChunks.includes('invoices')"
                    :invoices="invoices"
                    :displayedColumns="['invoice_number', 'total', 'remaining_amount', 'invoice_date', 'due_date', 'status']"
                  ></invoice-list>
                </v-sheet>
              </v-tab-item>
            </v-tabs-items>
          </v-tab-item>
        </v-tabs-items>
        <div class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
          <v-fade-transition>
            <div v-show="showBookingOverlay" @click="showBookingOverlay = false" style="height: 88px; min-height: 88px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
          </v-fade-transition>
          <v-scroll-y-reverse-transition>
            <booking-dialog-content
              v-show="showBookingOverlay"
              is-overlay
              :booking="bookingOverlay.booking"
              :charges="bookingOverlay.charges"
              :invoices="bookingOverlay.invoices"
              :receipts="bookingOverlay.receipts"
              :editing-records="bookingOverlay.editingRecords"
              :emails="bookingOverlay.emails"
              :accommodation-charges-calendar-items="bookingOverlay.accommodationChargesCalendarItems"
              :is-loading="isFetchingCustomer || bookingOverlay.isLoading || bookingOverlay.bookingId === null"
              :is-open="showBookingOverlay"
              @close="close"
              class="flex-grow-1"
              style="pointer-events: auto;"
            ></booking-dialog-content>
          </v-scroll-y-reverse-transition>
        </div>
        <v-card-actions class="customer-dialog-footer full-width">
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="ma-2 px-4"
            large
            :disabled="isLoading"
            @click="startNewBooking"
          >
            Ny bokning
          </v-btn>
        </v-card-actions>
      </v-card>

      <div v-if="editedCustomer" class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
        <v-fade-transition>
          <div v-show="isShowCustomerForm" @click="closeCustomerForm" style="height: 76px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
        </v-fade-transition>
        <v-scroll-y-reverse-transition>
          <v-card v-show="isShowCustomerForm" class="flex-grow-1 overflow-y-auto" light style="pointer-events: auto; ">
            <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
              <div class="d-flex">
                <v-toolbar-title class="headline">Redigera kund</v-toolbar-title>
              </div>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeCustomerForm">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar>
            <div class="flex-grow-1 px-7 pt-3">
              <customer-form
                v-model="editedCustomer"
                savable
                @customer-saved="customerSaved"
              ></customer-form>
            </div>
          </v-card>
        </v-scroll-y-reverse-transition>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import CustomerDataList from '@/components/customer/DataList.vue'
import EditingRecordTimeline from '@/components/common/EditingRecordTimeline.vue'
import CustomerAvatar from '@/components/customer/Avatar.vue'
import CustomerCreditTimeline from '@/components/customer/CustomerCreditTimeline.vue'
import CustomerForm from '@/components/customer/Form.vue'
import BookingsList from '@/components/customer/BookingsList.vue'
import InvoiceList from '@/components/invoice/List.vue'
import ReceiptList from '@/components/receipt/List.vue'
import BookingDialogContent from '@/components/booking/DialogContent'

export default {
  name: 'CustomerDialog',
  components: {
    CustomerDataList,
    EditingRecordTimeline,
    CustomerAvatar,
    CustomerCreditTimeline,
    CustomerForm,
    BookingsList,
    InvoiceList,
    ReceiptList,
    BookingDialogContent
  },
  data: () => ({
    editedCustomer: null,
    isShowCustomerForm: false
  }),
  computed: {
    ...mapState(
      {
        isSmaller: state => state.customer.dialog.isSmaller,
        customer: state => state.customer.dialog.customer,
        bookings: state => state.customer.dialog.bookings,
        invoices: state => state.customer.dialog.invoices,
        receipts: state => state.customer.dialog.receipts,
        editingRecords: state => state.customer.dialog.editingRecords,
        customerCreditTimeline: state => state.customer.dialog.customerCreditTimeline,
        bookingOverlay: state => state.customer.dialog.bookingOverlay,
        isLoading: state => state.customer.dialog.isLoading,
        fetchingChunks: state => state.customer.dialog.fetchingChunks,
        openTab: state => state.customer.dialog.openTab,
        triggerInvoiceUpdated: state => state.invoice.triggerInvoiceUpdated,
        triggerBookingUpdated: state => state.booking.triggerBookingUpdated,
        triggerCustomerUpdated: state => state.customer.triggerCustomerUpdated,
        triggerCustomerCreditUpdated: state => state.invoice.triggerCustomerCreditUpdated
      }
    ),
    show: {
      get () {
        return this.$store.state.customer.dialog.show
      },
      set (val) {
        this.$store.commit('customer/SET_SHOW_DIALOG', val)
        if (!val) {
          this.showBookingOverlay = false
          this.closeCustomerForm()
        }
      }
    },
    showBookingOverlay: {
      get () {
        return this.bookingOverlay.show
      },
      set (val) {
        this.$store.commit('customer/SET_BOOKING_OVERLAY_SHOW', val)
      }
    },
    isFetchingCustomer: function () {
      return this.isLoading || this.fetchingChunks.includes('customer')
    },
    isFetchingCustomerCreditTimeline: function () {
      return this.isLoading || this.fetchingChunks.includes('customer-credit-timeline')
    },
    tabIndex: {
      get () {
        const openTab = this.openTab
        if (openTab === 'invoices' || openTab === 'receipts') {
          return 'invoices-receipts'
        }
        return this.openTab
      },
      set (val) {
        if (val === 'invoices-receipts') {
          val = this.defaultSubTab
        }
        this.$store.commit('customer/SET_DIALOG_OPEN_TAB', val)
      }
    },
    subTabIndex: {
      get () {
        if (this.openTab !== 'receipts' && this.openTab !== 'invoices') {
          return this.defaultSubTab
        }
        return this.openTab
      },
      set (val) {
        this.$store.commit('customer/SET_DIALOG_OPEN_TAB', val)
      }
    },
    defaultSubTab: function () {
      if (this.invoices.length === 0) {
        return 'receipts'
      }
      if (this.receipts.length === 0) {
        return 'invoices'
      }
      const mostRecentReceipt = this.receipts[this.receipts.length - 1].created_at
      const mostRecentInvoice = this.invoices[this.invoices.length - 1].created_at
      if (mostRecentInvoice.isAfter(mostRecentReceipt)) {
        return 'invoices'
      }
      return 'receipts'
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    receiptCount: function () {
      if (!this.receipts || this.receipts.length === 0) {
        return ''
      }
      if (this.receipts.length > 99) {
        return '99+'
      }
      return this.receipts.length
    },
    invoiceCount: function () {
      if (!this.invoices || this.invoices.length === 0) {
        return ''
      }
      if (this.invoices.length > 99) {
        return '99+'
      }
      return this.invoices.length
    },
    receiptsAndInvoicesCount: function () {
      if (!this.receipts || !this.invoices || (this.invoices.length + this.receipts.length) === 0) {
        return ''
      }
      const count = this.receipts.length + this.invoices.length
      if (count > 99) {
        return '99+'
      }
      return count
    },
    bookingsCount: function () {
      if (!this.bookings || this.bookings.length === 0) {
        return ''
      }
      const count = this.bookings.length
      if (count > 99) {
        return '99+'
      }
      return count
    },
    title: function () {
      if (!this.customer) {
        return ''
      }
      let title = this.customer.full_name
      if (this.customer.is_org) {
        title += ' (företagskund)'
      }
      return title
    },
    subtitle: function () {
      return this.customer && this.customer.customer_description
    }
  },
  methods: {
    showBooking: function (booking) {
      if (this.isSmaller) {
        this.$store.commit('customer/SET_DIALOG_IS_SMALLER', false)
      }
      if (this.isMobile) {
        this.close()
        this.$store.dispatch('booking/openDialogById', { bookingId: booking.id })
      } else {
        this.$store.dispatch('customer/openBookingOverlay', { bookingId: booking.id })
      }
    },
    close: function () {
      this.show = false
    },
    openCustomerForm: function () {
      this.editedCustomer = Object.assign({}, this.customer)
      if (this.isSmaller) {
        this.$store.commit('customer/SET_DIALOG_IS_SMALLER', false)
      }
      this.isShowCustomerForm = true
    },
    closeCustomerForm: function () {
      this.isShowCustomerForm = false
    },
    customerSaved: function () {
      // ladda om uppdaterar customer objektet i dialogen
      this.$store.dispatch('customer/getCustomer', this.customer.id)
      this.closeCustomerForm()
    },
    startNewBooking: function () {
      this.$store.dispatch('booking/startNewBooking', {
        customer: this.customer
      })
    }
  },
  watch: {
    triggerInvoiceUpdated: function () {
      // variabel som uppdateras efter fakturaändringar
      if (this.show && this.customer && this.customer.id) {
        this.$store.dispatch('customer/getCustomerInvoices', this.customer.id)
      }
    },
    triggerBookingUpdated: function () {
      if (this.show && this.customer && this.customer.id) {
        this.$store.dispatch('customer/getCustomerBookings', this.customer.id)
      }
    },
    triggerCustomerUpdated: function () {
      // Hämtar om editingRecords när customer har sparats
      if (this.show && this.customer && this.customer.id) {
        this.$store.dispatch('customer/getCustomerEditingRecords', this.customer.id)
      }
    },
    triggerCustomerCreditUpdated: function () {
      if (this.show && this.customer && this.customer.id) {
        this.$store.dispatch('customer/getCustomerCredit', this.customer.id)
      }
    }
  }
}
</script>
