<template>
<v-dialog
  v-model="show"
  persistent
  max-width="450"
>
  <v-card>
    <v-card-title class="text-h5">
      <span class="text-h5 ">Kortterminal</span>
      <v-spacer></v-spacer>
      <v-icon large v-if="terminalPrinting && !terminalCancelling" class="animation-fadeinout">
        mdi-printer
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="terminalMessage != null"
        dense
        prominent
        outlined
        type="error"
      >
        <span v-html="explainedTerminalMessage"></span>
      </v-alert>
      <div v-if="terminalError">
        <div
          v-if="terminalError.length > 0"
          class="text--primary"
          center
        >
          Utskrift från terminalen:
        </div>
        <br>
        <div
          v-for="(line, i) in terminalError"
          :key="i"
        >
          <span>{{ line }}</span>
          <br>
        </div>
      </div>
      <div
        v-else
        class="text--primary"
        center
      >
        {{ terminalText }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="error"
        text
        :loading="terminalCancelling"
        @click="cancel()"
      >
        Avbryt
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="terminalError != null"
        color="primary"
        text
        @click="print()"
      >
        Skriv ut
      </v-btn>
      <v-btn
        v-if="terminalError != null"
        color="success"
        text
        @click="retry()"
      >
        Försök igen
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TerminalStatusDialog',
  components: {},
  props: {},
  data: () => ({
    terminalText: '',
    terminalPrinting: false,
    terminalProcessing: false
  }),
  computed: {
    ...mapState(
      {
        isLoadingCheckoutTerminalPayment: state => state.posCheckout.isLoadingTerminalPayment,
        isLoadingInvoiceTerminalPayment: state => state.invoice.isLoadingTerminalPayment,
        isLoadingCustomerCreditTerminalPayment: state => state.customer.isLoadingTerminalPayment,
        terminalError: state => state.posTerminal.terminalError,
        terminalActive: state => state.posTerminal.terminalActive,
        terminalCancelling: state => state.posTerminal.terminalCancelling,
        terminalMessage: state => state.posTerminal.terminalMessage,
        triggerVerifyTerminalConnection: state => state.posTerminal.triggerVerifyTerminalConnection,
        register: state => state.posRegister.register,
        netsTerminal: state => state.posRegister.netsTerminal,
        retryPath: state => state.posTerminal.retryPath
      }
    ),
    show: function () {
      return this.terminalError != null || (this.terminalActive && (this.isLoadingInvoiceTerminalPayment || this.isLoadingCheckoutTerminalPayment || this.isLoadingCustomerCreditTerminalPayment))
    },
    explainedTerminalMessage: function () {
      if (this.terminalMessage == null) {
        return ''
      } else if (this.terminalMessage === 'Terminal Disconnected') {
        return 'Internetuppkopplingen avbröts mitt under transaktionen. Tryck på försök igen för att se om transaktionen gick igenom. Om betalningen lyckades så kommer köpet registreras och kvitto skrivas ut. Annars kommer en ny betalning att initieras i terminalen.'
      } else if (this.terminalMessage === 'Terminal busy') {
        return 'Terminalen tillfälligt upptagen, försök igen senare'
      } else if (this.terminalMessage === 'Terminal / timeout') {
        return 'Transaktionen tog för lång tid, transaktionen måste avslutas inom 120 sekunder'
      } else if (this.terminalMessage === 'Terminal / cancel key pressed') {
        return 'Kunden avbröt transaktionen med röd knapp'
      } else if (this.terminalMessage === 'Terminal / duplicate transaction detected') {
        return 'Duplikat transaktion, samma belopp och samma kort direkt efter varandra är ej tillåtet. Använd ett annat kort eller vänta minst 5 minuter.'
      } else if (this.terminalMessage === 'Invalid terminal ID or terminal not connected') {
        return 'Terminalen ej inkopplad. Kontrollera anslutningen och starta om terminalen.'
      } else if (this.terminalMessage === 'Terminal / rejected transaction') {
        return 'Transaktionen avvisades av terminalen'
      } else if (this.terminalMessage === 'Terminal / busy with menu invoked') {
        return 'Terminalen är i menyläge, stäng menyn och försök igen'
      } else if (this.terminalMessage === 'PSP / perform end-of-day reconciliation') {
        return 'Dagsavslut måste göras innan terminalen kan användas. Gå till Enheter och klicka på terminalen under rätt kassa för att göra dagsavslut.'
      } else if (this.terminalMessage === 'Terminal / unable to go online, offline transaction declined') {
        return 'Köpet medges ej, offlinetransaktion ej möjlig'
      } else if (this.terminalMessage === 'LocalMode error code missing. Possibly given in error event details.') {
        return 'Okänt lokalt fel på terminalen, prova starta om terminalen. Ytterligare information kan finnas på terminalens skärm. Om det står hämta avtal eller att uppdatering krävs kan detta göras från sidan enheter. Prova först hämta data (max 1 minut) och därefter uppdatera terminal (uppemot en halvtimme) ifall det behövs.'
      } else if (this.terminalMessage === 'Terminal / card removed') {
        return 'Kunden tog ut kortet från terminalen'
      } else if (this.terminalMessage.startsWith('Chip')) { // exempel "Chip card / Z4 response code"
        return 'Felmeddelande från kortet: ' + this.terminalMessage.replace('Chip card /', '')
      } else if (this.terminalMessage.startsWith('PSP')) { // exempel "PSP / No such issuer"
        return '<strong>Medges ej</strong><br>Felmeddelande från inlösaren:<br>' + this.terminalMessage.replace('PSP /', '')
      } else if (this.terminalMessage.startsWith('Terminal')) {
        return 'Felmeddelande från terminalen: ' + this.terminalMessage.replace('Terminal /', '')
      } else {
        return 'Okänt fel från Terminalen eller Nets, se eventuell utskrift nedan (Felmeddelande: ' + this.terminalMessage + ')'
      }
    }
  },
  methods: {
    cancel: function () {
      if (this.terminalError != null) {
        this.$store.commit('posTerminal/SET_TERMINAL_ERROR', null)
        this.$store.commit('posTerminal/SET_TERMINAL_MESSAGE', null)
      } else {
        if (!this.terminalProcessing) {
          return false
        }
        this.$store.commit('posTerminal/SET_TERMINAL_CANCELLING', true)
        window.nets.cancel()
      }
    },
    print: function () {
      const printObject = {
        content: [],
        port: 'SIM',
        width: 42
      }
      Object.values(this.terminalError).forEach((line) => {
        printObject.content.push({
          type: 'text',
          alignment: 'left',
          value: line,
          text_size: 0,
          bold: false
        })
      })
      printObject.content.push({
        type: 'cut'
      })
      this.$store.dispatch('print/print', printObject)
    },
    retry: function () {
      this.$store.commit('posTerminal/SET_TERMINAL_ACTIVE', true)
      this.$store.commit('posTerminal/SET_TERMINAL_ERROR', null)
      this.$store.commit('posTerminal/SET_TERMINAL_MESSAGE', null)
      this.$store.commit('posTerminal/SET_TERMINAL_CANCELLING', false)
      this.$store.commit(this.retryPath, undefined)
    },
    verifyConnection: function () {
      this.terminalProcessing = false
      try {
        const manufacturerId = this.register ? this.register.manufacturer_id : 'SECO-WORKSTATION-' + window.hardware.hostname
        console.log('nets open:', window.nets.open(
          this.netsTerminal.server,
          this.netsTerminal.authorization,
          manufacturerId,
          this.netsTerminal.terminal_id
        ))
      } catch (error) {
        console.error(error)
      }

      if (window.netslistener !== undefined) {
        window.removeEventListener('nets', window.netslistener)
      }
      this.terminalText = 'Redo för köp'
      window.addEventListener('nets', window.netslistener = (e) => {
        console.log('nets', e.detail)
        const response = JSON.parse(e.detail)
        if (response.NetsResponse.Dfs13TerminalReady !== undefined || (response.NetsResponse.MethodRejected !== undefined && response.NetsResponse.MethodRejected.Info === 'ALREADY_OPEN')) {
          this.terminalProcessing = false
        } else {
          this.terminalProcessing = true
        }

        if (response.NetsResponse.Dfs13DisplayText !== undefined) {
          this.terminalText = response.NetsResponse.Dfs13DisplayText._
        }
        this.$store.commit('posTerminal/SET_TERMINAL_ACTIVE', true)
      })
    }
  },
  watch: {
    triggerVerifyTerminalConnection: function () {
      this.verifyConnection()
    }
  }
}
</script>
