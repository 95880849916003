import TerminalService from '@/services/TerminalService.js'
export const namespaced = true

export const state = {
  units: [],
  isFetchingUnits: false,
  terminalError: null,
  terminalMessage: null,
  terminalActive: false,
  terminalCancelling: false,
  retryPath: null,
  triggerVerifyTerminalConnection: 0
}

export const mutations = {
  SET_UNITS (state, val) {
    state.units = val
  },
  SET_IS_FETCHING_UNITS (state, val) {
    state.isFetchingUnits = val
  },
  SET_TERMINAL_ERROR (state, val) {
    state.terminalError = val
  },
  SET_TERMINAL_ACTIVE (state, val) {
    state.terminalActive = val
  },
  SET_TERMINAL_CANCELLING (state, val) {
    state.terminalCancelling = val
  },
  SET_TERMINAL_MESSAGE (state, val) {
    state.terminalMessage = val
  },
  SET_RETRY_PATH (state, val) {
    state.retryPath = val
  },
  TRIGGER_VERIFY_TERMINAL_CONNECTION (state, val) {
    state.triggerVerifyTerminalConnection++
  }
}

export const actions = {
  initialise ({ dispatch }) {
    dispatch('fetchRegister')
  },
  fetchUnits ({ commit }) {
    commit('SET_IS_FETCHING_UNITS', true)
    commit('SET_UNITS', [])

    TerminalService.getTerminals()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_UNITS', data.data)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_UNITS', false)
      })
  },
  forceVerifyTerminalConnection ({ commit }) {
    commit('TRIGGER_VERIFY_TERMINAL_CONNECTION')
  }
}
