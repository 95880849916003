<template>
  <div class="customer-form d-flex flex-column full-height" @keydown="keydown">
    <div class="">
      <v-row v-show="!customer.is_org" class="mt-0">
        <v-col>
          <input-name
            ref="inputName"
            :given-name="customer.given_name"
            :surname="customer.surname"
            :force-validation="forceNameValidation"
            :force-reset-validation="forceResetNameValidation"
            @set-given-name="setGivenName"
            @set-surname="setSurname"
            @set-validation="setNameValidation"
          ></input-name>
        </v-col>
      </v-row>
      <v-row v-show="customer.is_org" class="mt-0">
        <v-col>
          <input-organisation
            v-model="customer"
            :force-validation="forceOrgValidation"
            :force-reset-validation="forceResetOrgValidation"
            @set-validation="setOrgValidation"
          ></input-organisation>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <input-address
            ref="inputAddress"
            :street-name="customer.street_name"
            :street-number="customer.street_number"
            :zip="customer.zip"
            :city="customer.city"
            :country="customer.country"
            :force-validation="forceAddressValidation"
            :force-reset-validation="forceResetAddressValidation"
            @set-street-name="setStreetName"
            @set-street-number="setStreetNumber"
            @set-zip="setZip"
            @set-city="setCity"
            @set-country="setCountry"
            @set-validation="setAddressValidation"
          ></input-address>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <input-email
            v-model="customer.email"
            :customer-id="customer.id"
            @set-validation="setEmailValidation"
            :force-validation="forceEmailValidation"
            :force-reset-validation="forceResetEmailValidation"
            validate-address-is-unique
          ></input-email>
        </v-col>
        <v-col cols="12" md="7">
          <input-phone-number
            :customer-id="customer.id"
            :phone-number="customer.phone_number"
            :dialling-code-country="customer.diallingCodeCountry"
            :country="customer.country"
            :force-validation="forcePhoneValidation"
            :force-reset-validation="forceResetPhoneValidation"
            @set-phone-number="setPhoneNumber"
            @set-dialling-code-country="setDiallingCodeCountry"
            @set-validation="setPhoneValidation"
          ></input-phone-number>
        </v-col>
      </v-row>
      <v-row v-show="suggestions.length > 0" class="my-3">
        <v-col cols="12" class="pa-0">
          <v-subheader class="pt-0" style="height: 24px;" v-text="customerSuggestionsHeaderText"></v-subheader>
          <v-slide-group
            show-arrows
          >
            <v-slide-item v-for="suggestion in suggestions" :key="suggestion.customer.id">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs"
                    v-on="on">
                  <customer-card
                    :customer="suggestion.customer"
                    clickable
                    @click="suggestionClicked(suggestion)"
                  ></customer-card>
                  </div>
                </template>
                <span>Anledning till detta förslag: {{ suggestion.reason }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </div>
    <div v-if="savable" class="flex-grow-1 d-flex justify-end align-end pb-4" style="margin-right: -12px;">
      <v-btn
        color="success"
        @click="save"
        :loading="isSaving"
      >
        Spara Kund
      </v-btn>
    </div>
  </div>
</template>

<script>
import InputEmail from './InputEmail.vue'
import InputPhoneNumber from './InputPhoneNumber.vue'
import InputName from './InputName.vue'
import InputAddress from './InputAddress.vue'
import InputOrganisation from './organisation/InputOrganisation.vue'
import CustomerCard from '@/components/customer/Card.vue'

import CustomerService from '@/services/CustomerService.js'

export default {
  name: 'CustomerForm',
  components: {
    InputEmail,
    InputPhoneNumber,
    InputName,
    InputAddress,
    InputOrganisation,
    CustomerCard
  },
  props: {
    value: Object,
    forceValidation: Number,
    forceResetValidation: Number,
    savable: Boolean,
    isMobile: Boolean
  },
  data: () => ({
    isSaving: false,
    saveAfterValidation: false,
    forceOrgValidation: 0,
    forceNameValidation: 0,
    forceAddressValidation: 0,
    forceEmailValidation: 0,
    forcePhoneValidation: 0,
    forceResetOrgValidation: 0,
    forceResetNameValidation: 0,
    forceResetAddressValidation: 0,
    forceResetEmailValidation: 0,
    forceResetPhoneValidation: 0,
    orgValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    nameValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    addressValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    emailValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    phoneValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    }
  }),
  computed: {
    customer: {
      get () {
        return this.value
      },
      set (customer) {
        this.$emit('input', customer)
      }
    },
    suggestions: function () {
      if (this.customer.id !== 0) {
        return []
      }
      let allSuggestions = this.emailValidation.suggestions.concat(this.phoneValidation.suggestions).concat(this.addressValidation.suggestions)
      if (this.customer.is_org) {
        allSuggestions = allSuggestions.concat(this.orgValidation.suggestions)
      }
      // Filtrerar eventuella dubbletter
      const filteredSuggestions = allSuggestions.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.customer.id === value.customer.id
        ))
      )
      return filteredSuggestions
    },
    customerSuggestionsHeaderText: function () {
      if (this.suggestions.length === 1) {
        return 'Menade du den här kunden?'
      }
      return 'Menade du någon av dem här kunderna?'
    }
  },
  methods: {
    validate: function () {
      if (this.customer.is_org) {
        this.forceOrgValidation++
      } else {
        this.forceNameValidation++
      }
      this.forceAddressValidation++
      this.forceEmailValidation++
      this.forcePhoneValidation++
    },
    resetValidation: function () {
      this.forceResetOrgValidation++
      this.forceResetNameValidation++
      this.forceResetAddressValidation++
      this.forceResetEmailValidation++
      this.forceResetPhoneValidation++
    },
    setGivenName (val) {
      this.customer.given_name = val
    },
    setSurname (val) {
      this.customer.surname = val
    },
    setStreetName (val) {
      this.customer.street_name = val
    },
    setStreetNumber (val) {
      this.customer.street_number = val
    },
    setZip (val) {
      this.customer.zip = val
    },
    setCity (val) {
      this.customer.city = val
    },
    setCountry (val) {
      this.customer.country = val
    },
    setEmail (val) {
      this.customer.email = val
    },
    setPhoneNumber (val) {
      this.customer.phone_number = val
    },
    setDiallingCodeCountry (val) {
      this.customer.diallingCodeCountry = val
    },
    setOrgValidation (validation) {
      this.orgValidation = validation
      this.updateCommonValidation()
    },
    setNameValidation (validation) {
      this.nameValidation = validation
      this.updateCommonValidation()
    },
    setAddressValidation (validation) {
      this.addressValidation = validation
      this.updateCommonValidation()
    },
    setPhoneValidation (validation) {
      this.phoneValidation = validation
      this.updateCommonValidation()
    },
    setEmailValidation (validation) {
      this.emailValidation = validation
      this.updateCommonValidation()
    },
    updateCommonValidation () {
      let orgOrNameValidation
      if (this.customer.is_org) {
        orgOrNameValidation = this.orgValidation
      } else {
        orgOrNameValidation = this.nameValidation
      }
      this.validation.hasChanges = orgOrNameValidation.hasChanges || this.addressValidation.hasChanges || this.emailValidation.hasChanges || this.phoneValidation.hasChanges
      this.validation.isLoading = orgOrNameValidation.isLoading || this.addressValidation.isLoading || this.emailValidation.isLoading || this.phoneValidation.isLoading
      this.validation.isValidated = orgOrNameValidation.isValidated && this.addressValidation.isValidated && this.emailValidation.isValidated && this.phoneValidation.isValidated
      this.validation.hasError = orgOrNameValidation.hasError || this.addressValidation.hasError || this.emailValidation.hasError || this.phoneValidation.hasError
      this.validation.hasWarning = orgOrNameValidation.hasWarning || this.addressValidation.hasWarning || this.emailValidation.hasWarning || this.phoneValidation.hasWarning
      if (orgOrNameValidation.hasError) {
        this.validation.errorMessage = orgOrNameValidation.errorMessage
      } else if (this.addressValidation.hasError) {
        this.validation.errorMessage = this.addressValidation.errorMessage
      } else if (this.emailValidation.hasError) {
        this.validation.errorMessage = this.emailValidation.errorMessage
      } else if (this.phoneValidation.hasError) {
        this.validation.errorMessage = this.phoneValidation.errorMessage
      } else if (orgOrNameValidation.hasWarning) {
        this.validation.errorMessage = orgOrNameValidation.errorMessage
      } else if (this.addressValidation.hasWarning) {
        this.validation.errorMessage = this.addressValidation.errorMessage
      } else if (this.emailValidation.hasWarning) {
        this.validation.errorMessage = this.emailValidation.errorMessage
      } else if (this.phoneValidation.hasWarning) {
        this.validation.errorMessage = this.phoneValidation.errorMessage
      } else {
        this.validation.errorMessage = ''
      }
      this.$emit('set-validation', this.validation)
      if (this.saveAfterValidation && this.validation.isValidated) {
        this.save()
      }
    },
    suggestionClicked: function (suggestion) {
      this.customer = suggestion.customer
      this.validate()
    },
    save: function () {
      if (this.isSaving) {
        return false
      }
      if (!this.validation.isValidated) {
        this.saveAfterValidation = true
        this.validate()
        return false
      }
      this.saveAfterValidation = false
      if (this.validation.hasError) {
        return false
      }
      this.isSaving = true
      CustomerService.saveCustomer(this.customer)
        .then(({ data }) => {
          if (data.status === 'success') {
            window.enrich.enrichCustomer(data.data.customer)
            this.$emit('customer-saved', data.data.customer) // Vi har räddat kunden!
          }
        })
        .finally(() => {
          this.isSaving = false
          this.$store.commit('customer/TRIGGER_CUSTOMER_UPDATED')
        })
    },
    keydown: function (e) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.$emit('close')
        e.preventDefault()
      } else if (e.key === 'Return' || e.key === 'Enter') {
        this.save()
      }
    }
  },
  watch: {
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  }
}
</script>
