import API from './API.js'

export default {
  fetchPaymentMethods () {
    return API.httpClient.get('payment-methods')
  },
  associatePaymentMethodToWorkstation ({ paymentMethodId, workstationId, associatePaymentMethod }) {
    return API.httpClient.post('payment-methods/associate', {
      payment_method_id: paymentMethodId,
      workstation_id: workstationId,
      toggle_association: associatePaymentMethod
    })
  }
}
