import SeasonService from '@/services/SeasonService.js'

export const namespaced = true

export const state = {
  seasons: [],
  isFetchingSeasons: false,
  triggerReloadSeasonBookings: 0
}

export const mutations = {
  SET_SEASONS (state, val) {
    state.seasons = val
  },
  SET_IS_FETCHING_SEASONS (state, val) {
    state.isFetchingSeasons = val
  },
  TRIGGER_RELOAD_SEASON_BOOKINGS (state) {
    state.triggerReloadSeasonBookings++
  }
}

export const actions = {
  fetchSeasons ({ commit }) {
    commit('SET_IS_FETCHING_SEASONS', true)
    return SeasonService.getSeasons()
      .then(({ data }) => {
        window.enrich.enrichSeason(data.data.seasons)
        commit('SET_SEASONS', data.data.seasons)
        commit('SET_IS_FETCHING_SEASONS', false)
      })
  }
}

export const getters = {}
