<template>
  <v-list class="customer-bookings-list">
    <template v-if="loading">
      <v-list-item v-for="i in 4" :key="i">
        <v-list-item-content>
          <v-list-item-title>
            <v-skeleton-loader type="table-cell" class="skeleton-loader-text-medium-height skeleton-loader-text-medium-width"></v-skeleton-loader>
          </v-list-item-title>
          <v-list-item-subtitle class="mt-1">
            <v-skeleton-loader type="table-cell" class="skeleton-loader-text-small-height skeleton-loader-text-large-width"></v-skeleton-loader>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon class="ml-2 action-items">
          <v-skeleton-loader type="table-cell" class="skeleton-loader-button"></v-skeleton-loader>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <template v-else-if="bookings.length === 0">
      <div class="d-flex flex-column full-width">
        <div class="mx-auto mt-6">
          <v-icon large>mdi-ghost-outline</v-icon>
        </div>
        <p class="text-caption-2 text--secondary my-auto mx-auto py-6">
          Här var det tomt
        </p>
      </div>
    </template>

    <template v-else>
      <!-- TODO: pagination/infinite-scroll, en del kunder har väldigt många bokningar -->
      <div
        v-for="bookingYear in bookingsOrdered"
        :key="bookingYear.year"
      >
        <v-subheader>{{ bookingYear.year }}</v-subheader>
        <div
          v-for="booking in bookingYear.bookings"
          :key="booking.id"
        >
          <customer-bookings-list-row
            :booking="booking"
            @clicked="bookingClicked"
            @close="close"
          ></customer-bookings-list-row>
        </div>
      </div>
    </template>
  </v-list>
</template>

<script>
import CustomerBookingsListRow from './BookingsListRow.vue'

export default {
  name: 'CustomerBookingsList',
  components: {
    CustomerBookingsListRow
  },
  props: {
    bookings: Array,
    loading: Boolean
  },
  data: () => ({}),
  computed: {
    bookingsOrdered: function () {
      const bookings = [...this.bookings].sort((a, b) => (a.check_in.isAfter(b.check_in) ? -1 : 1))
      const years = bookings.map(booking => booking.check_in.year())
      const uniqueYears = [...new Set(years)]
      const dividedBookings = []
      uniqueYears.forEach(year => {
        dividedBookings.push({
          year,
          bookings: bookings.filter(booking => booking.check_in.year() === year).sort((a, b) => {
            // b före a = 1, a före b = -1
            // Sorterar bokningar med senare utcheckning först
            return a.check_out.isBefore(b.check_out) ? 1 : -1
          })
        })
      })
      return dividedBookings
    }
  },
  methods: {
    bookingClicked: function (booking) {
      this.$emit('booking-clicked', booking)
    },
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
