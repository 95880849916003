<template>
  <v-snackbar
    :value="show"
    :color="snackbarColor"
    bottom
    left
    rounded
    :timeout="-1"
  >
    <div class="d-flex">
      <v-icon>mdi-connection</v-icon>
      <div class="ml-4 my-auto pr-4">
        {{ snackbarText }}
      </div>
      <div v-show="isConnecting" class="ml-auto my-auto">
        <v-progress-circular
          indeterminate
          :size="24"
          color="white"
        ></v-progress-circular>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ConnectionStatus',
  data: () => ({}),
  computed: {
    ...mapState(
      {
        pusherState: state => state.pusher.pusherState,
        disconnectedAt: state => state.pusher.disconnectedAt,
        isResyncing: state => state.pusher.isResyncing,
        isOffline: state => state.isOffline,
        now: state => state.now
      }
    ),
    show: function () {
      return this.isDisconnected || this.isOffline || this.isResyncing
    },
    isDisconnected: function () {
      return !!this.disconnectedAt
    },
    isConnecting: function () {
      return this.pusherState === 'connecting' || this.isResyncing
    },
    hasShortDisruption: function () {
      // obs är true även om hasLongDisruption, men är lite effektivare så
      return this.isDisconnected
    },
    hasLongDisruption: function () {
      return this.isDisconnected && this.disconnectedAt.add(15, 'seconds').isBefore(this.now)
    },
    snackbarText: function () {
      if (this.isOffline) {
        return 'Datorn är offline, kontrollera din internetuppkoppling'
      } else if (this.hasLongDisruption) {
        return 'Problem med anslutning till servern, kontrollera din internetuppkoppling'
      } else if (this.hasShortDisruption) {
        return 'Problem med anslutning till servern'
      } else if (this.isResyncing) {
        return 'Återansluter'
      }
      return ''
    },
    snackbarColor: function () {
      if (this.isOffline) {
        return 'error'
      } else if (this.hasLongDisruption) {
        return 'error'
      } else if (this.hasShortDisruption) {
        return 'warning'
      } else if (this.isResyncing) {
        return 'success'
      }
      return ''
    }
  },
  methods: {},
  watch: {},
  mounted () {}
}
</script>
