<template>
  <v-card
    class="draft-bookings"
    :loading="isFetchingBookings ? 'secondary' : false"
    width="450"
  >
    <template v-if="isFetchingBookings">
      <v-skeleton-loader
        class="mx-auto"
        style="border-radius: 0;"
        height="48"
        type="card"
      ></v-skeleton-loader>
      <v-skeleton-loader
        style="padding-top: 10px; border-radius: 0;"
        type="list-item-avatar, card-heading, list-item-avatar, card-heading, list-item-avatar, card-heading"
      ></v-skeleton-loader>
    </template>
    <template v-else>
      <v-toolbar
        light
        flat
        dense
      >
        <template>
          <v-toolbar-title class="pr-2 flex-shrink-0 flex-grow-1">
            Utkast bokningar
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </template>
      </v-toolbar>
      <v-divider></v-divider>
      <div style="max-height: calc(100% - 48px - 4px); overflow-y: auto;">

      <v-row
        v-if="bookings.length === 0"
        align="center"
        class="text-center fill-height ma-0"
      >
        <v-col cols="12" sm="2"></v-col>
        <v-col cols="12" sm="8" class="pt-5 pb-5">
          <v-alert
            dense
            text
            type="info"
            class="mb-0"
          >
            Det finns inga pågående bokningar
          </v-alert>
        </v-col>
      </v-row>

      <v-list
        v-else
        style="transition: height .7s;"
        max-height="65vh"
      >
        <v-subheader>Pågående onlinebokningar</v-subheader>
        <v-slide-y-transition group hide-on-leave>
          <drafts-list-row
            v-for="booking in bookings"
            :key="booking.id"
            :booking="booking"
            :now="now"
          ></drafts-list-row>
        </v-slide-y-transition>
      </v-list>
      </div>
    </template>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DraftsListRow from './DraftsListRow.vue'

export default {
  name: 'RecentBookings',
  components: {
    DraftsListRow
  },
  props: {},
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isFetchingBookings: state => state.booking.isFetchingBookings,
        now: state => state.now
      }
    ),
    ...mapGetters(
      {
        bookings: 'booking/bookingDrafts'
      }
    ),
    hasError: function () {
      return this.error !== null
    }
  },
  methods: {},
  watch: {},
  mounted () {}
}
</script>
