import API from './API.js'

export default {
  validateEmail (email) {
    return API.httpClient.get('validate/email', {
      params: {
        email
      }
    })
  },
  validatePhoneNumber (diallingCode, phoneNumber) {
    return API.httpClient.get('validate/phone', {
      params: {
        dialling_code: diallingCode,
        phone_number: phoneNumber
      }
    })
  },
  validateOrgNumber (orgNumber) {
    return API.httpClient.get('validate/org-number', {
      params: {
        org_number: orgNumber
      }
    })
  },
  searchAddress (session, input, countryCode) {
    return API.httpClient.get('search/address', {
      params: {
        session,
        input,
        country_code: countryCode
      }
    })
  },
  getPlace (session, placeId) {
    return API.httpClient.get('search/address/place', {
      params: {
        session,
        place_id: placeId
      }
    })
  }
}
