import axios from 'axios'
import store from '@/store'

const httpClient = axios.create({
  withCredentials: true, // Sanctum vill ha det
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CampingOnline-Version': process.env.VUE_APP_VERSION
  }
})

console.log('setting axios interceptors')
httpClient.interceptors.response.use(
  response => {
    if (response.data.snackbar) {
      store.dispatch('snackbars/createSnackbar', response.data.snackbar, { root: true })
    }
    if (response.data.print) {
      store.dispatch('print/print', response.data.print, { root: true })
    }
    store.commit('user/SET_HAS_TIMEOUT_ERROR', false)
    return response
  },
  error => {
    console.log('intercept error', error.response)
    if (error.response === undefined) {
      store.commit('user/SET_HAS_TIMEOUT_ERROR', true)
      store.commit('user/SET_AUTHENTICATED_STATUS', false)
      store.dispatch('snackbars/createSnackbar', {
        color: 'error',
        text: 'Servern svarade inte, kontrollera din anslutning'
      }, { root: true })
    } else if (store.state.user.domain === '' || error.response.status === 401) {
      store.commit('user/SET_HAS_TIMEOUT_ERROR', false)
      store.commit('user/SET_AUTHENTICATED_STATUS', false)
      store.commit('user/REMOVE_USER')
      store.commit('user/REMOVE_TOKEN')
    } else if (error.response.status === 403 && error.response.data.error_code === 'update_required') {
      store.commit('user/SET_HAS_VERSION_ERROR', true)
      store.commit('SET_UPDATE_URL', error.response.data.url)
      store.commit('SET_SHOW_UPDATE_DIALOG', true)
    } else if (error.response.status === 0) {
      // Troligen ett nätverksfel, försök igen
      // Ska fungera likadant för yttre kod som skickar api anropen
      error.config.retries = error.config.retries || 0
      error.config.retries += 1
      if (error.config.retries < 3) {
        console.log('Nätverksfel, försöker igen')
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            httpClient(error.config)
              .then(resolve)
              .catch(err => {
                reject(err)
              })
          }, 1000 * error.config.retries) // Väntar lite, med inbyggd backoff
        })
      } else {
        console.log('Har försökt 3 gånger, ger upp')
        store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Problem med internetuppkopplingen'
        }, { root: true })
        return Promise.reject(error) // Ger upp och bryter rekursionen
      }
    } else {
      // har response, men inte 401 eller 403
      // 524 är en cloudflare timeout, efter 100s svarar cloudflare åt oss med 524
      // Villkoret här är för att terminalfunktionerna som svarar inom 140s skickar en ny request och väntar på servern
      // error.response.status === 0 betyder ingen response alls så nätverket är nere
      if (error.response.status !== 524) {
        store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: `Ett oväntat fel inträffade. Felkod: ${error.response.status}`
        }, { root: true })
      }
    }
    return Promise.reject(error)
  }
)

export default { httpClient }
