<template>
  <v-dialog v-model="show" max-width="600px" :fullscreen="isMobile">
    <receipt-dialog-content :receipt="receipt" :is-loading="isLoading" :show="show" @close="show = false"></receipt-dialog-content>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import ReceiptDialogContent from './DialogContent.vue'

export default {
  name: 'ReceiptDialog',
  components: {
    ReceiptDialogContent
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        receipt: state => state.posReceipt.dialog.receipt,
        isLoading: state => state.posReceipt.dialog.isLoading,
        errorMessage: state => state.posReceipt.dialog.errorMessage,
        showDialog: state => state.posReceipt.dialog.show
      }
    ),
    show: {
      get () {
        return this.showDialog
      },
      set (value) {
        this.$store.commit('posReceipt/SET_SHOW_DIALOG', value)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {}
}
</script>
