<template>
  <div class="booking-cancellation-preview">
    <div class="card-inner-container pa-0 d-flex flex-column">
      <template v-if="loading">
        <div class="d-flex px-8 pb-8">
          <div class="d-flex flex-column flex-grow-1">
            <div class="text-subtitle-1">Total före</div>
            <v-skeleton-loader type="table-cell" class="skeleton-loader-text-large-height skeleton-loader-text-medium-width"></v-skeleton-loader>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <div class="text-subtitle-1">Total efter</div>
            <v-skeleton-loader type="table-cell" class="skeleton-loader-text-large-height skeleton-loader-text-medium-width"></v-skeleton-loader>
          </div>
        </div>
        <div v-for="i in 3" :key="i" class="mx-4">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </div>
      </template>
      <template v-else>
        <div class="d-flex px-8">
          <div class="d-flex flex-column flex-grow-1">
            <div class="text-subtitle-1">Total före</div>
            <div class="text-h4">{{ formatAmount(data.total_before) }}</div>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <div class="text-subtitle-1">Total efter</div>
            <div class="text-h4">{{ formatAmount(data.total_after) }}</div>
          </div>
        </div>

        <div class="d-flex flex-column mt-4" style="height: calc(100% - 68px - 32px - 168px);">
          <div class="text-subtitle-2 ml-auto mr-8">Återbetalning</div>
          <div class="overflow-y-auto px-4" style="max-height: calc(100% - 22px);">
            <div
              v-for="(productCategory, i) in data.specification_list"
              :key="i"
              class="d-flex highlight-on-hover py-2 px-4 rounded"
            >
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-subtitle-1">{{ productCategory.title }}</div>
                <div class="text-h5">{{ formatAmount(productCategory.total) }}</div>
              </div>
              <div class="my-auto d-flex flex-column">
                <template v-if="productCategory.refund_percent !== null">
                  <div class="text-subtitle-1 text-right">{{ productCategory.refund_percent }}%</div>
                  <div class="text-subtitle-1 text-right">{{ refundedAmount(productCategory) }}</div>
                </template>
                <template v-else>
                  <div class="text-subtitle-1 text-right">{{ refundedPercent(productCategory) }}</div>
                  <div class="text-subtitle-1 text-right">{{ formatAmount(productCategory.refund_amount) }}</div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 mt-8">
          <v-alert text type="info" :class="{ 'alert-center-icon': !isMobile }">
            <div class="d-flex flex-column flex-lg-row">
              <div class="flex-grow-1 my-auto pr-4">
                <div v-html="data.result_text"></div>
              </div>
            </div>
          </v-alert>
          <div class="pt-2 pb-4 ml-auto" style="width: 40%;">
            <v-row class="ma-0">
              <v-col class="d-flex pa-0">
                <span class="text-subtitle-2">Total:</span>
                <span class="text-subtitle-2 ml-auto pull-right">{{ formatAmount(data.total_after) }}</span>
              </v-col>
            </v-row>
            <v-row class="ma-0 mt-1">
              <v-col class="d-flex pa-0">
                <span class="text-subtitle-2 success--text">Betalt:</span>
                <span class="text-subtitle-2 ml-auto pull-right success--text">{{ formatAmount(data.paid_amount) }}</span>
              </v-col>
            </v-row>
            <v-row class="ma-0 mt-1">
              <v-col class="d-flex pa-0">
                <span class="text-h5" v-if="data.payable_amount >= 0" >Att betala:</span>
                <span class="text-h5" v-else >Åter:</span>
                <span class="text-h5 ml-auto pull-right">{{ formatAmount(Math.abs(data.payable_amount)) }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingCancellationPreview',
  components: {},
  props: {
    data: Object,
    loading: Boolean
  },
  data: () => ({}),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    formatAmount: function (amount) {
      if (amount === null || amount === undefined) {
        return 'N/A'
      }
      return window.conversion.toHumanReadableAmount(amount / 100)
    },
    refundedAmount: function (productCategory) {
      if (!productCategory.refund_percent) {
        return this.formatAmount(0)
      }
      let amount = productCategory.total * (productCategory.refund_percent / 100)
      amount = Math.round(amount)
      return this.formatAmount(amount)
    },
    refundedPercent: function (productCategory) {
      if (!productCategory.refund_amount) {
        return '0%'
      }
      let percent = productCategory.refund_amount / productCategory.total * 100
      percent = Math.round(percent * 100) / 100
      return `${percent}%`
    }
  }
}
</script>
