import API from './API.js'

export default {
  //
  // GET
  //
  getCustomer (id) {
    return API.httpClient.get(`customers/${id}`)
  },
  getCustomerBookings (id) {
    return API.httpClient.get(`customers/${id}/bookings`)
  },
  getCustomerInvoices (id) {
    return API.httpClient.get(`customers/${id}/invoices`)
  },
  getCustomerReceipts (id) {
    return API.httpClient.get(`customers/${id}/receipts`)
  },
  getCustomerEditingRecords (customerId) {
    return API.httpClient.get('customers/editing-records', {
      params: {
        customer_id: customerId
      }
    })
  },

  //
  // POST
  //
  saveCustomer (customer) {
    return API.httpClient.post('customers', {
      id: customer.id,
      given_name: customer.given_name,
      surname: customer.surname,
      dialling_code_country_id: customer.diallingCodeCountry ? customer.diallingCodeCountry.id : null,
      phone_number: customer.phone_number,
      email: customer.email,
      street_name: customer.street_name,
      street_number: customer.street_number,
      zip: customer.zip,
      city: customer.city,
      is_org: customer.is_org,
      org_name: customer.org_name,
      org_number: customer.org_number,
      org_reference: customer.org_reference,
      country_id: customer.country.id
    })
  }
}
