import API from './API.js'

export default {
  //
  // GET
  //
  getDashboards () {
    return API.httpClient.get('dashboard/dashboards')
  },
  getDashboardCards () {
    return API.httpClient.get('dashboard/dashboard-cards')
  },

  //
  // POST
  //
  moveDashboardCards (changes) {
    // changes en array med objekt i formatet { pivot_id, column, row }
    return API.httpClient.post('dashboard/move-dashboard-card', {
      changes
    })
  },
  addDashboardCardToDashboard ({ dashboardId, dashboardCardId, column, row }) {
    return API.httpClient.post('dashboard/add-dashboard-card-to-dashboard', {
      dashboard_id: dashboardId,
      dashboard_card_id: dashboardCardId,
      column,
      row
    })
  },
  removeDashboardCardFromDashboard (pivotId) {
    return API.httpClient.post('dashboard/remove-dashboard-card-from-dashboard', {
      pivot_id: pivotId
    })
  },
  createDashboard (name) {
    return API.httpClient.post('dashboard/create-dashboard', {
      name
    })
  },
  deleteDashboard (dashboardId) {
    return API.httpClient.post('dashboard/delete-dashboard', {
      dashboard_id: dashboardId
    })
  },
  updateDashboardCardGlobalSetting ({ pivotId, settingName, settingValue }) {
    return API.httpClient.post('dashboard/update-dashboard-card-global-setting', {
      pivot_id: pivotId,
      setting_name: settingName,
      setting_value: settingValue
    })
  }
}
