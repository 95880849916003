<template>
  <v-form
    @submit.prevent
    ref="formOrgName"
  >
    <v-text-field
      label="Företagsnamn*"
      v-model.trim="orgName"
      :rules="[orgNameRule]"
    ></v-text-field>
  </v-form>
</template>

<script>

export default {
  name: 'CustomerInputOrgName',
  components: {},
  props: {
    value: String,
    forceValidation: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    originalOrgName: ''
  }),
  computed: {
    orgName: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    orgNameRule: function (v) {
      let verdict = ''
      if (v == null || v === '') {
        verdict = 'Fyll i företagsnamn'
      } else if (v.length > 100) {
        verdict = 'Företagsnamn kan vara max 100 tecken'
      }
      this.validation.hasError = (verdict !== '')
      this.validation.errorMessage = verdict
      this.validation.isValidated = true
      this.validation.hasChanges = (this.originalOrgName === v)
      this.$emit('set-validation', this.validation)
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    validate: function () {
      return this.$refs.formOrgName.validate()
    },
    resetValidation: function () {
      this.$refs.formOrgName.resetValidation()
    }
  },
  watch: {
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  mounted () {
    this.originalOrgName = this.orgName
  }
}
</script>
