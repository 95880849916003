import API from './API.js'

export default {
  getConsumptionPage ({ bookingId, page = 1 }) {
    return API.httpClient.get('tally/consumption', {
      params: {
        booking_id: bookingId,
        page
      }
    })
  },
  getTallyCharges ({ bookingId }) {
    return API.httpClient.get('tally/charges', {
      params: {
        booking_id: bookingId
      }
    })
  },
  fetchCards () {
    return API.httpClient.get('tally/activecards')
  },
  createProvision ({ bookingId }) {
    return API.httpClient.post('tally/provision', {
      booking_id: bookingId
    })
  },
  addCard ({ bookingId, cardUid }) {
    return API.httpClient.post('tally/addcard', {
      booking_id: bookingId,
      card_uid: cardUid
    })
  },
  removeCard ({ bookingId, cardUid }) {
    return API.httpClient.post('tally/removecard', {
      booking_id: bookingId,
      card_uid: cardUid
    })
  },
  regeneratePincode (bookingId) {
    return API.httpClient.post('tally/regenerate-pincode', {
      booking_id: bookingId
    })
  }
}
