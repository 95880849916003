import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(error => {
    if (
      error.name !== 'NavigationDuplicated' &&
      !error.message.includes('Avoided redundant navigation to current location')
    ) {
      throw error
    }
  })
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    alias: '/index.html'
  },
  {
    path: '/actions',
    name: 'ActionsItems',
    component: () => import(/* webpackChunkName: "action-items" */ '@/views/ActionItems.vue')
  },
  {
    path: '/map/:positionId?',
    name: 'Map',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "map" */ '@/views/Map.vue')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue')
  },
  {
    path: '/register/orders',
    name: 'PosOrders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/pos/Orders.vue')
  },
  {
    path: '/register/units',
    name: 'PosUnits',
    component: () => import(/* webpackChunkName: "units" */ '@/views/pos/Units.vue')
  },
  {
    path: '/register/:chargable?/:id?/:checkout?', // chargable är 'booking', 'order' eller 'customer' (ej stöd än)
    name: 'PosRegister',
    component: () => import(/* webpackChunkName: "register" */ '@/views/pos/Register.vue')
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: () => import(/* webpackChunkName: "receipts" */ '@/views/pos/Receipts.vue')
  },
  {
    path: '/products/:id?',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/admin/Products.vue')
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/admin/Invoices.vue')
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: () => import(/* webpackChunkName: "bookings" */ '@/views/Bookings.vue')
  },
  {
    path: '/bankgiro',
    name: 'Bankgiro',
    component: () => import(/* webpackChunkName: "bankgiro" */ '@/views/admin/Bankgiro.vue')
  },
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import(/* webpackChunkName: "accounting" */ '@/views/admin/Accounting.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/admin/Settings.vue')
  },
  {
    path: '/seasons',
    name: 'Seasons',
    component: () => import(/* webpackChunkName: "seasons" */ '@/views/admin/Seasons.vue')
  },
  {
    path: '/cancellations',
    name: 'Cancellations',
    component: () => import(/* webpackChunkName: "cancellations" */ '@/views/admin/Cancellations.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/Statistics.vue')
  },
  {
    path: '/sales-reports',
    name: 'SalesReports',
    component: () => import(/* webpackChunkName: "sales-reports" */ '@/views/statistics/SalesReports.vue')
  },
  {
    path: '/scb',
    name: 'Scb',
    component: () => import(/* webpackChunkName: "scb" */ '@/views/statistics/Scb.vue')
  },
  {
    // Måste vara sist för att fungera
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  routes
})

export default router
