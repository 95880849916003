export const namespaced = true

export const state = {
  snackbars: []
}

export const mutations = {
  ADD_SNACKBAR (state, val) {
    val.id = Date.now()
    state.snackbars.push(val)
  },
  DELETE_SNACKBAR (state, val) {
    const i = state.snackbars.findIndex(s => {
      return s.id === val
    })
    if (i > -1) {
      state.snackbars.splice(i, 1)
    }
  }
}

export const actions = {
  createSnackbar ({ commit }, { color, text }) {
    // Använd denna action för att visa en snackbar, den tar bort sig själv automatiskt efter 5 sek
    commit('ADD_SNACKBAR', { color, text })
  }
}
