import API from './API.js'

export default {
  login (credentials, machineId, hostname) {
    return API.httpClient.post('login', {
      credentials,
      machine_id: machineId,
      hostname
    })
  },
  reconnect (machineId, hostname) {
    return API.httpClient.post('reconnect', {
      machine_id: machineId,
      hostname
    })
  },
  logout () {
    return API.httpClient.post('logout')
  },
  authorizeSocket (options) {
    return API.httpClient.post('broadcasting/auth', options)
  }
}
