import API from './API.js'

export default {
  getChangelog (version) {
    return API.httpClient.get('portal/changelog', {
      params: {
        version
      }
    })
  }
}
