<!-- Kan användas för både registrera inbetalning på faktura, utbetalning på kreditfaktura, och utbetalning av kundkredit -->
<template>
  <v-card class="register-payment d-flex flex-column full-height px-4 justify-start" flat tile>
    <div>
      <v-select
        v-model="selectedPaymentMethod"
        :items="displayedPaymentMethods"
        label="Använd betalningsmetod"
        outlined
        return-object
        item-text="name"
        :error-messages="paymentMethodErrorMessages"
        :class="{ 'sub-errors-for-warning': paymentMethodHasWarning }"
        :persistent-hint="!!paymentMethodHint"
        :hint="paymentMethodHint"
      ></v-select>
    </div>

    <div class="pt-3">
      <v-select
        v-model="selectedAccount"
        :items="selectedPaymentMethod.accounts"
        label="Bokföringskonto"
        outlined
        return-object
        :item-text="accountNameText"
        mandatory
        :disabled="selectedPaymentMethod.accounts.length < 2"
      ></v-select>
    </div>

    <div class="pt-3">
      <v-menu
        v-model="showDatePickerMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="pickerDate"
            label="Betalningsdatum"
            append-icon="mdi-calendar"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pickerDate"
          :first-day-of-week="1"
          :allowed-dates="allowedPaymentDates"
          @input="showDatePickerMenu = false"
        ></v-date-picker>
      </v-menu>
    </div>

    <div class="pt-3">
      <v-form
        @submit.prevent="registerPayment"
        ref="formAmount"
      >
        <v-text-field
          v-model="inputAmount"
          :label="amountLabel"
          append-icon="coi-currency-sek"
          outlined
          type="number"
          :hide-details="!amountValidation.hasWarning && !amountValidation.hasError && showRemainingAmountButton"
          :error-messages="amountValidation.hasError || amountValidation.hasWarning ? [amountValidation.errorMessage] : []"
          :class="{ 'sub-errors-for-warning': amountValidation.hasWarning }"
        ></v-text-field>
      </v-form>
      <v-chip
        v-show="showRemainingAmountButton"
        :outlined="!isRemainingAmount"
        :class="{ 'mt-2': !amountValidation.hasWarning && !amountValidation.hasError }"
        color="secondary"
        @click="selectRemainingAmount"
      >
        {{ remainingAmountButtonText }}
      </v-chip>
    </div>

    <v-card-actions class="mt-auto flex-grow-0 px-0 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="registerPayment"
        :loading="isLoadingRegisterPayment"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegisterPayment',
  components: {},
  props: {
    customer: Object,
    isOpen: Boolean,
    invoice: Object,
    customerCreditAmount: Number,
    isIncomingCustomerCreditPayment: Boolean
  },
  data: () => ({
    selectedPaymentMethodId: 0,
    selectedAccountId: 0,
    showDatePickerMenu: false,
    selectedPaymentDate: window.dayjs().startOf('day'),
    selectedAmount: 0,
    isStrongValidation: false,
    amountValidation: {
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    }
  }),
  computed: {
    ...mapState(
      {
        paymentMethods: state => state.posCheckout.paymentMethods,
        isLoadingRegisterInvoicePayment: state => state.invoice.isLoadingRegisterPayment,
        isLoadingRegisterCustomerCreditPayment: state => state.customer.isLoadingRegisterCustomerCreditPayment
      }
    ),
    hasInvoice: function () {
      return !!this.invoice
    },
    isLoadingRegisterPayment: function () {
      return this.isLoadingRegisterInvoicePayment || this.isLoadingRegisterCustomerCreditPayment
    },
    inputAmount: {
      get: function () {
        return this.selectedAmount / 100
      },
      set: function (val) {
        let amount = Math.round(val * 100)
        if (Number.isNaN(amount)) {
          amount = 0
        }
        this.selectedAmount = amount
      }
    },
    isIncomingPayment: function () {
      if (this.hasInvoice) {
        return !this.invoice.is_credit_invoice
      } else {
        return this.isIncomingCustomerCreditPayment
      }
    },
    selectedPaymentMethod: {
      get: function () {
        if (this.selectedPaymentMethodId) {
          return this.paymentMethods.find(pm => pm.id === this.selectedPaymentMethodId)
        }
        return this.paymentMethods.find(pm => pm.gateway === 'bankgiro')
      },
      set: function (val) {
        this.selectedPaymentMethodId = val.id
      }
    },
    selectedAccount: {
      get: function () {
        if (this.selectedPaymentMethod.accounts.length === 0) {
          return null
        }
        if (this.selectedAccountId) {
          const account = this.selectedPaymentMethod.accounts.find(account => account.id === this.selectedAccountId)
          if (account) {
            return account
          }
        }
        return this.selectedPaymentMethod.accounts[0]
      },
      set: function (val) {
        this.selectedAccountId = val.id
      }
    },
    pickerDate: {
      get: function () {
        return this.selectedPaymentDate.format('YYYY-MM-DD')
      },
      set: function (val) {
        this.selectedPaymentDate = window.dayjs(val)
      }
    },
    paymentMethodHasMultipleAccounts: function () {
      return this.selectedPaymentMethod.accounts.length > 1
    },
    paymentMethodHasNoAccount: function () {
      return this.selectedPaymentMethod.accounts.length === 0
    },
    paymentMethodErrorMessages: function () {
      if (this.paymentMethodHasNoAccount) {
        return ['Det finns inget konto kopplat till denna betalningsmetod']
      }
      if (this.paymentMethodHasMultipleAccounts) {
        return ['Det finns flera konton kopplade till denna betalningsmetod, välj rätt konto nedan']
      }
      return []
    },
    paymentMethodHasWarning: function () {
      return this.paymentMethodHasMultipleAccounts
    },
    paymentMethodHint: function () {
      if (this.selectedPaymentMethod.accounts.length === 1) {
        const account = this.selectedPaymentMethod.accounts[0]
        return `Betalningen kommer bokföras på konto ${account.account_number} ${account.name}`
      }
      return ''
    },
    displayedPaymentMethods: function () {
      // filtrerar bort payment method integrerad kortterminal
      return this.paymentMethods.filter(pm => pm.gateway !== 'integrated_terminal' && pm.gateway !== 'customer_credit')
    },
    amountLabel: function () {
      if (this.isIncomingPayment) {
        // fakturor eller kontantfaktura
        return 'Mottaget belopp'
      } else {
        return 'Utbetalt belopp'
      }
    },
    buttonText: function () {
      if (this.isIncomingPayment) {
        // fakturor eller kontantfaktura
        return 'Registrera inbetalning'
      } else {
        return 'Registrera utbetalning'
      }
    },
    remainingAmountButtonText: function () {
      if (this.hasInvoice) {
        return 'Återstående fakturabelopp'
      } else {
        return 'Hela tillgodobeloppet'
      }
    },
    showRemainingAmountButton: function () {
      if (!this.hasInvoice && this.isIncomingPayment) {
        // ingen logik i att visa denna knapp om man ska registrera inkommande kundkredit betalning
        return false
      }
      return true
    },
    isRemainingAmount: function () {
      if (this.hasInvoice) {
        return this.invoice && Math.abs(this.invoice.remaining_amount) > 0 && Math.abs(this.invoice.remaining_amount) === this.selectedAmount
      } else {
        return this.customerCreditAmount === this.selectedAmount
      }
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    accountNameText: function (account) {
      return `${account.account_number} ${account.name}`
    },
    allowedPaymentDates: function (date) {
      if (this.hasInvoice) {
        return window.dayjs(date).isSameOrBefore(window.dayjs())
      } else {
        return true // validerar inte datum för registrera utbetalning kundkredit
      }
    },
    validateAmount: function () {
      const amount = this.selectedAmount
      this.amountValidation.hasError = false
      this.amountValidation.hasWarning = false

      if (amount === '' || amount === 0) {
        if (this.isStrongValidation) {
          this.amountValidation.errorMessage = 'Fyll i ett belopp'
          this.amountValidation.hasError = true
          return false
        }
        return true
      }
      const n = Number.parseFloat(amount)
      if (Number.isNaN(n)) {
        this.amountValidation.errorMessage = 'Beloppet måste vara ett tal'
        this.amountValidation.hasError = true
        return false
      }
      if (amount <= 0) {
        if (this.isIncomingPayment) {
          if (this.hasInvoice) {
            this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För återbetalningar välj istället att kreditera hela eller delar av fakturan och gör återbetalning på den skapade kreditfaktura'
          } else {
            this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För utbetalningar välj istället utbetalning längst upp i formuläret'
          }
        } else {
          if (this.hasInvoice) {
            this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För utbetalningar på kreditfakturor ska det angivna beloppet vara positivt'
          } else {
            this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För inbetalningar välj inbetalning högst upp i formuläret'
          }
        }
        this.amountValidation.hasError = true
        return false
      }
      if (this.hasInvoice) {
        // registrerar betalning på faktura
        // validerar inte belopp på registrera utbetalning av kundkredit
        if (amount > Math.abs(this.invoice.remaining_amount)) {
          if (this.isIncomingPayment) {
            if (amount - Math.abs(this.invoice.remaining_amount) > 50) {
              this.amountValidation.errorMessage = 'Beloppet är högre än återstående belopp på fakturan. Överskottet sparas som tillgodo på kundens konto'
              this.amountValidation.hasWarning = true
            }
            return true
          } else {
            this.amountValidation.errorMessage = 'Utbetalningen kan inte vara större än det återstående beloppet på kreditfakturan'
            this.amountValidation.hasError = true
            return false
          }
        }
      }
      return true
    },
    resetForm: function () {
      this.isStrongValidation = false
      this.selectedAmount = 0
      this.selectedPaymentDate = window.dayjs().startOf('day')
      this.validateAmount()
    },
    selectRemainingAmount: function () {
      if (this.hasInvoice) {
        this.selectedAmount = Math.abs(this.invoice.remaining_amount)
      } else {
        this.selectedAmount = this.customerCreditAmount
      }
    },
    registerPayment: function () {
      this.isStrongValidation = true
      const isValid = this.validateAmount() && !this.paymentMethodHasNoAccount
      if (isValid) {
        if (this.hasInvoice) {
          this.$store.dispatch('invoice/registerInvoicePayment', {
            invoice: this.invoice,
            paymentMethod: this.selectedPaymentMethod,
            account: this.selectedAccount,
            date: this.selectedPaymentDate,
            amount: this.selectedAmount
          })
            .then(() => {
              this.resetForm()
              this.close()
            })
        } else {
          // registrera utbetalning kundkredit
          let amount = this.selectedAmount
          if (!this.isIncomingCustomerCreditPayment) {
            amount = amount * -1
          }
          this.$store.dispatch('customer/registerCustomerCreditPayment', {
            customer: this.customer,
            paymentMethod: this.selectedPaymentMethod,
            account: this.selectedAccount,
            date: this.selectedPaymentDate,
            amount
          })
            .then(() => {
              this.resetForm()
              this.$store.commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED')
              this.close()
            })
        }
      }
    }
  },
  watch: {
    isOpen: function () {
      if (!this.isOpen) {
        // återställer formuläret när componenten stängs
        this.resetForm()
      }
    },
    selectedAmount: function () {
      this.validateAmount()
    }
  }
}
</script>
