import Vue from 'vue'
import Vuex from 'vuex'

import API from '@/services/API.js'

import * as Sentry from '@sentry/vue'

// store modules
import * as account from '@/store/modules/account.js'
import * as booking from '@/store/modules/booking.js'
import * as cancellationPolicy from '@/store/modules/cancellationPolicy.js'
import * as country from '@/store/modules/country.js'
import * as customer from '@/store/modules/customer.js'
import * as invoice from '@/store/modules/invoice.js'
import * as localSettings from '@/store/modules/localsettings.js'
import * as map from '@/store/modules/map.js'
import * as onboarding from '@/store/modules/onboarding.js'
import * as position from '@/store/modules/position.js'
import * as posOrder from '@/store/modules/pos/order.js'
import * as posCart from '@/store/modules/pos/cart.js'
import * as posCheckout from '@/store/modules/pos/checkout.js'
import * as posRegister from '@/store/modules/pos/register.js'
import * as posErrors from '@/store/modules/pos/errors.js'
import * as posReceipt from '@/store/modules/pos/receipt.js'
import * as posTerminal from '@/store/modules/pos/terminal.js'
import * as pusher from '@/store/modules/pusher.js'
import * as print from '@/store/modules/print.js'
import * as product from '@/store/modules/product.js'
import * as user from '@/store/modules/user.js'
import * as season from '@/store/modules/season.js'
import * as snackbars from '@/store/modules/snackbars.js'
import * as stats from '@/store/modules/stats.js'
import * as tally from '@/store/modules/tally.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    booking,
    cancellationPolicy,
    country,
    customer,
    invoice,
    localSettings,
    map,
    onboarding,
    position,
    posOrder,
    posCart,
    posCheckout,
    posRegister,
    posErrors,
    posReceipt,
    posTerminal,
    pusher,
    print,
    product,
    user,
    season,
    snackbars,
    stats,
    tally
  },
  state: {
    now: null,
    isOffline: false,
    currentPageName: '',
    isLoadingPage: false,
    isFetchingData: false,
    hasHardwareAccess: false,
    isElectron: false,
    workstation: null,
    globalSearchString: null, // Sätt till '' för att öppna dialog
    confirmationDialog: { // OBS: lägg till nya properties i open och close mutation metoderna nedan också
      show: false,
      header: '', // obligatorisk
      body: '', // obligatorisk
      confirmationFunction: '', // string eller function, obligatorisk
      confirmationArgument: null,
      withTimer: false,
      disableCancel: false
    },
    genericInputDialog: { // OBS: lägg till nya properties i open och close mutation metoderna nedan också
      show: false,
      header: '',
      prefilledValue: '',
      saveFunction: '' // string eller function, obligatorisk
    },
    showUpdateDialog: false,
    updateUrl: null
  },
  mutations: {
    SET_IS_OFFLINE (state, val) {
      state.isOffline = val
    },
    SET_OPEN_CONFIRMATION_DIALOG (state, val) {
      state.confirmationDialog.header = val.header
      state.confirmationDialog.body = val.body
      state.confirmationDialog.confirmationFunction = val.confirmationFunction
      state.confirmationDialog.confirmationArgument = val.confirmationArgument ?? null
      state.confirmationDialog.withTimer = val.withTimer ?? false
      state.confirmationDialog.disableCancel = val.disableCancel ?? false
      state.confirmationDialog.show = true
    },
    SET_CLOSE_CONFIRMATION_DIALOG (state) {
      state.confirmationDialog.show = false
      state.confirmationDialog.header = ''
      state.confirmationDialog.body = ''
      state.confirmationDialog.confirmationFunction = ''
      state.confirmationDialog.confirmationArgument = null
      state.confirmationDialog.withTimer = false
      state.confirmationDialog.disableCancel = false
    },
    SET_OPEN_GENERIC_INPUT_DIALOG (state, val) {
      state.genericInputDialog.header = val.header ?? ''
      state.genericInputDialog.saveFunction = val.saveFunction
      state.genericInputDialog.prefilledValue = val.prefilledValue ?? ''
      state.genericInputDialog.show = true
    },
    SET_CLOSE_GENERIC_INPUT_DIALOG (state) {
      state.genericInputDialog.show = false
      state.genericInputDialog.header = ''
      state.genericInputDialog.saveFunction = ''
      state.genericInputDialog.prefilledValue = ''
    },
    SET_SHOW_UPDATE_DIALOG (state, val) {
      state.showUpdateDialog = val
    },
    SET_UPDATE_URL (state, val) {
      state.updateUrl = val
    },
    SET_CURRENT_PAGE_NAME (state, val) {
      state.currentPageName = val
    },
    SET_IS_LOADING_PAGE (state, val) {
      state.isLoadingPage = val
    },
    SET_IS_FETCHING_DATA (state, val) {
      state.isFetchingData = val
    },
    SET_HAS_HARDWARE_ACCESS (state, val) {
      state.hasHardwareAccess = val
    },
    SET_IS_ELECTRON (state, val) {
      state.isElectron = val
    },
    SET_GLOBAL_SEARCH_STRING (state, val) {
      state.globalSearchString = val
    },
    SET_WORKSTATION (state, val) {
      localStorage.setItem('AUTH_WORKSTATION', JSON.stringify(val))
      if (window.hardware) {
        window.hardware.setConfig('AUTH_WORKSTATION', JSON.stringify(val))
      }
      if (val && val.machine_id) {
        // skriver över med machine_id som kommer från server, likaså accepterar server förslag på befintliga maskinid som skickas in
        localStorage.setItem('AUTH_MACHINE_ID', val.machine_id)
        if (window.hardware) {
          window.hardware.setConfig('AUTH_MACHINE_ID', val.machine_id)
        }
      }
      state.workstation = val
      API.httpClient.defaults.headers.common['x-workstation-id'] = val.id
      Sentry.setTag('workstation_id', val.id)
    }
  },
  actions: {
    initialiseStore ({ dispatch, commit }) {
      commit('SET_IS_OFFLINE', !window.navigator.onLine)
      window.addEventListener('offline', function () {
        commit('SET_IS_OFFLINE', true)
      })
      window.addEventListener('online', function () {
        commit('SET_IS_OFFLINE', false)
      })
      const userAgent = navigator.userAgent.toLowerCase()
      const isElectron = userAgent.indexOf(' electron/') > -1
      commit('SET_IS_ELECTRON', isElectron)
      const hasHardwareAccess = !!window.hardware
      commit('SET_HAS_HARDWARE_ACCESS', hasHardwareAccess)
      dispatch('localSettings/initialise')
      dispatch('user/initialise')
    },
    fetchData ({ commit, dispatch }, { quickly = false }) {
      // Initialisering som kräver authentisering först, startas från user module
      commit('SET_IS_FETCHING_DATA', true)
      let seconds = 4.8
      if (process.env.NODE_ENV === 'development' || quickly) {
        seconds = 0
      }
      const finishFirstAt = window.dayjs().add(seconds, 'seconds')
      // OBS: Tänk på att alla actions nedan behöver returnera en promise om svaret ska inväntas
      // VIKTIGT: saker som syncas med pusher ska läggas till också i resync action i pusher.js, så det hämtas om vid anslutningsproblem
      Promise.allSettled([
        /* syncas med pusher */dispatch('posOrder/fetchOrders'),
        dispatch('position/fetchMap'),
        /* remarks syncas med pusher */dispatch('position/fetchPositions'),
        dispatch('position/fetchCategories'),
        dispatch('country/fetchCountries'),
        dispatch('invoice/fetchPaymentTerms'),
        dispatch('posCheckout/fetchPaymentMethods'),
        /* syncas med pusher */dispatch('booking/fetchBookings'),
        dispatch('cancellationPolicy/fetchCancellationPolicies'),
        dispatch('position/fetchLodgingTypes'),
        dispatch('posRegister/fetchRegister'),
        dispatch('product/fetchProducts'),
        dispatch('product/fetchProductGroups'),
        dispatch('product/fetchProductLayoutTabs'),
        dispatch('season/fetchSeasons'),
        /* syncas med pusher */dispatch('tally/fetchCards'),
        /* syncas med pusher */dispatch('stats/fetchDashboards'),
        /* syncas med pusher */dispatch('stats/fetchDashboardCards')
      ])
        .then(() => {
          console.log('all promises finished')
          Promise.allSettled([
            dispatch('position/enrichPositions'),
            dispatch('position/enrichCategories'),
            dispatch('country/enrichCountries'),
            dispatch('booking/enrichBookings'),
            dispatch('posOrder/enrichOrders'),
            dispatch('product/enrichProducts'),
            dispatch('product/enrichProductGroups'),
            dispatch('product/enrichProductLayoutTabs')
          ])
            .then(() => {
              console.log('all enriched finished')
            })
            .finally(() => {
              // Visar laddningssidan minimum 4.8 sek
              const diff = finishFirstAt.diff(window.dayjs(), 'ms')
              if (diff < 0) {
                commit('SET_IS_FETCHING_DATA', false)
              } else {
                setTimeout(() => {
                  commit('SET_IS_FETCHING_DATA', false)
                }, diff)
              }
            })
        })

      dispatch('pusher/initialise')
    },
    refreshNow ({ state }) {
      state.now = window.dayjs()
    },
    navigated ({ commit }, route) {
      commit('SET_CURRENT_PAGE_NAME', route.to.name)
      window.scannerVM.setCurrentPageName(route.to.name)
    },
    updateClient ({ state }) {
      window.hardware.updateClient(state.updateUrl)
    },
    closeDialogs ({ commit }) {
      commit('invoice/SET_SHOW_DIALOG', false)
      commit('booking/SET_SHOW_DIALOG', false)
      commit('customer/SET_SHOW_DIALOG', false)
      commit('posReceipt/SET_SHOW_DIALOG', false)
      commit('SET_GLOBAL_SEARCH_STRING', null) // stänger sökdialogen
    }
  },
  getters: {}
})
