<template>
  <div class="customer-credit-timeline">
    <template v-if="loading">
      <v-skeleton-loader
        type="image"
      ></v-skeleton-loader>
    </template>
    <template v-else>
      <v-alert :color="netCreditAmount === 0 ? 'primary' : 'success'" text dense class="ma-0 mb-12">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <v-icon :color="netCreditAmount === 0 ? 'primary' : 'success'">
              mdi-account-cash
            </v-icon>
            <div class="ml-4 my-auto" style="line-height: 28px;">Kunden har {{ netCreditAmount / 100 }} kr tillgodo</div>
            <v-menu
              v-model="showTimelineMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ml-auto"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <v-card flat class="px-3 overflow-y-auto" max-height="500">
                <div class="py-4 px-1">
                  <v-btn
                    color="success"
                    @click="openPaymentDialog"
                  >
                    Genomför betalning
                  </v-btn>
                  <v-btn
                    color="success"
                    class="ml-4"
                    text
                    @click="openRegisterPaymentDialog"
                  >
                    Registrera betalning
                  </v-btn>
                </div>

                <v-alert
                  v-if="timelineItems.length === 0"
                  type="info"
                  text
                >
                  <div>
                    Det saknas händelser som påverkar kundens tillgodo
                  </div>
                </v-alert>
                <v-timeline
                  v-else
                  align-top
                  dense
                >
                  <v-timeline-item
                    v-for="(timelineItem, i) in timelineItems"
                    :key="i"
                    :color="timelineItem.amount < 0 ? 'error': 'success'"
                    :icon="timelineItem.amount < 0 ? 'mdi-minus': 'mdi-plus'"
                    fill-dot
                    small
                  >
                    <v-row class="">
                      <v-col cols="3">
                        <strong>
                          {{ formattedDate(timelineItem.date) }}
                        </strong><br>
                        <span class="text-body-2">
                          {{ formattedTime(timelineItem.date) }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <strong>
                          <span v-show="timelineItem.amount > 0">+</span>
                          <span v-show="timelineItem.amount < 0">-</span>
                          {{ Math.abs(timelineItem.amount) / 100 }}kr
                        </strong>
                        <div class="text-body-2">
                          {{ timelineItem.text }}
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <strong>
                          {{ timelineItem.balance_amount / 100 }}kr
                        </strong><br>
                        <span class="text-body-2">Saldo</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card>
            </v-menu>
          </div>
        </div>
      </v-alert>
    </template>
    <div>
      <v-dialog v-model="showDialog" max-width="500">
        <v-sheet color="white" class="pb-2">
          <v-toolbar
            elevation="0"
            class=""
          >
            <v-toolbar-title class="">{{ dialogTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-btn-toggle
            v-model="isRegisterIncomingPayment"
            color="secondary"
            class="pt-2 pb-6 px-4 d-flex full-width"
            mandatory
          >
            <v-btn :value="false" class="flex-grow-1">
              Utbetalning
            </v-btn>
            <v-btn :value="true" class="flex-grow-1">
              Inbetalning
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <div v-if="showPaymentDialog">
          <customer-credit-payment
            :customer="customer"
            :is-incoming-customer-credit-payment="isRegisterIncomingPayment"
            :customer-credit-amount="netCreditAmount"
            :is-open="showPaymentDialog"
            @close="closeDialog"
          ></customer-credit-payment>
        </div>
        <div v-else-if="showRegisterPaymentDialog">
          <register-payment
            :customer="customer"
            :is-open="showRegisterPaymentDialog"
            :is-incoming-customer-credit-payment="isRegisterIncomingPayment"
            :customer-credit-amount="netCreditAmount"
            @close="closeDialog"
          ></register-payment>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import RegisterPayment from '@/components/admin/RegisterPayment.vue'
import CustomerCreditPayment from './CustomerCreditPayment.vue'

export default {
  name: 'CustomerCreditTimeline',
  components: {
    RegisterPayment,
    CustomerCreditPayment
  },
  props: {
    customer: Object,
    customerCreditTimeline: Object,
    loading: Boolean
  },
  data: () => ({
    showTimelineMenu: false,
    showRegisterPaymentDialog: false,
    showPaymentDialog: false,
    isRegisterIncomingPayment: false
  }),
  computed: {
    showDialog: {
      get: function () {
        return this.showRegisterPaymentDialog || this.showPaymentDialog
      },
      set: function (val) {
        if (!val) {
          this.closeDialog()
        }
      }
    },
    netCreditAmount: function () {
      if (!this.customerCreditTimeline || !this.customerCreditTimeline.customerCredit) {
        return 0
      }
      return this.customerCreditTimeline.customerCredit
    },
    timelineItems: function () {
      if (!this.customerCreditTimeline || !this.customerCreditTimeline.timelineItems) {
        return []
      }
      return this.customerCreditTimeline.timelineItems
    },
    dialogTitle: function () {
      if (this.showRegisterPaymentDialog) {
        return 'Registrera betalning mot kundens tillgodo'
      } else if (this.showPaymentDialog) {
        return 'Genomför betalning mot kundens tillgodo'
      }
      return ''
    }
  },
  methods: {
    formattedDate: function (dt) {
      const date = dt.startOf('day')
      return window.conversion.toHumanReadableDate(date)
    },
    formattedTime: function (dt) {
      if (dt.hour() === 0 && dt.minute() === 0 && dt.second() === 0) {
        return ''
      }
      return dt.format('HH:mm')
    },
    openRegisterPaymentDialog: function () {
      this.showRegisterPaymentDialog = true
      this.showPaymentDialog = false
      this.showTimelineMenu = false
    },
    openPaymentDialog: function () {
      this.showPaymentDialog = true
      this.showRegisterPaymentDialog = false
      this.showTimelineMenu = false
    },
    closeDialog: function () {
      this.showRegisterPaymentDialog = false
      this.showPaymentDialog = false
    }
  }
}
</script>
