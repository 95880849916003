<template>
  <div class="">
    <v-chip-group
      v-model="selectedDateChip"
      class="common-dates-chip-group"
      column
      color="secondary"
    >
      <v-chip
        v-for="(dates, i) in commonDateSearches"
        :key="i"
        outlined
      >
        {{ dates.buttonText }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>

export default {
  name: 'CommonDatesSelection',
  props: {
    checkInDate: Object,
    checkOutDate: Object
  },
  data: () => ({
    commonDateSearches: []
  }),
  computed: {
    selectedDateChip: {
      get: function () {
        const i = this.commonDateSearches.findIndex(datePair => {
          return datePair.checkin.isSame(this.checkInDate) && datePair.checkout.isSame(this.checkOutDate)
        })
        return i >= 0 ? i : null
      },
      set: function (i) {
        if (i !== undefined) {
          const datePair = this.commonDateSearches[i]
          this.$emit('set-dates', { checkin: datePair.checkin, checkout: datePair.checkout })
        }
      }
    }
  },
  methods: {
    getCommonDateSearches: function () {
      const today = window.dayjs().startOf('day')
      const arr = [
        {
          checkin: today,
          checkout: today.add(1, 'day'),
          buttonText: 'Till imorgon'
        }
      ]
      if (today.day() === 5) {
        // Det är fredag mina bekanta
        arr.push({
          checkin: today,
          checkout: today.add(2, 'day'),
          buttonText: 'Över helgen'
        })
      } else {
        arr.push({
          checkin: today,
          checkout: today.add(2, 'day'),
          buttonText: 'Två nätter'
        })
        const friday = (today.day() === 6) ? today.add(6, 'days') : today.add(5 - today.day(), 'days')
        arr.push({
          checkin: friday,
          checkout: friday.add(2, 'day'),
          buttonText: 'Nästa helg'
        })
      }

      this.commonDateSearches = arr
    }
  },
  watch: {

  },
  created () {
    this.getCommonDateSearches()
  }
}
</script>
