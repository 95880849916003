<template>
  <v-card class="login-card overflow-hidden">
    <v-img
      v-if="!isMobile"
      class="p-absolute"
      :src="require('@/assets/images/artsy_alpaca.jpg')"
      height="100%"
    ></v-img>
    <v-form  @submit.prevent="login" class="d-flex flex-column">
      <v-toolbar
        :color="showError ? 'error' : isMobile ? 'primary' : 'transparent'"
        dark
        flat
      >
        <v-toolbar-title v-if="isOffline">Datorn är offline</v-toolbar-title>
        <v-toolbar-title v-else-if="isLockscreen && hasTimeoutError">Uppkoppling avbruten</v-toolbar-title>
        <v-toolbar-title v-else-if="isLockscreen && hasVersionError">Programmet behöver uppdateras</v-toolbar-title>
        <v-toolbar-title v-else>Inloggning</v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="!isMobile" class="d-flex flex-column">
          <span class="text-subtitle-2 text-right">v. {{ appVersion }}</span>
          <span class="text-subtitle-2 text-right">{{ appVersionName }}</span>
        </div>
      </v-toolbar>
      <div class="p-absolute mt-16" style="height: 100%; width: 100%; background-color: white; opacity: .94;"></div>
      <v-card-text class="opacity-1 z-2">
        <div v-if="isLockscreen" class="d-flex flex-column">
          <div class="d-flex justify-center align-center full-width mt-4">
            <v-avatar :color="user.color" size="100">
              <span class="white--text" v-text="user.initials" style="font-size: 2.5em;"></span>
            </v-avatar>
          </div>
          <v-card-title style="width: 100%;" class="pb-0 d-flex justify-center">
            <div class="display-1">
              {{ user.name }}
            </div>
          </v-card-title>
          <span class="text-overline pb-0 d-flex justify-center" style="width: 100%; line-height: 30px;">
            {{ user.email }}
          </span>
        </div>
        <div v-else>
          <v-input
            v-if="!isAddShortlistDomain"
            prepend-icon="mdi-server"
            class="pt-2 domain-input"
            label="Domän"
            append-icon="mdi-plus success--text"
            @click:append="startAddShortlistDomain"
            :error-messages="form.errors.domain"
          >
            <v-chip-group
              v-model="form.domainId"
              color="secondary"
              center-active
              mandatory
              small
            >
              <v-chip
                v-for="shortlistDomain in shortlistDomains"
                :key="shortlistDomain.id"
                :value="shortlistDomain.id"
                close
                @click:close="removeShortlistDomain(shortlistDomain)"
              >
                {{ shortlistDomain.name }}
              </v-chip>
            </v-chip-group>
          </v-input>
          <div v-else>
            <v-autocomplete
              v-model="domainToAdd"
              :items="allDomains"
              return-object
              item-text="name"
              prepend-icon="mdi-server"
              label="Lägg till ny domän"
              :error-messages="form.errors.domain"
              hint="Välj en domän sen klicka 'lägg till'"
              :persistent-hint="showDomainHint"
            >
              <template v-slot:append-outer>
                <v-btn
                  text
                  color="success"
                  @click="addShortlistDomain"
                >
                  Lägg till
                </v-btn>
                <v-btn
                  v-if="shortlistDomains.length > 0"
                  text
                  @click="isAddShortlistDomain = false;"
                >
                  Tillbaka
                </v-btn>
              </template>
            </v-autocomplete>
          </div>

          <v-text-field
            v-model="form.email"
            label="Användarnamn"
            name="email"
            prepend-icon="mdi-account"
            type="text"
            :error-messages="form.errors.email"
          ></v-text-field>

          <v-text-field
            id="password"
            v-model="form.password"
            label="Lösenord"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            :error-messages="form.errors.password"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions class="mt-auto">
        <v-spacer></v-spacer>

        <v-btn v-show="isLockscreen" text color="primary" class="ma-2" @click="changeUser" :disabled="form.isLoading">Byt användare</v-btn>
        <v-btn v-show="isLockscreen" color="primary" class="ma-2" @click="reconnect" :loading="form.isLoading">Återanslut</v-btn>

        <v-btn v-show="!isLockscreen" type="submit" color="primary" class="ma-2" :loading="form.isLoading">Logga in</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<style lang="scss">
  .login-card {
    .domain-input .v-input__prepend-outer,
    .domain-input .v-input__append-outer {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .domain-input .v-label {
      left: -6px !important;
      position: absolute !important;
      max-width: 133%;
      transform: translateY(-28px) scale(.75);
      pointer-events: auto;
    }
    .domain-input .v-messages {
      margin-top: -8px;
    }
  }
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoginCard',
  props: {
    isMobile: Boolean
  },
  data: () => ({
    isAddShortlistDomain: false,
    domainToAdd: null,
    showDomainHint: false,
    form: {
      email: '',
      password: '',
      domainId: null,
      errors: {},
      isLoading: false
    }
  }),
  computed: {
    ...mapState(
      {
        user: state => state.user.user,
        hasTimeoutError: state => state.user.hasTimeoutError,
        hasVersionError: state => state.user.hasVersionError,
        domain: state => state.user.domain,
        token: state => state.user.token,
        allDomains: state => state.user.allDomains,
        shortlistDomains: state => state.user.shortlistDomains,
        isOffline: state => state.isOffline
      }
    ),
    isLockscreen: function () {
      return (this.token && this.user && this.domain)
    },
    showError: function () {
      return (this.isOffline || (this.isLockscreen && this.hasTimeoutError) || (this.isLockscreen && this.hasVersionError))
    },
    appVersion: function () {
      return process.env.VUE_APP_VERSION
    },
    appVersionName: function () {
      return process.env.VUE_APP_VERSION_NAME
    }
  },
  methods: {
    changeUser: function () {
      this.disconnect()
    },
    disconnect: function () {
      this.$store.dispatch('user/logout')
    },
    reconnect: function () {
      this.form.isLoading = true
      this.form.errors = {}
      this.$store.dispatch('user/reconnect')
        .then((data) => {
          if (!data.user) {
            this.disconnect()
          }
        })
        .catch((err) => {
          this.form.errors = {}
          // REVIEW: Kanske kan skippa disconnect här? Om t.ex. internet är nere vill vi ju inte att man ska behöva logga in igen
          // SVAR: jag är osäker på varför det varit med från första början så jag vet inte, några statusar är kanske ändå bra att de får ange användarnamn och lösenord igen?
          if (err.response !== undefined && err.response.status !== 403) {
            this.disconnect()
          }
        })
        .finally(() => {
          this.form.isLoading = false
        })
    },
    login: function () {
      this.form.errors = {}
      let domain
      if (this.isAddShortlistDomain) {
        domain = this.domainToAdd
      } else {
        domain = this.allDomains.find(d => d.id === this.form.domainId)
      }
      if (!domain) {
        this.form.errors.domain = 'Välj en domän'
      }
      if (this.form.password === '') {
        this.form.errors.password = 'Ange lösenord'
      }
      if (this.form.email === '') {
        this.form.errors.email = 'Ange användarnamn (email)'
      }
      if (this.form.errors.domain || this.form.errors.password || this.form.errors.email) {
        return false
      }
      this.form.isLoading = true
      this.$store.commit('user/UPDATE_SHORTLIST_DOMAIN', { domainId: domain.id, lastUsedAt: window.dayjs(), lastUsedEmail: this.form.email })
      this.$store.dispatch('user/login', {
        domain,
        email: this.form.email,
        password: this.form.password
      })
        .catch((err) => {
          if (err.response === undefined || err.code === 'ERR_NETWORK') {
            this.form.errors.password = 'Kunde inte kommunicera med servern.'
          } else {
            if (err.response.data.errors) {
              this.form.errors = err.response.data.errors
            } else {
              this.form.errors.password = err.response.data.message
            }
          }
        })
        .finally(() => {
          this.form.isLoading = false
        })
    },
    startAddShortlistDomain: function () {
      this.isAddShortlistDomain = true
    },
    addShortlistDomain: function () {
      if (this.domainToAdd) {
        this.showDomainHint = false
        this.$store.commit('user/ADD_SHORTLIST_DOMAIN', this.domainToAdd.id)
        this.isAddShortlistDomain = false
      } else {
        this.showDomainHint = true
      }
    },
    removeShortlistDomain: function (domain) {
      this.$store.commit('user/REMOVE_SHORTLIST_DOMAIN', domain.id)
      if (this.form.domainId === domain.id) {
        this.form.domainId = null
      }
    }
  },
  watch: {
    'form.email': function () {
      this.form.errors.email = ''
    },
    'form.password': function () {
      this.form.errors.password = ''
    },
    'form.domainId': function () {
      this.form.errors.domain = ''
      if (this.form.domainId) {
        const domain = this.allDomains.find(d => d.id === this.form.domainId)
        if (domain.lastUsedEmail) {
          this.form.email = domain.lastUsedEmail
        }
      } else {
        this.form.email = ''
      }
      this.form.password = ''
    },
    domainToAdd: function () {
      this.form.errors.domain = ''
      if (this.domainToAdd && this.domainToAdd.lastUsedEmail) {
        this.form.email = this.domainToAdd.lastUsedEmail
      } else {
        this.form.email = ''
      }
      this.form.password = ''
    },
    shortlistDomains: function () {
      if (this.shortlistDomains.length === 0) {
        this.isAddShortlistDomain = true
      }
    }
  },
  mounted () {
    if (this.shortlistDomains.length === 0) {
      this.isAddShortlistDomain = true
    } else {
      this.form.domainId = this.domain.id
    }
  }
}
</script>
