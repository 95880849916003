<template>
  <div>
    <v-btn
      @click="searchButtonClicked"
      color="grey lighten-4"
      large
      rounded
      :icon="isMobile"
      :min-width="isMobile ? 'auto' : 400"
    >
      <div class="d-flex full-width">
        <v-icon :color="isMobile ? 'white' : 'black'" class="my-auto">mdi-magnify</v-icon>
        <span v-show="!isMobile" class="black--text my-auto ml-4">{{ searchLabel }}</span>
      </div>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      content-class="app-search-dialog"
      @keydown="keydown"
      :fullscreen="isMobile"
    >
      <v-card tile class="app-search-dialog-card rounded-b-0 elevation-2 z-2" rounded="lg">
        <v-card-title class="d-flex pa-0">
          <v-text-field
            v-model="searchInput"
            class="search-input"
            large
            solo
            flat
            hide-details
            autofocus
            height="70"
            :loading="isLoading"
            prepend-inner-icon="mdi-magnify"
            :placeholder="isMobile ? 'Sök' : 'Sök på gäster, bokningar, kartplatser och produkter'"
          >
          <template v-slot:append>
            <v-btn @click="clearSearchInput" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          </v-text-field>
        </v-card-title>
      </v-card>
      <div class="search-results-box">
        <v-card class="full-height rounded-t-0 d-flex flex-column" flat rounded="lg">
          <template v-if="!!errorMessage">
            <div class="d-flex my-auto full-width">
              <v-alert
                dense
                text
                type="error"
                class="mx-auto mt-4"
              >
                {{ errorMessage }}
              </v-alert>
            </div>
          </template>
          <template v-else-if="showNoResultsAlert">
            <div class="d-flex flex-column my-auto full-width">
              <div class="mx-auto mt-6">
                <v-icon large>mdi-ghost-outline</v-icon>
              </div>
              <p class="text-caption-2 text--secondary my-auto mx-auto py-6">
                Inga sökresultat hittades
              </p>
            </div>
          </template>
          <div v-else class="card-body">
            <div class="search-results-list-container">
              <template v-if="showSkeletonLoadersSearchResultsList">
                <div class="d-flex flex-column mb-auto w-75 mr-auto">
                  <div
                    v-for="i in 6"
                    :key="i"
                  >
                    <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                  </div>
                </div>
              </template>
              <v-list v-else class="search-results-list">
                <v-alert
                  v-show="showRecentSearches && displayedSearchResults.length > 0"
                  color="primary"
                  tile
                  transition="fade-transition"
                  text
                  dense
                  class="ma-0 full-width text-center"
                >
                  Sökhistorik
                </v-alert>
                <div
                  v-for="(searchResult, i) in displayedSearchResults"
                  :key="searchResult.unique_id"
                  :id="`app-search-${searchResult.unique_id}`"
                >
                  <template v-if="searchResult.group_title !== displayedSearchResults[i - 1]?.group_title && !showRecentSearches">
                    <v-subheader>{{ searchResult.group_title }}</v-subheader>
                  </template>
                  <template>
                    <v-list-item
                      @click="selectedResult(searchResult)"
                      @mouseover="selectionIndex = i"
                      :class="{ 'selected': selectionIndex === i }"
                    >
                      <v-list-item-avatar :color="searchResult.avatar.color" class="justify-center">
                        <span v-if="searchResult.avatar.text" class="white--text" v-text="searchResult.avatar.text"></span>
                        <v-icon
                          color="white"
                          v-else-if="searchResult.avatar.icon"
                        >
                          {{ searchResult.avatar.icon }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="searchResult.title"></v-list-item-title>
                        <v-list-item-subtitle v-html="searchResult.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon v-show="selectionIndex === i" class="my-auto">
                        <v-icon class="my-auto">mdi-keyboard-return</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </div>
              </v-list>
            </div>
            <v-divider v-if="!isMobile" vertical></v-divider>
            <div v-if="!isMobile" class="selected-search-result-preview d-flex">
              <div v-if="!!selectedSearchResult && selectedSearchResult.type !== 'query'" class="d-flex flex-column full-height mx-auto full-width">
                <div class="flex-grow-0 d-flex flex-column px-8 mb-12">
                  <v-avatar
                    :color="selectedSearchResult.avatar.color"
                    size="80"
                    class="mx-auto mt-8 mb-4"
                  >
                    <span v-if="selectedSearchResult.avatar.text" class="white--text" style="font-size: 28px;" v-text="selectedSearchResult.avatar.text"></span>
                    <v-icon v-else-if="selectedSearchResult.avatar.icon" color="white" x-large>{{ selectedSearchResult.avatar.icon }}</v-icon>
                  </v-avatar>
                  <div class="text-center" style="font-size: 28px;" v-html="selectedSearchResult.title"></div>
                  <div class="text-center" v-html="selectedSearchResult.subtitle"></div>
                </div>

                <div class="flex-grow-1 overflow-y-auto px-8">
                  <div v-if="hasPreviewDataError" class="ma-auto">
                    <v-alert
                      dense
                      text
                      type="error"
                    >
                      Något gick fel - förhandsvisning inte tillgänglig
                    </v-alert>
                  </div>
                  <template v-else-if="showSkeletonLoadersPreviewData">
                    <div class="d-flex flex-column mb-auto w-75 mr-auto">
                      <div
                        v-for="i in 3"
                        :key="i"
                      >
                        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                      </div>
                    </div>
                  </template>
                  <div v-show="!showSkeletonLoadersPreviewData">
                    <search-preview-pane
                      :data="searchResultPreviewData"
                      @close="close"
                    ></search-preview-pane>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <v-card-actions v-if="!isMobile" class="elevation-4">
            <div class="pr-12">
              <v-icon class="mr-1" color="primary">mdi-keyboard-return</v-icon> för att välja
            </div>
            <div class="pr-12">
              <v-icon color="primary">mdi-arrow-up</v-icon><v-icon class="mr-1" color="primary">mdi-arrow-down</v-icon> för att navigera
            </div>
            <div class="pr-12">
              <v-icon class="mr-1" color="primary">mdi-keyboard-esc</v-icon> för att stänga
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchService from '@/services/SearchService.js'
import Fuse from 'fuse.js'
import { mapState } from 'vuex'

import SearchPreviewPane from '@/components/layout/SearchPreviewPane.vue'

export default {
  name: 'AppSearch',
  components: {
    SearchPreviewPane
  },
  data: () => ({
    isLoading: false,
    selectionIndex: 0,
    errorMessage: '',
    searchResponse: null,
    fuse: null,
    fuseResults: [],
    recentSearches: [],
    searchResultPreviewData: null,
    hasPreviewDataError: false
  }),
  computed: {
    ...mapState(
      {
        products: state => state.product.products
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },
    searchInput: {
      get: function () {
        return this.$store.state.globalSearchString
      },
      set: function (val) {
        this.$store.commit('SET_GLOBAL_SEARCH_STRING', val)
      }
    },
    showDialog: {
      get: function () {
        return this.searchInput !== null
      },
      set: function (val) {
        if (!val) {
          if (this.searchInput) {
            this.addToRecentSearches({
              type: 'query',
              title: `"${this.searchInput}"`,
              value: this.searchInput,
              id: 0,
              avatar: {
                color: 'primary',
                icon: 'mdi-history'
              }
            })
          }
          this.searchInput = null
        } else if (this.searchInput === null) {
          this.searchInput = ''
        }
      }
    },
    searchLabel: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'Sök'
      } else {
        return 'Sök (ctrl + F)'
      }
    },
    hasNoSearchResults: function () {
      if (!this.searchResponse) {
        return false
      }
      return this.searchResponse && this.displayedSearchResults.length === 0
    },
    showNoResultsAlert: function () {
      return this.hasNoSearchResults && !!this.searchInput && (!this.isLoading || window.navigator.connection?.downlink >= 2)
    },
    showSkeletonLoadersSearchResultsList: function () {
      return this.isLoading && this.hasNoSearchResults && window.navigator.connection?.downlink < 2
    },
    showSkeletonLoadersPreviewData: function () {
      return this.selectedSearchResult && (!this.searchResultPreviewData || this.searchResultPreviewData.uniqueId !== this.selectedSearchResult.unique_id)
    },
    showRecentSearches: function () {
      // visar senaste sökningar istället för sökresultat om inte skrivit in någon sök query
      return !this.searchInput
    },
    displayedSearchResults: function () {
      if (!this.searchInput) {
        return this.recentSearches
      }

      // const displayedResults = []
      if (this.errorMessage) {
        return []
      }

      if (!this.searchResponse || this.searchResponse.search_results.length === 0) {
        return []
      }

      const options = {
        includeMatches: true,
        shouldSort: false,
        distance: 1000, // Hur många karaktärer den ska söka på från början av strängen (default 100)
        threshold: 1, // Inkludera alla resultat, även de som Fuse tycker är helt irrelevanta (score 1), server får bestämma urvalet helt (default 0.6)
        keys: ['title', 'subtitle']
      }

      const fuse = new Fuse(this.searchResponse.search_results, options)
      const fuseResults = fuse.search(this.searchResponse.input)
      const highlightedSearchResults = window.conversion.fuseHighlight(fuseResults, 'font-weight-black')

      return highlightedSearchResults
    },
    selectedSearchResult: function () {
      // Det objekt som visas i preview
      const searchResult = this.displayedSearchResults[this.selectionIndex]
      if (!searchResult) {
        return null
      }
      return this.displayedSearchResults[this.selectionIndex]
    }
  },
  methods: {
    keydown: function (e) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.showDialog = false
      } else if (e.key === 'Return' || e.key === 'Enter') {
        const selectedResult = this.displayedSearchResults[this.selectionIndex]
        if (selectedResult) {
          this.selectedResult(selectedResult)
        }
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        // Navigerar sökresultatlistan med arrow keys
        e.preventDefault() // för att inte cursor ska gå till början av input vid ArrowUp
        if (this.displayedSearchResults.length === 0) {
          return false
        }
        let selectedResult = null
        let iterations = 0
        while (!selectedResult) {
          if (iterations++ > 10) {
            break
          }
          e.key === 'ArrowDown' ? this.selectionIndex++ : this.selectionIndex--
          if (this.selectionIndex < 0) {
            this.selectionIndex = this.displayedSearchResults.length - 1
          } else if (this.selectionIndex >= this.displayedSearchResults.length) {
            this.selectionIndex = 0
          }
          selectedResult = this.displayedSearchResults[this.selectionIndex]
        }
        const selectedElement = document.getElementById(`app-search-${selectedResult.unique_id}`)
        if (selectedElement) {
          selectedElement.scrollIntoView({ behaviour: 'smooth', block: 'nearest' })
        }
      }
    },
    searchButtonClicked: function () {
      this.showDialog = true
    },
    selectedResult: function (searchResult) {
      // Klickat på sökresultat i dialogen
      if (searchResult.type === 'query') {
        // klickat på sökhistorik sökning
        this.searchInput = searchResult.value
        return false
      }
      this.searchInput = null // Stänger dialogen på det här viset istället för att sätta showDialog = false för att inte lägga till söktexten i sökhistoriken
      this.addToRecentSearches(searchResult)

      let product = null
      switch (searchResult.type) {
        case 'customer':
          this.$store.dispatch('customer/openDialogById', { customerId: searchResult.id })
          break

        case 'booking':
          this.$store.dispatch('booking/openDialogById', { bookingId: searchResult.id })
          break

        case 'position':
          this.$router.push({ name: 'Map', params: { positionId: searchResult.id } })
          break

        case 'booking_group':
          break

        case 'product':
          // Kan hända att produkten på servern inte finns lokalt, hämtar isåfall om produkter och kontrollerar igen
          product = this.products.find(prod => prod.id === searchResult.id)
          if (product) {
            this.$store.dispatch('posCart/addToCart', product)
          } else {
            this.$store.dispatch('product/reloadProducts')
              .then(() => {
                product = this.products.find(prod => prod.id === searchResult.id)
                if (product) {
                  this.$store.dispatch('posCart/addToCart', product)
                }
              })
          }
          break

        default:
          break
      }
    },
    clearSearchInput: function () {
      if (this.searchInput) {
        this.searchInput = ''
      } else {
        this.showDialog = false
      }
    },
    close: function () {
      this.showDialog = false
    },
    addToRecentSearches: function (val) {
      let recentSearches = this.recentSearches

      // Om redan finns samma i historiken, tar bort det först innan den läggs till överst
      if (val.type === 'query') {
        recentSearches = recentSearches.filter(searchResult => searchResult.type !== 'query' || searchResult.value !== this.searchInput)
      } else {
        recentSearches = recentSearches.filter(searchResult => searchResult.type !== val.type || searchResult.id !== val.id)
      }

      recentSearches.unshift(val)
      recentSearches = recentSearches.splice(0, 20) // sparar max 20st

      const stringValue = JSON.stringify(recentSearches)
      localStorage.setItem('RECENT_SEARCH_RESULTS', stringValue)

      this.recentSearches = recentSearches
    },
    getSearchResultPreviewData: function (searchResult) {
      if (searchResult.type === 'query' || (this.searchResultPreviewData && this.searchResultPreviewData.unique_id === searchResult.unique_id)) {
        // har redan hämtat data
        return false
      }

      this.hasPreviewDataError = false
      SearchService.getSearchResultPreviewData({ type: searchResult.type, id: searchResult.id })
        .then(({ data }) => {
          if (searchResult?.unique_id === this.selectedSearchResult.unique_id) {
            // selectedSearchResult har inte ändrats sedan request skickades
            if (data.status === 'success') {
              if (data.data.booking) {
                window.enrich.enrichBooking(data.data.booking)
              }
              if (data.data.customer_bookings) {
                window.enrich.enrichBooking(data.data.customer_bookings)
              }
              if (data.data.checked_in_position_booking) {
                window.enrich.enrichBooking(data.data.checked_in_position_booking)
              }
              if (data.data.future_position_bookings) {
                window.enrich.enrichBooking(data.data.future_position_bookings)
              }
              if (data.data.sales_history_data) {
                data.data.sales_history_data.forEach(salesObject => {
                  salesObject.month = window.dayjs(salesObject.month)
                })
              }
              this.searchResultPreviewData = {
                uniqueId: searchResult.unique_id,
                type: searchResult.type,
                id: searchResult.id,
                previewData: data.data
              }
            } else {
              this.hasPreviewDataError = true
            }
          }
        })
        .catch((err) => {
          console.log('search error:', err)
          this.hasPreviewDataError = true
        })
    }
  },
  watch: {
    showDialog: function () {
      if (!this.showDialog) {
        // Återställer variabler när dialog stängs
        this.selectionIndex = 0
      }
    },
    selectedSearchResult: function () {
      if (this.selectedSearchResult) {
        const val = this.selectedSearchResult
        setTimeout(() => {
          if (this.selectedSearchResult && val.unique_id === this.selectedSearchResult.unique_id) {
            // har varit samma valda sökresultat i 500ms, hämtar preview data att visa
            this.getSearchResultPreviewData(this.selectedSearchResult)
          }
        }, 500)
      }
    },
    searchInput: function (val) {
      // Skickar sökrequest till server när searchInput ändras
      this.errorMessage = ''

      if (val === null || val === '') {
        this.searchResponse = null
        if (val === this.searchInput) {
          this.isLoading = false
        }
        return false
      }

      this.isLoading = true
      SearchService.search(val)
        .then(({ data }) => {
          if (val === this.searchInput) {
            this.searchResponse = data.data
          }
        })
        .catch((err) => {
          console.log('search error:', err)
          if (val === this.searchInput) {
            this.errorMessage = `Ett fel har inträffat, felkod: ${err.response ? err.response.status : 'okänd'}`
          }
        })
        .finally(() => {
          if (val === this.searchInput) {
            this.isLoading = false
          }
        })
    }
  },
  mounted () {
    const recentSearchResults = localStorage.getItem('RECENT_SEARCH_RESULTS')
    if (recentSearchResults) {
      const parsedValue = JSON.parse(recentSearchResults)
      this.recentSearches = parsedValue
    }
  }
}
</script>
