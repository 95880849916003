<template>
  <v-dialog v-model="show" max-width="500px" :fullscreen="isMobile">
    <div class="position-block-dialog p-relative">
      <template v-if="!!positionBlock || isCreatingMode">
        <v-card tile class="full-height d-flex flex-column">
          <v-toolbar
            style="height: auto;"
            elevation="0"
            class="py-3 toolbar-with-subheader flex-grow-0"
            color="white"
          >
            <div class="toolbar-titles-container">
              <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
              <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="!isCreatingMode"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="startEdit"
                  class="mr-2"
                >
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
              </template>
              <div>
                Redigera platsavstängning
              </div>
            </v-tooltip>
            <v-btn icon v-if="isMobile" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <position-block-form
            v-if="isCreatingMode"
            v-model="dialog.editedPositionBlock"
            :is-saving="dialog.isSaving"
          ></position-block-form>

          <div v-else class="d-flex flex-column full-height">
            <v-list subheader class="flex-grow-1 px-4 pt-4">
              <v-list-item class="mb-4" style="min-height: 62px;">
                <v-list-item-icon class="my-auto">
                  <v-icon>mdi-map-marker-off</v-icon>
                </v-list-item-icon>
                <div class="d-flex flex-row" style="flex: unset;">
                  <div class="d-flex flex-column text-left">
                    <v-list-item-title class="text-left full-width">
                      <span>{{ closingDateFormatted }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="">Stängd från och med</v-list-item-subtitle>
                  </div>
                  <div class="d-flex ml-4">
                    <template v-if="positionBlock.status === 'ongoing'">
                      <v-chip
                        small
                        color="success"
                        text-color="white"
                        class="my-auto"
                      >
                        Pågående
                      </v-chip>
                    </template>
                    <template v-else-if="positionBlock.status === 'passed'">
                      <v-chip
                        small
                        color="primary"
                        text-color="white"
                        class="my-auto"
                      >
                        Passerad
                      </v-chip>
                    </template>
                    <template v-else-if="positionBlock.status === 'future'">
                      <v-chip
                        small
                        color="info"
                        text-color="white"
                        class="my-auto"
                      >
                        Planerad
                      </v-chip>
                    </template>
                  </div>
                </div>
              </v-list-item>

              <v-list-item class="mb-4" style="min-height: 62px;">
                <v-list-item-icon class="my-auto">
                  <v-icon>mdi-map-marker-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ openingDateFormatted }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Platsen öppnar igen</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item style="min-height: 62px;">
                <v-list-item-icon class="my-auto">
                  <v-icon>mdi-text-box-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="white-space: break-spaces;">"{{ positionBlock.reason ? positionBlock.reason : ' ' }}"</v-list-item-title>
                  <v-list-item-subtitle>Anteckning</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions class="pa-3">
              <v-btn text color="error" @click="deletePositionBlock" :loading="dialog.isDeleting">Ta bort platsavstängning</v-btn>
            </v-card-actions>
          </div>
        </v-card>

        <div v-if="!!dialog.editedPositionBlock" class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
          <v-fade-transition>
            <div @click="closeForm" :class="{ 'd-none': true, 'd-md-block': isEditMode }" style="height: 64px; min-height: 64px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
          </v-fade-transition>
          <v-scroll-y-reverse-transition>
            <div v-show="isEditMode" class="flex-grow-1">
              <v-card class="full-height d-flex flex-column" width="500px" light style="pointer-events: auto;">
                <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
                  <div class="d-flex">
                    <v-toolbar-title class="headline">Redigera platsavstängning</v-toolbar-title>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeForm">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="flex-grow-1 pt-3">
                  <position-block-form
                    v-model="dialog.editedPositionBlock"
                    :is-saving="dialog.isSaving"
                  ></position-block-form>
                </div>
              </v-card>
            </div>
          </v-scroll-y-reverse-transition>
        </div>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import PositionBlockForm from './PositionBlockForm.vue'

export default {
  name: 'PositionBlockDialog',
  components: {
    PositionBlockForm
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        dialog: state => state.position.positionBlockDialog
      }
    ),
    show: {
      get () {
        return this.dialog.show
      },
      set (value) {
        this.$store.commit('position/SET_SHOW_POSITION_BLOCK_DIALOG', value)
      }
    },
    positionBlock: function () {
      return this.dialog.positionBlock
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isCreatingMode: function () {
      return this.dialog.isEditMode && this.dialog.editedPositionBlock && this.dialog.editedPositionBlock.id === 0
    },
    isEditMode: function () {
      return this.dialog.isEditMode && !this.isCreatingMode
    },
    title: function () {
      if (this.isCreatingMode) {
        return 'Lägg till ny platsavstängning'
      }
      return `Platsavstängning ${this.positionBlock.position.full_name}`
    },
    subtitle: function () {
      if (this.isCreatingMode) {
        return this.dialog.editedPositionBlock.position.full_name
      }
      const nightsFormatted = window.conversion.diffInDays(this.positionBlock.closing_date, this.positionBlock.opening_date)
      return `Avstängd i ${nightsFormatted}`
    },
    closingDateFormatted: function () {
      return window.conversion.toHumanReadableDate(this.positionBlock.closing_date)
    },
    openingDateFormatted: function () {
      return window.conversion.toHumanReadableDate(this.positionBlock.opening_date)
    }
  },
  methods: {
    close: function () {
      this.show = false
    },
    startEdit: function () {
      this.$store.dispatch('position/startEditPositionBlock', { positionBlock: this.positionBlock })
    },
    deletePositionBlock: function () {
      this.$store.dispatch('position/deletePositionBlock', { positionBlock: this.positionBlock })
    },
    closeForm: function () {
      this.$store.commit('position/SET_POSITION_BLOCK_DIALOG_IS_EDIT_MODE', false)
    }
  }
}
</script>
