var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-avatar",staticStyle:{"text-align":"center"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.clickable},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-avatar',_vm._g(_vm._b({class:{
          'justify-center ml-0': true,
          'cursor-pointer': _vm.clickable,
          'mx-0 my-0': !_vm.listItem,
          'large': _vm.large,
          'clickable': _vm.clickable
        },attrs:{"color":_vm.customerColor,"size":_vm.large ? 48 : 30},on:{"click":_vm.onClick}},'v-list-item-avatar',attrs,false),on),[_c('span',{staticClass:"white--text",domProps:{"textContent":_vm._s(_vm.customerInitials)}})])]}}])},[_c('span',[_vm._v("Visa kund")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }