import API from './API.js'

export default {
  //
  // GET
  //
  getPositions () {
    return API.httpClient.get('positions')
  },
  getCategories () {
    return API.httpClient.get('categories')
  },
  getLodgingTypes () {
    return API.httpClient.get('categories/lodging-types')
  },
  getAvailabilities ({ checkIn, checkOut, bookingId, occupancy, dogs }) {
    return API.httpClient.get('positions/availability', {
      params: {
        check_in: checkIn.format('YYYY-MM-DD'),
        check_out: checkOut.format('YYYY-MM-DD'),
        booking_id: bookingId,
        occupancy,
        dogs
      }
    })
  },
  getCategoryAvailabilities ({ checkIn, checkOut, bookingId, occupancy, dogs }) {
    return API.httpClient.get('categories/availability', {
      params: {
        check_in: checkIn.format('YYYY-MM-DD'),
        check_out: checkOut.format('YYYY-MM-DD'),
        booking_id: bookingId,
        occupancy,
        dogs
      }
    })
  },
  getPositionBlocks ({ positionId = null }) {
    return API.httpClient.get('positions/blocks', {
      params: {
        position_id: positionId
      }
    })
  },
  getPositionBlock ({ positionBlockId }) {
    return API.httpClient.get('positions/blocks/show', {
      params: {
        position_block_id: positionBlockId
      }
    })
  },
  getPositionCalendarEvents (position_id) {
    return API.httpClient.get('calendar/position', {
      params: {
        position_id
      }
    })
  },
  getMostRecentElectricRecord ({ positionId }) {
    return API.httpClient.get('positions/most-recent-electric-record', {
      params: {
        position_id: positionId
      }
    })
  },
  getElectricRecords ({ positionId, limitId }) {
    return API.httpClient.get('positions/electric-records', {
      params: {
        position_id: positionId,
        limit_id: limitId
      }
    })
  },
  getCalendarEvents () {
    return API.httpClient.get('calendar')
  },

  //
  // POST
  //
  updateRemarks ({ positionId, remarks }) {
    return API.httpClient.post(`positions/${positionId}/remarks`, {
      remarks,
      timestamp: Date.now()
    })
  },
  savePositionBlock ({ positionBlock }) {
    if (positionBlock.id === 0) {
      return API.httpClient.post('positions/block', {
        position_id: positionBlock.position_id,
        closing_date: positionBlock.closing_date.format('YYYY-MM-DD'),
        opening_date: positionBlock.opening_date.format('YYYY-MM-DD'),
        reason: positionBlock.reason
      })
    } else {
      return API.httpClient.post('positions/update-block', {
        position_block_id: positionBlock.id,
        closing_date: positionBlock.closing_date.format('YYYY-MM-DD'),
        opening_date: positionBlock.opening_date.format('YYYY-MM-DD'),
        reason: positionBlock.reason
      })
    }
  },
  deletePositionBlock ({ positionBlock }) {
    return API.httpClient.post('positions/unblock', {
      position_block_id: positionBlock.id
    })
  },
  saveElectricRecord ({ positionId, value }) {
    return API.httpClient.post('positions/create-electric-record', {
      position_id: positionId,
      value
    })
  },
  saveElectricityCharge ({ charge }) {
    return API.httpClient.post('positions/save-electricity-charge', {
      charge
    })
  }
}
