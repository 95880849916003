<template>
  <div class="email-timeline">
    <v-timeline
      dense
      class="overflow-hidden"
    >
      <template v-if="loading">
        <!-- Loader (email hämtas fortfarande) -->
        <v-timeline-item
          color="secondary"
          fill-dot
          class="skeleton-loaders-timeline-item"
        >
          <template v-slot:icon>
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
          </template>
          <v-row>
            <v-col class="">
              <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-timeline-item>
      </template>

      <template v-else>
        <!-- Inga emails meddelande -->
        <v-timeline-item
          v-if="emails.length === 0 && !isAwaitingEmailSavedEvent"
          fill-dot
          class="align-center"
        >
          <template v-slot:icon>
            <v-icon color="white">mdi-email-off</v-icon>
          </template>
          <p class="text-body-2 my-auto">
            {{ noEmailsMessage }}
          </p>
        </v-timeline-item>

        <!-- Emails -->
        <v-timeline-item
          v-for="timelineItem in timelineItems"
          :key="timelineItem.id"
          fill-dot
          :color="timelineItem.color"
        >
          <template v-slot:icon>
            <v-icon color="white">{{ timelineItem.icon }}</v-icon>
          </template>
          <v-row class="pt-1 mx-0">
            <v-col cols="12" lg="3" class="d-flex flex-column pb-0 pb-lg-3">
              <div class="my-auto">{{ timelineItem.sentAtFormatted }}</div>
            </v-col>
            <v-col cols="12" lg="9" class="d-flex">
              <div class="d-flex flex-column pr-4" style="overflow: hidden;">
                <div class="d-flex">
                  <div class="text-caption" style="min-width: 80px;">Rubrik:</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="text-body-1 font-weight-black"
                        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; cursor: default;"
                      >{{ timelineItem.title }}</div>
                    </template>
                    <span>{{ timelineItem.title }}</span>
                  </v-tooltip>
                </div>
                <div class="d-flex">
                  <div class="text-caption" style="min-width: 80px;">Mottagare:</div>
                  <div class="text-subtitle-2" style="">{{ timelineItem.subtitle }}</div>
                </div>
              </div>
              <div class="ml-auto d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="timelineItem.statusColor"
                      v-bind="attrs"
                      v-on="on"
                      class="my-auto"
                    >
                      {{ timelineItem.statusIcon }}
                    </v-icon>
                  </template>
                  <span>{{ timelineItem.statusText }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>

        <!-- Loader från det att email köats, till EmailSaved event kommer -->
        <v-timeline-item
          v-if="isAwaitingEmailSavedEvent"
          fill-dot
          color="secondary"
          class="align-center"
        >
          <template v-slot:icon>
            <v-progress-circular
              indeterminate
              size="24"
              class=""
              color="white"
            ></v-progress-circular>
          </template>
          <v-skeleton-loader
            type="text"
            style="width: 220px; max-width: 220px;"
            class="my-auto"
          ></v-skeleton-loader>
        </v-timeline-item>
      </template>

      <slot name="timeline-actions"></slot>

    </v-timeline>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EmailTimeline',
  props: {
    emails: Array,
    loading: Boolean,
    bookingId: Number,
    invoiceId: Number,
    noEmailsMessage: String
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isAwaitingEmailSavedBookingIds: state => state.booking.isAwaitingEmailSavedBookingIds,
        isAwaitingEmailSavedInvoiceIds: state => state.invoice.isAwaitingEmailSavedInvoiceIds
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isAwaitingEmailSavedEvent: function () {
      return (this.bookingId && this.isAwaitingEmailSavedBookingIds.includes(this.bookingId)) || (this.invoiceId && this.isAwaitingEmailSavedInvoiceIds.includes(this.invoiceId))
    },
    timelineItems: function () {
      return this.emails.map(email => {
        let statusIcon = ''
        let statusText = ''
        let statusColor = ''

        if (email.status === 'sent') {
          statusIcon = 'mdi-check'
          statusText = 'Skickat'
          statusColor = 'grey lighten-1'
        } else if (email.status === 'delivered') {
          statusIcon = 'mdi-check-all'
          statusText = 'Mottaget'
          statusColor = 'grey lighten-1'
        } else if (email.status === 'read') {
          statusIcon = 'mdi-check-all'
          statusText = 'Läst'
          statusColor = 'success'
        } else if (email.status === 'failed') {
          statusIcon = 'mdi-close'
          statusText = 'Misslyckades'
          statusColor = 'error'
        } else if (email.status === 'complaint') {
          statusIcon = 'mdi-account-cancel-outline'
          statusText = 'Kunden har blockerat vår e-post'
          statusColor = 'error'
        }

        return {
          id: email.id,
          sentAtFormatted: window.conversion.toHumanReadableDate(email.sent_at),
          icon: email.icon,
          color: 'secondary',
          title: email.title,
          subtitle: email.recipient_email,
          statusColor,
          statusIcon,
          statusText
        }
      })
    }
  },
  methods: {},
  watch: {}
}
</script>
