import router from '@/router'
import PositionService from '@/services/PositionService.js'
export const namespaced = true

export const state = {
  positions: [], // OBS: inkluderar borttagna platser. Använd getter activePositions på ställen där vi inte vill ha med borttagna platser
  categories: [], // OBS: inkluderar borttagna kategorier, och kategorier som inte har några positions. Använd getter activeCategories på ställen där vi inte vill ha med borttagna kategorier
  lodgingTypes: [],
  isFetchingPositions: false,
  isFetchingLodgingTypes: false,

  fetchedAvailabilities: [],
  fetchingAvailabilities: [],
  availabilitiesErrors: [],

  fetchedCategoryAvailabilities: [],
  fetchingCategoryAvailabilities: [],
  categoryAvailabilitiesErrors: [],

  positionBlockDialog: {
    show: false,
    isEditMode: false,
    positionBlock: null,
    editedPositionBlock: null,
    hasError: false,
    isFetching: false,
    isSaving: false,
    isDeleting: false
  },
  mapPath: '',
  triggerPositionBlocksUpdated: 0
}

export const mutations = {
  SET_POSITIONS (state, val) {
    state.positions = val
  },
  SET_CATEGORIES (state, val) {
    state.categories = val
  },
  SET_LODGING_TYPES (state, val) {
    state.lodgingTypes = val
  },
  SET_IS_FETCHING_POSITIONS (state, val) {
    state.isFetchingPositions = val
  },
  SET_IS_FECTHING_LODGING_TYPES (state, val) {
    state.isFetchingLodgingTypes = val
  },

  ADD_FETCHED_AVAILABILITY (state, val) {
    state.fetchedAvailabilities.push(val)
  },
  DELETE_FETCHED_AVAILABILITY (state, val) {
    const i = state.fetchedAvailabilities.findIndex(fetchedAvailability => {
      return fetchedAvailability.checkIn.isSame(val.checkIn) && fetchedAvailability.checkOut.isSame(val.checkOut) && fetchedAvailability.bookingId === val.bookingId && fetchedAvailability.occupancy === val.occupancy && fetchedAvailability.dogs === val.dogs
    })
    if (i >= 0) {
      state.fetchedAvailabilities.splice(i, 1)
    }
  },
  ADD_FETCHING_AVAILABILITY (state, val) {
    state.fetchingAvailabilities.push(val)
  },
  DELETE_FETCHING_AVAILABILITY (state, val) {
    const i = state.fetchingAvailabilities.findIndex(fetchingAvailability => {
      return fetchingAvailability.checkIn.isSame(val.checkIn) && fetchingAvailability.checkOut.isSame(val.checkOut) && fetchingAvailability.bookingId === val.bookingId && fetchingAvailability.occupancy === val.occupancy && fetchingAvailability.dogs === val.dogs
    })
    if (i >= 0) {
      state.fetchingAvailabilities.splice(i, 1)
    }
  },
  ADD_AVAILABILITY_ERROR (state, val) {
    state.availabilitiesErrors.push(val)
  },
  DELETE_AVAILABILITY_ERROR (state, val) {
    const i = state.availabilitiesErrors.findIndex(availabilityError => {
      return availabilityError.checkIn.isSame(val.checkIn) && availabilityError.checkOut.isSame(val.checkOut) && availabilityError.bookingId === val.bookingId && availabilityError.occupancy === val.occupancy && availabilityError.dogs === val.dogs
    })
    if (i >= 0) {
      state.availabilitiesErrors.splice(i, 1)
    }
  },

  ADD_FETCHED_CATEGORY_AVAILABILITY (state, val) {
    state.fetchedCategoryAvailabilities.push(val)
  },
  DELETE_FETCHED_CATEGORY_AVAILABILITY (state, val) {
    const i = state.fetchedCategoryAvailabilities.findIndex(fetchedCategoryAvailability => {
      return fetchedCategoryAvailability.checkIn.isSame(val.checkIn) && fetchedCategoryAvailability.checkOut.isSame(val.checkOut) && fetchedCategoryAvailability.bookingId === val.bookingId && fetchedCategoryAvailability.occupancy === val.occupancy && fetchedCategoryAvailability.dogs === val.dogs
    })
    if (i >= 0) {
      state.fetchedCategoryAvailabilities.splice(i, 1)
    }
  },
  ADD_FETCHING_CATEGORY_AVAILABILITY (state, val) {
    state.fetchingCategoryAvailabilities.push(val)
  },
  DELETE_FETCHING_CATEGORY_AVAILABILITY (state, val) {
    const i = state.fetchingCategoryAvailabilities.findIndex(fetchingCategoryAvailability => {
      return fetchingCategoryAvailability.checkIn.isSame(val.checkIn) && fetchingCategoryAvailability.checkOut.isSame(val.checkOut) && fetchingCategoryAvailability.bookingId === val.bookingId && fetchingCategoryAvailability.occupancy === val.occupancy && fetchingCategoryAvailability.dogs === val.dogs
    })
    if (i >= 0) {
      state.fetchingCategoryAvailabilities.splice(i, 1)
    }
  },
  ADD_CATEGORY_AVAILABILITY_ERROR (state, val) {
    state.categoryAvailabilitiesErrors.push(val)
  },
  DELETE_CATEGORY_AVAILABILITY_ERROR (state, val) {
    const i = state.categoryAvailabilitiesErrors.findIndex(categoryAvailabilitiesError => {
      return categoryAvailabilitiesError.checkIn.isSame(val.checkIn) && categoryAvailabilitiesError.checkOut.isSame(val.checkOut) && categoryAvailabilitiesError.bookingId === val.bookingId && categoryAvailabilitiesError.occupancy === val.occupancy && categoryAvailabilitiesError.dogs === val.dogs
    })
    if (i >= 0) {
      state.categoryAvailabilitiesErrors.splice(i, 1)
    }
  },

  UPDATE_POSITION_REMARKS (state, val) {
    const position = state.positions.find(pos => pos.id === val.positionId)
    if (position.timestampLastPositionRemarkUpdate && val.timestamp < position.timestampLastPositionRemarkUpdate) {
      return false
    }
    position.timestampLastPositionRemarkUpdate = val.timestamp
    if (position) {
      position.remarks = val.remarks
    }
  },

  SET_SHOW_POSITION_BLOCK_DIALOG (state, val) {
    state.positionBlockDialog.show = val
  },
  SET_POSITION_BLOCK_DIALOG_IS_EDIT_MODE (state, val) {
    state.positionBlockDialog.isEditMode = val
  },
  SET_POSITION_BLOCK_DIALOG_POSITION_BLOCK (state, val) {
    state.positionBlockDialog.positionBlock = val
  },
  SET_POSITION_BLOCK_DIALOG_EDITED_POSITION_BLOCK (state, val) {
    state.positionBlockDialog.editedPositionBlock = val
  },
  SET_POSITION_BLOCK_DIALOG_IS_FETCHING (state, val) {
    state.positionBlockDialog.isFetching = val
  },
  SET_POSITION_BLOCK_DIALOG_IS_SAVING (state, val) {
    state.positionBlockDialog.isSaving = val
  },
  SET_POSITION_BLOCK_DIALOG_IS_DELETING (state, val) {
    state.positionBlockDialog.isDeleting = val
  },
  SET_POSITION_BLOCK_DIALOG_HAS_ERROR (state, val) {
    state.positionBlockDialog.hasError = val
  },
  SET_MAP_PATH (state, val) {
    state.mapPath = val
  },
  TRIGGER_POSITION_BLOCKS_UPDATED (state) {
    state.triggerPositionBlocksUpdated++
  }
}

export const actions = {
  fetchPositions ({ commit }) {
    commit('SET_IS_FETCHING_POSITIONS', true)
    return PositionService.getPositions()
      .then(({ data }) => {
        commit('SET_POSITIONS', data.data.positions)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_POSITIONS', false)
        window.channel.stopListening('PositionRemarksUpdated').listen('PositionRemarksUpdated', function (data) {
          commit('UPDATE_POSITION_REMARKS', { positionId: data.position_id, remarks: data.remarks, timestamp: data.timestamp })
        })
      })
  },
  enrichPositions ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichPosition(state.positions)
      resolve(true)
    })
  },
  fetchCategories ({ commit }) {
    return PositionService.getCategories()
      .then(({ data }) => {
        commit('SET_CATEGORIES', data.data.categories)
      })
  },
  fetchMap ({ rootState, commit }) {
    const mapPath = `https://assets.campingonline.se/client/${rootState.user.domain.namespace}/map.png`
    const backupPath = 'https://assets.campingonline.se/client/default/map.png'

    const img = new Image()
    img.onload = () => {
      commit('SET_MAP_PATH', mapPath)
    }
    img.onerror = () => {
      commit('SET_MAP_PATH', backupPath)
    }
    img.src = mapPath
  },
  enrichCategories ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichCategory(state.categories)
      resolve(true)
    })
  },
  fetchLodgingTypes ({ commit }) {
    commit('SET_IS_FECTHING_LODGING_TYPES', true)
    return PositionService.getLodgingTypes()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_LODGING_TYPES', data.data.lodging_types)
        }
      })
      .finally(() => {
        commit('SET_IS_FECTHING_LODGING_TYPES', false)
      })
  },
  fetchAvailability ({ state, commit }, { checkIn, checkOut, bookingId, occupancy, dogs }) {
    let availability = state.fetchedAvailabilities.find(fetchedAvailability => {
      return fetchedAvailability.checkIn.isSame(checkIn) && fetchedAvailability.checkOut.isSame(checkOut) && fetchedAvailability.bookingId === bookingId && fetchedAvailability.occupancy === occupancy && fetchedAvailability.dogs === dogs
    })
    if (availability && availability.fetchedAt.isAfter(window.dayjs().subtract(5, 'seconds'))) {
      return false
    }
    availability = state.fetchingAvailabilities.find(fetchingAvailability => {
      return fetchingAvailability.checkIn.isSame(checkIn) && fetchingAvailability.checkOut.isSame(checkOut) && fetchingAvailability.bookingId === bookingId && fetchingAvailability.occupancy === occupancy && fetchingAvailability.dogs === dogs
    })
    if (availability) {
      return false
    }

    commit('DELETE_FETCHED_AVAILABILITY', { checkIn, checkOut, bookingId, occupancy, dogs })
    commit('DELETE_AVAILABILITY_ERROR', { checkIn, checkOut, bookingId, occupancy, dogs })
    commit('ADD_FETCHING_AVAILABILITY', { sentAt: window.dayjs(), checkIn, checkOut, bookingId, occupancy, dogs })
    PositionService.getAvailabilities({ checkIn, checkOut, bookingId, occupancy, dogs })
      .then(({ data }) => {
        commit('ADD_FETCHED_AVAILABILITY', {
          fetchedAt: window.dayjs(),
          checkIn,
          checkOut,
          bookingId,
          occupancy,
          dogs,
          data: data.data.positions
        })
      })
      .catch(() => {
        commit('ADD_AVAILABILITY_ERROR', { fetchedAt: window.dayjs(), checkIn, checkOut, bookingId, occupancy, dogs })
      })
      .finally(() => {
        commit('DELETE_FETCHING_AVAILABILITY', { checkIn, checkOut, bookingId, occupancy, dogs })
      })
  },
  fetchCategoryAvailability ({ state, commit }, { checkIn, checkOut, bookingId, occupancy, dogs }) {
    const fetchedAvailability = state.fetchedCategoryAvailabilities.find(fetchedCategoryAvailability => {
      return fetchedCategoryAvailability.checkIn.isSame(checkIn) && fetchedCategoryAvailability.checkOut.isSame(checkOut) && fetchedCategoryAvailability.bookingId === bookingId && fetchedCategoryAvailability.occupancy === occupancy && fetchedCategoryAvailability.dogs === dogs
    })
    if (fetchedAvailability && fetchedAvailability.fetchedAt.isAfter(window.dayjs().subtract(5, 'seconds'))) {
      return false
    }
    const fetchingAvailability = state.fetchingCategoryAvailabilities.find(fetchingCategoryAvailability => {
      return fetchingCategoryAvailability.checkIn.isSame(checkIn) && fetchingCategoryAvailability.checkOut.isSame(checkOut) && fetchingCategoryAvailability.bookingId === bookingId && fetchingCategoryAvailability.occupancy === occupancy && fetchingCategoryAvailability.dogs === dogs
    })
    if (fetchingAvailability) {
      return false // Hämtar redan
    }

    commit('DELETE_FETCHED_CATEGORY_AVAILABILITY', { checkIn, checkOut, bookingId, occupancy, dogs })
    commit('DELETE_CATEGORY_AVAILABILITY_ERROR', { checkIn, checkOut, bookingId, occupancy, dogs })
    commit('ADD_FETCHING_CATEGORY_AVAILABILITY', { sentAt: window.dayjs(), checkIn, checkOut, bookingId, occupancy, dogs })
    PositionService.getCategoryAvailabilities({
      checkIn,
      checkOut,
      bookingId,
      occupancy,
      dogs
    })
      .then(({ data }) => {
        commit('ADD_FETCHED_CATEGORY_AVAILABILITY', { fetchedAt: window.dayjs(), checkIn, checkOut, bookingId, occupancy, dogs, data: data.data.categories })
      })
      .catch(() => {
        commit('ADD_CATEGORY_AVAILABILITY_ERROR', { fetchedAt: window.dayjs(), checkIn, checkOut, bookingId, occupancy, dogs })
      })
      .finally(() => {
        commit('DELETE_FETCHING_CATEGORY_AVAILABILITY', { checkIn, checkOut, bookingId, occupancy, dogs })
      })
  },
  gotoPosition ({ commit }, position) {
    // Använd för att gå till kartsidan, och välja och zooma in på en specifik plats
    router.push({ name: 'Map', params: { positionId: position.id } })
  },
  openPositionBlockDialog ({ commit }, { positionBlock = null, positionBlockId = null }) {
    // Funderar att använda antingen positionBlock object direkt, eller positionBlockId för att hämta object från server först
    commit('SET_POSITION_BLOCK_DIALOG_EDITED_POSITION_BLOCK', null)
    commit('SET_POSITION_BLOCK_DIALOG_IS_FETCHING', false)
    commit('SET_POSITION_BLOCK_DIALOG_IS_EDIT_MODE', false)
    commit('SET_POSITION_BLOCK_DIALOG_HAS_ERROR', false)
    if (positionBlockId !== null) {
      commit('SET_POSITION_BLOCK_DIALOG_IS_FETCHING', true)
      PositionService.getPositionBlock({ positionBlockId })
        .then(({ data }) => {
          if (data.status === 'success') {
            window.enrich.enrichPositionBlock(data.data.position_block)
            commit('SET_POSITION_BLOCK_DIALOG_POSITION_BLOCK', data.data.position_block)
          } else {
            commit('SET_POSITION_BLOCK_DIALOG_HAS_ERROR', true)
          }
        })
        .catch(() => {
          commit('SET_POSITION_BLOCK_DIALOG_HAS_ERROR', true)
        })
        .finally(() => {
          commit('SET_POSITION_BLOCK_DIALOG_IS_FETCHING', false)
        })
    } else {
      commit('SET_POSITION_BLOCK_DIALOG_POSITION_BLOCK', positionBlock)
    }
    commit('SET_SHOW_POSITION_BLOCK_DIALOG', true)
  },
  startNewPositionBlock ({ commit }, { position }) {
    const positionBlock = {
      id: 0,
      position_id: position.id,
      position,
      closing_date: window.dayjs().startOf('day'),
      opening_date: window.dayjs().add(1, 'day').startOf('day'),
      reason: ''
    }
    commit('SET_POSITION_BLOCK_DIALOG_EDITED_POSITION_BLOCK', positionBlock)
    commit('SET_POSITION_BLOCK_DIALOG_IS_EDIT_MODE', true)
    commit('SET_SHOW_POSITION_BLOCK_DIALOG', true)
  },
  startEditPositionBlock ({ commit }, { positionBlock }) {
    const positionBlockCopy = Object.assign({}, positionBlock)
    commit('SET_POSITION_BLOCK_DIALOG_EDITED_POSITION_BLOCK', positionBlockCopy)
    commit('SET_POSITION_BLOCK_DIALOG_IS_EDIT_MODE', true)
    commit('SET_SHOW_POSITION_BLOCK_DIALOG', true)
  },
  savePositionBlock ({ commit, dispatch }, { positionBlock }) {
    commit('SET_POSITION_BLOCK_DIALOG_IS_SAVING', true)
    PositionService.savePositionBlock({ positionBlock })
      .then(({ data }) => {
        if (data.status === 'success') {
          const savedPositionBlock = data.data.position_block
          window.enrich.enrichPositionBlock(savedPositionBlock)
          dispatch('openPositionBlockDialog', { positionBlock: savedPositionBlock })
          commit('TRIGGER_POSITION_BLOCKS_UPDATED')
        }
      })
      .finally(() => {
        commit('SET_POSITION_BLOCK_DIALOG_IS_SAVING', false)
      })
  },
  deletePositionBlock ({ state, commit }, { positionBlock }) {
    commit('SET_POSITION_BLOCK_DIALOG_IS_DELETING', true)
    PositionService.deletePositionBlock({ positionBlock })
      .then(({ data }) => {
        if (data.status === 'success') {
          // Success snackbar skickas från server
          if (state.positionBlockDialog.positionBlock?.id === positionBlock.id) { // Kontrollerar att det fortfarande är samma positionblock som är öppen i dialogen innan vi stänger den
            // Stänger position block dialog
            commit('SET_SHOW_POSITION_BLOCK_DIALOG', false)
          }
          commit('TRIGGER_POSITION_BLOCKS_UPDATED')
        }
      })
      .finally(() => {
        commit('SET_POSITION_BLOCK_DIALOG_IS_DELETING', false)
      })
  }
}

export const getters = {
  activePositions: function (state) {
    if (state.positions.length === 0) {
      return []
    }
    const positions = state.positions.filter(position => {
      return position.deleted_at === null
    })
    return positions
  },
  activeCategories: function (state) {
    // categories som inte är bortplockade och som har positions (som inte är bortplockade)
    if (state.categories.length === 0) {
      return []
    }
    const categories = state.categories.filter(category => {
      return category.deleted_at === null && category.positions.filter(pos => pos.deleted_at === null).length > 0
    })
    return categories
  },
  defaultBookingCategory: function (state, getters) {
    if (getters.activeCategories.length === 0) {
      return null
    }
    return getters.activeCategories[0]
  },
  activeLodgingTypes: function (state, getters) {
    // lodgingTypes som används av aktiva categories
    if (getters.activeCategories.length === 0 || state.lodgingTypes.length === 0) {
      return []
    }
    const allCategoryLodgingTypes = getters.activeCategories.flatMap(cat => cat.lodgingTypes)
    const uniqueCategoryLodgingTypes = allCategoryLodgingTypes.filter((lodgingType, i, self) => {
      return self.findIndex(x => x.id === lodgingType.id) === i
    })
    return uniqueCategoryLodgingTypes
  }
}
