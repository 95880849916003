<template>
<div class="accommodation-price-input">
  <v-text-field
    v-model="priceInput"
    label="Platsavgift"
    outlined
    hide-details="auto"
    type="number"
    append-icon="coi-currency-sek"
  ></v-text-field>
</div>
</template>

<script>

export default {
  name: 'AccommodationPriceInput',
  components: {},
  props: {
    value: Number
  },
  data: () => ({}),
  computed: {
    priceInput: {
      get: function () {
        if (this.value === null) {
          return ''
        }
        return this.value / 100
      },
      set: function (val) {
        if (val === '') {
          if (this.value === 0) {
            this.$emit('input', 100) // för att det inte ska gå att skriva "0-{siffror}" utan att value resettas
            this.$nextTick(() => { this.$emit('input', 0) })
          } else {
            this.$emit('input', 0)
          }
        } else {
          const integerValue = parseInt(val, 10)
          if (!isNaN(integerValue)) {
            this.$emit('input', integerValue * 100)
          } else {
            if (this.value === 0) {
              this.$emit('input', 100) // för att det inte ska gå att skriva "0-{siffror}" utan att value resettas
              this.$nextTick(() => { this.$emit('input', 0) })
            } else {
              this.$emit('input', 0)
            }
          }
        }
      }
    }
  },
  methods: {}
}
</script>
