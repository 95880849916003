import API from './API.js'

export default {
  //
  // GET
  //
  getOrders () {
    return API.httpClient.get('parked-orders')
  },
  getOrder ({ parkedOrderId }) {
    return API.httpClient.get('parked-orders/get-parked-order', {
      params: {
        parked_order_id: parkedOrderId
      }
    })
  },
  getTableCheck ({ parkedOrderId }) {
    return API.httpClient.get('parked-orders/get-table-check', {
      params: {
        parked_order_id: parkedOrderId
      }
    })
  },

  //
  // POST
  //
  createOrder ({ name, charges }) {
    const items = charges.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('parked-orders/create', {
      name,
      items
    })
  },
  parkOrder ({ orderId, charges }) {
    const items = charges.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('parked-orders/update', {
      parked_order_id: orderId,
      items
    })
  },
  updateOrderName ({ orderId, newName }) {
    return API.httpClient.post('parked-orders/update-order-name', {
      parked_order_id: orderId,
      new_name: newName
    })
  },
  splitOrderEvenly ({ orderId, parts }) {
    return API.httpClient.post('parked-orders/split-order-evenly', {
      parked_order_id: orderId,
      parts
    })
  },
  splitOrderManually ({ orderId, remainingCart, newCart }) {
    remainingCart = remainingCart.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    newCart = newCart.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('parked-orders/split-order-manually', {
      parked_order_id: orderId,
      remaining_cart: remainingCart,
      new_cart: newCart
    })
  },

  //
  // DELETE
  //
  deleteOrder ({ orderId }) {
    return API.httpClient.delete('parked-orders/delete', {
      params: {
        parked_order_id: orderId
      }
    })
  }
}
