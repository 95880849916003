export default (o, c, d) => {
  c.prototype.weeksInMonth = function () {
    const daysInMonth = this.endOf('month').date()
    let firstWeekDayInMonth = this.startOf('month').day() // 0 = sön, mån = 1
    if (firstWeekDayInMonth === 0) {
      firstWeekDayInMonth = 7 // sön = 7, mån = 1
    }
    const overflowingDays = firstWeekDayInMonth - 1 // extra dagar i första veckan som inte tillhör månaden
    const weeks = Math.ceil((overflowingDays + daysInMonth) / 7)
    return weeks
  }
}
