import API from './API.js'

export default {
  //
  // GET
  //
  getCancellationPolicies () {
    return API.httpClient.get('booking-cancellations/cancellation-policies')
  },
  getBookingCancellationsIndex ({ requestId, offset, visibleBookings, sortedColumn, sortedDesc, filterCustom, searchString }) {
    return API.httpClient.get('booking-cancellations', {
      params: {
        request_id: requestId,
        offset,
        visible_bookings: visibleBookings,
        sorted_column: sortedColumn,
        sorted_desc: sortedDesc,
        filter_custom: filterCustom,
        search_string: searchString
      }
    })
  },
  getBookingCancellation ({ bookingId }) {
    return API.httpClient.get('booking-cancellations/show', {
      params: {
        booking_id: bookingId
      }
    })
  },
  getCancellationPolicyTimelineItems ({ policyId, bookingId = null, checkInDate = null }) {
    return API.httpClient.get('booking-cancellations/cancellation-policy-timeline-items', {
      params: {
        policy_id: policyId,
        booking_id: bookingId,
        check_in_date: checkInDate ? checkInDate.format('YYYY-MM-DD') : null
      }
    })
  },
  getBookingCancellationManualSpecification ({ bookingCancellationId }) {
    return API.httpClient.get('booking-cancellations/get-manual-specification', {
      params: {
        booking_cancellation_id: bookingCancellationId
      }
    })
  },

  //
  // POST
  //
  bookingCancellationSelectOption ({ bookingCancellationId, selectedOption }) {
    return API.httpClient.post('booking-cancellations/select-option', {
      booking_cancellation_id: bookingCancellationId,
      selected_option: selectedOption
    })
  },
  sendDocumentRequest ({ bookingCancellationId }) {
    return API.httpClient.post('booking-cancellations/send-document-request', {
      booking_cancellation_id: bookingCancellationId
    })
  },
  sendCancellationConfirmation ({ bookingCancellationId }) {
    return API.httpClient.post('booking-cancellations/send-confirmation', {
      booking_cancellation_id: bookingCancellationId
    })
  },
  confirmDocumentRequest ({ bookingCancellationId, hasValidDocument }) {
    return API.httpClient.post('booking-cancellations/confirm-document-request', {
      booking_cancellation_id: bookingCancellationId,
      has_valid_document: hasValidDocument
    })
  },
  bookingCancellationCompleteCancellation ({ bookingCancellationId, preview }) {
    return API.httpClient.post('booking-cancellations/complete-cancellation', {
      booking_cancellation_id: bookingCancellationId,
      preview
    })
  },
  getBookingCancellationPreview ({ bookingCancellationId, manualSpecification }) {
    // Använder POST för att vi skickar ett objekt till server
    return API.httpClient.post('booking-cancellations/get-preview', {
      booking_cancellation_id: bookingCancellationId,
      manual_specification: manualSpecification
    })
  }
}
