<template>
  <v-main class="login-page">
    <v-container class="fill-height px-0 py-0 d-flex flex-column" :class="{ 'is-loading': transitionLogo }" fluid>

      <div class="background-overlay"></div>

      <v-img
        v-if="!isMobile"
        id="background-image"
        :lazy-src="backgroundImageLazy"
        height="100%"
        width="100%"
        gradient="to bottom, rgba(0,0,0,.6), rgba(0,0,0,0.4), rgba(0,0,0,0), rgba(0,0,0,0)"
        :style="hasImageError ? 'filter: blur(6px);' : ''"
        :src="backgroundImage"
        @error="OnBackgroundImageError"
      ></v-img>

      <div class="logo-container mx-auto d-flex flex-column flex-shrink-1 flex-grow-0">
        <logo-animation :loading="isFetchingData"></logo-animation>
        <div v-if="isFetchingData" class="mt-12 mx-auto p-relative full-width">
          <div class="white--text mx-auto text-center d-flex">
            <div class="loading-text mx-auto">
              <span class="letter" style="--i:1">L</span>
              <span class="letter" style="--i:2">A</span>
              <span class="letter" style="--i:3">D</span>
              <span class="letter" style="--i:4">D</span>
              <span class="letter" style="--i:5">A</span>
              <span class="letter" style="--i:6">R</span>
            </div>
          </div>
        </div>
      </div>

      <v-slide-y-reverse-transition>
        <div v-show="!isFetchingData" class="mx-auto login-card-container align-stretch align-self-stretch overflow-hidden">
          <login-card :is-mobile="isMobile"></login-card>
        </div>
      </v-slide-y-reverse-transition>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
import LoginCard from '@/components/user/LoginCard.vue'
import LogoAnimation from '@/components/common/LogoAnimation.vue'

export default {
  name: 'LoginPage',
  components: {
    LoginCard,
    LogoAnimation
  },
  data: () => ({
    hasImageError: false,
    transitionLogo: false
  }),
  computed: {
    ...mapState(
      {
        isOffline: state => state.isOffline,
        isFetchingData: state => state.isFetchingData
      }
    ),
    backgroundImage () {
      if (!this.hasImageError && !this.isOffline) {
        const seed = window.dayjs().format('YYYYMMDD')
        const dailyImageUrl = 'https://picsum.photos/seed/' + seed + '/1920/1080?blur'
        // dailyImageUrl = `https://source.unsplash.com/featured/1920x1080/?camping`
        return dailyImageUrl
      } else {
        return require('@/assets/images/offline_test_pattern.png')
      }
    },
    backgroundImageLazy () {
      const seed = window.dayjs().format('YYYYMMDD')
      const dailyImageUrl = 'https://picsum.photos/seed/' + seed + '/10/6'
      return dailyImageUrl
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    OnBackgroundImageError: function () {
      this.hasImageError = true
    }
  },
  watch: {
    isFetchingData: function () {
      if (this.isFetchingData) {
        const element = document.getElementsByClassName('logo-container')[0]
        element.style.top = element.offsetTop + 'px'
        setTimeout(() => {
          element.style.marginTop = '250px' // `calc(50%-77px-${element.offsetTop}px)`
          this.transitionLogo = true
        }, 400)
      } else {
        this.transitionLogo = false
      }
    }
  }
}
</script>
