<template>
<v-overlay :value="isLoading">
  <v-progress-circular
    indeterminate
    size="64"
  ></v-progress-circular>
</v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageLoader',
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isLoading: state => state.isLoadingPage
      }
    )
  },
  methods: {

  }
}
</script>
