<template>
  <v-form
    ref="formName"
  >
    <v-row>
      <v-col cols="12" sm="11" md="11" class="">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              label="Förnamn*"
              v-model.trim="inputGivenName"
              :rules="[givenNameRule]"
              @keyup.delete="givenNameDelete"
              @focusin="givenNameFocusIn"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              label="Efternamn*"
              v-model.trim="inputSurname"
              :rules="[surnameRule]"
              @keyup.delete="surnameDelete"
              @focusin="surnameFocusIn"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="1" md="1" class="d-none d-sm-block" style="text-align: center;">
        <customer-avatar
          :customer="dummyCustomer"
          large
        ></customer-avatar>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CustomerAvatar from '@/components/customer/Avatar.vue'

export default {
  name: 'CustomerInputName',
  components: {
    CustomerAvatar
  },
  props: {
    givenName: String,
    surname: String,
    forceValidation: Number,
    forceResetValidation: Number
  },
  data: () => ({
    givenNameValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    surnameValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    validation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    givenNameAutoCapitalization: true,
    givenNameLength: 0,
    surnameLength: 0,
    surnameAutoCapitalization: true,
    originalGivenName: '',
    originalSurname: ''
  }),
  computed: {
    inputGivenName: {
      get: function () {
        return this.givenName
      },
      set: function (val) {
        if (val.length <= this.givenNameLength) {
          // Input har förkortats
          this.givenNameAutoCapitalization = (val.length <= 1)
          this.givenNameLength = val.length
          this.$emit('set-given-name', val)
          return false
        }
        const words = val.split(' ')
        const capitalizedWords = []
        words.forEach(w => {
          if (this.givenNameAutoCapitalization && w.length >= 4) {
            w = w.charAt(0).toUpperCase() + w.slice(1)
          }
          capitalizedWords.push(w)
        })
        val = capitalizedWords.join(' ')
        this.givenNameLength = val.length
        this.$emit('set-given-name', val)
      }
    },
    inputSurname: {
      get: function () {
        return this.surname
      },
      set: function (val) {
        if (val.length <= this.surnameLength) {
          this.surnameAutoCapitalization = (val.length <= 1)
          this.surnameLength = val.length
          this.$emit('set-surname', val)
          return false
        }
        const words = val.split(' ')
        const capitalizedWords = []
        words.forEach(w => {
          if (this.surnameAutoCapitalization && w.length >= 4) {
            w = w.charAt(0).toUpperCase() + w.slice(1)
          }
          capitalizedWords.push(w)
        })
        val = capitalizedWords.join(' ')
        this.surnameLength = val.length
        this.$emit('set-surname', val)
      }
    },
    dummyCustomer: function () {
      return {
        given_name: this.inputGivenName,
        surname: this.inputSurname
      }
    }
  },
  methods: {
    givenNameRule: function (v) {
      let verdict = ''
      if (v == null || v === '') {
        verdict = 'Fyll i förnamn'
      } else if (v.length > 50) {
        verdict = 'Förnamn kan vara max 50 tecken'
      }
      this.givenNameValidation.hasError = (verdict !== '')
      this.givenNameValidation.errorMessage = verdict
      this.givenNameValidation.isValidated = true
      this.givenNameValidation.hasChanges = (this.originalGivenName === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    surnameRule: function (v) {
      let verdict = ''
      if (v == null || v === '') {
        verdict = 'Fyll i efternamn'
      } else if (v.length > 50) {
        verdict = 'Efternamn kan vara max 50 tecken'
      }
      this.surnameValidation.hasError = (verdict !== '')
      this.surnameValidation.errorMessage = verdict
      this.surnameValidation.isValidated = true
      this.surnameValidation.hasChanges = (this.originalSurname === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    updateCommonValidation: function () {
      this.validation.hasChanges = this.givenNameValidation.hasChanges || this.surnameValidation.hasChanges
      this.validation.isValidated = this.givenNameValidation.isValidated && this.surnameValidation.isValidated
      this.validation.hasError = this.givenNameValidation.hasError || this.surnameValidation.hasError
      this.validation.hasWarning = this.givenNameValidation.hasWarning || this.surnameValidation.hasWarning
      this.validation.errorMessage = this.givenNameValidation.errorMessage || this.surnameValidation.errorMessage
      this.$emit('set-validation', this.validation)
    },
    validate: function () {
      return this.$refs.formName.validate()
    },
    resetValidation: function () {
      this.$refs.formName.resetValidation()
    },
    givenNameDelete: function () {
      this.givenNameAutoCapitalization = false
    },
    givenNameFocusIn: function () {
      this.givenNameAutoCapitalization = true
    },
    surnameDelete: function () {
      this.surnameAutoCapitalization = false
    },
    surnameFocusIn: function () {
      this.surnameAutoCapitalization = true
    }
  },
  watch: {
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  mounted () {
    this.surnameLength = this.surname?.length
    this.originalSurname = this.surname
    this.givenNameLength = this.givenName?.length
    this.originalGivenName = this.givenName
  }
}
</script>
