import API from './API.js'

export default {
  //
  // GET
  //
  getSeasons () {
    return API.httpClient.get('season')
  },
  getSeasonTabs () {
    return API.httpClient.get('season/tabs')
  },
  getSeasonBookings (seasonSlug) {
    return API.httpClient.get('season/bookings', {
      params: {
        slug: seasonSlug
      }
    })
  },
  getSeasonPeriods (seasonSlug) {
    return API.httpClient.get('season/periods', {
      params: {
        slug: seasonSlug
      }
    })
  },

  //
  // POST
  //
  confirmSeasonBookingDraft (bookingId) {
    return API.httpClient.post('season/booking/update', {
      booking_id: bookingId,
      status: 'confirmed'
    })
  },
  deleteSeasonBookingDraft (bookingId) {
    return API.httpClient.post('season/booking/update', {
      booking_id: bookingId,
      status: 'cancelled'
    })
  }
}
