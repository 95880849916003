<template>
  <div class="booking-form-price">
    <template v-if="hasError">
      <v-alert
        dense
        text
        type="error"
        class="d-block status-alert ml-4"
      >
        <div class="d-flex">
          <div class="flex-grow-1 my-auto">Ett fel inträffade</div>
        </div>
      </v-alert>
    </template>
    <template v-else-if="!isExistingBooking">
      <!-- Kostnad ny bokning -->
      <div class="pl-4 d-flex align-center" :class="{ 'd-block py-2': isMobile }">
        <span class="text-h6">Kostnad:</span>
        <v-progress-circular
          v-if="loading"
          indeterminate
          size="24"
          class="ml-6"
        ></v-progress-circular>
        <span v-else class="text-h6 pl-6" v-text="chargeTotalFormatted"></span>
      </div>
    </template>
    <template v-else>
      <!-- Kostnad redigerar bokning -->
      <div class="pl-4 d-flex align-center" :class="{ 'd-block py-2': isMobile }">
        <div class="d-flex flex-column">
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="24"
            class="ml-6"
          ></v-progress-circular>
          <div v-else class="text-h6">{{ formatAmount(currentTotal) }}</div>
        </div>
        <v-icon class="mx-4">mdi-chevron-right</v-icon>
        <div class="d-flex" style="width: 340px;">
          <v-select
            v-model="inputRebookingOption"
            return-object
            item-value="level"
            :loading="loading"
            :disabled="loading"
            :items="rebookingOptions"
            hide-details
            outlined
            label="Nytt pris"
          >
            <template v-slot:selection="data">
              <div class="d-flex">
                <div class="my-auto">
                  <v-icon
                    class="mr-4"
                    :color="data.item.level === 'free' ? 'success' : data.item.level === 'limited' ? 'warning' : data.item.level === 'restrictive' ? 'error' : 'primary'"
                  >
                    {{ data.item.level === 'free' ? 'mdi-book-sync-outline' : data.item.level === 'limited' ? 'mdi-sync-alert' : data.item.level === 'restrictive' ? 'mdi-sync-off' : 'mdi-lock-outline' }}
                  </v-icon>
                </div>
                <div class="my-auto d-flex flex-column">
                  <div class="text-body-1 text-nowrap">{{ data.item.title }}</div>
                  <div class="text-subtitle-2">{{ formatAmount(data.item.total) }}</div>
                </div>
              </div>
            </template>
            <template v-slot:item="data">
              <div class="d-flex py-2 full-width">
                <div class="my-auto">
                  <v-icon
                    class="mr-4"
                    :color="data.item.level === 'free' ? 'success' : data.item.level === 'limited' ? 'warning' : data.item.level === 'restrictive' ? 'error' : 'primary'"
                  >
                    {{ data.item.level === 'free' ? 'mdi-book-sync-outline' : data.item.level === 'limited' ? 'mdi-sync-alert' : data.item.level === 'restrictive' ? 'mdi-sync-off' : 'mdi-lock-outline' }}
                  </v-icon>
                </div>
                <div class="my-auto d-flex flex-column">
                  <div class="text-body-1">{{ data.item.title }}</div>
                  <div class="text-subtitle-2">{{ formatAmount(data.item.total) }}</div>
                </div>
                <div class="my-auto flex-grow-1 text-right">
                  <div v-show="data.item.is_default" class="text-caption success--text pl-4">Enligt ombokningsvillkor</div>
                </div>
              </div>
            </template>
          </v-select>
          <v-menu
            right
            transition="scale-transition"
            origin="left"
            max-height="600"
            :close-on-content-click="isMobile"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                color="transparent"
                elevation="0"
                class="my-auto"
              >
                <v-icon color="secondary">mdi-information</v-icon>
              </v-btn>
            </template>
            <v-card max-width="500">
              <v-card-text class="py-2">
                <div class="d-flex py-2">
                  <div class="mt-1">
                    <v-icon
                      class="mr-4"
                      color="success"
                      large
                    >
                      mdi-book-sync-outline
                    </v-icon>
                  </div>
                  <div class="my-auto d-flex flex-column">
                    <div class="text-subtitle-1 font-weight-medium">Fri ombokning</div>
                    <div class="text-body-2">
                      Gästen kan fritt ändra in- och utcheckningsdatum, antal personer och platsval och betalar alltid det nya priset.
                    </div>
                    <div class="text-caption mt-1 font-italic">
                      {{ deadlineText('free') }}
                    </div>
                  </div>
                </div>

                <div class="d-flex py-2">
                  <div class="mt-1">
                    <v-icon
                      class="mr-4"
                      color="warning"
                      large
                    >
                      mdi-sync-alert
                    </v-icon>
                  </div>
                  <div class="my-auto d-flex flex-column">
                    <div class="text-subtitle-1 font-weight-medium">Begränsad ombokning</div>
                    <div class="text-body-2">
                      Gästen kan ändra in- och utcheckningsdatum, antal personer och platsval men slutpriset vid ombokning kan inte understiga det ursprungliga priset före ombokningen.
                    </div>
                    <div class="text-caption mt-1 font-italic">
                      {{ deadlineText('limited') }}
                    </div>
                  </div>
                </div>

                <div class="d-flex py-2">
                  <div class="mt-1">
                    <v-icon
                      class="mr-4"
                      color="error"
                      large
                    >
                      mdi-sync-off
                    </v-icon>
                  </div>
                  <div class="my-auto d-flex flex-column">
                    <div class="text-subtitle-1 font-weight-medium">Restriktiv ombokning</div>
                    <div class="text-body-2">
                      Ombokningen kan inte resultera i ett lägre dygnspris. Nätter som inte längre ingår i bokningsperioden återbetalas inte. Alla nätter som tillkommer debiteras med det nya dygnspriset.
                    </div>
                    <div class="text-caption mt-1 font-italic">
                      {{ deadlineText('restrictive') }}
                    </div>
                  </div>
                </div>

                <div class="d-flex py-2">
                  <div class="mt-1">
                    <v-icon
                      class="mr-4"
                      color="primary"
                      large
                    >
                      mdi-lock-outline
                    </v-icon>
                  </div>
                  <div class="my-auto d-flex flex-column">
                    <div class="text-subtitle-1 font-weight-medium">Behåll nuvarande platsavgifter</div>
                    <div class="text-body-2">
                      Genomför ombokning utan att automatiskt ändra i bokningens platsavgifter.
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'BookingFormPrice',
  props: {
    isExistingBooking: Boolean,
    loading: Boolean,
    charges: Array,
    isMobile: Boolean,
    rebookingPreview: Object,
    selectedRebookingOption: Object,
    hasError: Boolean
  },
  components: {},
  data: () => ({}),
  computed: {
    inputRebookingOption: {
      get: function () {
        return this.selectedRebookingOption
      },
      set: function (val) {
        this.$emit('select-rebooking-option', val)
      }
    },
    currentTotal: function () {
      // pris före ändring när redigerar bokning
      if (!this.rebookingPreview) {
        return 0
      }
      return this.rebookingPreview.current_total
    },
    rebookingOptions: function () {
      if (!this.rebookingPreview) {
        return []
      }
      return this.rebookingPreview.rebooking_options
    },
    chargeTotal: function () {
      const total = this.charges.reduce((acc, c) => acc + (c.total), 0)
      return total
    },
    chargeTotalFormatted: function () {
      if (this.chargeTotal !== 0) {
        return `${this.chargeTotal / 100} kr`
      }
      return 'Inget pris'
    }
  },
  methods: {
    formatAmount: function (amount) {
      if (amount === null || amount === undefined) {
        return 'N/A'
      }
      return window.conversion.toHumanReadableAmount(amount / 100)
    },
    deadlineText: function (level) {
      if (!this.rebookingOptions) {
        return ''
      }
      const rebookingOption = this.rebookingOptions.find(ro => ro.level === level)
      if (!rebookingOption) {
        return ''
      }
      return rebookingOption.deadline_description
    }
  },
  watch: {}
}
</script>
