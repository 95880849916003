<template>
  <v-list subheader class="receipt-data-list">
    <template v-if="!hiddenSections.includes('booking')">
      <template v-if="isLoading">
        <div aria-busy="true" aria-live="polite" role="alert" class="v-skeleton-loader v-skeleton-loader--is-loading theme--light" style="max-width: 300px; height: 62px;">
          <div class="v-skeleton-loader__list-item-avatar v-skeleton-loader__bone" style="margin-top: 7px;">
            <div class="v-skeleton-loader__avatar v-skeleton-loader__bone" style="width: 30px; height: 30px; margin-left: -3px;"></div>
            <div style="margin-top: -8px; width: 160px; max-width: 160px; margin-left: 12px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
            <div style="position: absolute; left: 72px; top: 32px; width: 212px; height: 8px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
          </div>
        </div>
      </template>
      <template v-else-if="receipt.booking">
        <v-list-item @click.native="openBooking" :ripple="bookingClickable" :class="{ 'cursor-pointer highlight-on-hover': bookingClickable }">
          <v-list-item-icon style="margin-right: 29px;">
            <v-list-item-avatar
              color="deep-purple"
              class="justify-center mr-0 my-0"
              size="30"
              style="margin-left: -3px;"
            >
            <v-icon color="white" small>mdi-book-marker-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <span>{{ receipt.booking.booking_number }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-show="hasBookingRemarks"
                    aria-hidden="false"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tag-text-outline
                  </v-icon>
                </template>
                <div style="padding: 4px;">
                  <h3>Bokningsanteckningar:</h3>
                  <i>{{ receipt.booking.remarks }}</i>
                </div>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle style="width: auto;">Bokningsnummer</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('customer')">
      <template v-if="isLoading">
        <div aria-busy="true" aria-live="polite" role="alert" class="v-skeleton-loader v-skeleton-loader--is-loading theme--light" style="max-width: 300px; height: 62px;">
          <div class="v-skeleton-loader__list-item-avatar v-skeleton-loader__bone" style="margin-top: 7px;">
            <div class="v-skeleton-loader__avatar v-skeleton-loader__bone" style="width: 30px; height: 30px; margin-left: -3px;"></div>
            <div style="margin-top: -8px; width: 160px; max-width: 160px; margin-left: 12px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
            <div style="position: absolute; left: 72px; top: 32px; width: 212px; height: 8px;" class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
          </div>
        </div>
      </template>
      <template v-else-if="receipt.customer">
        <v-list-item @click.native="openCustomer" :ripple="customerClickable" :class="{ 'cursor-pointer highlight-on-hover': customerClickable }">
          <v-list-item-icon style="margin-right: 29px;">
            <customer-avatar :customer="receipt.customer" style="margin-left: -3px;"></customer-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="receipt.customer.full_name"></v-list-item-title>
            <v-list-item-subtitle v-text="receipt.customer.shortAddress"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('receipt-date')">
      <v-list-item>
        <v-list-item-icon class="my-auto">
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <template v-if="isLoading">
              <v-skeleton-loader
                type="list-item"
                style="max-width: 200px;"
                class="list-item-has-subtitle"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              {{ receiptDateFormatted }}
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>Skapad</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-if="!hiddenSections.includes('total')">
      <v-list-item>
        <v-list-item-icon class="my-auto">
          <v-icon>mdi-cash</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <template v-if="isLoading">
              <v-skeleton-loader
                type="list-item"
                style="max-width: 200px;"
                class="list-item-has-subtitle"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              {{ receiptAmountFormatted }}
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>Belopp</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

  </v-list>
</template>

<script>
import CustomerAvatar from '@/components/customer/Avatar.vue'

export default {
  name: 'ReceiptDataList',
  components: {
    CustomerAvatar
  },
  props: {
    receipt: Object,
    isLoading: Boolean,
    customerClickable: Boolean,
    bookingClickable: Boolean,
    hiddenSections: {
      type: Array,
      default: function () { return [] }
    }
  },
  data: () => ({}),
  computed: {
    receiptDateFormatted: function () {
      if (!this.receipt) {
        return ''
      }
      const formattedDate = window.conversion.toHumanReadableDate(this.receipt.created_at)
      return `${formattedDate}`
    },
    receiptAmountFormatted: function () {
      if (!this.receipt) {
        return ''
      }
      const amount = this.receipt.total / 100
      return `${amount} kr SEK`
    },
    hasBookingRemarks: function () {
      return this.receipt?.booking && !!this.receipt.booking.remarks
    }
  },
  methods: {
    openCustomer: function () {
      if (this.customerClickable && this.receipt.customer_id) {
        this.$store.commit('customer/SET_SHOW_DIALOG', false) // stänger dialog först, om dialogen redan skulle vara öppen i bakgrunden så flyttas den inte till förgrunden annars
        this.$nextTick(() => {
          this.$store.dispatch('customer/openDialogById', { customerId: this.receipt.customer_id })
        })
      }
    },
    openBooking: function () {
      if (this.bookingClickable && this.receipt.booking_id) {
        this.$store.commit('booking/SET_SHOW_DIALOG', false) // stänger dialog först, om dialogen redan skulle vara öppen i bakgrunden så flyttas den inte till förgrunden annars
        this.$nextTick(() => {
          this.$store.dispatch('booking/openDialogById', { bookingId: this.receipt.booking_id })
        })
      }
    }
  }
}
</script>
