<template>
  <div>
    <v-menu
      ref="menu"
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          prepend-inner-icon="mdi-clock-time-four-outline"
          class="input-full-height"
          readonly
          clearable
          v-bind="attrs"
          v-on="on"
          @click="clickTextField"
          @click:prepend-inner="clickTextField"
          @click:clear="clear"
          outlined
        ></v-text-field>
      </template>
      <v-time-picker
        v-model="time"
        full-width
        format="24hr"
        :allowed-minutes="allowedMinutes"
        @input="setTime"
        @click:hour="setHour"
        @click:minute="showMenu = false;"
        scrollable
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'TimePicker',
  props: {
    value: String,
    label: String,
    allowedMinutes: Array,
    defaultTime: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showMenu: false
  }),
  computed: {
    time: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setTime: function (val) {
      this.time = val
    },
    setHour: function (val) {
      this.setTime(`${val}:00`)
    },
    clickTextField: function () {
      this.$nextTick(function () {
        this.showMenu = true
      })
    },
    clear: function () {
      this.$nextTick(function () {
        document.activeElement.blur()
      })
    }
  },
  watch: {
    showMenu: function () {
      if (this.showMenu && (this.time === '' || this.time == null)) {
        this.time = this.defaultTime
      }
    }
  }
}
</script>
