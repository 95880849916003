import * as Sentry from '@sentry/vue'

export const namespaced = true

export const state = {
  hasGetRegisterError: false,
  getRegisterErrorMessage: '',

  hasNotRegisteredError: false,
  hasDidNotCloseError: false,

  isCheckingCleanCashNetworkStatus: false,
  isCleanCashServerOffline: false,

  isCheckingCleanCashStatus: false,
  hasCleanCashLowStorage: false,
  hasCleanCashNoStorage: false,
  hasCleanCashError: false,
  hasCleanCashErrorDifId: false,
  cleanCashErrorCode: null,
  hasCleanCashConnectionError: false
}

export const mutations = {
  SET_HAS_GET_REGISTER_ERROR (state, val) {
    state.hasGetRegisterError = val
  },
  SET_GET_REGISTER_ERROR_MESSAGE (state, val) {
    state.getRegisterErrorMessage = val
  },
  SET_HAS_NOT_REGISTERED_ERROR (state, val) {
    state.hasNotRegisteredError = val
  },
  SET_HAS_DID_NOT_CLOSE_ERROR (state, val) {
    state.hasDidNotCloseError = val
  },
  SET_IS_CHECKING_CLEAN_CASH_NETWORK_STATUS (state, val) {
    state.isCheckingCleanCashNetworkStatus = val
  },
  SET_IS_CLEAN_CASH_SERVER_OFFLINE (state, val) {
    state.isCleanCashServerOffline = val
  },
  SET_IS_CHECKING_CLEAN_CASH_STATUS (state, val) {
    state.isCheckingCleanCashStatus = val
  },
  SET_HAS_CLEAN_CASH_LOW_STORAGE (state, val) {
    state.hasCleanCashLowStorage = val
  },
  SET_HAS_CLEAN_CASH_NO_STORAGE (state, val) {
    state.hasCleanCashNoStorage = val
  },
  SET_HAS_CLEAN_CASH_ERROR (state, val) {
    state.hasCleanCashError = val
  },
  SET_CLEAN_CASH_ERROR_CODE (state, val) {
    state.cleanCashErrorCode = val
  },
  SET_HAS_CLEAN_CASH_ERROR_DIF_ID (state, val) {
    state.hasCleanCashErrorDifId = val
  },
  SET_HAS_CLEAN_CASH_CONNECTION_ERROR (state, val) {
    state.hasCleanCashConnectionError = val
  }
}

export const actions = {
  async checkForErrors ({ dispatch }) {
    dispatch('checkCleanCash')
  },
  async checkCleanCash ({ commit, rootState }) {
    const requiresSignature = rootState.posRegister.register.current_control_unit.requires_client_signature
    if (!requiresSignature) {
      return
    }

    console.log('checkCleanCash')
    commit('SET_IS_CHECKING_CLEAN_CASH_NETWORK_STATUS', true)
    const cleanCashIsOnline = await window.cleancash.checkServerIsOnline()
    commit('SET_IS_CHECKING_CLEAN_CASH_NETWORK_STATUS', false)
    commit('SET_IS_CLEAN_CASH_SERVER_OFFLINE', !cleanCashIsOnline)

    if (!cleanCashIsOnline) {
      return
    }

    commit('SET_IS_CHECKING_CLEAN_CASH_STATUS', true)
    const status = await window.cleancash.requestStatus(rootState.posRegister.register.legal_name, rootState.posRegister.register.org_number)
    console.log('CleanCash status / e', JSON.stringify(status))
    commit('SET_IS_CHECKING_CLEAN_CASH_STATUS', false)
    if (!status || status.isUnexpectedError) {
      // sentry capture
      Sentry.captureMessage('CleanCash error')

      // Kända felmeddelande som hamnar här:
      // status.error?.message === 'NETWORK_ERROR'
      commit('SET_HAS_CLEAN_CASH_CONNECTION_ERROR', true)
      commit('SET_HAS_CLEAN_CASH_ERROR_DIF_ID', false)
      commit('SET_HAS_CLEAN_CASH_ERROR', false)
      commit('SET_CLEAN_CASH_ERROR_CODE', null)
      commit('SET_HAS_CLEAN_CASH_LOW_STORAGE', false)
      commit('SET_HAS_CLEAN_CASH_NO_STORAGE', false)
    } else if (status.Code === '7' && status.ShortMessage === 'ROUTE_NOT_FOUND') {
      // konfigurationsfel i cleancashserver
      commit('SET_HAS_CLEAN_CASH_CONNECTION_ERROR', true)
      commit('SET_HAS_CLEAN_CASH_ERROR_DIF_ID', false)
      commit('SET_HAS_CLEAN_CASH_ERROR', false)
      commit('SET_CLEAN_CASH_ERROR_CODE', null)
      commit('SET_HAS_CLEAN_CASH_LOW_STORAGE', false)
      commit('SET_HAS_CLEAN_CASH_NO_STORAGE', false)
    } else if (status.Code === '99' && status.ShortMessage === 'UNIT_NOT_FOUND') {
      // Cleancash server rätt konfigurerad, kontrollenheten ej anslutan
      commit('SET_HAS_CLEAN_CASH_CONNECTION_ERROR', true)
      commit('SET_HAS_CLEAN_CASH_ERROR_DIF_ID', false)
      commit('SET_HAS_CLEAN_CASH_ERROR', false)
      commit('SET_CLEAN_CASH_ERROR_CODE', null)
      commit('SET_HAS_CLEAN_CASH_LOW_STORAGE', false)
      commit('SET_HAS_CLEAN_CASH_NO_STORAGE', false)
    } else {
      commit('SET_HAS_CLEAN_CASH_CONNECTION_ERROR', false)
      commit('SET_HAS_CLEAN_CASH_ERROR_DIF_ID', status.Id !== rootState.posRegister.register.control_unit_manufacturer_id)
      commit('SET_HAS_CLEAN_CASH_ERROR', status.MainStatus !== '0')
      commit('SET_CLEAN_CASH_ERROR_CODE', status.MainStatus)
      commit('SET_HAS_CLEAN_CASH_LOW_STORAGE', status.StorageStatus === '1')
      commit('SET_HAS_CLEAN_CASH_NO_STORAGE', status.StorageStatus === '2')
    }
  }
}

export const getters = {
  hasFatalError: function (state, getters, rootState) {
    return rootState.isOffline || state.hasGetRegisterError || state.hasNotRegisteredError || state.hasDidNotCloseError || state.isCheckingCleanCashNetworkStatus || state.isCleanCashServerOffline || state.isCheckingCleanCashStatus || state.hasCleanCashNoStorage || state.hasCleanCashError || state.hasCleanCashErrorDifId || state.hasCleanCashConnectionError
  }
}
