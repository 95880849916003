<template>
<div class="booking-input-remarks" :class="{ 'soft-border': softBorder }">
  <v-textarea
    v-model="inputRemarks"
    outlined
    label="Bokningsanteckningar"
    prepend-inner-icon="mdi-tag-text-outline"
    counter="300"
    :auto-grow="!noResize"
    :no-resize="noResize"
    rows="3"
    @focusout="onFocusOut"
    :loading="isSaving"
    :disabled="isSkeletonLoader"
    :success-messages="successMessages"
  >
    <template v-if="isSkeletonLoader" v-slot:[`prepend-inner`]>
      <v-icon style="height: 24px;">mdi-tag-text-outline</v-icon>
      <div style="height: 0; width: 0; overflow: visible;">
        <v-skeleton-loader
          class="ml-3"
          style="width: 180px;"
          type="paragraph"
        ></v-skeleton-loader>
      </div>
    </template>
  </v-textarea>
</div>
</template>

<script>
import BookingService from '@/services/BookingService.js'

export default {
  name: 'BookingInputRemarks',
  props: {
    isOpen: Boolean,
    softBorder: Boolean,
    bookingRemarks: String,
    bookingId: Number,
    isSkeletonLoader: Boolean,
    noResize: Boolean
  },
  data: () => ({
    unsavedRemarks: null,
    savingRemarks: null,
    isSaving: false,
    hasSuccess: false,
    successId: 0
  }),
  computed: {
    inputRemarks: {
      get: function () {
        if (this.isSkeletonLoader) {
          return ' '
        }
        return this.unsavedRemarks
      },
      set: function (val) {
        if (val === '') {
          val = null
        }
        this.unsavedRemarks = val
        this.hasSuccess = false
        setTimeout(() => {
          if (this.isOpen && this.unsavedRemarks === val) {
            // Om text uppdaterats men inte ändrats på 2 sekunder, sparar
            this.saveRemarks()
          }
        }, 2000)
      }
    },
    successMessages: function () {
      if (this.hasSuccess) {
        return ['Sparat!']
      }
      return []
    }
  },
  methods: {
    onFocusOut: function () {
      this.saveRemarks()
    },
    saveRemarks: function () {
      if ((!this.isSaving && this.unsavedRemarks === this.bookingRemarks) ||
        (this.isSaving && this.unsavedRemarks === this.savingRemarks)) {
        // Avbryter om remark samma som den redan sparade och inte håller på att spara ny, eller om redan håller på att spara samma remark
        return false
      }
      const bookingId = this.bookingId
      this.isSaving = true
      this.savingRemarks = this.unsavedRemarks
      this.hasSuccess = false

      BookingService.updateRemarks({
        bookingId,
        remarks: this.savingRemarks
      })
        .then(({ data }) => {
          if (data.status === 'success') {
            this.$emit('remarks-updated', { bookingId, remarks: data.data.remarks }) // Uppdaterar alltid booking med senaste sparade remark

            if (this.unsavedRemarks === data.data.remarks) {
              // Snackbar/SuccessMessage bara om anteckningen inte har ändrats redan
              if (!this.isOpen) {
                this.$store.dispatch('snackbars/createSnackbar', {
                  color: 'success',
                  text: 'Bokningsanteckningen har sparats'
                })
              } else {
                this.hasSuccess = true
                this.successId++
                const thisSuccessId = this.successId
                setTimeout(() => {
                  if (thisSuccessId === this.successId) {
                    // sätter inte false om det har kommit en ny success
                    this.hasSuccess = false
                  }
                }, 5000)
              }
            }
          }
        })
        .finally(() => {
          this.isSaving = false
          this.savingRemarks = null
        })
    }
  },
  watch: {
    bookingId: function () {
      if (this.isOpen) {
        this.unsavedRemarks = this.bookingRemarks
        this.hasSuccess = false
      }
    }
  },
  mounted () {
    this.unsavedRemarks = this.bookingRemarks
    this.hasSuccess = false
  }
}
</script>
