<template>
  <v-list-item :disabled="isExpired">
    <v-list-item-icon style="margin-right: 29px;">
      <v-avatar
        :color="positionAvatarColor"
        size="30"
        class="my-auto"
        style="margin-left: -3px;"
      >
        <v-icon color="white" small>{{ booking.position.avatar.icon }}</v-icon>
      </v-avatar>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="booking.position.full_name"></v-list-item-title>
      <v-list-item-subtitle v-text="subtitle"></v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon class="ml-4 my-3">
      <div class="text-subtitle-2 text-right status-text">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="cursor-default">{{ statusText }}</span>
          </template>
          <div style="max-width: 200px; width: auto;">Kvarstående tid innan reservationen löper ut och platsen blir tillgänglig igen</div>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="deleteDraft"
              class="error-on-hover ml-2"
              v-bind="attrs"
              v-on="on"
              :disabled="isExpired"
              :loading="isDeleting"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <div style="max-width: 200px; width: auto;">Ta bort reservationen nu och gör platsen tillgänglig för bokning direkt</div>
        </v-tooltip>
      </div>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import BookingService from '@/services/BookingService.js'

export default {
  name: 'DraftsListRow',
  components: {},
  props: {
    booking: Object,
    now: Object
  },
  data: () => ({
    isDeleting: false,
    isDeleted: false
  }),
  computed: {
    isExpired: function () {
      return this.statusText === '0s' || this.isDeleted
    },
    statusText: function () {
      let seconds = this.booking.draft_expires_at.diff(this.now, 'seconds')
      seconds = Math.max(seconds, 0)
      const minutes = Math.floor(seconds / 60)
      if (minutes === 0) {
        return `${seconds}s`
      }
      const remainingSeconds = seconds - (60 * minutes)
      return `${minutes}m ${remainingSeconds}s`
    },
    positionAvatarColor: function () {
      if (this.isExpired) {
        return 'grey'
      }
      return this.booking.position.avatar.color
    },
    subtitle: function () {
      const checkInFormatted = window.conversion.toHumanReadableDate(this.booking.check_in)
      const checkOutFormatted = window.conversion.toHumanReadableDate(this.booking.check_out)
      return `${checkInFormatted} till ${checkOutFormatted}`
    }
  },
  methods: {
    deleteDraft: function () {
      this.isDeleting = true
      BookingService.deleteDraft(this.booking.id)
        .then(({ data }) => {
          if (data.status === 'success') {
            // uppdateras med pusher
            this.isDeleted = true
          }
        })
        .finally(() => {
          this.isDeleting = false
        })
    }
  }
}
</script>
