// Ange alltid parametrarna checkIn (incheckningsdag på bokning) och amount (fakturabelopp) när dessa är tillgängliga
// funktionen returnerar false om den inte är applicerbar med givna parametrar (ex. betalvillkoret använder due_date_days_before_checkin men $checkIn parameter är inte angivet)
// VIKTIGT: det finns en motsvarande funktion i client för att beräkna dueDate utan att behöva fråga servern i mixins/paymentTerm.js - så ändringar i någon av funktionerna ska speglas i den andra
function calculateDueDate (invoiceDate = null, checkIn = null, amount = null) {
  if (invoiceDate === null) {
    // om specifikt fakturadatum inte är angivet så använder vi dagens datum
    invoiceDate = window.dayjs().startOf('day')
  }
  if (this.id === 1) {
    // för villkoret 'Manuellt val av förfallodatum' är det inte meningen att vi ska beräkna förfallodatum
    return false
  }
  if (this.id === 2) {
    // för villkoret 'Kontant' är det alltid förfallodatum samma dag som fakturadatum
    return invoiceDate
  }

  if (!(this.due_date_date !== null ^ this.due_date_days_before_checkin !== null ^ this.due_date_days_from_invoicing !== null)) {
    // Om en och endast en inte är null, sen ! på allt
    console.error('Villkoren ej kompatibla')
    return false
  }

  if (this.due_date_date) {
    // för villkor med ett fast datum angivet ska inte datumet beräknas, alternativ med fasta datum som passerat borde döljas i klienten
    return window.dayjs(this.due_date_date).startOf('day')
  }

  if (this.due_date_days_from_invoicing) {
    // ska inte behöva bry oss om min days eftersom antalet dagar från fakturadatum alltid kommer vara samma här
    return invoiceDate.add(this.due_date_days_from_invoicing, 'days')
  }

  if (this.due_date_days_before_checkin) {
    if (!checkIn) {
      return false
    }
    const calculatedDate = checkIn.clone().subtract(this.due_date_days_before_checkin, 'days')
    const minDate = window.dayjs().startOf('day').add(this.min_days_due_date, 'days')
    return calculatedDate.isAfter(minDate) ? calculatedDate : minDate
  }
}

function calculateDepositDueDate (invoiceDate = null, checkIn = null, amount = null) {
  if (!invoiceDate) {
    invoiceDate = window.dayjs().startOf('day')
  }
  if (!shouldHaveDeposit(invoiceDate, checkIn, amount, this)) {
    return false
  }

  if (this.deposit_date) {
    return window.dayjs(this.deposit_date)
  } else if (this.deposit_days_before_checkin) {
    return checkIn.clone().subtract(this.deposit_days_before_checkin, 'days')
  } else if (this.deposit_days_from_invoicing) {
    return invoiceDate.clone().add(this.deposit_days_from_invoicing, 'days')
  }
}

function calculateDepositAmount (invoiceDate = null, checkIn = null, amount = null) {
  if (!invoiceDate) {
    invoiceDate = window.dayjs().startOf('day')
  }
  if (!shouldHaveDeposit(invoiceDate, checkIn, amount, this)) {
    return false
  }

  if (this.deposit_amount) {
    return this.deposit_amount
  } else if (this.deposit_percent) {
    return Math.round(amount * this.deposit_percent / 100 / 100) * 100 // avrundar till hela kronor, dividerar två gånger för deposit_percent är procent i heltal
  }
}

function shouldHaveDeposit (invoiceDate, checkIn, amount, paymentTerm) {
  // Se om villkoren är uppfyllda för att kunna använda deposit
  if ((paymentTerm.deposit_condition_min_amount && amount < paymentTerm.deposit_condition_min_amount) ||
      (paymentTerm.deposit_condition_min_days_before_checkin && checkIn && checkIn.diff(invoiceDate, 'days') < paymentTerm.deposit_condition_min_days_before_checkin) ||
      (!paymentTerm.deposit_date && !paymentTerm.deposit_days_before_checkin && !paymentTerm.deposit_days_from_invoicing)) {
    return false
  }

  // Kontrollerar om villkoren är motstridiga
  if (!(paymentTerm.deposit_date !== null ^ paymentTerm.deposit_days_before_checkin !== null ^ paymentTerm.deposit_days_from_invoicing !== null)) {
    // Om en och endast en inte är null, sen ! på allt
    console.error('Antingen deposit_date, deposit_days_before_checkin eller deposit_days_from_invoicing behövs för att beräkna depositDueDate')
    return false
  }
  if (!(paymentTerm.deposit_amount !== null ^ paymentTerm.deposit_percent !== null)) {
    // Om en och endast en inte är null, sen ! på allt
    console.error('Antingen deposit_amount eller deposit_percent behövs för att beräkna depositAmount')
    return false
  }

  if (paymentTerm.deposit_condition_min_days_before_checkin && !checkIn) {
    console.error('deposit_condition_min_days_before_checkin kräver att checkIn är angivet')
    return false
  }

  if (paymentTerm.deposit_amount !== null && paymentTerm.deposit_condition_min_amount !== null && paymentTerm.deposit_condition_min_amount < paymentTerm.deposit_amount) {
    console.error('Ogiltig PaymentTerm, borde inte ha lagts in')
    return false
  }

  return true
}

export default {
  calculateDueDate,
  calculateDepositDueDate,
  calculateDepositAmount
}
