import router from '@/router'
export const namespaced = true

export const state = {
  owner: null, // null, booking, order eller customer
  ownerClass: '', // '', 'booking', 'order' eller 'customer'
  charges: [], // varukorgen i kassan
  accommodationChargesAmount: 0, // Uppdateras från booking.js getRegisterAccommodationSums
  appliedCustomerCreditAmount: 0,
  isLoadingCart: false,

  isReturn: false,
  nextChargeId: 1, // client_id på nyskapade charges

  editedCharge: null,
  originalEditedCharge: null, // en kopia av editedCharge innan den började redigeras, för att kunna återställa ändringar
  showEditChargeDialog: false,

  triggerUnpaidInvoiceAlert: 0
}

export const mutations = {
  SET_CHARGES (state, val) {
    // OBS: metoden ignorerar tally charges
    state.charges = state.charges.filter(charge => charge.is_tally_charge) // Filtrerar allt utom tally charges först
    state.charges = state.charges.concat(val)
  },
  SET_TALLY_CHARGES (state, val) {
    // OBS: metoden ignorerar vanliga charges
    state.charges = state.charges.filter(charge => !charge.is_tally_charge) // Filtrerar bort tally charges först
    state.charges = state.charges.concat(val)
  },
  SET_ACCOMMODATION_CHARGES_AMOUNT (state, val) {
    state.accommodationChargesAmount = val
  },
  SET_APPLIED_CUSTOMER_CREDIT_AMOUNT (state, val) {
    state.appliedCustomerCreditAmount = val
  },
  SET_IS_LOADING_CART (state, val) {
    state.isLoadingCart = val
  },
  SET_OWNER (state, { ownerClass, owner }) {
    state.ownerClass = ownerClass
    state.owner = owner
  },
  SET_IS_RETURN (state, val) {
    state.charges.forEach(charge => {
      if ((val && charge.quantity > 0) || (!val && charge.quantity < 0)) {
        charge.quantity = charge.quantity * -1
      }
    })
    if (state.originalEditedCharge) {
      if ((val && state.originalEditedCharge.quantity > 0) || (!val && state.originalEditedCharge.quantity < 0)) {
        state.originalEditedCharge.quantity = state.originalEditedCharge.quantity * -1
      }
    }
    state.isReturn = val
  },
  ADD_CHARGE (state, val) {
    state.charges.push(val)
  },
  DELETE_CHARGE (state, charge) {
    state.charges = state.charges.filter(c => c.client_id !== charge.client_id)
  },
  SET_EDITED_CHARGE (state, val) {
    const chargeClone = Object.assign({}, val)
    state.originalEditedCharge = chargeClone
    state.editedCharge = val
  },
  SET_SHOW_EDIT_CHARGE_DIALOG (state, val) {
    state.showEditChargeDialog = val
  },
  TRIGGER_UNPAID_INVOICE_ALERT (state) {
    state.triggerUnpaidInvoiceAlert++
  }
}

export const actions = {
  addToCart ({ state, commit, rootState }, product) {
    if (rootState.currentPageName !== 'PosRegister') {
      // om man lägger till en produkt i varukorgen från någon annanstans än kassan (exvis sökt på en produkt eller med varuscanner) så routar vi till kassan
      router.push({ name: 'PosRegister' })
    }

    if (!product.price) {
      const price = product.price === 0 ? '' : product.price
      const charge = {
        id: 0,
        client_id: state.nextChargeId++,
        product,
        quantity: state.isReturn ? -1000 : 1000,
        price,
        original_price: product.price,
        note: '', // Kommer på kvitto
        discount_type: null,
        discount_value: 0,
        created_at: null,
        updated_at: null
      }
      commit('ADD_CHARGE', charge)
      commit('SET_EDITED_CHARGE', charge)
      commit('SET_SHOW_EDIT_CHARGE_DIALOG', true)
      return false
    }

    const existingCharge = state.charges.find(c => { return (c.product.id === product.id && c.price === product.price && c.note === '' && c.id === 0) })
    if (existingCharge) {
      // Om finns en charge med samma produkt, samma pris, ingen kommentar och inte är sparad i db så incremetera den istället för att lägga till ny rad
      if (state.isReturn) {
        existingCharge.quantity -= 1000
      } else {
        existingCharge.quantity += 1000
      }
    } else {
      // Om inte finns en produkt enligt ovan kriterier så skapar vi ny rad
      const charge = {
        id: 0,
        client_id: state.nextChargeId++,
        product,
        quantity: state.isReturn ? -1000 : 1000,
        price: product.price,
        original_price: product.price,
        note: '', // Kommer på kvitto
        discount_type: null,
        discount_value: 0,
        created_at: null,
        updated_at: null
      }
      commit('ADD_CHARGE', charge)
    }
  },
  deleteCharge ({ commit }, charge) {
    commit('DELETE_CHARGE', charge)
  },
  openBooking ({ commit }, { booking }) {
    // Anropas från booking.js getRegisterData efter registerdata har hämtats från server
    commit('SET_OWNER', { ownerClass: 'booking', owner: booking })
  },
  openOrder ({ commit }, order) {
    commit('SET_OWNER', { ownerClass: 'order', owner: order })
    commit('SET_CHARGES', order.charges)
  },
  park ({ state, dispatch }) {
    // parkera order/booking
    const charges = state.charges
    if (state.ownerClass === 'booking') {
      const booking = state.owner
      dispatch('booking/parkBooking', { booking, charges }, { root: true })
    } else if (state.ownerClass === 'order') {
      const order = state.owner
      dispatch('posOrder/parkOrder', { order, charges }, { root: true })
    }
  },
  cancel ({ commit }) {
    // Avbryter pågående varukorg utan att spara
    // Återställer inte route, ska endast användas när routar bort från kassan
    commit('SET_CHARGES', [])
    commit('SET_TALLY_CHARGES', [])
    commit('SET_ACCOMMODATION_CHARGES_AMOUNT', 0)
    commit('SET_APPLIED_CUSTOMER_CREDIT_AMOUNT', 0)
    commit('SET_IS_RETURN', false)
    commit('SET_OWNER', { ownerClass: '', owner: null })
  },
  reset ({ commit }) {
    // Nollställer varukorgen, t.ex. efter en order parkerats
    commit('SET_CHARGES', [])
    commit('SET_TALLY_CHARGES', [])
    commit('SET_ACCOMMODATION_CHARGES_AMOUNT', 0)
    commit('SET_APPLIED_CUSTOMER_CREDIT_AMOUNT', 0)
    commit('SET_IS_RETURN', false)
    commit('SET_OWNER', { ownerClass: '', owner: null })
    router.push({ name: 'PosRegister', params: {} })
  },
  openEditChargeDialog ({ state, commit }, chargeClientId) {
    const charge = state.charges.find(c => c.client_id === chargeClientId)
    if (charge) {
      commit('SET_EDITED_CHARGE', charge)
      commit('SET_SHOW_EDIT_CHARGE_DIALOG', true)
    }
  },
  clearAllDiscounts ({ state }) {
    state.charges.forEach((charge) => {
      charge.discount_type = null
      charge.discount_value = null
      charge.price = charge.original_price
    })
  },
  setDiscountOnAll ({ state }, { discountType, discountValue, overrideExistingDiscounts }) {
    if (!['flat', 'percent'].includes(discountType)) {
      return false // bara stöd för dessa två rabatter
    }
    if (discountValue <= 0) {
      return false
    }

    state.charges.forEach((charge) => {
      if (charge.discount_type === null || overrideExistingDiscounts) {
        // om inte overrideExistingDiscounts = true så sätts inte discount på charges som redan har en discount
        charge.discount_type = discountType
        const originalPrice = (charge.original_price ?? charge.price)
        if (discountType === 'flat') {
          charge.discount_value = Math.min(Math.round(discountValue * 100), originalPrice) // får inte överstiga ursprungspriset
          charge.price = originalPrice - charge.discount_value
        } else if (discountType === 'percent') {
          charge.discount_value = Math.min(Math.round(discountValue * 100), 10000) // får inte överstiga 100%
          charge.price = originalPrice * (1 - (charge.discount_value / 10000))
        }
      }
    })
  },
  printCart ({ dispatch, state, rootState, getters }) {
    const printObject = {
      content: [],
      port: 'SIM',
      width: 42
    }
    if (rootState.workstation.default_printer) {
      printObject.port = rootState.workstation.default_printer.port
      printObject.width = rootState.workstation.default_printer.width
    }
    printObject.content.push({
      type: 'text',
      alignment: 'center',
      value: `*** ${getters.cartTitle} ***`,
      text_size: 1,
      bold: false
    })
    printObject.content.push({
      type: 'line'
    })
    printObject.content.push({
      type: 'newline'
    })

    Object.values(state.charges).forEach((charge) => {
      printObject.content.push({
        type: 'left-right',
        value: [`${charge.product.name}`, `${charge.quantity / 1000} x ${parseInt(charge.price) / 100}kr`],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
    })
    printObject.content.push({
      type: 'cut'
    })
    dispatch('print/print', printObject, { root: true })
  }
}

export const getters = {
  totalAmount: function (state) {
    let totalAmount = state.charges.reduce((acc, c) => { return (acc + (c.quantity / 1000 * c.price)) }, 0)
    totalAmount += state.accommodationChargesAmount
    return Math.round(totalAmount) // Detta är inte öresavrundning, utan rundar bara till närmsta öre för att inte skicka double till server
  },
  totalPayableAmount: function (state, getters) {
    const payableAmount = getters.totalAmount - state.appliedCustomerCreditAmount
    return Math.sign(payableAmount) * Math.round(Math.abs(payableAmount / 100)) * 100 // Math.round avrundar -1.5 till -1 men vi vill avrunda till -2, viktigt att det blir samma vid köp och retur
  },
  hasUnsavedCharges: function (state) {
    return state.charges.filter(c => { return c.id === 0 }).length > 0
  },
  cartTitle: function (state) {
    if (state.ownerClass === '') {
      return 'Varukorg'
    } else if (state.ownerClass === 'booking') {
      return `${state.owner.title} (${state.owner.customer.full_name})`
    } else if (state.ownerClass === 'order') {
      return `Parkerad nota ${state.owner.name}`
    }
    return ''
  }
}
