<template>
  <g
    :transform="`translate(${(position.map_x * originalImageWidth)},${(position.map_y * originalImageHeight)})`"
    class="position-container"
    :class="positionClassList"
  >
    <template>
      <rect
        :id="position.svg_rect_id"
        class="position"
        @click.stop="selectPosition"
        :width="position.width_percent"
        :height="position.height_percent"
        :rx="position.svg_rect_rx"
      />
      <clipPath :id="position.svg_rect_clip_path_id">
        <use v-bind:xlink:href="position.svg_rect_clip_path_href"></use>
      </clipPath>
      <svg :width="position.width_percent" :height="position.height_percent" viewBox="0 0 100 100" preserveAspectRatio="none">
        <clipPath :id="position.svg_clip_path_id">
          <rect
            width="100"
            height="100"
            :rx="position.border_radius_percent"
          />
        </clipPath>
        <polygon
          class="position-inner-right-triangle"
          points="0,100 100,0 100,100"
          @click.stop="selectPosition"
          :clip-path="position.svg_polygon_clip_path"
        />
      </svg>
      <text
        :clip-path="position.svg_text_clip_path"
        class="position-text"
        dominant-baseline="middle"
        text-anchor="middle"
        :x="position.svg_text_x"
        :y="position.svg_text_y"
      >
        <tspan :font-size="position.svg_text_font_size">
          {{ position.map_name }}
        </tspan>
      </text>
    </template>
  </g>
</template>

<script>

export default {
  name: 'MapPosition',
  props: {
    position: Object,
    checkedInBookings: Array,
    availability: Array,
    showAvailability: Boolean,
    selectedPositionId: Number,
    selectedCategoryId: Number,
    mapImageAspectRatio: Number
  },
  data: () => ({
    originalImageWidth: 1000
  }),
  computed: {
    originalImageHeight: function () {
      return this.originalImageWidth * this.mapImageAspectRatio
    },
    positionClassList: function () {
      if (this.showAvailability) {
        if (this.availability.length === 0) {
          return {}
        }
        const positionAvailability = this.availability[this.position.id]
        if (!positionAvailability) {
          return {}
        }
        return {
          'is-selected': this.isSelected,
          'not-selected-category': !this.isSelectedCategory,
          'not-selected-position': this.hasSelectedPosition && !this.isSelected,
          'is-available': positionAvailability.is_available,
          'is-checkingout': positionAvailability.status === 'bookable',
          'is-blocked': positionAvailability.status === 'blocked',
          'is-reserved': positionAvailability.status === 'reserved'
        }
      } else {
        return {
          'is-selected': this.isSelected,
          'not-selected-category': !this.isSelectedCategory,
          'not-selected-position': this.hasSelectedPosition && !this.isSelected,
          'is-occupied': this.isOccupied
        }
      }
    },
    isOccupied: function () {
      return this.checkedInBookings.findIndex(booking => booking.position.id === this.position.id) >= 0
    },
    isSelected: function () {
      return this.selectedPositionId === this.position.id
    },
    isSelectedCategory: function () {
      return !this.selectedCategoryId || this.selectedCategoryId === this.position.category_id
    },
    hasSelectedPosition: function () {
      return !!this.selectedPositionId
    }
  },
  methods: {
    selectPosition: function () {
      this.$emit('select-position')
    }
  },
  watch: {}
}
</script>
