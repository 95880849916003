<template>
  <div class="print-registration-card">
    <v-badge
      avatar
      color="RGBA(0, 0, 0, 0)"
      overlap
      offset-y="26"
      offset-x="20"
      class="click-thru-badge"
    >
      <template v-slot:badge>
        <v-avatar size="12">
          <span class="fi" :class="`fi-${defaultLanguage.flagCode}`"></span>
        </v-avatar>
      </template>
      <template v-slot:default>
        <v-btn
          @click="printRegistrationCard(defaultLanguage.languageCode)"
          icon
          color="primary"
        >
          <v-icon>mdi-printer-pos</v-icon>
        </v-btn>
      </template>
    </v-badge>
    <v-menu
      bottom
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-subheader class="py-0">Skriv ut registreringskort</v-subheader>
        <v-list-item
          v-for="language in registrationCardLanguages"
          :key="language.id"
          @click="printRegistrationCard(language.languageCode)"
        >
          <v-list-item-title>
            <span class="fi mr-2" :class="`fi-${language.flagCode}`"></span> {{ language.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PrintRegistrationCard',
  props: {
    customer: Object
  },
  data: () => ({
    registrationCardLanguages: [
      {
        id: 1,
        name: 'Svenska',
        languageCode: 'sv',
        flagCode: 'se'
      },
      {
        id: 2,
        name: 'Engelska',
        languageCode: 'en',
        flagCode: 'gb'
      },
      {
        id: 3,
        name: 'Tyska',
        languageCode: 'de',
        flagCode: 'de'
      },
      {
        id: 4,
        name: 'Norska',
        languageCode: 'nb',
        flagCode: 'no'
      },
      {
        id: 5,
        name: 'Danska',
        languageCode: 'da',
        flagCode: 'dk'
      },
      {
        id: 6,
        name: 'Nederländska',
        languageCode: 'nl',
        flagCode: 'nl'
      },
      {
        id: 7,
        name: 'Franska',
        languageCode: 'fr',
        flagCode: 'fr'
      }
    ]
  }),
  computed: {
    ...mapState(
      {
        defaultPrinter: state => state.workstation.default_printer
      }
    ),
    defaultCountry: function () {
      return this.customer?.country
    },
    defaultLanguage: function () {
      const swedishLanguage = this.registrationCardLanguages.find(lang => lang.languageCode === 'sv')
      if (!this.defaultCountry) {
        return swedishLanguage
      }
      const language = this.registrationCardLanguages.find(lang => lang.languageCode === this.defaultCountry.default_language)
      return language ?? swedishLanguage
    }
  },
  methods: {
    printRegistrationCard: function (languageCode) {
      const printObject = {
        content: [],
        port: 'SIM',
        width: 48
      }
      if (this.defaultPrinter) {
        printObject.port = this.defaultPrinter.port
        printObject.width = this.defaultPrinter.width
      }

      if (this.defaultPrinter && this.defaultPrinter.logo_local_path) {
        printObject.content.push({
          type: 'image',
          alignment: 'center',
          value: this.defaultPrinter.logo_local_path
        })
        printObject.content.push({
          type: 'newline'
        })
        printObject.content.push({
          type: 'newline'
        })
      }

      let title = ''
      let givenNameLabel = ''
      let surnameLabel = ''
      let addressLabel = ''
      let emailLabel = ''
      let phoneNumberLabel = ''
      switch (languageCode) {
        case 'en':
          title = 'Registration'
          givenNameLabel = 'Given name:'
          surnameLabel = 'Surname:'
          addressLabel = 'Address:'
          emailLabel = 'Email:'
          phoneNumberLabel = 'Phone number:'
          break
        case 'de':
          title = 'Registrierung'
          givenNameLabel = 'Vorname:'
          surnameLabel = 'Nachname:'
          addressLabel = 'Adresse:'
          emailLabel = 'E-Mail:'
          phoneNumberLabel = 'Telefonnummer:'
          break
        case 'nb':
          title = 'Registrering'
          givenNameLabel = 'Fornavn:'
          surnameLabel = 'Etternavn:'
          addressLabel = 'Adresse:'
          emailLabel = 'E-post:'
          phoneNumberLabel = 'Telefonnummer:'
          break
        case 'da':
          title = 'Registrering'
          givenNameLabel = 'Fornavn:'
          surnameLabel = 'Efternavn:'
          addressLabel = 'Adresse:'
          emailLabel = 'E-mail:'
          phoneNumberLabel = 'Telefonnummer:'
          break
        case 'fr':
          title = 'Inscription'
          givenNameLabel = 'Prénom:'
          surnameLabel = 'Nom de famille:'
          addressLabel = 'Adresse:'
          emailLabel = 'Email:'
          phoneNumberLabel = 'Numéro de téléphone:'
          break
        case 'nl':
          title = 'Registratie'
          givenNameLabel = 'Voornaam:'
          surnameLabel = 'Achternaam:'
          addressLabel = 'Adres:'
          emailLabel = 'E-mail:'
          phoneNumberLabel = 'Telefoonnummer:'
          break
        case 'sv': // sv default
        default:
          title = 'Registrering'
          givenNameLabel = 'Förnamn:'
          surnameLabel = 'Efternamn:'
          addressLabel = 'Adress:'
          emailLabel = 'Email:'
          phoneNumberLabel = 'Telefon nr:'
      }

      printObject.content.push({
        type: 'text',
        alignment: 'center',
        value: title,
        text_size: 1,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'newline'
      })

      const givenName = this.customer.given_name ? this.customer.given_name : '___________________'
      const surname = this.customer.surname ? this.customer.surname : '___________________'

      const country = (this.customer.street_name || this.customer.city || this.customer.zip) ? this.customer.country : null // skriver bara ut angivet country om fyllt i andra adressuppgifter, för att inte skriva ut sverige som default om inte påbörjat adressen
      let addressLineOne = ''
      if (languageCode === 'fr') {
        // Frankrike har gatunumret före gatunamnet
        addressLineOne = `${this.customer.street_number ? this.customer.street_number : '______'}   ${this.customer.street_name ? this.customer.street_name : '___________________'}`
      } else {
        addressLineOne = `${this.customer.street_name ? this.customer.street_name : '___________________'}   ${this.customer.street_number ? this.customer.street_number : '______'}`
      }

      const addressLineTwo = `${this.customer.zip ? this.customer.zip : '___________'},  ${this.customer.city ? this.customer.city : '____________________________'}` // 42 tecken
      const addressLineThree = country?.name ?? '__________________________________________' // 42 tecken

      const email = this.customer.email ? this.customer.email : '______________________'
      let phoneNumber = ''
      if (this.customer.phone_number) {
        phoneNumber = `${this.customer.diallingCodeCountry?.dialling_code} ${this.customer.phone_number}`
      } else {
        phoneNumber = '______________________'
      }

      printObject.content.push({
        type: 'left-right',
        value: [givenNameLabel, givenName],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'left-right',
        value: [surnameLabel, surname],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'left-right',
        value: [addressLabel, addressLineOne],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'text',
        value: addressLineTwo,
        alignment: 'right',
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'text',
        value: addressLineThree,
        alignment: 'right',
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'left-right',
        value: [emailLabel, email],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })
      printObject.content.push({
        type: 'left-right',
        value: [phoneNumberLabel, phoneNumber],
        text_size: 0,
        bold: false
      })
      printObject.content.push({
        type: 'newline'
      })

      printObject.content.push({
        type: 'cut'
      })
      this.$store.dispatch('print/print', printObject)
    }
  }
}
</script>
