<template>
  <v-dialog
    v-model="show"
    persistent
    :max-width="380"
  >
    <v-card>
      <v-card-title class="text-h5" style="word-break: keep-all;">
        CampingOnline Appen
      </v-card-title>
      <v-card-text>
        Du kan installera CampingOnline som APP på din enhet för lättare åtkomst.
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="danger"
          text
          @click="dismissPrompt"
        >
          Avböj
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="installPWA"
        >
          Påbörja installation
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PwaPrompt',
  data: () => ({
    deferredPrompt: null
  }),
  computed: {
    ...mapState(
      {
        showUpdateDialog: state => state.showUpdateDialog,
        isElectron: state => state.isElectron,
        ignoreAppInstallation: state => state.localSettings.ignoreAppInstallation
      }
    ),
    show: function () {
      // Visas bara när systemet har stöd för det, inte på Electron och inte när en uppdatering väntar, bättre att uppdatera först och sen få denna då
      return this.deferredPrompt !== null && !this.showUpdateDialog && !this.isElectron && !this.isWindows && !this.ignoreAppInstallation
    },
    isWindows: function () {
      // Vill inte visa på Windows för att inte förväxlas med Electronappen
      return navigator.userAgent.includes('Windows')
    }
  },
  methods: {
    installPWA: function () {
      if (this.deferredPrompt) {
        // Startar systemets installtionsprocess
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Installationen påbörjad, hanteras av systemet')
          } else {
            console.log('Användaren avböjde installationen genom systemdialogen')
          }
        })
        // Kommer inte kunna använda samma event igen, men Chrome brukar generera ett nytt direkt efter
        this.deferredPrompt = null
      }
    },
    dismissPrompt: function () {
      // Har användaren väl avböjt behöver vi inte fråga varje gång även om webbläsaren vill det
      // Kan fortfarande installera från adressfältet och menyn
      this.deferredPrompt = null
      this.$store.commit('localSettings/SET_IGNORE_APP_INSTALLATION', true) // REVIEW: ev. ha någon annan localstorage module för denna typ av sparad data, eftersom det inte är en inställning
    }
  },
  created () {
    // PwaPrompt behöver ligga i App.vue för att listenern ska fungera
    window.addEventListener('beforeinstallprompt', (e) => {
      // Detta eventet triggas bara när systemet tillåter installation
      e.preventDefault() // Detta förhindrar systemets egna dialog att visas direkt, den visas bara ibland medan eventen kommer alltid så mer konsekvent att den triggas inuti app
      this.deferredPrompt = e
    })
  }
}
</script>
