<template>
  <div class="">
    <template v-if="icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            @click="print"
            v-bind="attrs"
            v-on="on"
            :loading="loading"
            :disabled="disabled"
          >
            <v-icon>
              mdi-printer-pos
            </v-icon>
          </v-btn>
        </template>
        {{ buttonText }}
      </v-tooltip>
    </template>

    <template v-else>
      <v-btn
        color="primary"
        @click="print"
        :loading="loading"
        :disabled="disabled"
      >
        {{ buttonText }}
        <v-icon
          right
        >
          mdi-printer-pos
        </v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>

export default {
  name: 'PrintButton',
  props: {
    printObject: Object,
    isReceipt: Boolean, // Viktigt att denna sätts för kvitton eftersom de inte ska skrivas ut igen, kopia skapas och skrivas ut
    receiptId: Number,
    loading: Boolean,
    buttonText: String, // buttonText visas som en tooltip om icon
    icon: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  data: () => ({}),
  computed: {},
  methods: {
    print: function () {
      if (this.isReceipt) {
        this.$store.dispatch('posReceipt/initCopy', { receiptId: this.receiptId })
      } else {
        this.$store.dispatch('print/print', this.printObject)
      }
    }
  },
  watch: {}
}
</script>
