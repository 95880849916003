<template>
<div class="accommodation-price-card">
  <div class="inner-container d-flex flex-column">
    <template v-if="!isEditMode">
      <v-list-item two-line class="list-item-total-price">
        <v-list-item-content>
          <v-list-item-title>{{ booking.total_accommodation_amount / 100 }} kr</v-list-item-title>
          <v-list-item-subtitle>Totala platsavgifter</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="startEdit">
            <v-icon>
              mdi-pen
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-else>
      <div class="d-flex pa-4">
        <div class="flex-grow-1 accommodation-price-input-container">
          <accommodation-price-input
            v-model="accommodationPriceInput"
          ></accommodation-price-input>
        </div>
        <div class="d-flex pl-4">
          <v-btn
            :loading="isLoadingSetAccommodationPrice"
            :disabled="!hasNewPrice"
            color="success"
            large
            style="margin-top: 6px;"
            @click="saveAccommodationPrice"
          >{{ saveButtonText }}</v-btn>
        </div>
      </div>
    </template>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>{{ booking.invoice_accommodation_amount / 100 }} kr</v-list-item-title>
        <v-list-item-subtitle>Fakturerade platsavgifter</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line v-show="booking.receipt_accommodation_amount !== 0">
      <v-list-item-content>
        <v-list-item-title>{{ booking.receipt_accommodation_amount / 100 }} kr</v-list-item-title>
        <v-list-item-subtitle>Betalt i kassaregister</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>{{ booking.remaining_accommodation_amount / 100 }} kr</v-list-item-title>
        <v-list-item-subtitle>Kvarstående platsavgifter</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</div>
</template>

<script>

import BookingService from '@/services/BookingService.js'

import AccommodationPriceInput from './AccommodationPriceInput.vue'

export default {
  name: 'AccommodationPriceCard',
  components: {
    AccommodationPriceInput
  },
  props: {
    booking: Object
  },
  data: () => ({
    isEditMode: false,
    accommodationPriceInput: 0,
    isLoadingSetAccommodationPrice: false
  }),
  computed: {
    hasNewPrice: function () {
      return this.booking.total_accommodation_amount !== this.accommodationPriceInput
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    saveButtonText: function () {
      if (this.isMobile) {
        return 'Spara'
      }
      return 'Spara nytt pris'
    }
  },
  methods: {
    saveAccommodationPrice: function () {
      this.isLoadingSetAccommodationPrice = true
      const bookingId = this.booking.id
      const price = this.accommodationPriceInput
      BookingService.setAccommodationPrice({ bookingId, price })
        .then(({ data }) => {
          if (data.status === 'success') {
            const booking = data.data.booking
            window.enrich.enrichBooking(booking)
            if (this.$store.state.booking.dialog.booking && (this.$store.state.booking.dialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
              this.$store.commit('booking/SET_DIALOG_BOOKING', booking)
            }
            if (this.$store.state.posCart.owner && this.$store.state.posCart.ownerClass === 'booking' && (this.$store.state.posCart.owner.id === bookingId)) {
              // Bokningen är öppen i kassan, ersätter booking med uppdaterat objekt
              this.$store.commit('posCart/SET_OWNER', { ownerClass: 'booking', owner: booking })
            }
          }
        })
        .finally(() => {
          this.isLoadingSetAccommodationPrice = false
          this.exitEditMode()
          this.$store.commit('booking/TRIGGER_ACCOMMODATION_CHARGES_UPDATED') // för att uppdatera charges-table om är på kassasidan
          this.$store.commit('season/TRIGGER_RELOAD_SEASON_BOOKINGS') // för att uppdatera bookings-list om man är på säsongssidan
        })
    },
    startEdit: function () {
      this.accommodationPriceInput = this.booking.total_accommodation_amount
      this.isEditMode = true
    },
    exitEditMode: function () {
      this.isEditMode = false
    }
  },
  watch: {},
  mounted () {}
}
</script>
