<template>
  <v-dialog v-model="show" max-width="850px" :fullscreen="isMobile">
    <booking-dialog-content
      :booking="booking"
      :charges="charges"
      :invoices="invoices"
      :receipts="receipts"
      :editing-records="editingRecords"
      :emails="emails"
      :accommodation-charges-calendar-items="accommodationChargesCalendarItems"
      :is-loading="isLoading"
      :is-open="show"
      @close="close"
    ></booking-dialog-content>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import BookingDialogContent from './DialogContent.vue'

export default {
  name: 'BookingDialog',
  components: {
    BookingDialogContent
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        booking: state => state.booking.dialog.booking,
        charges: state => state.booking.dialog.charges,
        invoices: state => state.booking.dialog.invoices,
        receipts: state => state.booking.dialog.receipts,
        emails: state => state.booking.dialog.emails,
        editingRecords: state => state.booking.dialog.editingRecords,
        accommodationChargesCalendarItems: state => state.booking.dialog.accommodationChargesCalendarItems,
        isLoading: state => state.booking.dialog.isLoading,
        showDialog: state => state.booking.dialog.show
      }
    ),
    show: {
      get () {
        return this.showDialog
      },
      set (value) {
        this.$store.commit('booking/SET_SHOW_DIALOG', value)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    close: function () {
      this.show = false
    }
  }
}
</script>
