export const namespaced = true

export const state = {
  menu: {
    mode: 'availability', // availability eller checkedin
    checkIn: null,
    checkOut: null
  },
  sidebar: {
    activeTab: 'position-info'
  }
}

export const mutations = {
  SET_MAP_MENU_CHECK_IN (state, val) {
    state.menu.checkIn = val
  },
  SET_MAP_MENU_CHECK_OUT (state, val) {
    state.menu.checkOut = val
  },
  SET_MAP_MENU_MODE (state, val) {
    state.menu.mode = val
  },
  SET_MAP_SIDEBAR_ACTIVE_TAB (state, val) {
    state.sidebar.activeTab = val
  }
}
