var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"barcode-input-dialog"},[_c('v-card-title',{staticClass:"pb-4",staticStyle:{"word-break":"keep-all"}},[_vm._v(" Skanna streckkoden ")]),_c('v-card-subtitle',[_vm._v(" eller skriv in manuellt, 8-13 siffror ")]),_c('v-card-text',[_c('v-otp-input',{class:{
          'barcode-length-8': _vm.value.length <= 8,
          'barcode-length-9': _vm.value.length === 9,
          'barcode-length-10': _vm.value.length === 10,
          'barcode-length-11': _vm.value.length === 11,
          'barcode-length-12': _vm.value.length === 12,
          'barcode-length-13': _vm.value.length >= 13
        },attrs:{"length":_vm.length},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }