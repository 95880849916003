<template>
  <div class="sales-items-table">
    <v-simple-table
      fixed-header
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>Vara</th>
            <th>Antal</th>
            <th>Pris</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="salesItem in salesItems"
            :key="salesItem.id"
          >
            <td>
              <div :class="{ 'pt-2': !!salesItem.caption }">
                <v-chip
                  :color="salesItem.product.gui_color"
                  dark
                >
                  {{ salesItem.product.name }}
                </v-chip>
              </div>
              <div v-show="!!salesItem.caption" class="pb-2 pt-1 pl-3 text-caption"><span>{{ salesItem.caption }}</span></div>
            </td>

            <td>
              {{ salesItem.quantity / 1000 }}
            </td>

            <td>
              <template v-if="salesItem.discount_type === null || salesItem.original_price === 0">
                {{ salesItem.price / 100 }}
              </template>
              <template v-else-if="salesItem.discount_type === 'fixed'">
                <div>
                  <span class="text-decoration-line-through">
                    {{ salesItem.original_price / 100 }}
                  </span>
                  <span class="red--text ml-2">
                    {{ salesItem.price / 100 }}
                  </span>
                </div>
                <div>
                  <i>Rabatt: {{ salesItem.discount_value / 100 }}kr</i>
                </div>
              </template>
              <template v-else-if="salesItem.discount_type === 'flat'">
                <div>
                  <span class="text-decoration-line-through">
                    {{ salesItem.original_price / 100 }}
                  </span>
                  <span class="red--text ml-2">
                    {{ salesItem.price / 100 }}
                  </span>
                </div>
                <div>
                  <i>Rabatt: {{ salesItem.discount_value / 100 }}kr</i>
                </div>
              </template>
              <template v-else-if="salesItem.discount_type === 'percent'">
                <div>
                  <span class="text-decoration-line-through">
                    {{ salesItem.original_price / 100 }}
                  </span>
                  <span class="red--text ml-2">
                    {{ salesItem.price / 100 }}
                  </span>
                </div>
                <div>
                  <i>Rabatt: {{ salesItem.discount_value / 100 }}%</i>
                </div>
              </template>
            </td>

            <td>
              {{ salesItem.sale / 100 }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'SalesItemsTable',
  props: {
    salesItems: Array
  },
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {}
}
</script>
