<template>
  <v-dialog
    v-model="showDialog"
    max-width="700"
  >
    <v-card class="barcode-input-dialog">
      <v-card-title class="pb-4" style="word-break: keep-all;">
        Skanna streckkoden
      </v-card-title>
      <v-card-subtitle>
        eller skriv in manuellt, 8-13 siffror
      </v-card-subtitle>
      <v-card-text>
        <v-otp-input
          v-model="input"
          :length="length"
          :class="{
            'barcode-length-8': value.length <= 8,
            'barcode-length-9': value.length === 9,
            'barcode-length-10': value.length === 10,
            'barcode-length-11': value.length === 11,
            'barcode-length-12': value.length === 12,
            'barcode-length-13': value.length >= 13
          }"
        ></v-otp-input>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<style lang="scss">
  .barcode-input-dialog .v-otp-input.barcode-length-8 .v-text-field:nth-child(n+9) {
    opacity: 0.2;
  }
  .barcode-input-dialog .v-otp-input.barcode-length-9 .v-text-field:nth-child(n+10) {
    opacity: 0.2;
  }
  .barcode-input-dialog .v-otp-input.barcode-length-10 .v-text-field:nth-child(n+11) {
    opacity: 0.2;
  }
  .barcode-input-dialog .v-otp-input.barcode-length-11 .v-text-field:nth-child(n+12) {
    opacity: 0.2;
  }
  .barcode-input-dialog .v-otp-input.barcode-length-12 .v-text-field:nth-child(n+13) {
    opacity: 0.2;
  }
  .barcode-input-dialog .v-otp-input.barcode-length-13 .v-text-field:nth-child(n+14) {
    opacity: 0.2;
  }
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BarcodeInput',
  props: {
    value: String,
    show: Boolean
  },
  data: () => ({
    length: 13
  }),
  computed: {
    ...mapState(
      {
        scannerInput: state => state.product.scannerInput
      }
    ),
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    input: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    requestInput: function () {
      console.log('requestInput')
      window.scannerVM.requestInput('product/barcodeInputRequest')
    },
    haltRequestInput: function () {
      window.scannerVM.abortInputRequest('product/barcodeInputRequest')
    }
  },
  watch: {
    showDialog: function () {
      if (this.showDialog) {
        this.requestInput()
      } else {
        this.haltRequestInput()
      }
    },
    scannerInput: function () {
      if (!this.showDialog) {
        return false // För att man ska kunna ha flera av denna komponenten på samma sida
      }
      console.log('scannerInput changed', this.scannerInput)
      const vm = this
      const scannerInput = vm.scannerInput.slice() // För att kopiera strängen ist för referens
      if (scannerInput) {
        vm.input = ''
        let input = ''
        for (let i = 0; i < scannerInput.length; i++) {
          const timeoutMs = i * 40
          setTimeout(() => {
            input = input + scannerInput.charAt(i)
            vm.input = input
          }, timeoutMs)
        }

        setTimeout(() => {
          vm.showDialog = false
        }, (scannerInput.length * 40) + 200)

        vm.$store.commit('product/SET_SCANNER_INPUT', '')
      }
    }
  },
  mounted () {

  }
}
</script>
