function getTotal () {
  return this.charges.reduce((acc, c) => { return (acc + (c.quantity / 1000 * c.price)) }, 0)
}

function getTotalFormatted () {
  const total = this.getTotal() / 100
  return window.conversion.toHumanReadableAmount(total)
}

export default {
  getTotal,
  getTotalFormatted
}
