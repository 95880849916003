<template>
  <div class="tally-order">
    <v-card class="d-flex flex-column">
      <v-card-title class="py-2">
        <div class="d-flex justify-start align-center full-width">
          <access-card-icon
            :size="denseHeader ? '36' : '60'"
            class="pl-0 pr-4"
            :active="isReadyForScanning"
          ></access-card-icon>
          <div class="pr-2 d-none d-xl-block">Accesskort by</div>
          <img
            :src="require('@/assets/images/tallykey_logo.png')"
            height="22"
          />
          <v-menu v-if="!bookingCancelledOrCheckedOut" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-auto my-auto"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item v-if="hasTallyResource">
                  <v-btn
                    v-if="isElectron"
                    @click="openTallyWeb"
                    block
                    text
                    color="primary"
                  >
                    Öppna order i TallyWeb
                  </v-btn>
                  <v-btn
                    v-else
                    :href="tallyWebUrl"
                    target="_blank"
                    block
                    text
                    color="primary"
                  >
                    Öppna order i TallyWeb
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="!hasTallyResource">
                  <v-btn
                    block
                    text
                    :loading="isCreatingProvision"
                    :disabled="isLoading"
                    @click="createProvision"
                    color="primary"
                  >
                    Skapa tallyorder utan att checka in
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="bookingCancelledOrCheckedOut" class="">
        <v-alert
          type="info"
          text
          class="ma-4 alert-center-icon"
        >
          {{ checkedOutBookingAlertMessage }}
        </v-alert>
      </div>
      <div v-else-if="!hasTallyResource && !isLoading">
        <div
          v-if="isCreatingProvision"
          class="ma-auto mt-4 d-flex flex-column align-self-stretch align-center"
        >
          <h3>Skapar tallyorder</h3>
          <v-progress-circular
            indeterminate
            size="48"
            class="mt-4 mb-3"
            color="secondary"
          ></v-progress-circular>
        </div>
        <v-alert
          v-else
          type="info"
          text
          class="mx-4 mt-4 alert-center-icon"
        >
          Det finns ingen aktiv tallyorder på den här bokningen
        </v-alert>
      </div>
      <div v-else class="tabs-container flex-grow-1 d-flex flex-column">
        <div v-if="hasPincode" class="d-block py-1 px-4 text-subtitle-2 grey lighten-2">
          Pinkod: {{ booking.tally_resource.pincode }}
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="regeneratePincode"
                :loading="isRegeneratingPincode"
              >
                <v-icon>mdi-restore</v-icon>
              </v-btn>
            </template>
            <div>
              Generera ny pinkod
            </div>
          </v-tooltip>
        </div>
        <v-tabs
          v-model="tabIndex"
          color="secondary"
          show-arrows
          grow
          equal-width
          class="flex-grow-0"
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab href="#card-list">
            <v-badge
              :value="cardsCount !== ''"
              color="secondary"
              :content="cardsCount"
              offset-x="-2"
              offset-y="8"
            >
              Kort
            </v-badge>
          </v-tab>
          <v-tab href="#consumption">
            Konsumption
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabIndex" class="flex-grow-1">
          <v-tab-item value="card-list">
            <tally-card-list
              :booking="booking"
              :is-open="isOpen"
              :is-loading="isLoading"
              :is-ready-for-scanning="isReadyForScanning"
              @start-add-new-card="startAddNewCard"
              @abort-add-new-card="abort"
            ></tally-card-list>
          </v-tab-item>
          <v-tab-item value="consumption">
            <tally-consumption-table
              :booking-id="booking?.id"
              :is-displayed="isOpen && tabIndex === 'consumption'"
              :is-loading="isLoading"
            ></tally-consumption-table>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AccessCardIcon from './AccessCardIcon.vue'
import TallyConsumptionTable from './ConsumptionTable.vue'
import TallyCardList from './CardList.vue'

export default {
  name: 'TallyOrder',
  components: {
    AccessCardIcon,
    TallyCardList,
    TallyConsumptionTable
  },
  props: {
    booking: Object,
    isOpen: Boolean,
    isLoading: Boolean,
    denseHeader: Boolean,
    defaultTab: {
      type: String,
      default: 'card-list'
    }
  },
  data: () => ({
    clickedTab: '',
    isReadyForScanning: false,
    isRegeneratingPincode: false
  }),
  computed: {
    ...mapState(
      {
        isCreatingProvisionBookingIds: state => state.tally.isCreatingProvisionBookingIds,
        isElectron: state => state.isElectron
      }
    ),
    tabIndex: {
      get: function () {
        if (this.clickedTab !== '') {
          return this.clickedTab
        }
        return this.defaultTab
      },
      set: function (val) {
        this.clickedTab = val
      }
    },
    cardsCount: function () {
      if (!this.hasTallyResource) {
        return ''
      }
      return '' + this.booking.tally_resource.cards.length
    },
    hasTallyResource: function () {
      return this.booking && this.booking.tally_resource
    },
    hasPincode: function () {
      return this.hasTallyResource && this.booking.tally_resource.pincode
    },
    tallyWebUrl: function () {
      if (!this.hasTallyResource) {
        return ''
      }
      return this.booking.tally_resource.order_url
    },
    isCreatingProvision: function () {
      return !!this.booking && this.isCreatingProvisionBookingIds.includes(this.booking.id)
    },
    bookingCancelledOrCheckedOut: function () {
      return !!this.booking && (!!this.booking.deleted_at || !!this.booking.checked_out_at)
    },
    checkedOutBookingAlertMessage: function () {
      // Meddelande som visas när bokning är utcheckad eller avbokad
      if (!!this.booking && !!this.booking.deleted_at) {
        if (this.hasTallyResource) {
          return 'Bokningen är avbokad. Tally ordern kunde inte avslutas, den kommer avslutas automatiskt vid ett senare tillfälle.'
        }
        return 'Bokningen är avbokad och har ingen aktiv tally order.'
      }
      if (!!this.booking && !!this.booking.checked_out_at) {
        if (this.hasTallyResource) {
          return 'Bokningen är utcheckad. Tally ordern kunde inte avslutas, den kommer avslutas automatiskt vid ett senare tillfälle.'
        }
        return 'Bokningen är utcheckad och har ingen aktiv tally order.'
      }
      return ''
    }
  },
  methods: {
    createProvision: function () {
      this.$store.dispatch('tally/createProvision', this.booking)
    },
    setIsReadyForScanning: function (val) {
      this.isReadyForScanning = val
    },
    startAddNewCard: function () {
      this.isReadyForScanning = true
      window.scannerVM.requestInput('tally/addCard', this.booking, true) // OBS: tredje param efterfrågar flera inputs, requestInput återställs då inte efter första input och är viktigt att abort körs när det är klart
    },
    regeneratePincode: function () {
      this.isRegeneratingPincode = true
      this.$store.dispatch('tally/regeneratePincode', this.booking).then(() => {
        this.isRegeneratingPincode = false
      })
    },
    abort: function () {
      this.isReadyForScanning = false
      window.scannerVM.abortInputRequest('tally/addCard')
    },
    openTallyWeb: function () {
      if (!this.hasTallyResource) {
        return ''
      }
      window.shell.openExternalLink(this.tallyWebUrl)
    }
  },
  watch: {
    isCreatingProvision: function () {
      if (!this.isCreatingProvision && this.isOpen) {
        this.startAddNewCard()
      }
    },
    isOpen: function () {
      console.log('watch isOpen', this.isOpen)
      if (!this.isOpen) {
        this.clickedTab = ''
      }
    },
    tabIndex: function () {
      console.log('watch tabIndex', this.tabIndex)
    }
  }
}
</script>
