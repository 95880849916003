<template>
<div class="cancellation-policy-picker">
  <div class="d-flex">
    <div class="subheading ml-3 my-auto" style="padding-top: 3px; padding-bottom: 3px;" :class="{ 'disabled--text': isLocked }">Avbokningsskydd</div>
    <template v-if="isLocked">
      <v-btn icon small class="secondary-on-hover my-auto ml-2" @click="openUnlockDialog">
        <v-icon small>mdi-lock</v-icon>
      </v-btn>
    </template>
  </div>
  <v-chip-group
    v-model="activePolicy"
    mandatory
    color="secondary"
    center-active
    show-arrows
    :column="isMobile"
  >
    <v-chip
      v-for="policy in policies"
      :key="policy.id"
      :value="policy.id"
      :disabled="isLocked"
      outlined
    >
      <div class="d-flex">
        <div class="d-flex justify-center align-center flew-grow-1 overflow-hidden">
          {{ policy.name }}
        </div>
        <div class="justify-center pa-0">
          <v-btn
            @click="openMenu(policy)"
            fab
            color="transparent"
            elevation="0"
          >
            <v-icon color="secondary">mdi-information</v-icon>
          </v-btn>
        </div>
      </div>
    </v-chip>
  </v-chip-group>
  <div class="cancellation-policy-picker-menu-activator"></div>

  <v-menu
    v-model="menuOpen"
    activator=".cancellation-policy-picker-menu-activator"
    bottom
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="isMobile"
  >
    <cancellation-policy-card
      :is-open="menuOpen"
      :policy="openedMenuPolicy"
      :check-in-date="checkInDate"
      :booking-id="bookingId"
    ></cancellation-policy-card>
  </v-menu>

  <v-dialog v-model="showUnlockDialog" max-width="450">
    <v-sheet color="white" class="">
      <v-toolbar elevation="0" class="">
        <v-toolbar-title>Valet av avbokningsskydd är låst</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeUnlockDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="pa-4 text-body-2">
        Enligt bokningsvillkoren kan inte gästen ändra sitt val av avbokningsskydd i efterhand.
      </div>
      <div class="pa-2 d-flex">
        <v-btn text color="primary" class="ml-auto" @click="unlock">Lås upp valet av avbokningsskydd</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</div>
</template>

<script>
import CancellationPolicyCard from '@/components/cancellation-policy/CancellationPolicyCard.vue'

export default {
  name: 'CancellationPolicyPicker',
  components: {
    CancellationPolicyCard
  },
  props: {
    policies: Array,
    selectedPolicy: Object,
    checkInDate: Object,
    bookingId: Number,
    disabled: Boolean,
    isOpen: Boolean
  },
  data: () => ({
    menuOpen: false,
    openedMenuPolicy: null,
    showUnlockDialog: false,
    isManuallyUnlocked: false
  }),
  computed: {
    activePolicy: {
      get: function () {
        return this.selectedPolicy ? this.selectedPolicy.id : 0
      },
      set: function (id) {
        if (id !== undefined) {
          const policy = this.policies.find(p => p.id === id)
          if (policy) {
            this.$emit('set-policy', policy)
          }
        }
      }
    },
    isLocked: function () {
      return this.disabled && !this.isManuallyUnlocked
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    openMenu: function (policy) {
      this.openedMenuPolicy = policy
      this.menuOpen = true
    },
    openUnlockDialog: function () {
      this.showUnlockDialog = true
    },
    closeUnlockDialog: function () {
      this.showUnlockDialog = false
    },
    unlock: function () {
      this.isManuallyUnlocked = true
      this.closeUnlockDialog()
    }
  },
  watch: {
    isOpen: function () {
      this.isManuallyUnlocked = false
    }
  }
}
</script>
