<template>
<div class="guest-picker">
  <v-text-field
    v-model="guestsFormatted"
    class="sub-errors-for-warning"
    append-icon="mdi-menu-down"
    height="50"
    label="Antal gäster"
    readonly
    tabindex="-1"
    outlined
    :error-messages="errorMessages"
  ></v-text-field>
  <v-menu
    v-model="showMenu"
    activator=".guest-picker"
    :close-on-content-click="false"
    :offset-y="!$vuetify.breakpoint.xsOnly"
    nudge-bottom="8"
    :min-width="$vuetify.breakpoint.smAndDown ? 'auto' : '260'"
  >
    <v-card flat class="guest-picker-menu py-4 px-6" style="width: auto;">
      <v-card-text class="pa-0">
        <div>Antal gäster</div>
      </v-card-text>

      <!-- Vuxna -->
      <div class="d-block d-md-flex flex-row ma-0 py-2">
        <div class="d-flex mr-0 mr-md-auto pr-0 pr-md-8">
          <p class="text-center text-md-left mt-3 mb-2 my-md-auto" style="width: 100%;">Vuxna (18+)</p>
        </div>
        <div class="d-block d-flex-md align-center ml-auto">
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canDecrementAdults"
            @click="incrementAdults(-1)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <div style="display: inline-flex; justify-content: center; min-width: 60px; height: 40px; line-height: 40px;">
            <h2>{{ adults }}</h2>
          </div>
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canIncrementAdults"
            @click="incrementAdults(1)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Barn -->
      <div class="d-block d-md-flex flex-row ma-0 py-2">
        <div class="d-flex mr-0 mr-md-auto pr-0 pr-md-8">
          <p class="text-center text-md-left mt-3 mb-2 my-md-auto" style="width: 100%;">Barn (0-17)</p>
        </div>
        <div class="d-block d-flex-md align-center ml-auto">
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canDecrementChildren"
            @click="incrementChildren(-1)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <div style="display: inline-flex; justify-content: center; min-width: 60px; height: 40px; line-height: 40px;">
            <h2>{{ children }}</h2>
          </div>
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canIncrementChildren"
            @click="incrementChildren(1)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Hundar -->
      <div class="d-block d-md-flex flex-row ma-0 py-2">
        <div class="d-flex mr-0 mr-md-auto pr-0 pr-md-8">
          <p class="text-center text-md-left mt-3 mb-2 my-md-auto" style="width: 100%;">Hundar</p>
        </div>
        <div class="d-block d-flex-md align-center ml-auto">
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canDecrementDogs"
            @click="incrementDogs(-1)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <div style="display: inline-flex; justify-content: center; min-width: 60px; height: 40px; line-height: 40px;">
            <h2>{{ dogs }}</h2>
          </div>
          <v-btn
            fab
            small
            color="primary"
            elevation="0"
            :disabled="!canIncrementDogs"
            @click="incrementDogs(1)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</div>
</template>

<script>

export default {
  name: 'GuestPicker',
  props: {
    maxGuests: Number,
    maxAdults: Number,
    maxChildren: Number,
    adults: Number,
    children: Number,
    dogs: Number
  },
  data: () => ({
    showMenu: false
  }),
  computed: {
    guestsFormatted () {
      let str = ''
      if (this.adults === 1) {
        str += '1 vuxen'
      } else {
        str += this.adults + ' vuxna'
      }
      if (this.children > 0) {
        str += `, ${this.children} barn`
      }
      if (this.dogs === 1) {
        str += ', 1 hund'
      } else if (this.dogs > 1) {
        str += `, ${this.dogs} hundar`
      }
      return str
    },
    canDecrementChildren: function () {
      return this.adults + this.children > 1 && this.children > 0
    },
    canIncrementChildren: function () {
      return this.children < 99
    },
    canDecrementAdults: function () {
      return this.adults + this.children > 1 && this.adults > 0
    },
    canIncrementAdults: function () {
      return this.adults < 99
    },
    canDecrementDogs: function () {
      return this.dogs > 0
    },
    canIncrementDogs: function () {
      return this.dogs < 9
    },
    errorMessages: function () {
      // behåller client-validering för maxGuests, maxAdults och maxChildren som varningsmeddelande i guest-picker
      // riktig validering sker i availability
      if (this.maxGuests && this.maxGuests < (this.adults + this.children)) {
        return [`Max ${this.maxGuests} gäster tillåtna`]
      } else if (this.maxAdults && this.maxAdults < this.adults) {
        return [`Max ${this.maxAdults} vuxna tillåtna`]
      } else if (this.maxChildren && this.maxChildren < this.children) {
        return [`Max ${this.maxChildren} barn tillåtna`]
      }
      return []
    }
  },
  methods: {
    incrementChildren: function (val) {
      const newVal = this.children + val
      if (newVal >= 0 && newVal <= 99 && (this.adults + newVal >= 1)) {
        this.$emit('set-children', newVal)
      }
    },
    incrementAdults: function (val) {
      const newVal = this.adults + val
      if (newVal >= 0 && newVal <= 99 && (this.children + newVal >= 1)) {
        this.$emit('set-adults', newVal)
      }
    },
    incrementDogs: function (val) {
      const newVal = this.dogs + val
      if (newVal >= 0 && newVal <= 9) {
        this.$emit('set-dogs', newVal)
      }
    }
  },
  watch: {},
  mounted () {}
}
</script>
