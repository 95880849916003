import OnboardingService from '@/services/OnboardingService.js'

export const namespaced = true

export const state = {
  isSavingPositions: false
}

export const mutations = {
  SET_IS_SAVING_POSITIONS (state, val) {
    state.isSavingPositions = val
  }
}

export const actions = {
  savePositions ({ commit }, { positions }) {
    commit('SET_IS_SAVING_POSITIONS', true)
    return OnboardingService.savePositions(positions)
      .then(({ data }) => {
        window.enrich.enrichPosition(data.data.positions)
        commit('position/SET_POSITIONS', data.data.positions, { root: true })
      })
      .finally(() => {
        commit('SET_IS_SAVING_POSITIONS', false)
      })
  }
}

export const getters = {}
