<template>
<div class="speed-dial-wrapper" :class="{ 'nudge-left': shouldNudgeLeft }">
  <v-row>
    <v-col v-if="showCreateBooking" class="pa-0 my-auto">
      <v-speed-dial id="createDial">
        <template v-slot:activator>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click="openBookingForm"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Ny Bokning</span>
          </v-tooltip>
        </template>
      </v-speed-dial>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SpeedDial',
  data: () => ({
    fabCreate: false,
    nudgeLeftOnPages: ['Receipts'], // sidor där speed dial behöver flyttas lite till vänster, t.ex. på kvittosidan där knappen döljer en bit av scrollbaren
    hideCreateBookingOnPages: ['PosRegister', 'Calendar'] // sidor där "pluset" som öppnar booking form inte ska visas
  }),
  computed: {
    ...mapState(
      {
        currentPageName: state => state.currentPageName,
        modules: state => state.user.user.modules
      }
    ),
    showCreateBooking: function () {
      return !this.hideCreateBookingOnPages.includes(this.currentPageName) && this.modules && this.modules.includes('PMS')
    },
    shouldNudgeLeft: function () {
      return this.nudgeLeftOnPages.includes(this.currentPageName)
    }
  },
  methods: {
    openBookingForm: function () {
      this.$store.dispatch('booking/startNewBooking')
    }
  }
}
</script>
