<template>
  <div>
    <v-navigation-drawer
      v-model="showMenu"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :mini-variant="menuFolded"
    >
      <main-menu :menu-folded="menuFolded"></main-menu>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary darken-2"
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="toggleMenu"
        class="mr-2 mr-md-0"
      ></v-app-bar-nav-icon>
      <v-toolbar-title style="width: auto" class="ml-0 px-12 hidden-sm-and-down">
        <span>
          <router-link to="/">
            <v-img
              :src="require('@/assets/logos/logo-white.svg')"
              contain
              :height="42"
              :width="160"
              alt="CampingOnline"
            ></v-img>
          </router-link>
        </span>
      </v-toolbar-title>

      <v-spacer class="d-block d-md-none"></v-spacer>

      <div class="mx-0 d-flex flex-md-grow-1">
        <app-search></app-search>
      </div>

      <v-spacer class="d-none d-md-block"></v-spacer>

      <v-menu
        v-model="showDraftMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-badge
                    color="secondary"
                    :content="bookingDrafts.length === 0 ? '0' : bookingDrafts.length"
                  >
                    <v-icon>mdi-book-edit-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Utkast bokningar</span>
            </v-tooltip>
          </div>
        </template>
        <drafts-list v-if="showDraftMenu"></drafts-list>
      </v-menu>

      <v-btn icon @click.stop="userMenuIsOpen = !userMenuIsOpen" class="mx-2 mx-md-1">
        <v-avatar item :color="user.color">
          <span class="white--text" v-text="user.initials"></span>
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <user-navigation-drawer :is-open="userMenuIsOpen" @set-is-open="setUserMenuIsOpen"></user-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppSearch from './AppSearch'
import UserNavigationDrawer from './UserNavigationDrawer'
import MainMenu from './MainMenu.vue'
import DraftsList from '@/components/booking/lists/DraftsList.vue'

export default {
  name: 'AppNavigation',
  components: {
    AppSearch,
    UserNavigationDrawer,
    MainMenu,
    DraftsList
  },
  data: () => ({
    showMenu: false,
    menuHistory: false,
    userMenuIsOpen: false,
    menuFolded: false,
    showDraftMenu: false,
    userFoldChoice: false // om användaren har togglat menyn till ikonläge, förutom på kartsidan
  }),
  computed: {
    ...mapGetters(
      {
        bookingDrafts: 'booking/bookingDrafts'
      }
    ),
    user () {
      return this.$store.state.user.user
    },
    currentRoute: function () {
      return this.$route.name
    },
    isMapPage: function () {
      return this.currentRoute === 'Map'
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    setUserMenuIsOpen: function (val) {
      this.userMenuIsOpen = val
    },
    toggleMenu: function () {
      if (this.isMobile) {
        this.showMenu = !this.showMenu
      } else {
        this.menuFolded = !this.menuFolded
      }
      if (!this.isMapPage) {
        this.userFoldChoice = this.menuFolded // om användaren togglar menyn utanför kartsidan, spara det valet
      }
    }
  },
  watch: {
    isMapPage: function () {
      if (this.isMobile) {
        return false // aldrig ikonläge på mobil
      }
      if (this.isMapPage) {
        this.menuFolded = true
      } else {
        // När lämnar kartsida, använder användarens senaste toggle val från utanför kartsidan
        this.menuFolded = this.userFoldChoice
      }
    },
    isMobile: function () {
      if (this.isMobile) {
        this.menuFolded = false
        this.showMenu = false
      } else {
        this.showMenu = true
        this.menuFolded = this.isMapPage
      }
    }
  },
  created () {
    if (this.isMobile) {
      this.menuFolded = false
      this.showMenu = false
    } else {
      this.showMenu = true
      this.menuFolded = this.isMapPage
    }
  }
}
</script>
