<template>
  <v-form ref="formPhoneNumber">
    <v-row>
      <v-col cols="12" sm="4" md="5">
        <v-autocomplete
          v-model="inputDiallingCodeCountry"
          :filter="searchFunctionDiallingCode"
          :rules="[ diallingCodeRule ]"
          :items="countries"
          item-text="name"
          return-object
          height="32"
          label="Landskod"
          @input="isCountryCodeSelectedByUser = true"
        ><!-- kan inte använda dialling_code som item-text här, för det är inte unikt, bara första landet med en specifik dialling_code kommer med då, t.ex. kommer inte USA (+1) med utan Antigua och Barbuda -->
          <template v-slot:selection="data">
            <v-avatar left>
              <span :class="'fi fi-' + data.item.iso_code.toLowerCase()"></span>
            </v-avatar>
            {{ data.item.dialling_code }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar primary>
                <span :class="'fi fi-' + data.item.iso_code.toLowerCase()"></span>
              </v-list-item-avatar>
              <v-list-item-content primary>
                <v-list-item-title v-text="data.item.dialling_code"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="8" md="7">
        <v-text-field
          label="Mobilnummer"
          v-model.trim="inputPhoneNumber"
          :rules="[ phoneNumberRule ]"
          :loading="phoneNumberValidation.isLoading ? 'secondary' : false"
          :messages="phoneNumberValidation.isLoading ? 'Kontrollerar mobilnummer' : ''"
          :class="{
            'sub-errors-for-warning warning--text': phoneNumberValidation.hasWarning,
            'input-success success--text': hasSuccess
          }"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ValidationService from '@/services/ValidationService.js'
import { mapState } from 'vuex'

export default {
  name: 'CustomerInputPhoneNumber',
  props: {
    country: Object,
    phoneNumber: String,
    diallingCodeCountry: Object,
    customerId: Number,
    forceValidation: Number,
    forceResetValidation: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    diallingCodeValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    phoneNumberValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    isCountryCodeSelectedByUser: false,
    phoneNumberValidated: '',
    diallingCodeValidated: '',
    phoneNumberBeingValidated: '',
    diallingCodeBeingValidated: '',
    validationResponse: null,
    originalDiallingCodeCountry: null,
    originalPhoneNumber: ''
  }),
  computed: {
    ...mapState(
      {
        countries: state => state.country.countries
      }
    ),
    inputDiallingCodeCountry: {
      get: function () {
        return this.diallingCodeCountry
      },
      set: function (val) {
        this.$emit('set-dialling-code-country', val)
      }
    },
    inputPhoneNumber: {
      get: function () {
        return this.phoneNumber
      },
      set: function (val) {
        if (val) {
          val = val.replace(/\D/g, '')
        }
        while (val != null && val.length > 0 && val[0] === '0') {
          val = val.substring(1)
        }
        this.$emit('set-phone-number', val)
      }
    },
    hasSuccess: function () {
      return this.inputPhoneNumber !== '' && this.phoneNumberValidation.isValidated && !this.phoneNumberValidation.hasWarning && !this.phoneNumberValidation.hasError && !this.phoneNumberValidation.isLoading
    }
  },
  methods: {
    searchFunctionDiallingCode: function (item, queryText, itemText) {
      return item.searchableString.includes(queryText.toLowerCase())
    },
    diallingCodeRule: function (v) {
      let verdict = ''
      if (this.inputPhoneNumber && (v === undefined || v === null || v === '')) {
        verdict = 'Landskod behövs'
      }
      this.diallingCodeValidation.hasError = (verdict !== '')
      this.diallingCodeValidation.errorMessage = verdict
      this.diallingCodeValidation.isValidated = true
      this.diallingCodeValidation.hasChanges = (this.originalDiallingCodeCountry?.id !== this.diallingCodeCountry?.id)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    phoneNumberRule: function (v) {
      let verdict = ''
      let diallingCode = ''
      this.validation.suggestions = []
      if (this.inputDiallingCodeCountry) {
        diallingCode = this.inputDiallingCodeCountry.dialling_code
      }
      this.phoneNumberValidation.hasError = false
      this.phoneNumberValidation.hasWarning = false

      if (v != null && v !== '') {
        if (!/^\d{4,14}$/.test(v)) {
          verdict = 'Inkorrekt formaterat mobilnummer'
          this.phoneNumberValidation.hasError = true
        } else if (diallingCode === '') {
          verdict = '' // Felmeddelande i landskod fält räcker
        } else if (this.phoneNumberValidated === v && this.diallingCodeValidated === diallingCode) {
          this.phoneNumberValidation.isLoading = false
          if (this.validationResponse && this.validationResponse.status === '200') {
            if (this.validationResponse.result === 'deliverable') {
              verdict = ''
            } else if (this.validationResponse.result === 'undeliverable') {
              this.phoneNumberValidation.hasWarning = true
              verdict = 'Mobilnumret existerar ej'
            } else if (this.validationResponse.result === 'duplicate') {
              if (this.validationResponse.customer.id === this.customerId) {
                verdict = '' // Mobilnumret upptaget av kunden som redigeras
              } else {
                this.validation.suggestions.push({
                  customer: this.validationResponse.customer,
                  reason: 'Samma mobilnummer'
                })
                this.phoneNumberValidation.hasError = true
                verdict = 'Mobilnumret används av: ' + this.validationResponse.customer.full_name
              }
            } else {
              this.phoneNumberValidation.hasWarning = true
              verdict = this.validationResponse.result
            }
          } else {
            this.phoneNumberValidation.hasWarning = true
            if (this.validationResponse && this.validationResponse.status) {
              verdict = 'Extern valideringsserver svarade med felkod ' + this.validationResponse.status
            } else {
              verdict = 'Extern valideringsserver svarade med okänd felkod'
            }
          }
        } else {
          setTimeout(() => {
            if (this.inputPhoneNumber === v && this.inputDiallingCodeCountry.dialling_code === diallingCode && (this.diallingCodeBeingValidated !== diallingCode || this.phoneNumberBeingValidated !== v)) {
              this.validatePhone(diallingCode, v)
            }
          }, 400)
          verdict = ''
        }
      } else {
        verdict = ''
      }

      this.phoneNumberValidation.isValidated = true
      this.phoneNumberValidation.hasChanges = (this.originalPhoneNumber !== this.inputPhoneNumber)
      this.phoneNumberValidation.errorMessage = verdict
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    updateCommonValidation: function () {
      this.validation.hasChanges = this.diallingCodeValidation.hasChanges || this.phoneNumberValidation.hasChanges
      this.validation.isLoading = this.phoneNumberValidation.isLoading
      this.validation.isValidated = this.diallingCodeValidation.isValidated && this.phoneNumberValidation.isValidated
      this.validation.hasError = this.diallingCodeValidation.hasError || this.phoneNumberValidation.hasError
      this.validation.hasWarning = this.diallingCodeValidation.hasWarning || this.phoneNumberValidation.hasWarning

      if (this.diallingCodeValidation.hasError) {
        this.validation.errorMessage = this.diallingCodeValidation.errorMessage
      } else if (this.phoneNumberValidation.hasError) {
        this.validation.errorMessage = this.phoneNumberValidation.errorMessage
      } else if (this.diallingCodeValidation.hasWarning) {
        this.validation.errorMessage = this.diallingCodeValidation.errorMessage
      } else if (this.phoneNumberValidation.hasWarning) {
        this.validation.errorMessage = this.phoneNumberValidation.errorMessage
      } else {
        this.validation.errorMessage = ''
      }
      this.$emit('set-validation', this.validation)
    },
    validate () {
      return this.$refs.formPhoneNumber?.validate() // frågetecken för formuläret kan ha stängts innan denna metod körs
    },
    resetValidation: function () {
      this.$refs.formPhoneNumber.resetValidation()
    },
    validatePhone (diallingCode, phone) {
      this.phoneNumberValidation.isLoading = true
      this.diallingCodeBeingValidated = diallingCode
      this.phoneNumberBeingValidated = phone
      ValidationService.validatePhoneNumber(diallingCode, phone)
        .then(({ data }) => {
          if (this.phoneNumber === phone && this.inputDiallingCodeCountry.dialling_code === diallingCode) {
            this.validationResponse = data.data.validation_result
          }
        })
        .catch(error => {
          if (this.inputPhoneNumber === phone && this.inputDiallingCodeCountry.dialling_code === diallingCode) {
            this.validationResponse = error.response
          }
        })
        .finally(() => {
          if (this.inputPhoneNumber === phone && this.inputDiallingCodeCountry.dialling_code === diallingCode) {
            this.phoneNumberValidated = phone
            this.diallingCodeValidated = diallingCode
            this.phoneNumberValidation.isLoading = false
            this.validate()
          }
        })
    }
  },
  watch: {
    country: function () {
      /* Country har uppdaterats, uppdaterar dialling code country också om det inte redan har ändrats av användaren, uppdaterar inte när man redigerar kund som redan har telefonnr */
      if (this.country && !this.isCountryCodeSelectedByUser && (this.customerId === 0 || this.phoneNumber === '' || this.phoneNumber === null)) {
        this.inputDiallingCodeCountry = this.country
        if (this.inputPhoneNumber != null && this.inputPhoneNumber !== '') {
          this.validate()
        }
      }
    },
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  mounted () {
    this.originalPhoneNumber = this.phoneNumber
    this.originalDiallingCodeCountry = this.diallingCodeCountry
  }
}
</script>
