<template>
  <div class="customer-avatar" style="text-align: center;">
    <v-tooltip bottom :disabled="!clickable">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar
          @click="onClick"
          v-bind="attrs"
          v-on="on"
          :color="customerColor"
          :class="{
            'justify-center ml-0': true,
            'cursor-pointer': clickable,
            'mx-0 my-0': !listItem,
            'large': large,
            'clickable': clickable
          }"
          :size="large ? 48 : 30"
        >
          <span class="white--text" v-text="customerInitials"></span>
        </v-list-item-avatar>
        </template>
      <span>Visa kund</span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'CustomerAvatar',
  props: {
    large: Boolean,
    listItem: Boolean,
    clickable: Boolean,
    customer: Object,
    initials: String,
    color: String
  },
  data: () => ({}),
  computed: {
    customerInitials: function () {
      if (this.initials) {
        return this.initials
      }
      if (this.customer.is_org) {
        return this.customer.org_name.charAt(0).toUpperCase()
      }
      let strFirst = ''
      let strSecond = ''
      if (this.customer.given_name !== '' && this.customer.given_name !== null) {
        strFirst = this.customer.given_name.charAt(0).toUpperCase()
      } else {
        strFirst = 'X'
      }
      if (this.customer.surname !== '' && this.customer.surname !== null) {
        const words = this.customer.surname.split(' ')
        if (words.length > 1 && words[0].length <= 3) {
          strSecond = words[0].charAt(0) + words[1].charAt(0).toUpperCase()
        } else {
          strSecond = this.customer.surname.charAt(0).toUpperCase()
        }
      } else {
        strSecond = 'X'
      }
      return (strFirst + strSecond)
    },
    customerColor: function () {
      if (this.color) {
        return this.color
      }
      return this.customer.color || 'light-green'
    }
  },
  methods: {
    onClick: function () {
      if (this.clickable) {
        this.$emit('open-customer', this.customer)
      }
    }
  }
}
</script>
