<template>
  <v-list class="py-0">
    <template v-if="!hiddenSections.includes('name')">
      <v-subheader>Namn</v-subheader>
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ customer.full_name }}
            <v-chip
              v-if="nameError"
              :class="{ 'ml-2': !!customer.full_name }"
              small
              color="error"
            >
              {{ nameError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
      </template>
    </template>

    <template v-if="!hiddenSections.includes('adress')">
      <v-subheader>Adress</v-subheader>
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-city</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ streetAdress }}
            <v-chip
              v-if="streetError"
              :class="{ 'ml-2': !!streetAdress }"
              small
              color="warning"
            >
              {{ streetError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-action></v-list-item-action> -->
          <v-list-item-icon>
            <v-icon>mdi-city</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ areaAdress }}
            <v-chip
              v-if="areaError"
              :class="{ 'ml-2': !!areaAdress }"
              small
              color="warning"
            >
              {{ areaError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-action></v-list-item-action> -->
          <v-list-item-icon>
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ customer.country.name }}
            <v-icon small class="ml-3">
              {{ 'fi fi-' + customer.country.iso_code.toLowerCase() }}
            </v-icon>
            <v-chip
              v-if="countryError"
              :class="{ 'ml-2': !!customer.country }"
              small
              color="error"
            >
              {{ countryError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        </template>
    </template>

    <template v-if="!hiddenSections.includes('contact-details')">
      <v-subheader>Kontaktuppgifter</v-subheader>
      <template v-if="isLoading">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-skeleton-loader
              type="list-item"
              style="max-width: 200px;"
            ></v-skeleton-loader>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-if="isOrganisation">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Kontaktperson: {{ customer.org_reference }}
            <v-chip
              v-if="orgReferenceError"
              :class="{ 'ml-2': !!customer.org_reference }"
              small
              color="warning"
            >
              {{ orgReferenceError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ customer.fullPhoneNumber }}
            <v-chip
              v-if="phoneError"
              :class="{ 'ml-2': !!customer.fullPhoneNumber }"
              small
              color="warning"
            >
              {{ phoneError }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ customer.email }}
            <template v-if="customer.email_status === 'blocked'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Blockerad
                  </v-chip>
                </template>
                <div style="max-width: 300px; width: auto;">Den här email adressen har blockerats och inga fler email kommer skickas till den. Det kan till exempel bero på att den mottagande email servern inte svarar, eller att kunden har rapporterat ett mottaget mail som skräppost.</div>
              </v-tooltip>
            </template>
            <template v-else-if="!!emailError">
              <v-chip
                :class="{ 'ml-2': !!customer.email }"
                small
                color="warning"
              >
                {{ emailError }}
              </v-chip>
            </template>
          </v-list-item-title>
        </v-list-item>
      </template>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'CustomerDataList',
  props: {
    customer: Object,
    isLoading: Boolean,
    hiddenSections: {
      type: Array,
      default: function () { return [] }
    }
  },
  data: () => ({}),
  computed: {
    isOrganisation: function () {
      return this.customer && this.customer.is_org
    },
    nameError: function () {
      if (!this.customer.given_name && !this.customer.surname) {
        return 'För- och efternamn saknas'
      } else if (!this.customer.surname) {
        return 'Efternamn saknas'
      } else if (!this.customer.given_name) {
        return 'Förnamn saknas'
      }
      return ''
    },
    streetAdress: function () {
      if (this.customer.street_name && this.customer.street_number) {
        return `${this.customer.street_name} ${this.customer.street_number}`
      } else if (this.customer.street_name) {
        return this.customer.street_name
      } else if (this.customer.street_number) {
        return this.customer.street_number
      }
      return ''
    },
    streetError: function () {
      if (!this.customer.street_name && !this.customer.street_number) {
        return 'Gatuadress saknas'
      } else if (!this.customer.street_name) {
        return 'Gatunamn saknas'
      } else if (!this.customer.street_number) {
        return 'Gatunummer saknas'
      }
      return ''
    },
    areaAdress: function () {
      if (this.customer.zip && this.customer.city) {
        return `${this.customer.zip}, ${this.customer.city}`
      } else if (this.customer.zip) {
        return this.customer.zip
      } else if (this.customer.city) {
        return this.customer.city
      }
      return ''
    },
    areaError: function () {
      if (!this.customer.zip && !this.customer.city) {
        return 'Postkod och ort saknas'
      } else if (!this.customer.zip) {
        return 'Postkod saknas'
      } else if (!this.customer.city) {
        return 'Ort saknas'
      }
      return ''
    },
    countryError: function () {
      if (!this.customer.country) {
        return 'Land saknas'
      }
      return ''
    },
    phoneError: function () {
      if (!this.customer.phone_number && !this.customer.diallingCodeCountry) {
        return 'Telefonnummer saknas'
      } else if (!this.customer.phone_number) {
        return 'Telefonnummer saknas'
      } else if (!this.customer.diallingCodeCountry) {
        return 'Landskod saknas'
      }
      return ''
    },
    emailError: function () {
      if (!this.customer.email) {
        return 'Email saknas'
      }
      return ''
    },
    orgReferenceError: function () {
      if (this.isOrganisation && !this.customer.org_reference) {
        return 'Kontaktperson saknas'
      }
      return ''
    }
  }
}
</script>
