<template>
<div class="cancellation-policy-timeline" :class="{ 'is-horizontal': horizontal }">
  <v-timeline
    align-top
    dense
  >
    <template v-if="isFetching">
      <v-timeline-item
        v-for="i in 4"
        :key="i"
        fill-dot
        large
        color="transparent"
        class="skeleton-loader-timeline-item"
      >
        <template v-slot:icon>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </template>
        <v-row class="">
          <v-col class="mt-2">
            <div class="text-subtitle-2">
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </div>
            <div class="text-body-2">
              <v-skeleton-loader type="sentences"></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </template>

    <template v-else>
      <v-timeline-item
        v-for="(timelineItem, i) in timelineItems"
        :key="i"
        fill-dot
        large
        :color="timelineItem.is_cancellation_date ? 'error' : 'secondary'"
        :class="{ 'disabled': timelineItem.date.isBefore(nowOrCancellationDate) }"
      >
        <template v-slot:icon>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-flex flex-column full-width full-height align-center justify-center rounded-circle"
              >
                <template v-if="timelineItem.is_deadline">
                  <span
                    class="white--text text-caption cursor-default"
                    style="text-align: center; line-height: 1.2;"
                    v-html="dotDateFirstLine(timelineItem.date)"
                  >
                  </span>
                  <span
                    class="white--text text-caption cursor-default"
                    style="text-align: center; line-height: 1.2;"
                    v-html="dotDateSecondLine(timelineItem.date)"
                  >
                  </span>
                </template>
                <template v-else-if="timelineItem.is_checkin_date">
                  <v-icon color="white">mdi-human-greeting</v-icon>
                </template>
                <template v-else-if="timelineItem.is_booking_date">
                  <v-icon color="white">mdi-check</v-icon>
                </template>
                <template v-else-if="timelineItem.is_cancellation_date">
                  <v-icon color="white">mdi-close</v-icon>
                </template>
              </div>
            </template>
            <span>{{ toHumanReadableDate(timelineItem.date) }}</span>
          </v-tooltip>
        </template>
        <v-row class="">
          <v-col class="mt-2">
            <div class="text-subtitle-2">
              {{ timelineItem.title }}
            </div>
            <div class="text-body-2">
              {{ timelineItem.text }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </template>

  </v-timeline>
</div>
</template>

<script>
import { mapState } from 'vuex'
import CancellationPolicyService from '@/services/CancellationPolicyService.js'

export default {
  name: 'CancellationPolicyTimeline',
  props: {
    horizontal: Boolean,
    isOpen: Boolean,
    bookingId: Number,
    policyId: Number,
    triggerUpdateTimeline: Number,
    checkInDate: {
      type: Object,
      default: null
    } /* behövs bara utan bookingId */
  },
  data: () => ({
    timelineItems: [],
    isFetching: false
  }),
  computed: {
    ...mapState(
      {
        now: state => state.now
      }
    ),
    nowOrCancellationDate: function () {
      const cancellationEvent = this.timelineItems.find(tli => tli.is_cancellation_date)
      if (cancellationEvent) {
        return cancellationEvent.date
      }
      return this.now
    }
  },
  methods: {
    toHumanReadableDate: function (dt) {
      return window.conversion.toHumanReadableDate(dt)
    },
    dotDateFirstLine: function (dt) {
      return dt.format('D MMM')
    },
    dotDateSecondLine: function (dt) {
      return dt.format('HH:mm')
    },
    getTimelineItems: function () {
      if (!this.policyId) {
        return false
      }
      if (!this.bookingId && !this.checkInDate) {
        return false
      }

      if (window.navigator.connection?.downlink < 2) {
        this.isFetching = true
        this.timelineItems = []
      }
      const policyId = this.policyId
      const bookingId = this.bookingId
      const checkInDate = this.checkInDate
      CancellationPolicyService.getCancellationPolicyTimelineItems({ policyId, bookingId, checkInDate })
        .then(({ data }) => {
          if (data.status === 'success') {
            data.data.timeline_items.forEach(tli => {
              tli.date = window.dayjs(tli.date)
            })
            this.timelineItems = data.data.timeline_items
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        this.getTimelineItems()
      } else {
        this.timelineItems = []
        this.isFetching = true
      }
    },
    triggerUpdateTimeline: function () {
      this.getTimelineItems()
    }
  },
  mounted () {
    if (this.isOpen) {
      this.getTimelineItems()
    } else {
      this.timelineItems = []
    }
  }
}
</script>
