import API from './API.js'

export default {
  //
  // GET
  //
  getCustomerCredit ({ bookingId, customerId, includeTimeline }) {
    return API.httpClient.get('customer-credit/customer', {
      params: {
        booking_id: bookingId ?? null,
        customer_id: customerId ?? null,
        include_timeline: includeTimeline
      }
    })
  },

  //
  // POST
  //
  customerCreditPayment ({ customerId, paymentMethodId, amount }) {
    return API.httpClient.post('customer-credit/payment', {
      customer_id: customerId,
      payment_method_id: paymentMethodId,
      amount
    }).catch((error) => {
      console.log('error', error)
      return API.httpClient.post('customer-credit/wait-for-payment', {})
    })
  },
  registerCustomerCreditPayment ({ customerId, paymentMethodId, accountId, amount, date }) {
    return API.httpClient.post('customer-credit/register-payment', {
      customer_id: customerId,
      payment_method_id: paymentMethodId,
      account_id: accountId,
      date,
      amount
    })
  }
}
