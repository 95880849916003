<template>
<div>
  <v-dialog v-model="show" max-width="800px" :fullscreen="isMobile">
    <v-card class="late-checkout-dialog" tile>
      <v-toolbar elevation="0">
        <v-toolbar-title class="">Sen utcheckning</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="isMobile" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-sheet
        class="d-flex overflow-y-auto pa-4"
        :class="{ 'flex-column': isMobile }"
      >
        <div class="d-flex mr-2">
          <v-card outlined>
            <v-date-picker
              v-model="datepickerInput"
              :min="datePickerMin"
              :max="datePickerMax"
              color="primary"
            ></v-date-picker>
          </v-card>
        </div>
        <div class="ml-2 d-flex flex-column">
          <v-alert
            type="warning"
            text
            class="alert-center-icon"
          >
            Bokningen skulle checkat ut <strong>{{ checkoutDateFormatted }}</strong>
          </v-alert>
          <div class="text-body-2 px-1">Välj bokningens verkliga utcheckningsdatum, väljer du ett senare utcheckningsdatum i kalendern så adderas platsavgifter på de nytillkomna nätterna.</div>
          <div class="full-width d-flex mt-auto">
            <v-btn
              @click="saveCheckoutDate"
              :disabled="!datepickerInput"
              :loading="isUpdatingCheckoutDate"
              class="mt-auto ml-auto"
              color="success"
            >
              Fortsätt
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapState } from 'vuex'

import BookingService from '@/services/BookingService.js'

export default {
  name: 'LateCheckoutDialog',
  components: {},
  data: () => ({
    datepickerInput: null,
    isUpdatingCheckoutDate: false
  }),
  computed: {
    ...mapState(
      {
        booking: state => state.booking.lateCheckoutDialog.booking
      }
    ),
    show: {
      get () {
        return this.$store.state.booking.lateCheckoutDialog.show
      },
      set (value) {
        if (!value) {
          this.datepickerInput = null // återställer date input när stänger dialog
        }
        this.$store.commit('booking/SET_SHOW_LATE_CHECKOUT_DIALOG', value)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    checkoutDateFormatted: function () {
      if (!this.booking) {
        return ''
      }
      return window.conversion.toHumanReadableDate(this.booking.check_out, true, false)
    },
    datePickerMax: function () {
      return window.dayjs().format('YYYY-MM-DD')
    },
    datePickerMin: function () {
      if (!this.booking) {
        return this.datePickerMax
      }
      return this.booking.check_out.format('YYYY-MM-DD')
    }
  },
  methods: {
    close: function () {
      this.show = false
    },
    saveCheckoutDate: function () {
      if (this.booking.check_out.format('YYYY-MM-DD') === this.datepickerInput) {
        // valt samma utcheckningsdatum som redan är på bokningen, behöver inte skicka till servern
        this.$router.push({ name: 'PosRegister', params: { chargable: 'booking', id: this.booking.id, checkout: 'checkout' } })
        this.close()
      } else {
        // valt nytt utcheckningsdatum på bokningen, skicka till server för att uppdatera datum och platsavgifter sen routa till kassan
        const bookingId = this.booking.id
        const checkoutDate = window.dayjs(this.datepickerInput)
        this.isUpdatingCheckoutDate = true
        BookingService.updateCheckoutDate({ bookingId, checkoutDate })
          .then(({ data }) => {
            if (data.status === 'success') {
              this.close()
              this.$router.push({ name: 'PosRegister', params: { chargable: 'booking', id: this.booking.id, checkout: 'checkout' } })
            }
          })
          .finally(() => {
            // Behöver inte hämta om booking history, eftersom booking dialog stängs
            this.isUpdatingCheckoutDate = false
          })
      }
    }
  }
}
</script>
