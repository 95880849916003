export const namespaced = true

export const state = {
  reconciliationAccount: null
}

export const mutations = {
  SET_RECONCILIATION_ACCOUNT (state, val) {
    state.reconciliationAccount = val
  }
}
