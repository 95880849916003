<template>
  <div class="input-organisation">
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <input-org-number
          ref="inputOrgNumber"
          v-model="customer.org_number"
          :customer-id="customer.id"
          :force-validation="forceOrgNumberValidation"
          :force-reset-validation="forceResetValidation"
          @set-validation="setOrgNumberValidation"
        ></input-org-number>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <input-org-name
          ref="inputOrgName"
          v-model="customer.org_name"
          :force-validation="forceOrgNameValidation"
          :force-reset-validation="forceResetValidation"
          @set-validation="setOrgNameValidation"
        ></input-org-name>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <input-org-reference
          ref="inputOrgReference"
          v-model="customer.org_reference"
          :force-validation="forceOrgReferenceValidation"
          :force-reset-validation="forceResetValidation"
          @set-validation="setOrgReferenceValidation"
        ></input-org-reference>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputOrgNumber from './InputOrgNumber.vue'
import InputOrgName from './InputOrgName.vue'
import InputOrgReference from './InputOrgReference.vue'

export default {
  name: 'CustomerInputOrganisation',
  components: {
    InputOrgNumber,
    InputOrgName,
    InputOrgReference
  },
  props: {
    value: Object,
    forceValidation: Number,
    forceResetValidation: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    orgNumberValidation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    orgNameValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    orgReferenceValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    forceOrgNumberValidation: 0,
    forceOrgNameValidation: 0,
    forceOrgReferenceValidation: 0
  }),
  computed: {
    customer: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setOrgNumberValidation: function (val) {
      this.orgNumberValidation = val
      this.updateCommonValidation()
    },
    setOrgNameValidation: function (val) {
      this.orgNameValidation = val
      this.updateCommonValidation()
    },
    setOrgReferenceValidation: function (val) {
      this.orgReferenceValidation = val
      this.updateCommonValidation()
    },
    updateCommonValidation () {
      this.validation.hasChanges = this.orgNumberValidation.hasChanges || this.orgNameValidation.hasChanges || this.orgReferenceValidation.hasChanges
      this.validation.isValidated = (this.orgNumberValidation.isValidated && this.orgNameValidation.isValidated && this.orgReferenceValidation.isValidated)
      this.validation.isLoading = this.orgNumberValidation.isLoading
      this.validation.hasError = this.orgNumberValidation.hasError || this.orgNameValidation.hasError || this.orgReferenceValidation.hasError
      this.validation.hasWarning = this.orgNumberValidation.hasWarning || this.orgNameValidation.hasWarning || this.orgReferenceValidation.hasWarning
      if (this.orgNumberValidation.hasError) {
        this.validation.errorMessage = this.orgNumberValidation.errorMessage
      } else if (this.orgNameValidation.hasError) {
        this.validation.errorMessage = this.orgNameValidation.errorMessage
      } else if (this.orgReferenceValidation.hasError) {
        this.validation.errorMessage = this.orgReferenceValidation.errorMessage
      } else if (this.orgNumberValidation.hasWarning) {
        this.validation.errorMessage = this.orgNumberValidation.errorMessage
      } else if (this.orgNameValidation.hasWarning) {
        this.validation.errorMessage = this.orgNameValidation.errorMessage
      } else if (this.orgReferenceValidation.hasWarning) {
        this.validation.errorMessage = this.orgReferenceValidation.errorMessage
      } else {
        this.validation.errorMessage = ''
      }
      this.validation.suggestions = this.orgNumberValidation.suggestions
      this.$emit('set-validation', this.validation)
    },
    validate: function () {
      this.forceOrgNumberValidation++
      this.forceOrgNameValidation++
      this.forceOrgReferenceValidation++
    }
  },
  watch: {
    forceValidation: function () {
      this.validate()
    }
  }
}
</script>
