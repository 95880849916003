<template>
<div class="cancellation-policy-card">
<v-card max-width="600" class="py-0 px-4">
  <v-row class="my-0">
    <v-col cols="12" lg="6" class="pa-0">
      <v-card-title class="pb-2">
        <span class="text-h6 font-weight-light">
          {{ policy.name }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-subtitle-2">Pris</div>
            <div class="text-body-2">
              {{ policy.human_readable_price }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-subtitle-2">Regler</div>
            <div class="text-body-2" v-html="policy.human_readable_text ?? 'Manuell hantering'"></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-col>
    <v-col cols="12" lg="6" class="pa-0">
      <v-card-text class="py-0 px-0 px-lg-4" style="height: 100%;">
        <cancellation-policy-timeline
          :is-open="isOpen"
          :booking-id="bookingId"
          :policy-id="policy.id"
          :check-in-date="checkInDate"
        ></cancellation-policy-timeline>
      </v-card-text>
    </v-col>
  </v-row>
</v-card>
</div>
</template>

<script>
import CancellationPolicyTimeline from '@/components/cancellation-policy/CancellationPolicyTimeline.vue'

export default {
  name: 'CancellationPolicyCard',
  components: {
    CancellationPolicyTimeline
  },
  props: {
    isOpen: Boolean,
    policy: Object,
    checkInDate: Object, /* behövs bara om inte har bookingId */
    bookingId: Number
  },
  data: () => ({
    isLoading: true
  }),
  computed: {},
  methods: {}
}
</script>
