<template>
  <v-dialog v-model="showDialog" max-width="800px" :fullscreen="isMobile">
    <div class="booking-cancellation-dialog">
      <div v-if="isFetchingBookingCancellation" class="overlay-loader">
        <v-progress-circular
          indeterminate
          :size="70"
          color="primary"
        ></v-progress-circular>
      </div>
      <template v-if="showSuccessAlert && tabIndex === 'start_page'">
        <div class="white pa-4">
          <v-alert text type="success" class="mb-0" :class="{ 'alert-center-icon': !isMobile }">
            <div class="d-flex flex-column flex-lg-row">
              <div class="flex-grow-1 my-auto">{{ successAlertText }}</div>
            </div>
          </v-alert>
        </div>
      </template>
      <v-toolbar
        v-if="isMobile || !showSuccessAlert || tabIndex !== 'start_page'"
        style="height: auto;"
        elevation="0"
        class="py-3 flex-grow-0 toolbar-with-subheader"
        color="white"
      >
        <div class="toolbar-titles-container">
          <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
          <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
        </div>
        <v-chip
          class="ml-4 justify-center"
          small
          :color="statusColor"
          text-color="white"
          style="min-width: 124px;"
        >
          {{ bookingCancellation?.status_text }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click="close" class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs-items v-model="tabIndex" touchless>
        <v-tab-item value="start_page">
          <v-card class="booking-cancellation-dialog-card flex-column" tile>
            <div class="card-inner-container px-4 pb-4">
              <div style="min-height: 224px;">
                <div class="text-subtitle-1 font-weight-medium py-4 ml-4">{{ cancellationPolicy?.name }}</div>
                <cancellation-policy-timeline
                  :horizontal="!isMobile"
                  :is-open="show && !!bookingCancellation"
                  :booking-id="bookingCancellation?.booking.id"
                  :policy-id="cancellationPolicy?.id"
                  :trigger-update-timeline="triggerUpdateTimeline"
                ></cancellation-policy-timeline>
              </div>

              <div class="mx-4" :class="{ 'mt-6': isMobile }" style="min-height: 77px;">
                <h3>{{ selectedOptionTitle }}</h3>
                <p>{{ selectedOptionBody }}</p>
              </div>

              <div class="py-4">
                <v-expansion-panels
                  accordion
                  v-model="expandedOptionPanelIndex"
                >
                  <!-- TODO: [CO-2938] förväntar sig att option_name är unikts -->
                  <v-expansion-panel
                    v-for="option in bookingCancellationOptions"
                    :key="option.id"
                    @change="selectOption(option)"
                  >
                    <v-expansion-panel-header class="py-3">
                      <div class="d-flex">
                        <v-icon
                          v-if="option.is_selected"
                          class=""
                          color="success"
                          :disabled="isSelectingOptionName !== ''"
                        >mdi-check-circle</v-icon>
                        <!-- TODO: [CO-2938] förväntar sig att option_name är unikts -->
                        <v-progress-circular
                          v-else-if="option.option_name === isSelectingOptionName"
                          indeterminate
                          color="secondary"
                          size="24"
                        ></v-progress-circular>
                        <v-radio
                          v-else
                          :disabled="isSelectingOptionName !== ''"
                          color="secondary"
                          class="d-inline-flex highlight-on-hover option-radio-button"
                          :ripple="false"
                          @click.stop="selectOption(option)"
                        ></v-radio>
                        <div class="ml-3 my-auto">{{ option.description_header }}</div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p v-html="option.description_body"></p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>

            <div class="d-flex pa-4">
              <v-btn
                color="error"
                text
                :loading="isReversingCancel"
                :disabled="isFetchingBookingCancellation || isSelectingOptionName !== ''"
                @click="reverseCancellation"
              >
                Ångra avbokning
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                @click="nextStep"
                :disabled="isFetchingBookingCancellation || isReversingCancel || isSelectingOptionName !== ''"
              >
                Gå vidare
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item value="document_request_page">
          <v-card class="booking-cancellation-dialog-card flex-column" tile>
            <booking-cancellation-document-request
              :booking-cancellation="bookingCancellation"
              @close="close"
              @confirmed="documentRequestConfirmed"
            ></booking-cancellation-document-request>
            <div class="d-flex pa-4">
              <v-btn
                color="primary"
                text
                @click="back"
              >
                Tillbaka
              </v-btn>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item value="manual_page">
          <v-card class="booking-cancellation-dialog-card flex-column" tile>
            <booking-cancellation-manual-refund
              :loading="isFetchingSpecificationList"
              :data="specificationList"
            ></booking-cancellation-manual-refund>
            <div class="d-flex pa-4 flex-column flex-lg-row">
              <v-btn
                color="primary"
                :block="isMobile"
                text
                @click="back"
                :disabled="isFetchingSpecificationList ||isFetchingPreviewData"
              >
                Tillbaka
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :block="isMobile"
                class="mt-4 mt-lg-0"
                :loading="isFetchingPreviewData"
                @click="completeManualRefund"
                :disabled="isFetchingSpecificationList"
              >
                Visa förhandsgranskning
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item value="preview_page">
          <v-card class="booking-cancellation-dialog-card flex-column" tile>
            <booking-cancellation-preview
              :loading="isFetchingPreviewData"
              :data="previewData"
            ></booking-cancellation-preview>
            <div class="d-flex pa-4 flex-column flex-lg-row mt-4 mt-lg-0">
              <v-btn
                color="primary"
                :block="isMobile"
                text
                @click="back"
                :disabled="isCompleting || isFetchingPreviewData"
              >
                Tillbaka
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :block="isMobile"
                class="mt-4 mt-lg-0"
                :loading="isCompleting"
                @click="completeCancellation"
                :disabled="isFetchingPreviewData"
              >
                {{ completeCancellationText }}
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item value="completed_page">
          <v-card class="booking-cancellation-dialog-card flex-column" tile>
            <booking-cancellation-completed
              :show="tabIndex === 'completed_page'"
              :booking-cancellation="bookingCancellation"
            ></booking-cancellation-completed>
            <div class="d-flex pa-4 flex-column flex-lg-row mt-4 mt-lg-0">
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :block="isMobile"
                class="mt-4 mt-lg-0"
                :loading="isSendingCancellationConfirmation"
                @click="sendCancellationConfirmation"
              >
                Skicka avbokningsbekräftelse
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import CancellationPolicyTimeline from '@/components/cancellation-policy/CancellationPolicyTimeline.vue'
import BookingCancellationPreview from '@/components/cancellation-policy/BookingCancellationPreview.vue'
import BookingCancellationDocumentRequest from '@/components/cancellation-policy/BookingCancellationDocumentRequest.vue'
import BookingCancellationManualRefund from '@/components/cancellation-policy/BookingCancellationManualRefund.vue'
import BookingCancellationCompleted from '@/components/cancellation-policy/BookingCancellationCompleted.vue'

export default {
  name: 'BookingCancellationDialog',
  components: {
    CancellationPolicyTimeline,
    BookingCancellationPreview,
    BookingCancellationDocumentRequest,
    BookingCancellationManualRefund,
    BookingCancellationCompleted
  },
  data: () => ({
    tabIndex: 'start_page', // 'start_page', 'document_request_page', 'manual_page', 'preview_page', 'completed_page'
    triggerUpdateTimeline: 0,
    expandedOptionPanelIndex: null
  }),
  computed: {
    ...mapState(
      {
        show: state => state.cancellationPolicy.bookingCancellationDialog.show,
        showSuccessAlert: state => state.cancellationPolicy.bookingCancellationDialog.showSuccessAlert,
        bookingCancellation: state => state.cancellationPolicy.bookingCancellationDialog.bookingCancellation,
        previewData: state => state.cancellationPolicy.bookingCancellationDialog.previewData,
        specificationList: state => state.cancellationPolicy.bookingCancellationDialog.specificationList,
        isReversingCancelBookingIds: state => state.booking.isReversingCancelBookingIds,
        isSelectingOptionName: state => state.cancellationPolicy.bookingCancellationDialog.isSelectingOptionName,
        isFetchingBookingCancellation: state => state.cancellationPolicy.bookingCancellationDialog.isFetchingBookingCancellation,
        isFetchingPreviewData: state => state.cancellationPolicy.bookingCancellationDialog.isFetchingPreviewData,
        isFetchingSpecificationList: state => state.cancellationPolicy.bookingCancellationDialog.isFetchingSpecificationList,
        isCompleting: state => state.cancellationPolicy.bookingCancellationDialog.isCompleting,
        isSendingCancellationConfirmation: state => state.cancellationPolicy.bookingCancellationDialog.isSendingCancellationConfirmation
      }
    ),
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.tabIndex = 'start_page'
          this.expandedOptionPanelIndex = null
        }
        this.$store.commit('cancellationPolicy/SET_BOOKING_CANCELLATION_DIALOG_SHOW', value)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    bookingCancellationOptions: function () {
      if (!this.bookingCancellation) {
        return []
      }
      return this.bookingCancellation.options
    },
    selectedBookingCancellationOption: function () {
      return this.bookingCancellationOptions.find(bco => bco.is_selected)
    },
    selectedOptionTitle: function () {
      if (!this.selectedBookingCancellationOption) {
        return ''
      }
      return this.selectedBookingCancellationOption.result_header
    },
    selectedOptionBody: function () {
      if (!this.selectedBookingCancellationOption) {
        return ''
      }
      return this.selectedBookingCancellationOption.result_body
    },
    cancellationPolicy: function () {
      return this.bookingCancellation?.booking.cancellation_policy
    },
    isReversingCancel: function () {
      return this.bookingCancellation && this.isReversingCancelBookingIds.includes(this.bookingCancellation.booking.id)
    },
    title: function () {
      if (this.bookingCancellation) {
        return `Avbokning ${this.bookingCancellation.booking.booking_number}`
      }
      return 'Avbokning'
    },
    subtitle: function () {
      if (this.bookingCancellation && this.bookingCancellation.booking && this.bookingCancellation.booking.customer) {
        return this.bookingCancellation.booking.customer.full_name
      }
      return ''
    },
    statusColor: function () {
      if (!this.bookingCancellation) {
        return ''
      }
      const status = ['Avslutad', 'Inväntar dokument', 'Oavslutad']
      const color = ['success', 'warning', 'error']
      const index = status.indexOf(this.bookingCancellation.status_text)
      return color[index]
    },
    successAlertText: function () {
      if (!this.bookingCancellation) {
        return ''
      }
      return `Bokning ${this.bookingCancellation.booking.booking_number} har avbokats och platsen är ledig igen. Slutför avbokningen nedanför för att genomföra eventuell återbetalning eller slutfakturering.`
    },
    isCompletedCancellation: function () {
      return !!this.bookingCancellation?.completed_at
    },
    hasDocumentRequest: function () {
      return !!this.bookingCancellation?.approval_request
    },
    completeCancellationText: function () {
      if (this.previewData && this.previewData.has_final_invoice) {
        return 'Avsluta och fakturera'
      } else if (this.previewData && this.previewData.has_balancing_invoice) {
        return 'Avsluta och kreditera'
      } else {
        return 'Avsluta'
      }
    }
  },
  methods: {
    close: function () {
      this.showDialog = false
    },
    back: function () {
      this.tabIndex = 'start_page'
    },
    selectOption: function (option) {
      if (option.is_selected) {
        return false // skickar inte till server om man valt samma option som redan är vald
      }
      const optionIndex = this.bookingCancellationOptions.findIndex(o => o.option_name === option.option_name) // TODO: [CO-2938] förväntar sig att option_name är unikts
      if (optionIndex >= 0 && this.expandedOptionPanelIndex !== optionIndex) {
        this.expandedOptionPanelIndex = null // om en annan expansion-panel än vald option är expanderad så stänger den
      }
      this.$store.dispatch('cancellationPolicy/bookingCancellationSelectOption', {
        bookingCancellationId: this.bookingCancellation.id,
        selectedOption: option
      })
        .finally(() => {
          this.triggerUpdateTimeline++
        })
    },
    nextStep: function () {
      if (!this.selectedBookingCancellationOption) {
        return false
      }
      this.tabIndex = this.selectedBookingCancellationOption.next_step
      if (this.tabIndex === 'preview_page') {
        this.getPreview()
      } else if (this.tabIndex === 'manual_page') {
        this.getSpecificationList()
      }
    },
    documentRequestConfirmed: function () {
      // kan vara både approve och deny, preview_data har redan hämtats
      this.tabIndex = 'preview_page'
    },
    formatAmount: function (amount) {
      return window.conversion.toHumanReadableAmount(amount)
    },
    formatDate: function (date) {
      return date.format('D MMM YYYY')
    },
    getPreview: function () {
      return this.$store.dispatch('cancellationPolicy/getBookingCancellationPreview', { bookingCancellationId: this.bookingCancellation.id })
    },
    getSpecificationList: function () {
      this.$store.dispatch('cancellationPolicy/getBookingCancellationSpecificationList', { bookingCancellationId: this.bookingCancellation.id })
    },
    reverseCancellation: function () {
      this.$store.dispatch('booking/reverseCancel', this.bookingCancellation.booking_id)
        .finally(() => {
          this.close()
        })
    },
    completeManualRefund: function () {
      this.$store.dispatch('cancellationPolicy/getBookingCancellationPreview', { bookingCancellationId: this.bookingCancellation.id, manualSpecification: this.specificationList })
        .then(() => {
          this.tabIndex = 'preview_page'
        })
    },
    completeCancellation: function () {
      this.$store.dispatch('cancellationPolicy/completeCancellation', { bookingCancellationId: this.bookingCancellation.id, preview: this.previewData })
    },
    sendCancellationConfirmation: function () {
      this.$store.dispatch('cancellationPolicy/sendCancellationConfirmation', { bookingCancellationId: this.bookingCancellation.id })
    }
  },
  watch: {
    bookingCancellation: function (newBookingCancellation, oldBookingCancellation) {
      if (oldBookingCancellation === null) {
        // när bookingCancellation har laddats in
        if (this.isCompletedCancellation) {
          this.tabIndex = 'completed_page'
        } else if (this.hasDocumentRequest) {
          if (this.bookingCancellation.approval_request.approved_at) {
            this.getPreview()
              .finally(() => {
                this.tabIndex = 'preview_page'
              })
          } else {
            this.tabIndex = 'document_request_page'
          }
        } else {
          this.tabIndex = 'start_page'
        }
      } else if (this.isCompletedCancellation) {
        this.tabIndex = 'completed_page'
      }
    }
  },
  mounted () {}
}
</script>
