<template>
  <v-snackbar
    :value="true"
    :color="color"
    :timeout="-1"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="deleteSelf()"
      >
        Stäng
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'CommonSnackbar',
  props: {
    color: String,
    text: String,
    id: Number
  },
  data: () => ({
    show: true
  }),
  methods: {
    deleteSelf: function () {
      this.$store.commit('snackbars/DELETE_SNACKBAR', this.id)
    }
  },
  created () {
    setTimeout(() => {
      this.deleteSelf()
    }, 5000)
  }
}
</script>
