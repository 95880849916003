<template>
  <v-dialog
    v-model="show"
    max-width="800"
  >
    <v-card class="product-form-dialog" :loading="isLoadingStoreProduct ? 'secondary' : false">
      <v-card-title class="pb-4" style="word-break: keep-all;">
        Skapa ny produkt
      </v-card-title>
      <v-card-text>
        <v-container v-if="!!editedProduct">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-form ref="produtNameTextField" @submit.prevent="saveProduct">
                <v-text-field
                  v-model="editedProduct.name"
                  label="Produktnamn"
                  counter="50"
                  :class="{ 'sub-errors-for-warning': productNameHasWarning }"
                  :error-messages="productNameErrorMessages"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-form ref="produtGroupIdSelect" @submit.prevent>
                <v-select
                  v-model="editedProduct.product_group_id"
                  :items="productGroups"
                  label="Produktgrupp"
                  item-value="id"
                  item-text="name"
                  :rules="produtGroupIdRules"
                  :persistent-hint="editedProduct.product_group_id && editedProduct.product_group_id > 0"
                  :hint="productGroupHint"
                ></v-select>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="priceInput"
                label="Pris inkl. moms"
                clearable
                hint="Lämna fältet tomt eller 0 om varan saknar ett bestämt pris"
                append-icon="coi-currency-sek"
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="editedProduct.gui_color"
                :items="availableProductColors"
                label="Produktfärg"
                prepend-icon="mdi-palette"
              >
                <template v-slot:item="{ item }">
                  <v-chip
                    :color="item"
                    dark
                    class="px-6 my-2 text-h5 cursor-pointer"
                  >
                    {{ editedProduct.name }}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip
                    :color="item"
                    dark
                    small
                    class="px-6 text-body-1 cursor-pointer"
                  >
                    {{ editedProduct.name }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <label class="text-caption" style="width: 100%; font-size: 16px;">Streckkod</label>
              <div class="d-flex align-center" style="">
                <v-icon left>
                  mdi-barcode
                </v-icon>
                <span v-text="barcodeText" :class="{ 'text-button': hasBarcode }"></span>
                <div class="ml-3">
                  <v-btn
                    v-if="hasBarcode"
                    text
                    color="error"
                    @click="deleteBarcode"
                  >
                    Ta bort streckkod
                  </v-btn>
                  <v-btn
                    v-else
                    color="success"
                    text
                    @click="openBarcodeInput"
                  >
                    Lägg till streckkod
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="show = false"
          class="mx-3"
          :disabled="isLoadingStoreProduct"
        >
          Avbryt
        </v-btn>
        <v-btn
          color="success"
          @click="saveProduct"
          :loading="isLoadingStoreProduct"
        >
          Spara produkt
        </v-btn>
      </v-card-actions>
    </v-card>
    <barcode-input
      v-model="barcodeInput"
      :show="showBarcodeInput"
      @close="closeBarcodeInput"
    ></barcode-input>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BarcodeInput from './BarcodeInput.vue'

export default {
  name: 'ProductFormDialog',
  components: {
    BarcodeInput
  },
  data: () => ({
    produtGroupIdRules: [v => !!v || 'Välj en produktgrupp till produkten'],
    barcodeInput: '',
    showBarcodeInput: false,
    hardValidation: false
  }),
  computed: {
    ...mapState(
      {
        productGroups: state => state.product.productGroups,
        products: state => state.product.products,
        availableProductColors: state => state.product.availableProductColors,
        isLoadingStoreProductProductIds: state => state.product.isLoadingStoreProductProductIds
      }
    ),
    show: {
      get () {
        return this.$store.state.product.showFormDialog
      },
      set (value) {
        this.$store.commit('product/SET_SHOW_FORM_DIALOG', value)
      }
    },
    editedProduct: {
      get () {
        return this.$store.state.product.editedProduct
      },
      set (value) {
        this.$store.commit('product/SET_EDITED_PRODUCT', value)
      }
    },
    priceInput: {
      get () {
        return this.editedProduct.price / 100
      },
      set (value) {
        this.editedProduct.price = Math.round(Math.abs(value * 100))
      }
    },
    productGroupHint: function () {
      if (this.editedProduct.product_group_id) {
        const productGroup = this.productGroups.find(pg => pg.id === this.editedProduct.product_group_id)
        if (productGroup) {
          return productGroup.tax.name
        }
      }
      return ''
    },
    hasBarcode () {
      return this.editedProduct && this.editedProduct.barcode
    },
    barcodeText () {
      if (this.hasBarcode) {
        return this.editedProduct.barcode
      }
      return 'Varan saknar streckkod'
    },
    isLoadingStoreProduct () {
      return this.editedProduct && this.isLoadingStoreProductProductIds.includes(this.editedProduct.id)
    },
    productNameErrorMessages: function () {
      const editedProductName = this.editedProduct.name.toLowerCase()
      if (editedProductName.length > 50) {
        return ['Max 50 tecken']
      } else if (this.hardValidation && editedProductName.length === 0) {
        return ['Ange ett namn']
      } else if (this.products.some(product => product.name.toLowerCase() === editedProductName)) {
        return ['Det finns redan en produkt med samma namn']
      }
      return []
    },
    productNameHasWarning: function () {
      const editedProductName = this.editedProduct.name.toLowerCase()
      return this.products.some(product => product.name.toLowerCase() === editedProductName)
    }
  },
  methods: {
    openBarcodeInput: function () {
      this.barcodeInput = this.editedProduct.barcode ? this.editedProduct.barcode : ''
      this.showBarcodeInput = true
    },
    closeBarcodeInput: function () {
      this.showBarcodeInput = false
      this.editedProduct.barcode = this.barcodeInput
    },
    deleteBarcode: function () {
      this.editedProduct.barcode = ''
    },
    validate: function () {
      // Uppdelat i en statement per input så att alla ska valideras och visa valideringserror och inte bara den första
      this.hardValidation = true
      const productGroupValid = this.$refs.produtGroupIdSelect.validate()
      const productNameValid = this.productNameErrorMessages.length === 0
      return productGroupValid && (productNameValid || this.productNameHasWarning)
    },
    saveProduct: function () {
      if (this.validate()) {
        this.$store.dispatch('product/storeProduct')
      }
    }
  },
  watch: {
    show: function () {
      if (!this.show) {
        this.hardValidation = false
      }
    }
  },
  mounted () {}
}
</script>
