<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="800"
  >
    <v-card class="print-preview-dialog">
      <v-toolbar
        elevation="0"
      >
        <v-toolbar-title class="pl-5">
          Virtuell Skrivare
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="container">
        <div v-html="htmlReceipt"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PrintPreviewDialog',
  data: () => ({}),
  computed: {
    ...mapState(
      {
        htmlReceipt: state => state.print.htmlReceipt
      }
    ),
    show: {
      get () {
        return this.$store.state.print.showDialog
      },
      set (value) {
        this.$store.commit('print/SET_SHOW_DIALOG', value)
      }
    }
  },
  methods: {
    close: function () {
      this.show = false
    }
  }
}
</script>
