<template>
  <div class="arrival-time-picker">
    <time-picker
      v-model="timePickerValue"
      label="Förväntad ankomsttid"
      :allowed-minutes="[0, 15, 30, 45]"
      default-time="13:00"
    ></time-picker>
  </div>
</template>

<script>
import TimePicker from '@/components/common/TimePicker.vue'

export default {
  name: 'ArrivalTimePicker',
  props: {
    selectedTime: String
  },
  components: {
    TimePicker
  },
  data: () => ({
    timePickerValue: '',
    time: ''
  }),
  computed: {},
  methods: {
    setTime: function (val) {
      this.time = val
    }
  },
  watch: {
    selectedTime: function () {
      this.timePickerValue = this.selectedTime
    },
    timePickerValue: function () {
      this.$emit('set-time', this.timePickerValue)
    }
  }
}
</script>
