<template>
  <v-dialog v-model="isOpen" max-width="900px" :fullscreen="isMobile">
    <v-card class="accommodation-charges-dialog" tile :loading="loading ? 'secondary' : false">
      <v-toolbar
        style="height: auto;"
        elevation="0"
        class="py-3 toolbar-with-subheader"
        color="white"
      >
        <div class="toolbar-titles-container">
          <v-toolbar-title class="pl-5">Platsavgifter</v-toolbar-title>
          <v-toolbar-title class="pl-5 toolbar-subheader">{{ subTitle }}</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon v-if="isMobile" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet
        class="d-flex flex-column overflow-y-auto pa-3"
        style="border-top: 1px solid lightgrey;"
      >
        <div class="pa-1">
          <v-alert
            dense
            text
            type="info"
            class="d-block"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">
                {{ alertText }}
              </div>
            </div>
          </v-alert>
        </div>
        <div class="d-flex-grow-1 overflow-hidden pa-1">
          <accommodation-charges-calendar
            :booking="booking"
            :calendar-items="calendarItems"
            :loading="loading"
            :is-open="isOpen"
            :single-column="isMobile"
          ></accommodation-charges-calendar>
        </div>
      </v-sheet>
    </v-card>
  </v-dialog>

</template>

<script>
import AccommodationChargesCalendar from './AccommodationChargesCalendar.vue'

export default {
  name: 'AccommodationChargesDialog',
  components: {
    AccommodationChargesCalendar
  },
  props: {
    show: Boolean,
    booking: Object,
    loading: Boolean,
    calendarItems: Array
  },
  data: () => ({}),
  computed: {
    isOpen: {
      get () {
        return this.show
      },
      set (val) {
        if (!val) {
          this.$emit('close')
        }
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    subTitle: function () {
      if (!this.booking) {
        return ''
      }
      return `${this.booking.title}, ${this.booking.subtitle}`
    },
    isCheckedIn: function () {
      return this.booking && this.booking.checked_in_at != null
    },
    isLateCheckin: function () {
      return this.isCheckedIn && this.booking.checked_in_at.startOf('day').isAfter(this.booking.check_in)
    },
    isEarlyCheckin: function () {
      return this.isCheckedIn && this.booking.checked_in_at.startOf('day').isBefore(this.booking.check_in)
    },
    alertText: function () {
      if (this.isLateCheckin) {
        const daysLate = this.booking.checked_in_at.startOf('day').diff(this.booking.check_in, 'days')
        return `Bokningen har checkat in ${daysLate} dagar senare än planerat.`
      } else if (this.isEarlyCheckin) {
        const daysEarly = this.booking.check_in.diff(this.booking.checked_in_at.startOf('day'), 'days')
        return `Bokningen har checkat in ${daysEarly} dagar tidigare än planerat, här kan du komplettera med platsavgifter.`
      } else {
        return ''
      }
    }
  },
  methods: {
    close: function () {
      this.isOpen = false
    }
  },
  watch: {}
}
</script>
