<template>
  <v-navigation-drawer
    v-model="show"
    fixed
    temporary
    right
    class="max-height-100vh"
  >
    <v-list-item class="py-4">
      <!-- <v-list-item-avatar :color="user.color">
        <span class="white--text" v-text="user.initials"></span>
      </v-list-item-avatar> -->
      <v-avatar item :color="user.color" class="mr-4">
        <span class="white--text" v-text="user.initials"></span>
      </v-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="user.name"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in userMenuItems"
        :key="item.text"
        link
        :to="item.path"
        :disabled="item.disabled"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          @click="showChangelog = true"
          block
          text
          color="secondary"
          class="mb-4"
        >
          <v-icon class="mr-2">mdi-alert-decagram</v-icon>
          Nyheter
        </v-btn>
        <a :href="termsUrl" target="_blank" class="text-decoration-none">
          <v-btn
            block
            text
            color="primary"
            class="mb-4"
          >
            <v-icon class="mr-2">mdi-file-sign</v-icon>
            Bokningsvillkor
          </v-btn>
        </a>
        <v-btn
          block
          outlined
          color="error"
          :loading="isLogoutLoading"
          @click="logout"
        >
          Logga ut
        </v-btn>
      </div>
    </template>
    <changelog-dialog v-model="showChangelog"></changelog-dialog>
  </v-navigation-drawer>
</template>

<script>
import ChangelogDialog from '@/components/changelog/ChangelogDialog.vue'

export default {
  name: 'UserNavigationDrawer',
  components: {
    ChangelogDialog
  },
  props: {
    isOpen: Boolean
  },
  data: () => ({
    menuHistory: false,
    isLogoutLoading: false,
    showChangelog: false
  }),
  computed: {
    user: function () {
      return this.$store.state.user.user
    },
    termsUrl: function () {
      if (!this.$store.state.user) {
        return ''
      }
      const namespace = this.$store.state.user.domain.namespace
      return `https://${namespace}.campingonline.se/villkor`
    },
    userMenuItems: function () {
      return [
        { icon: 'mdi-cogs', text: 'Inställningar', path: '/settings', disabled: false },
        { icon: 'mdi-account-edit', text: 'Användare', path: '/users', disabled: false }
      ]
    },
    show: {
      get: function () {
        return this.isOpen
      },
      set: function (val) {
        this.$emit('set-is-open', val)
      }
    }
  },
  methods: {
    logout: function () {
      this.isLogoutLoading = true
      this.$store.dispatch('user/logout')
        .finally(() => {
          this.isLogoutLoading = false
        })
    }
  },
  watch: {
    open: function () {
      this.show = this.open
    }
  }
}
</script>
