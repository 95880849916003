const versionCompare = function (left, right) {
  // https://gist.github.com/alexey-bass/1115557
  // Returns:
  // * -1 = left is LOWER than right
  // *  0 = they are equal
  // *  1 = left is GREATER = right is LOWER
  // *  And FALSE if one of input versions are not valid
  if (typeof left + typeof right !== 'stringstring') { return false }

  const a = left.split('.')
  const b = right.split('.')
  let i = 0; const len = Math.max(a.length, b.length)

  for (; i < len; i++) {
    if ((a[i] && !b[i] && parseInt(a[i]) > 0) || (parseInt(a[i]) > parseInt(b[i]))) {
      return 1
    } else if ((b[i] && !a[i] && parseInt(b[i]) > 0) || (parseInt(a[i]) < parseInt(b[i]))) {
      return -1
    }
  }

  return 0
}

export default {
  versionCompare
}
