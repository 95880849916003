<template>
<div class="payment-method-settings">
  <v-card tile>
    <v-toolbar
      style="height: auto;"
      elevation="0"
      class=""
      color="white"
    >
      <v-toolbar-title class="pl-5">Inställningar Betalningsmetoder</v-toolbar-title>
    </v-toolbar>
    <v-sheet
      class="overflow-y-auto pl-3"
      style="border-top: 1px solid lightgrey;"
    >
      <v-list flat>
        <v-subheader>Betalningsmetoder aktiva på den här enheten.</v-subheader>
        <v-list-item
          v-for="paymentMethod in paymentMethods"
          :key="paymentMethod.id"
          :disabled="!paymentMethod.allow_user_activation"
        >
          <v-list-item-icon class="my-auto">
            <v-icon v-text="paymentMethod.icon_class_name"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex width-100" style="white-space: normal;">
              <div class="my-auto d-flex flex-column width-100 pr-5">
                <div class="text-subtitle-2">{{ paymentMethod.button_text ?? paymentMethod.name }}</div>
                <div class="text-body-2">{{ paymentMethod.settings_description }}</div>
              </div>
              <div class="ml-auto my-auto">
                <v-switch
                  :value="paymentMethod.id"
                  v-model="activePaymentMethodIds"
                  color="secondary"
                  :loading="loadingPaymentMethodIds.includes(paymentMethod.id)"
                ></v-switch>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-alert
        v-if="isElectron"
        type="info"
        text
        class="mr-3"
      >
        Kontakta CampingOnline för att aktivera eller avaktivera betalningsmetoder
      </v-alert>
    </v-sheet>
  </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex'

import PaymentMethodService from '@/services/PaymentMethodService.js'

export default {
  name: 'PaymentMethodSettings',
  components: {},
  props: {},
  data: () => ({
    loadingPaymentMethodIds: []
  }),
  computed: {
    ...mapState(
      {
        workstation: state => state.workstation,
        paymentMethods: state => state.posCheckout.paymentMethods,
        isElectron: state => state.isElectron
      }
    ),
    activePaymentMethodIds: {
      get: function () {
        return this.paymentMethods.filter(paymentMethod => paymentMethod.workstation_ids.includes(this.workstation.id) || (this.loadingPaymentMethodIds.includes(paymentMethod.id && !paymentMethod.workstation_ids.includes(this.workstation.id)))).map(paymentMethod => paymentMethod.id)
      },
      set: function (val) {
        // val är den nya arrayen med aktiva payment method ids, sista id i array är senaste tillagda
        let difference = []
        if (this.activePaymentMethodIds.length > val.length) {
          // id har tagits bort
          // payment method is som finns i activePaymentMethodIds men inte i val och inte i loadingPaymentMethodIds
          difference = this.activePaymentMethodIds.filter(x => !val.includes(x)).concat(this.activePaymentMethodIds.filter(x => !this.loadingPaymentMethodIds.includes(x)))
        } else {
          // id har lagts till
          // payment method is som finns i val men inte i activePaymentMethodIds och inte i loadingPaymentMethodIds
          difference = val.filter(x => !this.activePaymentMethodIds.includes(x)).concat(val.filter(x => !this.loadingPaymentMethodIds.includes(x)))
        }
        const paymentMethod = this.paymentMethods.find(pm => pm.id === difference[0])
        this.toggleActivatedPaymentMethod(paymentMethod)
      }
    },
    activePaymentMethods: function () {
      if (!this.workstation) {
        return []
      }
      return this.paymentMethods.filter(pm => pm.workstation_ids.includes(this.workstation.id))
    }
  },
  methods: {
    toggleActivatedPaymentMethod: function (paymentMethod) {
      if (this.loadingPaymentMethodIds.includes(paymentMethod.id)) {
        return false
      }

      this.loadingPaymentMethodIds.push(paymentMethod.id)
      const associatePaymentMethod = this.activePaymentMethodIds.includes(paymentMethod.id) ? 0 : 1

      PaymentMethodService.associatePaymentMethodToWorkstation({
        paymentMethodId: paymentMethod.id,
        workstationId: this.workstation.id,
        associatePaymentMethod
      })
        .then(({ data }) => {
          console.log('associatePaymentMethodToWorkstation then', data)
          if (data.status === 'success') {
            if (associatePaymentMethod === 0) {
              // payment method har tagits bort från workstation
              const i = paymentMethod.workstation_ids.findIndex(workstationId => workstationId === this.workstation.id)
              if (i >= 0) {
                paymentMethod.workstation_ids.splice(i, 1)
                this.$store.dispatch('snackbars/createSnackbar', {
                  color: 'success',
                  text: 'Betalningsmetoden har tagits bort från den här enheten'
                })
              }
            } else {
              // payment method har lagts till på workstation
              paymentMethod.workstation_ids.push(this.workstation.id)
              this.$store.dispatch('snackbars/createSnackbar', {
                color: 'success',
                text: 'Betalningsmetoden har lagts till på den här enheten'
              })
            }
          }
        })
        .finally(() => {
          const i = this.loadingPaymentMethodIds.findIndex(id => id === paymentMethod.id)
          this.loadingPaymentMethodIds.splice(i, 1)
        })
    }
  },
  mounted () {

  }
}
</script>
