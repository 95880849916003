import API from './API.js'

export default {
  prepareSales ({ registerId, paymentMethodId, amount, accommodationChargesAmount, customerCreditAmount, charges, bookingId, parkedOrderId, shouldCheckOut }) {
    const items = charges.map((charge) => {
      const item = Object.assign({}, charge)
      item.position_id = charge.position ? charge.position.id : null
      item.product_id = charge.product.id
      item.position = null
      item.product = null
      return item
    })
    return API.httpClient.post('sales/prepare', {
      register_id: registerId,
      payment_method_id: paymentMethodId,
      amount,
      customer_credit_amount: customerCreditAmount,
      accommodation_charges_amount: accommodationChargesAmount,
      items,
      booking_id: bookingId,
      parked_order_id: parkedOrderId,
      should_check_out: shouldCheckOut
    }).catch((error) => {
      console.log('error', error)
      return API.httpClient.post('sales/wait-for-prepare', {
        register_id: registerId
      })
    })
  },
  completeSales ({ receiptId, controlData, shouldCheckOut, bongProductsCount }) {
    return API.httpClient.post('sales/complete', {
      receipt_id: receiptId,
      control_data: controlData,
      should_check_out: shouldCheckOut,
      bong_products_count: bongProductsCount
    })
  }
}
