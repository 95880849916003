import API from './API.js'

export default {
  //
  // GET
  //
  getTerminals () {
    return API.httpClient.get('terminal')
  },

  //
  // POST
  //
  reconcile (terminalId) {
    return API.httpClient.post('terminal/reconcile', {
      terminal_id: terminalId
    })
  },
  performAdministration (terminalId, action) {
    return API.httpClient.post('terminal/perform-administration', {
      terminal_id: terminalId,
      action
    })
  }
}
