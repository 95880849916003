<template>
<div class="product-price-input">
  <v-row>
    <v-col :cols="threeRows ? 12 : 4">
      <v-text-field
        v-model="inputPrice"
        label="Pris"
        type="number"
        outlined
        :autofocus="autofocus"
        :error-messages="errorMessages"
        :hint="priceHint"
        :persistent-hint="priceHint !== ''"
      ></v-text-field>
    </v-col>
    <v-col :cols="threeRows ? 12 : 4">
      <v-select
        :items="discountTypes"
        v-model="discountType"
        label="Rabatt"
        outlined
        hide-details
      ></v-select>
    </v-col>
    <v-col :cols="threeRows ? 12 : 4" v-if="!discountValueInputDisabled || !threeRows">
      <v-text-field
        :disabled="discountValueInputDisabled"
        :label="discountValueLabel"
        v-model="discountValue"
        :suffix="discountValueSuffix"
        type="number"
        outlined
        hide-details
      ></v-text-field>
    </v-col>
  </v-row>
</div>
</template>

<script>

export default {
  name: 'ProductPriceInput',
  props: {
    value: Object,
    errorMessages: Array,
    threeRows: Boolean,
    autofocus: Boolean
  },
  data: () => ({
    priceBeforeDiscountTypeChange: 0
  }),
  computed: {
    charge: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    discountTypes: function () {
      return [
        {
          text: 'Ingen rabatt',
          value: null
        },
        {
          text: 'Nytt pris',
          value: 'fixed',
          disabled: !this.hasOriginalPrice
        },
        {
          text: 'Prisnedsättning',
          value: 'flat'
        },
        {
          text: 'Procentuell',
          value: 'percent'
        }
      ]
    },
    inputPrice: {
      get () {
        if (this.charge.price === '') {
          return ''
        }
        return this.charge.price / 100
      },
      set (val) {
        const price = Math.round(val * 100)
        if (this.hasOriginalPrice && price !== this.charge.original_price) {
          this.discountType = 'fixed'
          this.discountValue = this.charge.original_price - price
        } else {
          this.discountType = null
        }
        this.price = price
      }
    },
    price: {
      get () {
        return this.charge.price
      },
      set (val) {
        this.charge.price = Math.round(val)
        this.$emit('updated-price')
      }
    },
    discountValue: {
      get () {
        if (this.charge.discount_value === null) {
          return null
        }
        return this.charge.discount_value / 100
      },
      set (val) {
        if (this.discountType === 'flat') {
          this.charge.discount_value = Math.round(val * 100)
          if (!this.hasOriginalPrice) {
            this.price = this.priceBeforeDiscountTypeChange - this.charge.discount_value
          } else {
            this.price = this.charge.original_price - this.charge.discount_value
          }
        } else if (this.discountType === 'percent') {
          this.charge.discount_value = Math.round(val * 100)
          if (!this.hasOriginalPrice) {
            this.price = this.priceBeforeDiscountTypeChange * (1 - (this.charge.discount_value / 10000))
          } else {
            this.price = this.charge.original_price * (1 - (this.charge.discount_value / 10000))
          }
        } else if (this.discountType === 'fixed') {
          //
        } else {
          // Ingen rabatt
          this.charge.discount_value = null
          if (this.hasOriginalPrice) {
            this.price = this.charge.original_price
          } else {
            this.price = this.priceBeforeDiscountTypeChange
          }
        }
      }
    },
    discountType: {
      get () {
        return this.charge.discount_type
      },
      set (val) {
        this.charge.discount_type = val
        if (['flat', 'percent'].includes(val)) {
          console.log('priceBeforeDiscountTypeChange', this.priceBeforeDiscountTypeChange, this.hasOriginalPrice, (!this.hasOriginalPrice && this.priceBeforeDiscountTypeChange === 0))
          if (!this.hasOriginalPrice && this.priceBeforeDiscountTypeChange === 0) {
            this.priceBeforeDiscountTypeChange = this.charge.price
          }
          this.discountValue = 0
        } else {
          this.discountValue = null
        }

        // Återställer ordinarie pris när ändrar discount type
        if (this.hasOriginalPrice) {
          this.price = this.charge.original_price
        } else {
          this.price = this.priceBeforeDiscountTypeChange
        }
      }
    },
    hasOriginalPrice: function () {
      return this.charge.original_price !== 0 && this.charge.original_price !== null
    },
    priceHint: function () {
      if (['flat', 'percent', 'fixed'].includes(this.discountType)) {
        let price = 0
        if (this.hasOriginalPrice) {
          price = this.charge.original_price
        } else {
          price = this.priceBeforeDiscountTypeChange
        }
        return `Ordinarie pris: ${price / 100}kr`
      }
      return ''
    },
    discountValueInputDisabled: function () {
      return !['flat', 'percent'].includes(this.discountType)
    },
    discountValueLabel: function () {
      if (this.discountType === 'flat') {
        return 'Rabatt i kronor'
      } else if (this.discountType === 'percent') {
        return 'Rabatt i procent'
      } else {
        return ''
      }
    },
    discountValueSuffix: function () {
      if (this.discountType === 'flat' || this.discountType === 'fixed') {
        return 'Kr'
      } else {
        return '%'
      }
    }
  },
  methods: {},
  watch: {
    charge: function () {
      // Återställer priceBeforeDiscountTypeChange när charge byts ut
      this.priceBeforeDiscountTypeChange = 0
    }
  }
}
</script>
