<template>
<div class="co-calendar">
  <div v-show="!isMobile" class="co-calendar-title">
    <h3>{{ title }}</h3>
  </div>
  <v-card flat class="co-calendar-menu mx-auto" :class="{ 'is-mobile': isMobile }" :loading="isLoading">
    <v-app-bar
      v-if="!isMobile"
      flat
      color="rgba(0, 0, 0, 0.03)"
    >
      <v-btn
        icon
        @click="prev"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title class="title title-left">
        <span>{{ firstCalendarTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="title title-right">
        <span>{{ secondCalendarTitle }}</span>
      </v-toolbar-title>

      <v-btn
        icon
        @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-app-bar>
    <v-row>
      <v-col v-if="isMobile" cols="12" class="pb-0 text-center">
        <v-btn depressed block @click="prev">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="isMobile" cols="12" class="pb-0 text-center">
        <h2>{{ firstCalendarTitle }}</h2>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card :disabled="isLoadingFirstMonth" elevation="0">
          <v-overlay
            absolute
            :value="isDisabledMonth(focusFirstCalendar)"
            class="disabled-month-overlay"
          >
            <v-alert type="info">
              Inga tillgängliga datum
            </v-alert>
          </v-overlay>
          <v-calendar
            ref="firstCalendar"
            show-week
            locale-first-day-of-year="4"
            v-model="focusFirstCalendar"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            type="month"
            color="primary"
            @change="firstCalendarMonthChange"
            @click:day="calendarClickedDate"
            :show-month-on-first="false"
          >
            <template v-slot:day-label="{ day, date }">
              <template v-if="isFirstDate(date)">
                <span class="checkin-day-btn-extension" :class="{ 'date-disabled': isDisabledDate(date) }"></span>
                <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small primary selected" :class="{ 'date-disabled': isDisabledDate(date) }">
                  <span class="v-btn__content">{{ day }}</span>
                </button>
              </template>
              <template v-else-if="isSecondDate(date)">
                <span class="checkout-day-btn-extension" :class="{ 'date-disabled': isDisabledDate(date) }"></span>
                <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small primary selected" :class="{ 'date-disabled': isDisabledDate(date) }">
                  <span class="v-btn__content">{{ day }}</span>
                </button>
              </template>
              <v-tooltip v-else-if="isBetweenDates(date)" bottom :disabled="dateFocused !== 'second'">
                <template v-slot:activator="{ on, attrs }">
                  <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round rounded-0 theme--light v-size--small secondary black--text btn-day-between" :class="{ 'disabled': isDisabledDate(date) }" v-bind="attrs" v-on="on">
                    <span class="v-btn__content">{{ day }}</span>
                  </button>
                </template>
                <span>{{ nightsAfterFirstDate(date) }} nätter</span>
              </v-tooltip>
              <button v-else-if="isDisabledDate(date)" disabled type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small transparent date-disabled">
                <span class="v-btn__content">{{ day }}</span>
              </button>
              <v-tooltip v-else bottom :disabled="dateFocused !== 'second'">
                <template v-slot:activator="{ on, attrs }">
                  <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small transparent" v-bind="attrs" v-on="on">
                    <span class="v-btn__content">{{ day }}</span>
                  </button>
                </template>
                <span>{{ nightsAfterFirstDate(date) }} nätter</span>
              </v-tooltip>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
      <v-col v-if="isMobile" cols="12" class="pb-0 text-center">
        <h2>{{ secondCalendarTitle }}</h2>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card :disabled="isLoadingSecondMonth" elevation="0">
          <v-overlay
            absolute
            :value="isDisabledMonth(focusSecondCalendar)"
            class="disabled-month-overlay"
          >
            <v-alert type="info">
              Inga tillgängliga datum
            </v-alert>
          </v-overlay>
          <v-calendar
            ref="secondCalendar"
            show-week
            locale-first-day-of-year="4"
            v-model="focusSecondCalendar"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            type="month"
            color="primary"
            @change="secondCalendarMonthChange"
            @click:day="calendarClickedDate"
            :show-month-on-first="false"
          >
            <template v-slot:day-label="{ day, date }">
              <template v-if="isFirstDate(date)">
                <span class="checkin-day-btn-extension" :class="{ 'date-disabled': isDisabledDate(date) }"></span>
                <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small primary selected" :class="{ 'date-disabled': isDisabledDate(date) }">
                  <span class="v-btn__content">{{ day }}</span>
                </button>
              </template>
              <template v-else-if="isSecondDate(date)">
                <span class="checkout-day-btn-extension" :class="{ 'date-disabled': isDisabledDate(date) }"></span>
                <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small primary selected" :class="{ 'date-disabled': isDisabledDate(date) }">
                  <span class="v-btn__content">{{ day }}</span>
                </button>
              </template>
              <v-tooltip v-else-if="isBetweenDates(date)" bottom :disabled="dateFocused !== 'second'">
                <template v-slot:activator="{ on, attrs }">
                  <button  type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round rounded-0 theme--light v-size--small secondary black--text btn-day-between" :class="{ 'disabled': isDisabledDate(date) }" v-bind="attrs" v-on="on">
                    <span class="v-btn__content">{{ day }}</span>
                  </button>
                </template>
                <span>{{ nightsAfterFirstDate(date) }} nätter</span>
              </v-tooltip>
              <button v-else-if="isDisabledDate(date)" disabled type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small transparent date-disabled">
                <span class="v-btn__content">{{ day }}</span>
              </button>
              <v-tooltip v-else bottom :disabled="dateFocused !== 'second'">
                <template v-slot:activator="{ on, attrs }">
                  <button type="button" class="v-btn v-btn--depressed v-btn--fab v-btn--round theme--light v-size--small transparent" v-bind="attrs" v-on="on">
                    <span class="v-btn__content">{{ day }}</span>
                  </button>
                </template>
                <span>{{ nightsAfterFirstDate(date) }} nätter</span>
              </v-tooltip>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
      <v-col v-if="isMobile" cols="12" class="pb-0 text-center">
        <v-btn depressed block @click="next">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</div>
</template>

<script>

export default {
  name: 'CoCalendar',
  props: {
    title: String,
    dateFocused: String,
    firstDate: Object,
    secondDate: Object,
    initialMonth: Object,
    dateStatus: Array,
    isLoadingFirstMonth: Boolean,
    isLoadingSecondMonth: Boolean,
    allowAllDates: Boolean
  },
  data: () => ({
    firstCalendarTitle: '',
    secondCalendarTitle: '',
    focusFirstCalendar: '',
    focusSecondCalendar: ''
  }),
  computed: {
    isLoading () {
      return this.isLoadingFirstMonth || this.isLoadingSecondMonth
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    setFirstDate (dt) {
      this.$emit('set-first-date', dt)
    },
    setSecondDate (dt) {
      this.$emit('set-second-date', dt)
    },
    setDateFocus (val) {
      this.$emit('set-date-focus', val)
    },
    prev () {
      this.$refs.firstCalendar.prev()
      this.$refs.secondCalendar.prev()
      this.$emit('navigated', {
        firstMonth: this.focusFirstCalendar,
        secondMonth: this.focusSecondCalendar
      })
    },
    next () {
      this.$refs.firstCalendar.next()
      this.$refs.secondCalendar.next()
      this.$emit('navigated', {
        firstMonth: this.focusFirstCalendar,
        secondMonth: this.focusSecondCalendar
      })
    },
    firstCalendarMonthChange (view) {
      const dt = window.dayjs(view.start.date)
      this.firstCalendarTitle = dt.format('MMMM YYYY')
    },
    secondCalendarMonthChange (view) {
      const dt = window.dayjs(view.start.date)
      this.secondCalendarTitle = dt.format('MMMM YYYY')
    },
    calendarClickedDate (e) {
      const dt = window.dayjs(e.date)
      if (this.isDisabledDate(dt)) {
        return false
      }
      if (this.dateFocused === 'first') {
        this.setFirstDate(dt)
        if (this.secondDate.isSameOrBefore(dt)) {
          this.setSecondDate(dt.add(1, 'day'))
        }
        this.setDateFocus('second')
      } else if (this.dateFocused === 'second') {
        this.setSecondDate(dt)
        if (dt.isSameOrBefore(this.firstDate)) {
          this.setFirstDate(dt.add(-1, 'day'))
        }
        this.setDateFocus('')
      }
    },
    nightsAfterFirstDate (dt) {
      return Math.abs(this.firstDate.diff(dt, 'day'))
    },
    isBetweenDates (dt) {
      return window.dayjs(dt).isBetween(this.firstDate, this.secondDate)
    },
    isFirstDate (dt) {
      return window.dayjs(dt).isSame(this.firstDate)
    },
    isSecondDate (dt) {
      return window.dayjs(dt).isSame(this.secondDate)
    },
    isDisabledDate (dt, dtFocus) {
      if (this.allowAllDates) { return false }
      const d = window.dayjs(dt)
      if (!dtFocus) {
        dtFocus = this.dateFocused
      }
      const obj = this.dateStatus.find(ds => ds.date.isSame(d))
      return (!obj || !obj.isEnabled)
    },
    isDisabledMonth (dt, dtFocus) {
      if (this.allowAllDates) { return false }
      if (!dtFocus) {
        dtFocus = this.dateFocused
      }
      const obj = this.dateStatus.filter(ds => ds.date.isSame(dt, 'month'))
      return obj.length > 0 && obj.every(s => !s.isEnabled)
    }
  },
  watch: {},
  mounted () {
    this.focusFirstCalendar = this.initialMonth.format('YYYY-MM-DD')
    this.focusSecondCalendar = this.initialMonth.add(1, 'month').format('YYYY-MM-DD')
  }
}
</script>
