<template>
  <div class="receipt-dialog">
    <v-card class="flex-column full-height" tile :loading="isLoading ? 'secondary' : false">
      <v-toolbar
        style="height: auto;"
        elevation="0"
        class="py-3 toolbar-with-subheader flex-grow-0"
        color="white"
      >
        <div class="toolbar-titles-container">
          <template v-if="isLoading">
            <v-toolbar-title class="pl-5 mt-1">
              <v-skeleton-loader
                type="heading"
                style="width: 200px; max-width: 200px;"
              ></v-skeleton-loader>
            </v-toolbar-title>
            <v-toolbar-title class="toolbar-subheader pl-5 mt-2">
              <v-skeleton-loader
                type="text"
                style="width: 120px; max-width: 120px;"
              ></v-skeleton-loader>
            </v-toolbar-title>
          </template>
          <template v-else>
            <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
            <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
          </template>
        </div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="isLoading"
              @click="showReceipt(receipt)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            </template>
          <span>Visa originalkvitto</span>
        </v-tooltip>

        <print-button
          :disabled="!allowPrintCopy"
          button-text="Skriv ut kvittokopia"
          :print-object="receipt?.print"
          is-receipt
          :receipt-id="receipt?.id"
          icon
          :loading="isLoadingPrintCopyReceiptIds.includes(receipt?.id)"
        ></print-button>
        <v-btn icon v-if="isMobile" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs
        v-model="tabIndex"
        color="secondary"
        show-arrows
        grow
      >
        <v-tab href="#receipt-information">
          Kvittoinformation
        </v-tab>
        <v-tab href="#sales-items">
          Kvittoinnehåll
        </v-tab>
        <v-tab href="#payments">
          Betalningar
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabIndex">
        <v-tab-item value="receipt-information">
          <v-sheet class="flex-grow-1">
            <receipt-data-list
              :is-loading="isLoading"
              :receipt="receipt"
              :hidden-sections="[]"
              customer-clickable
              booking-clickable
              @close="close"
            ></receipt-data-list>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="sales-items">
          <v-sheet class="flex-grow-1">
            <sales-items-table :sales-items="salesItems"></sales-items-table>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="payments">
          <v-sheet class="flex-grow-1 overflow-x-hidden">
            <payments-timeline
              :payments="payments"
            ></payments-timeline>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="mt-auto flex-grow-0 pa-4">
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ReceiptDataList from './DataList.vue'
import PaymentsTimeline from './PaymentsTimeline.vue'
import SalesItemsTable from '@/components/product/SalesItemsTable.vue'
import PrintButton from '@/components/common/PrintButton.vue'

export default {
  name: 'ReceiptDialogContent',
  components: {
    ReceiptDataList,
    PaymentsTimeline,
    SalesItemsTable,
    PrintButton
  },
  props: {
    receipt: Object,
    isLoading: Boolean,
    show: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    tabIndex: 'receipt-information'
  }),
  computed: {
    ...mapState(
      {
        isLoadingPrintCopyReceiptIds: state => state.posReceipt.isLoadingPrintCopyReceiptIds,
        register: state => state.posRegister.register
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isReturn: function () {
      return this.receipt && this.receipt.receipt_type === 'return'
    },
    allowPrintCopy: function () {
      return (this.register && this.receipt?.register_id === this.register.id) || (this.receipt && this.receipt.allow_printing_copy)
    },
    title: function () {
      if (this.isReturn) {
        return `Kvitto på återköp ${this.receipt.receipt_serial}`
      } else {
        // fakturor eller kontantfaktura
        return `Kvitto ${this.receipt.receipt_serial}`
      }
    },
    subtitle: function () {
      if (!this.receipt) {
        return ''
      }
      const formattedDate = this.receipt.created_at.format('YYYY-MM-DD')
      return `Skapad ${formattedDate}`
    },
    salesItems: function () {
      if (!this.receipt) {
        return []
      }
      return this.receipt.sales_items
    },
    payments: function () {
      if (!this.receipt) {
        return []
      }
      return this.receipt.receipt_payments
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    showReceipt: function (receipt) {
      this.$store.dispatch('print/showPrint', receipt.print)
    }
  },
  watch: {
    show: function () {
      if (!this.show) {
        this.tabIndex = 'receipt-information'
      }
    }
  }
}
</script>
