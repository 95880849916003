<template>
  <v-form
    @submit.prevent
    ref="formOrgReference"
  >
    <v-text-field
      label="Kontaktperson"
      v-model.trim="orgReference"
      :rules="[orgReferenceRule]"
    ></v-text-field>
  </v-form>
</template>

<script>

export default {
  name: 'CustomerInputOrgReference',
  components: {},
  props: {
    value: String,
    forceValidation: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    originalOrgReference: ''
  }),
  computed: {
    orgReference: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    orgReferenceRule: function (v) {
      let verdict = ''
      if (v != null && v.length > 100) {
        verdict = 'Kontaktperson kan vara max 100 tecken'
      }
      this.validation.hasError = (verdict !== '')
      this.validation.errorMessage = verdict
      this.validation.isValidated = true
      this.validation.hasChanges = (this.originalOrgReference === v)
      this.$emit('set-validation', this.validation)
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    validate: function () {
      return this.$refs.formOrgReference.validate()
    },
    resetValidation: function () {
      this.$refs.formOrgReference.resetValidation()
    }
  },
  watch: {
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  mounted () {
    this.originalOrgReference = this.orgReference
  }
}
</script>
