<template>
  <div class="customer-card" :class="{ 'pa-2': !flat }">
    <v-card
      :class="{
        'd-flex align-center': true,
        'cursor-pointer clickable': clickable,
        'is-large': large,
        'is-small': !large
      }"
      :flat="flat"
      :ripple="clickable"
      @click.native="cardClicked"
    >
      <customer-avatar :customer="customer" :large="large" class="px-3"></customer-avatar>
      <div class="customer-card-content d-flex flex-column">
        <v-card-title v-text="customer.full_name" class="pl-0"></v-card-title>
        <v-card-subtitle v-text="customer.shortAddress" class="pl-0"></v-card-subtitle>
      </div>
    </v-card>
  </div>
</template>

<script>
import CustomerAvatar from '@/components/customer/Avatar.vue'

export default {
  name: 'CustomerCard',
  components: {
    CustomerAvatar
  },
  props: {
    large: Boolean,
    flat: Boolean,
    clickable: Boolean,
    customer: Object
  },
  data: () => ({}),
  computed: {},
  methods: {
    cardClicked: function () {
      if (this.clickable) {
        this.$emit('click', this.customer)
      }
    }
  },
  mounted () {
    if (!this.customer.isEnriched) {
      window.enrich.enrichCustomer(this.customer)
    }
  }
}
</script>
