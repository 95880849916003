<template>
  <div class="apply-credit-form">
    <v-card class="white full-height d-flex flex-column" :flat="inlineForm">
      <v-toolbar
        v-show="!inlineForm"
        style="height: auto;"
        elevation="0"
        color="white"
        class="flex-grow-0"
      >
        <v-toolbar-title class="">
          Använd tillgodo
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="flex-grow-1 flex-shrink-1 align-start" :class="{ 'px-4': !inlineForm }">
        <v-row v-if="!inlineForm" class="mb-4">
          <v-col>
            <p class="text-overline ma-0 font-weight-regular">Att betala</p>
            <p class="text-h5">{{ totalPayable / 100 }}kr</p>
          </v-col>
          <v-col>
            <p class="text-overline ma-0 font-weight-regular">Tillgängligt tillgodo</p>
            <p class="text-h5">{{ customerCreditAvailable / 100 }}kr</p>
          </v-col>
        </v-row>
        <div v-else class="mb-4">
          <span class="text-caption">Tillgängligt tillgodo</span>
          <span class="ml-2 text-subtitle-2">{{ customerCreditAvailable / 100 }}kr</span>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1">
            <v-text-field
              v-model="inputAmount"
              label="Tillgodo att använda"
              append-icon="coi-currency-sek"
              outlined
              clearable
              type="number"
              :hide-details="!amountValidation.hasWarning && !amountValidation.hasError"
              :error-messages="amountValidation.hasError || amountValidation.hasWarning ? [amountValidation.errorMessage] : []"
              :class="{ 'sub-errors-for-warning': amountValidation.hasWarning }"
            ></v-text-field>
            <v-chip
              :outlined="!isMaxAmount"
              :class="{ 'mt-2': !amountValidation.hasWarning && !amountValidation.hasError }"
              color="secondary"
              @click="selectMaxAmount"
            >
              Använd maxbelopp
            </v-chip>
          </div>
          <div v-show="inlineForm" class="ml-4">
            <v-btn
              style="margin-top: 10px;"
              color="success"
              @click="applyAmount"
            >
              Applicera tillgodo
            </v-btn>
          </div>
        </div>
      </v-sheet>
      <v-card-actions v-show="!inlineForm" class="mt-auto pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="applyAmount"
        >
          Applicera tillgodo
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ApplyCreditForm',
  props: {
    value: Number, // applicerad tillgodo
    customerCreditAvailable: Number,
    totalPayable: Number,
    inlineForm: Boolean
  },
  data: () => ({
    selectedAmount: 0,
    amountValidation: {
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    }
  }),
  computed: {
    inputAmount: {
      get: function () {
        return this.selectedAmount / 100
      },
      set: function (val) {
        let amount = Math.round(val * 100)
        if (Number.isNaN(amount)) {
          amount = 0
        }
        this.selectedAmount = amount
      }
    },
    maxAppliedAmount: function () {
      return Math.min(this.totalPayable, this.customerCreditAvailable)
    },
    isMaxAmount: function () {
      return this.selectedAmount === this.maxAppliedAmount
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    applyAmount: function () {
      if (this.validateAmount()) {
        this.$emit('input', this.selectedAmount)
        this.close()
      }
    },
    selectMaxAmount: function () {
      this.selectedAmount = this.maxAppliedAmount
      this.validateAmount()
    },
    validateAmount: function () {
      const amount = this.selectedAmount
      this.amountValidation.hasError = false
      this.amountValidation.hasWarning = false

      const n = Number.parseFloat(amount)
      if (Number.isNaN(n)) {
        this.amountValidation.errorMessage = 'Beloppet måste vara ett tal'
        this.amountValidation.hasError = true
        return false
      }
      if (amount === this.totalPayable && this.totalPayable === 0) {
        this.amountValidation.errorMessage = 'Det finns inget att betala'
        this.amountValidation.hasWarning = true
      }
      if (amount < 0) {
        this.amountValidation.errorMessage = 'Beloppet måste vara positivt'
        this.amountValidation.hasError = true
        return false
      }
      if (amount > this.maxAppliedAmount) {
        if (amount > this.customerCreditAvailable) {
          this.amountValidation.errorMessage = 'Beloppet är högre vad som är tillgängligt'
        } else {
          this.amountValidation.errorMessage = 'Beloppet är högre än totala beloppet att betala'
        }
        this.amountValidation.hasError = true
        return false
      }
      return true
    }
  },
  watch: {
    value: function () {
      this.validateAmount()
    },
    selectedAmount: function () {
      this.validateAmount()
    },
    customerCreditAvailable: function () {
      this.validateAmount()
    },
    totalPayable: function () {
      this.validateAmount()
    }
  }
}
</script>
