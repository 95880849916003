<template>
<div class="booking-dialog" :class="{ 'is-overlay': isOverlay }">
  <v-card class="booking-dialog-card flex-column" tile :loading="isLoading ? 'secondary' : false">
    <v-toolbar
      style="height: auto;"
      elevation="0"
      class="py-3 toolbar-with-subheader flex-grow-0"
      color="white"
    >
      <div class="toolbar-titles-container overflow-hidden">
        <template v-if="isLoading">
          <v-toolbar-title class="pl-5 mt-1">
            <v-skeleton-loader
              type="heading"
              style="width: 200px; max-width: 200px;"
            ></v-skeleton-loader>
          </v-toolbar-title>
          <v-toolbar-title class="toolbar-subheader pl-5 mt-2">
            <v-skeleton-loader
              type="text"
              style="width: 120px; max-width: 120px;"
            ></v-skeleton-loader>
          </v-toolbar-title>
        </template>
        <template v-else>
          <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
          <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
        </template>
      </div>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
            @click="editBooking"
            class="mr-2"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
        <div>
          Redigera bokning
        </div>
      </v-tooltip>
      <v-btn icon v-if="isMobile" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-tabs
      v-model="tabIndex"
      color="secondary"
      show-arrows
      grow
    >
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab href="#booking-information">
        <v-icon v-if="isMobile">mdi-book-marker-outline</v-icon>
        <span v-else>Bokningsinformation</span>
      </v-tab>
      <v-tab href="#booking-history">
        <v-icon v-if="isMobile">mdi-book-clock-outline</v-icon>
        <span v-else>Historik</span>
      </v-tab>
      <v-tab href="#charges">
        <v-badge
          :value="hasAccommodationChargeWarnings"
          color="warning"
          dot
        >
          <v-icon v-if="isMobile">mdi-cash</v-icon>
          <span v-else>Avgifter</span>
        </v-badge>
      </v-tab>
      <v-tab href="#invoices-receipts">
        <v-badge
          :value="receiptsAndInvoicesCount !== ''"
          color="secondary"
          :content="receiptsAndInvoicesCount"
          offset-x="-2"
          offset-y="8"
        >
          <v-icon v-if="isMobile">mdi-receipt-text</v-icon>
          <span v-else>Kvitton och fakturor</span>
        </v-badge>
      </v-tab>
      <v-tab href="#emails">
        <v-badge
          :value="emailsCount !== ''"
          color="secondary"
          :content="emailsCount"
          offset-x="-2"
          offset-y="8"
        >
          <v-icon v-if="isMobile">mdi-email</v-icon>
          <span v-else>Email</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-slide-y-transition leave-absolute>
      <v-tabs
        v-show="tabIndex === 'charges'"
        v-model="subTabIndexCharges"
        centered
        slider-color="secondary"
      >
        <v-tab
          href="#accommodation-charges"
          class="px-8"
        >
          Platsavgifter
        </v-tab>
        <v-tab
          href="#other-charges"
          class="px-8"
        >
          <v-badge
            :value="chargesCount !== ''"
            color="secondary"
            :content="chargesCount"
            offset-x="-2"
            offset-y="8"
          >
            Andra avgifter
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-slide-y-transition>

    <v-slide-y-transition leave-absolute>
      <v-tabs
        v-show="tabIndex === 'invoices-receipts'"
        v-model="subTabIndexInvoicesReceipts"
        centered
        slider-color="secondary"
      >
        <v-tab
          href="#receipts"
          class="px-8"
        >
          <v-badge
            :value="receiptCount !== ''"
            color="secondary"
            :content="receiptCount"
            offset-x="-2"
            offset-y="8"
          >
            Kvitton
          </v-badge>
        </v-tab>
        <v-tab
          href="#invoices"
          class="px-8"
        >
          <v-badge
            :value="invoiceCount !== ''"
            color="secondary"
            :content="invoiceCount"
            offset-x="-2"
            offset-y="8"
          >
            Fakturor
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-slide-y-transition>

    <v-divider v-show="tabIndex === 'invoices-receipts' || tabIndex === 'charges'"></v-divider>

    <v-tabs-items v-model="tabIndex">
      <v-tab-item value="booking-information">
        <v-sheet class="overflow-y-auto pa-3 full-height">
          <v-row class="ma-0 full-height">
            <v-col class="pb-0 full-height">
              <booking-data-list
                :is-loading="isLoading"
                :booking="booking"
                inside-dialog
                :hidden-sections="['channel', 'booking']"
                position-clickable
                :customer-clickable="!isOverlay"
                allow-reverse-checkin
                allow-reverse-checkout
                @close="close"
              ></booking-data-list>
            </v-col>
            <v-col class="pb-0 full-height">
              <!-- REVIEW: skeleton loader visas inte eftersom vi inte vet om det är tally förrän booking redan har hämtats - sätta access_driver direkt i client istället för att appenda det på varje booking? -->
              <tally-order
                v-if="booking?.access_driver === 'tally'"
                dense-header
                :booking="booking"
                :is-open="isOpen"
                :is-loading="fetchingChunks.includes('booking')"
              ></tally-order>
              <booking-input-remarks
                class="pt-4"
                :is-open="isOpen"
                soft-border
                :is-skeleton-loader="isLoading"
                :booking-remarks="booking?.remarks"
                :booking-id="booking?.id"
                @remarks-updated="remarksUpdated"
              ></booking-input-remarks>
            </v-col>
          </v-row>
        </v-sheet>
      </v-tab-item>
      <v-tab-item value="booking-history">
        <v-sheet class="overflow-y-auto pa-3 full-height">
          <editing-record-timeline
            :editing-records="editingRecords"
            :loading="fetchingChunks.includes('editing-records')"
          ></editing-record-timeline>
        </v-sheet>
      </v-tab-item>
      <v-tab-item value="charges">
        <v-tabs-items v-model="subTabIndexCharges">
          <v-tab-item value="accommodation-charges">
            <v-sheet class="overflow-y-auto pa-3 full-height">
              <template v-if="isSeasonBooking">
                <accommodation-price-card
                  :booking="booking"
                  :loading="fetchingChunks.includes('booking')"
                ></accommodation-price-card>
              </template>
              <template v-else>
                <v-row class="full-height">
                  <v-col class="pb-0 full-height">
                    <accommodation-charges-calendar
                      :booking="booking"
                      :calendar-items="accommodationChargesCalendarItems"
                      :loading="fetchingChunks.includes('accommodation-charges-calendar-items')"
                      :is-open="isOpen"
                      :single-column="isMobile"
                    ></accommodation-charges-calendar>
                  </v-col>
                </v-row>
              </template>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="other-charges">
            <v-sheet class="overflow-y-auto pa-3 full-height">
              <charges-table
                :charges="charges"
                :loading="fetchingChunks.includes('charges')"
              ></charges-table>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
      <v-tab-item value="invoices-receipts">
        <v-tabs-items v-model="subTabIndexInvoicesReceipts">
          <v-tab-item value="receipts">
            <v-sheet
              class="pa-3 full-height"
            >
              <receipt-list
                class="full-height"
                :receipts="receipts"
                flat
                :loading="fetchingChunks.includes('receipts')"
                :displayedColumns="['receipt_number', 'total', 'created_at']"
              ></receipt-list>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="invoices">
            <v-sheet
              class="pa-3 full-height"
            >
              <invoice-list
                class="full-height"
                :invoices="invoices"
                flat
                :loading="fetchingChunks.includes('invoices')"
                :displayedColumns="['invoice_number', 'total', 'remaining_amount', 'invoice_date', 'due_date', 'status']"
              ></invoice-list>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
      <v-tab-item value="emails">
        <v-sheet
          class="overflow-y-auto pa-3 full-height"
        >
          <email-timeline
            :emails="emails"
            :booking-id="booking?.id"
            :loading="fetchingChunks.includes('emails')"
            no-emails-message="Inga bekräftelser har skickats på den här bokningen"
          >
            <template v-slot:timeline-actions>
              <v-timeline-item
                fill-dot
                color="success"
              >
                <template v-slot:icon>
                  <v-icon color="white">mdi-email-fast</v-icon>
                </template>
                <v-btn
                  color="success"
                  @click="sendBookingConfirmation"
                  :loading="isSendingBookingConfirmation"
                  :disabled="disableSendingBookingConfirmation"
                  :class="{ 'btn-two-rows': isMobile }"
                >
                  Skicka ny bokningsbekräftelse
                </v-btn>
              </v-timeline-item>
            </template>
          </email-timeline>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions class="booking-dialog-footer full-width">
      <v-btn
        v-if="isCheckedOut"
        color="error"
        class="ma-2 px-4"
        large
        text
        :disabled="isLoading"
        :loading="isReversingCheckout"
        @click="reverseCheckout"
      >
        Ångra utcheckning
      </v-btn>
      <v-btn
        v-else-if="isCheckedIn"
        color="error"
        class="ma-2 px-4"
        large
        text
        :disabled="isLoading"
        :loading="isReversingCheckin"
        @click="reverseCheckin"
      >
        Ångra incheckning
      </v-btn>
      <template v-else-if="isDeleted">
        <v-tooltip
          :value="showCancellationStatusText"
          top
          content-class="error tooltip-top"
          nudge-bottom
          :open-on-click="false"
          :open-on-focus="false"
          :open-on-hover="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="secondary"
              class="ma-2 px-4"
              large
              text
              :disabled="isLoading"
              @click="openBookingCancellation"
            >
              Visa avbokning
            </v-btn>
          </template>
          <div>
            <div @click="cancellationTooltipClosed = true">{{ booking.cancellation_status }}</div>
          </div>
        </v-tooltip>
      </template>
      <v-btn
        v-else
        color="error"
        class="ma-2 px-4"
        large
        text
        :disabled="isLoading"
        :loading="isCancellingBooking"
        @click="cancelBooking"
      >
        Avboka
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasNotCheckedIn && !isDeleted"
        color="success"
        class="ma-2 px-4"
        large
        :disabled="isLoading"
        @click="checkInBooking"
      >
        Checka in
      </v-btn>
      <v-btn
        v-if="isCheckedIn"
        color="success"
        class="ma-2 px-4"
        large
        :text="!shouldCheckOutToday"
        :disabled="isLoading"
        @click="checkOutBooking"
      >
        Påbörja utcheckning
      </v-btn>

      <v-btn
        color="secondary"
        class="ma-2 ml-auto px-4"
        large
        :disabled="isLoading"
        @click="openBookingInRegister"
      >
        Öppna i kassan
      </v-btn>
    </v-card-actions>
  </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex'

import AccommodationPriceCard from './AccommodationPriceCard.vue'
import BookingDataList from './DataList.vue'
import BookingInputRemarks from './InputRemarks.vue'
import EditingRecordTimeline from '@/components/common/EditingRecordTimeline.vue'
import ChargesTable from '@/components/product/ChargesTable.vue'
import InvoiceList from '@/components/invoice/List.vue'
import ReceiptList from '@/components/receipt/List.vue'
import EmailTimeline from '@/components/email/Timeline.vue'
import TallyOrder from '@/components/tally/Order.vue'
import AccommodationChargesCalendar from './AccommodationChargesCalendar.vue'

export default {
  name: 'BookingDialogContent',
  components: {
    AccommodationPriceCard,
    BookingDataList,
    BookingInputRemarks,
    EditingRecordTimeline,
    ChargesTable,
    InvoiceList,
    ReceiptList,
    EmailTimeline,
    TallyOrder,
    AccommodationChargesCalendar
  },
  props: {
    booking: Object,
    charges: Array,
    invoices: Array,
    receipts: Array,
    emails: Array,
    editingRecords: Array,
    accommodationChargesCalendarItems: Array,
    isLoading: Boolean,
    isOverlay: Boolean,
    isOpen: Boolean
  },
  data: () => ({
    cancellationTooltipClosed: false
  }),
  computed: {
    ...mapState(
      {
        fetchingChunks: state => state.booking.dialog.fetchingChunks,
        openDialogTab: state => state.booking.dialog.openTab,
        openOverlayTab: state => state.customer.dialog.bookingOverlay.openTab,
        triggerInvoiceUpdated: state => state.invoice.triggerInvoiceUpdated,
        isCancellingBookingIds: state => state.booking.isCancellingBookingIds,
        isSendingConfirmationBookingIds: state => state.booking.isSendingConfirmationBookingIds,
        isReversingCheckinBookingIds: state => state.booking.isReversingCheckinBookingIds,
        isReversingCheckoutBookingIds: state => state.booking.isReversingCheckoutBookingIds,
        triggerBookingUpdated: state => state.booking.triggerBookingUpdated,
        triggerAccommodationChargesUpdated: state => state.booking.triggerAccommodationChargesUpdated,
        triggerChargesUpdated: state => state.booking.triggerChargesUpdated
      }
    ),
    openTab: function () {
      if (this.isOverlay) {
        return this.openOverlayTab
      }
      return this.openDialogTab
    },
    tabIndex: {
      get () {
        return this.openTab.tabIndex
      },
      set (val) {
        if (this.isOverlay) {
          this.$store.commit('customer/SET_BOOKING_OVERLAY_OPEN_TAB', { tabIndex: val })
        } else {
          this.$store.commit('booking/SET_DIALOG_OPEN_TAB', { tabIndex: val })
        }
      }
    },
    subTabIndexCharges: {
      get: function () {
        if (this.subTabIndex !== 'accommodation-charges' && this.subTabIndex !== 'other-charges') {
          return 'accommodation-charges'
        }
        return this.subTabIndex
      },
      set: function (val) {
        if (this.tabIndex === 'charges') {
          this.subTabIndex = val
        }
      }
    },
    subTabIndexInvoicesReceipts: {
      get: function () {
        if (this.subTabIndex !== 'receipts' && this.subTabIndex !== 'invoices') {
          return this.defaultSubTab
        }
        return this.subTabIndex
      },
      set: function (val) {
        if (this.tabIndex === 'invoices-receipts') {
          this.subTabIndex = val
        }
      }
    },
    subTabIndex: {
      get () {
        return this.openTab.subTabIndex
      },
      set (val) {
        if (this.isOverlay) {
          this.$store.commit('customer/SET_BOOKING_OVERLAY_OPEN_TAB', { subTabIndex: val })
        } else {
          this.$store.commit('booking/SET_DIALOG_OPEN_TAB', { subTabIndex: val })
        }
      }
    },
    defaultSubTab: function () {
      if (this.invoices.length === 0) {
        return 'receipts'
      }
      if (this.receipts.length === 0) {
        return 'invoices'
      }
      const mostRecentReceipt = this.receipts[this.receipts.length - 1].created_at
      const mostRecentInvoice = this.invoices[this.invoices.length - 1].created_at
      if (mostRecentInvoice.isAfter(mostRecentReceipt)) {
        return 'invoices'
      }
      return 'receipts'
    },
    isCancellingBooking: function () {
      return this.booking && this.isCancellingBookingIds.includes(this.booking.id)
    },
    isReversingCheckin: function () {
      return this.booking && this.isReversingCheckinBookingIds.includes(this.booking.id)
    },
    isReversingCheckout: function () {
      return this.booking && this.isReversingCheckoutBookingIds.includes(this.booking.id)
    },
    isDeleted: function () {
      return this.booking && !!this.booking.deleted_at
    },
    disableSendingBookingConfirmation: function () {
      return this.isCancellingBooking || this.isDeleted || this.isCheckedOut
    },
    isSeasonBooking: function () {
      return this.booking && this.booking.type === 'season'
    },
    isSendingBookingConfirmation: function () {
      return this.booking && this.isSendingConfirmationBookingIds.includes(this.booking.id)
    },
    isCheckedIn: function () {
      if (!this.booking) {
        return false
      }
      return this.booking.checked_in_at !== null && this.booking.checked_out_at === null
    },
    isCheckedOut: function () {
      if (!this.booking) {
        return false
      }
      return this.booking.checked_out_at !== null
    },
    shouldCheckOutToday: function () {
      const today = window.dayjs().startOf('day')
      return this.isCheckedIn && this.booking.check_out.isSameOrBefore(today)
    },
    hasNotCheckedIn: function () {
      if (!this.booking) {
        return false
      }
      return this.booking.checked_in_at === null
    },
    chargesCount: function () {
      if (!this.charges || this.charges.length === 0) {
        return ''
      }
      if (this.charges.length > 99) {
        return '99+'
      }
      return this.charges.length
    },
    receiptCount: function () {
      if (!this.receipts || this.receipts.length === 0) {
        return ''
      }
      if (this.receipts.length > 99) {
        return '99+'
      }
      return this.receipts.length
    },
    invoiceCount: function () {
      if (!this.invoices || this.invoices.length === 0) {
        return ''
      }
      if (this.invoices.length > 99) {
        return '99+'
      }
      return this.invoices.length
    },
    receiptsAndInvoicesCount: function () {
      if (!this.receipts || !this.invoices || (this.invoices.length + this.receipts.length) === 0) {
        return ''
      }
      const count = this.receipts.length + this.invoices.length
      if (count > 99) {
        return '99+'
      }
      return count
    },
    emailsCount: function () {
      if (!this.emails || this.emails.length === 0) {
        return ''
      }
      if (this.emails.length > 99) {
        return '99+'
      }
      return this.emails.length
    },
    hasAccommodationChargeWarnings: function () {
      if (!this.accommodationChargesCalendarItems || this.accommodationChargesCalendarItems.length === 0) {
        return false
      }
      return this.accommodationChargesCalendarItems.findIndex(ac => !!ac && !!ac.suggested_accommodation_charge) >= 0
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },
    title: function () {
      return this.booking && this.booking.title
    },
    subtitle: function () {
      return this.booking && this.booking.subtitle
    },
    showCancellationStatusText: function () {
      return ['Inväntar dokument', 'Oavslutad'].includes(this.booking?.cancellation_status) && !this.cancellationTooltipClosed && this.isOpen && !this.isMobile && this.fetchingChunks.length === 0
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    openBookingInRegister: function () {
      this.$store.dispatch('closeDialogs')
      this.$router.push({ name: 'PosRegister', params: { chargable: 'booking', id: this.booking.id } })
      this.close() // behövs för om detta är en booking overlay i en customer dialog så stängs den inte med closeDialogs, så den är öppen nästa gång customer dialog öppnas
    },
    checkOutBooking: function () {
      this.$store.dispatch('closeDialogs')
      this.$store.dispatch('booking/startCheckOut', { booking: this.booking })
      this.close() // behövs för om detta är en booking overlay i en customer dialog så stängs den inte med closeDialogs, så den är öppen nästa gång customer dialog öppnas
    },
    checkInBooking: function () {
      this.$store.dispatch('closeDialogs')
      this.$store.dispatch('booking/checkIn', this.booking.id)
      this.close() // behövs för om detta är en booking overlay i en customer dialog så stängs den inte med closeDialogs, så den är öppen nästa gång customer dialog öppnas
    },
    cancelBooking: function () {
      const bookingId = this.booking.id
      this.cancellationTooltipClosed = true // vill inte visa denna tooltip direkt vid avbokning
      this.$store.commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: `Avboka ${this.booking.title}`,
        body: 'Är du säker på att du vill avboka den här bokningen?',
        confirmationFunction: 'booking/cancel',
        confirmationArgument: bookingId
      })
    },
    reverseCheckin: function () {
      if (!this.isCheckedIn) {
        return false
      }
      this.$store.dispatch('booking/reverseCheckin', this.booking.id)
    },
    reverseCheckout: function () {
      if (!this.isCheckedOut) {
        return false
      }
      this.$store.dispatch('booking/reverseCheckout', this.booking.id)
    },
    openBookingCancellation: function () {
      this.cancellationTooltipClosed = true
      this.$store.dispatch('cancellationPolicy/openBookingCancellationDialog', { bookingId: this.booking.id })
    },
    sendBookingConfirmation: function () {
      this.$store.dispatch('booking/sendBookingConfirmation', { bookingId: this.booking.id })
    },
    remarksUpdated: function ({ bookingId, remarks }) {
      if (this.booking && this.booking.id === bookingId) {
        this.$store.commit('booking/SET_DIALOG_BOOKING_REMARKS', remarks)
      }
      if (this.booking && this.booking.id === bookingId && !this.fetchingChunks.includes('editing-records')) {
        this.$store.dispatch('booking/getBookingEditingRecords', this.booking.id)
      }
    },
    editBooking: function () {
      this.$store.dispatch('booking/startEditBooking', this.booking)
    }
  },
  watch: {
    triggerInvoiceUpdated: function () {
      // variabel som uppdateras efter fakturaändringar
      if (this.isOpen && this.booking && this.booking.id) {
        this.$store.dispatch('booking/getBookingInvoices', this.booking.id)
      }
    },
    triggerBookingUpdated: function () {
      // De flesta booking ändringar leder till nya editingRecords, hämtar om
      if (this.isOpen && this.booking && this.booking.id && !this.fetchingChunks.includes('editing-records')) {
        this.$store.dispatch('booking/getBookingEditingRecords', this.booking.id)
      }
    },
    triggerAccommodationChargesUpdated: function () {
      // Direka ändringar i accommodation charges triggar denna
      if (this.isOpen && this.booking && this.booking.id) {
        if (!this.fetchingChunks.includes('editing-records')) {
          this.$store.dispatch('booking/getBookingEditingRecords', this.booking.id)
        }
        if (!this.fetchingChunks.includes('accommodation-charges-calendar-items')) {
          this.$store.dispatch('booking/getBookingCalendarItems', this.booking.id)
        }
      }
    },
    triggerChargesUpdated: function () {
      if (this.isOpen && this.booking && this.booking.id) {
        this.$store.dispatch('booking/getBookingCharges', this.booking.id)
      }
    },
    isOpen: function () {
      if (!this.isOpen) {
        this.cancellationTooltipClosed = false
      }
    }
  }
}
</script>
