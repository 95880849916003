<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="420"
  >
    <v-card>
      <v-card-title class="text-h5" style="word-break: keep-all;">
        {{ confirmationDialog.header }}
      </v-card-title>
      <v-card-text>{{ confirmationDialog.body }}</v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="show = false"
          :disabled="confirmationDialog.disableCancel"
        >
          Avbryt
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          :disabled="timeRemaining > 0"
          @click="confirm"
        >
          <span v-if="timeRemaining > 0" class="mr-2">({{ timeRemaining }})</span>
          <span>
            Bekräfta
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ConfirmationDialog',
  data: () => ({
    endOfTimer: null
  }),
  computed: {
    ...mapState(
      {
        confirmationDialog: state => state.confirmationDialog,
        now: state => state.now
      }
    ),
    show: {
      get () {
        return this.confirmationDialog.show
      },
      set (value) {
        if (!value) {
          this.$store.commit('SET_CLOSE_CONFIRMATION_DIALOG')
        }
      }
    },
    timeRemaining () {
      if (this.confirmationDialog.withTimer && this.endOfTimer) {
        const diff = this.endOfTimer.diff(this.now, 'seconds')
        return diff || 0
      }
      return 0
    }
  },
  methods: {
    confirm: function () {
      if ((typeof this.confirmationDialog.confirmationFunction) === 'function') {
        this.confirmationDialog.confirmationFunction(this.confirmationDialog.confirmationArgument)
        this.show = false
      } else {
        this.$store.dispatch(this.confirmationDialog.confirmationFunction, this.confirmationDialog.confirmationArgument)
        this.show = false
      }
    }
  },
  watch: {
    'confirmationDialog.withTimer': function () {
      if (this.confirmationDialog.withTimer) {
        this.endOfTimer = window.dayjs().add(10, 'seconds')
      }
    }
  },
  mounted () {

  }
}
</script>
