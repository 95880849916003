import CancellationPolicyService from '@/services/CancellationPolicyService.js'

export const namespaced = true

export const state = {
  isFetchingCancellationPolicies: false,
  cancellationPolicies: [],
  bookingCancellationDialog: {
    show: false,
    isSelectingOptionName: '',
    isFetchingBookingCancellation: false,
    isFetchingPreviewData: false,
    isFetchingSpecificationList: false,
    isCompleting: false,
    isSendingCancellationConfirmation: false,
    bookingCancellation: null,
    previewData: null,
    specificationList: null, // data till manual_page
    showSuccessAlert: false,
    hasError: false
  }
}

export const mutations = {
  SET_IS_FECTHING_CANCELLATION_POLICIES (state, val) {
    state.isFetchingCancellationPolicies = val
  },
  SET_CANCELLATION_POLICIES (state, val) {
    state.cancellationPolicies = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_SHOW (state, val) {
    state.bookingCancellationDialog.show = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_BOOKING_CANCELLATION (state, val) {
    state.bookingCancellationDialog.isFetchingBookingCancellation = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_SELECTING_OPTION_NAME (state, val) {
    state.bookingCancellationDialog.isSelectingOptionName = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_PREVIEW_DATA (state, val) {
    state.bookingCancellationDialog.isFetchingPreviewData = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_SPECIFICATION_LIST (state, val) {
    state.bookingCancellationDialog.isFetchingSpecificationList = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR (state, val) {
    state.bookingCancellationDialog.hasError = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION (state, val) {
    state.bookingCancellationDialog.bookingCancellation = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_PREVIEW_DATA (state, val) {
    state.bookingCancellationDialog.previewData = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_SPECIFICATION_LIST (state, val) {
    state.bookingCancellationDialog.specificationList = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_SHOW_SUCCESS_ALERT (state, val) {
    state.bookingCancellationDialog.showSuccessAlert = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_COMPLETING (state, val) {
    state.bookingCancellationDialog.isCompleting = val
  },
  SET_BOOKING_CANCELLATION_DIALOG_IS_SENDING_CANCELLATION_CONFIRMATION (state, val) {
    state.bookingCancellationDialog.isSendingCancellationConfirmation = val
  }
}

export const actions = {
  fetchCancellationPolicies ({ commit }) {
    commit('SET_IS_FECTHING_CANCELLATION_POLICIES', true)
    return CancellationPolicyService.getCancellationPolicies()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_CANCELLATION_POLICIES', data.data.cancellation_policies)
        }
      })
      .finally(() => {
        commit('SET_IS_FECTHING_CANCELLATION_POLICIES', false)
      })
  },
  openBookingCancellation ({ commit, dispatch }, { bookingCancellation, showSuccessAlert = false }) {
    // öppna med bookingCancellation (direkt efter avbokning)
    dispatch('resetBookingCancellationDialog')
    commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW', true)
    commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', bookingCancellation)
    commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW_SUCCESS_ALERT', showSuccessAlert)
  },
  resetBookingCancellationDialog ({ commit }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW', false)
    commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', false)
    commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', null)
    commit('SET_BOOKING_CANCELLATION_DIALOG_PREVIEW_DATA', null)
    commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW_SUCCESS_ALERT', false)
  },
  openBookingCancellationDialog ({ commit, dispatch }, { bookingId }) {
    // öppna med booking id
    dispatch('resetBookingCancellationDialog')

    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_BOOKING_CANCELLATION', true)
    commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW', true)

    return CancellationPolicyService.getBookingCancellation({ bookingId })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', data.data.booking_cancellation)
        } else {
          commit('SET_BOOKING_CANCELLATION_DIALOG_SHOW', false) // Har ingen error handling i dialogen så stänger dialogen igen och visar snackbaren från server bara
        }
      })
      .catch(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
      })
      .finally(() => {
        setTimeout(() => {
          // visar loader 500ms till efter bookingCancellation hämtats för att låta dialogen byta tab med animation
          commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_BOOKING_CANCELLATION', false)
        }, 500)
      })
  },
  bookingCancellationSelectOption ({ commit }, { bookingCancellationId, selectedOption }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_SELECTING_OPTION_NAME', selectedOption.option_name)
    return CancellationPolicyService.bookingCancellationSelectOption({ bookingCancellationId, selectedOption })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', data.data.booking_cancellation)
          commit('booking/TRIGGER_BOOKING_UPDATED', undefined, { root: true })
        } else {
          commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
        }
      })
      .catch(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
      })
      .finally(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_IS_SELECTING_OPTION_NAME', '')
      })
  },
  getBookingCancellationPreview ({ commit }, { bookingCancellationId, manualSpecification = null }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_PREVIEW_DATA', true)
    return CancellationPolicyService.getBookingCancellationPreview({ bookingCancellationId, manualSpecification })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_PREVIEW_DATA', data.data.preview_data)
        } else {
          commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
        }
      })
      .catch(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
      })
      .finally(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_PREVIEW_DATA', false)
      })
  },
  getBookingCancellationSpecificationList ({ commit }, { bookingCancellationId }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_SPECIFICATION_LIST', true)
    return CancellationPolicyService.getBookingCancellationManualSpecification({ bookingCancellationId })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_SPECIFICATION_LIST', data.data.specification_list)
        } else {
          commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
        }
      })
      .catch(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
      })
      .finally(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_IS_FETCHING_SPECIFICATION_LIST', false)
      })
  },
  completeCancellation ({ commit }, { bookingCancellationId, preview }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_COMPLETING', true)
    return CancellationPolicyService.bookingCancellationCompleteCancellation({
      bookingCancellationId,
      preview
    })
      .then(({ data }) => {
        console.log('bookingCancellationCompleteCancellation', data)
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', data.data.booking_cancellation)
          commit('invoice/TRIGGER_INVOICE_UPDATED', undefined, { root: true })
          commit('booking/TRIGGER_BOOKING_UPDATED', undefined, { root: true })
          commit('booking/TRIGGER_ACCOMMODATION_CHARGES_UPDATED', undefined, { root: true })
          commit('booking/TRIGGER_CHARGES_UPDATED', undefined, { root: true })
        }
      })
      .finally(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_IS_COMPLETING', false)
      })
  },
  sendCancellationConfirmation ({ commit, dispatch }, { bookingCancellationId }) {
    commit('SET_BOOKING_CANCELLATION_DIALOG_IS_SENDING_CANCELLATION_CONFIRMATION', true)
    return CancellationPolicyService.sendCancellationConfirmation({ bookingCancellationId })
      .then(({ data }) => {
        console.log('sendCancellationConfirmation', data)
        if (data.status === 'success') {
          commit('booking/SET_IS_AWAITING_EMAIL_SAVED_BOOKING_ID', { bookingId: data.data.booking_id, val: true }, { root: true })
          dispatch('resetBookingCancellationDialog')
          dispatch('booking/openDialogById', { bookingId: data.data.booking_id, startTab: 'emails' }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_IS_SENDING_CANCELLATION_CONFIRMATION', false)
      })
  },
  confirmDocumentRequest ({ commit }, { bookingCancellationId, hasValidDocument }) {
    return CancellationPolicyService.confirmDocumentRequest({
      bookingCancellationId,
      hasValidDocument
    })
      .then(({ data }) => {
        console.log('confirmDocumentRequest', data)
        if (data.status === 'success') {
          commit('SET_BOOKING_CANCELLATION_DIALOG_PREVIEW_DATA', data.data.preview_data)
          commit('booking/TRIGGER_BOOKING_UPDATED', undefined, { root: true })
          commit('SET_BOOKING_CANCELLATION_DIALOG_BOOKING_CANCELLATION', data.data.booking_cancellation)
        } else {
          commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
        }
      })
      .catch(() => {
        commit('SET_BOOKING_CANCELLATION_DIALOG_HAS_ERROR', true)
      })
  }
}

export const getters = {}
