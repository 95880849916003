import API from './API.js'

export default {
  //
  // GET
  //
  getPrinters () {
    return API.httpClient.get('printer')
  }

  //
  // POST
  //
}
