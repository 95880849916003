<template>
  <div class="">
    <v-row>
      <v-col class="">
        <v-select
          v-model="selection"
          :items="filteredSeasons"
          item-text="full_name"
          label="Säsong"
          return-object
          :outlined="outlined"
          hide-details="auto"
          :loading="isFetchingSeasons"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.item.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SeasonPicker',
  props: {
    outlined: Boolean,
    selectedSeasonId: Number,
    fromYear: Object
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        seasons: state => state.season.seasons,
        isFetchingSeasons: state => state.season.isFetchingSeasons
      }
    ),
    filteredSeasons: function () {
      // visar bara årets säsonger, eller om är en bokning från tidigare år
      let startDate = window.dayjs().startOf('year')
      if (this.fromYear && this.fromYear.isBefore(startDate)) {
        startDate = this.fromYear.startOf('year')
      }
      const seasons = this.seasons.filter(season => season.end.isAfter(startDate) || season.id === this.selectedSeasonId)

      // sorterar så säsonger som redan har inletts kommer sist i listan, och i andrahand sorterar på startdatum
      seasons.sort((a, b) => {
        // b före a = 1, a före b = -1
        if (a.start.isAfter(startDate) && !b.start.isAfter(startDate)) {
          return -1
        }
        return a.start.isBefore(b.start) ? -1 : 1
      })
      return seasons
    },
    selection: {
      get: function () {
        const season = this.seasons.find(s => s.id === this.selectedSeasonId)
        if (!season) {
          this.$emit('set-season', this.filteredSeasons[0])
          return this.filteredSeasons[0]
        }
        return season
      },
      set: function (val) {
        this.$emit('set-season', val)
      }
    }
  },
  methods: {},
  mounted () {}
}
</script>
