<template>
  <v-dialog
    v-model="show"
    max-width="1200"
  >
    <v-card class="dashboard-card-picker-dialog">
      <v-card-title class="pb-4" style="word-break: keep-all;">
        Välj ett kort
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <div class="px-6">
          <div class="pl-2 text-subtitle-2">Filter</div>
          <v-chip-group
            v-model="selectedTags"
            multiple
            column
            color="secondary"
          >
            <v-chip
              v-for="tag in uniqueTags"
              :key="tag.id"
              :value="tag.id"
              filter
              outlined
            >
              {{ tag.name }}
              <span class="ml-1">({{ countCardsWithTag(tag.id) }})</span>
            </v-chip>
          </v-chip-group>
        </div>
        <div style="max-height: 60vh; overflow-y: auto;">
          <v-sheet class="pb-8 px-6 pb-6">
            <div class="masonry-layout">
              <div v-for="card in filteredCards" :key="card.id" class="full-width py-3 dashboard-card-container">
                <dashboard-card :component-name="card.component_name"></dashboard-card>
                <div class="dashboard-card-overlay" @click="selectCard(card)"></div>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import DashboardCard from '@/components/dashboard/DashboardCard'

export default {
  name: 'DashboardCardPickerDialog',
  components: {
    DashboardCard
  },
  props: {
    value: Boolean
  },
  data: () => ({
    selectedTags: []
  }),
  computed: {
    ...mapState(
      {
        dashboardCards: state => state.stats.dashboardCards
      }
    ),
    show: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    },
    filteredCards: function () {
      if (this.selectedTags.length === 0) {
        return this.dashboardCards
      }
      return this.dashboardCards.filter(dbc => dbc.tags.some(tag => this.selectedTags.includes(tag.id)))
    },
    uniqueTags: function () {
      if (!this.dashboardCards) {
        return []
      }
      // slår ihop alla tags arrayen till en array
      let tags = this.dashboardCards.flatMap(dbc => dbc.tags)
      // filtrerar så tags bara innehåller unika taggar
      tags = tags.filter((tag, i, self) => {
        return self.findIndex(x => x.id === tag.id) === i
      })
      return tags
    }
  },
  methods: {
    countCardsWithTag: function (tagId) {
      return this.dashboardCards.filter(dbc => dbc.tags.some(tag => tag.id === tagId)).length
    },
    selectCard: function (card) {
      this.$emit('selected-card', card)
    }
  },
  mounted () {}
}
</script>
