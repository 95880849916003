<template>
  <v-card class="receipt-list" :flat="flat">
    <v-card-title v-if="showTitle" :class="{ 'py-2': shrinkWithoutReceipts && !hasReceipts, 'pb-0 pt-2': denseHeader && !(shrinkWithoutReceipts && !hasReceipts) }">
      <div class="card-title">Kvitton</div>
      <template v-if="loading">
        <v-progress-circular
          indeterminate
          size="40"
          class="ml-4"
          color="primary"
        ></v-progress-circular>
      </template>
      <template v-else>
        <v-alert
          v-show="shrinkWithoutReceipts && !hasReceipts"
          dense
          text
          color="info"
          class="ma-0 ml-4 py-1"
        >
          Det finns inga kvitton
        </v-alert>
      </template>
    </v-card-title>
    <v-data-table
      v-show="hasReceipts || !shrinkWithoutReceipts"
      :headers="headers"
      fixed-header
      :items="items"
      :loading="loading"
      hide-default-footer
      disable-filtering
      disable-pagination
    >
      <template v-slot:body="{ items }">
        <tbody>
          <div class="tbody-inner-container" :style="`height: ${scrollableTableHeight}; min-height: ${scrollableTableHeight}; max-height: ${scrollableTableHeight}`">
            <template v-if="loading">
              <v-simple-table class="skeleton-loader-table">
                <tr v-for="i in 2" :key="i">
                  <td v-for="(header, x) in headers" :key="x" :class="{ 'text-left': header.align === 'start', 'text-right': header.align === 'end' }">
                    <v-skeleton-loader
                      style=""
                      type="table-cell"
                      class=""
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </v-simple-table>
            </template>
            <template v-else-if="items.length === 0">
              <v-alert
                dense
                text
                color="info"
                class="ma-10 text-center"
              >
                Det finns inga kvitton att visa
              </v-alert>
            </template>

            <tr v-for="receipt in items" :key="receipt.id" @click="openReceipt(receipt)">
              <td v-if="displayedColumns.includes('receipt_number')">
                {{ receipt.receipt_serial }}
              </td>
              <td v-if="displayedColumns.includes('total')">
                {{ receipt.totalFormatted }}
              </td>
              <td v-if="displayedColumns.includes('created_at')">
                {{ receipt.createdAtFormatted }}
              </td>
            </tr>
          </div>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {
  name: 'ReceiptList',
  components: {},
  props: {
    receipts: Array,
    loading: Boolean,
    showTitle: Boolean,
    denseHeader: Boolean,
    flat: Boolean,
    shrinkWithoutReceipts: Boolean,
    displayedItems: { // antal kvitton som visas innan det blir scroll, 0 för obegränsat
      type: Number,
      default: function () { return 0 }
    },
    displayedColumns: {
      type: Array,
      default: function () { return ['receipt_number', 'total', 'created_at'] } // möjliga värden: receipt_number, total, created_at
    }
  },
  data: () => ({}),
  computed: {
    headers: function () {
      const columns = []
      if (this.displayedColumns.includes('receipt_number')) {
        columns.push({ text: 'Kvittonummer', align: 'start', sortable: false })
      }
      if (this.displayedColumns.includes('total')) {
        columns.push({ text: 'Belopp', align: 'start', sortable: false })
      }
      if (this.displayedColumns.includes('created_at')) {
        columns.push({ text: 'Skapad', align: 'start', sortable: false })
      }
      return columns
    },
    items: function () {
      return this.receipts.map(receipt => {
        receipt.totalFormatted = receipt.total / 100 + ' kr'
        receipt.createdAtFormatted = receipt.created_at.format('D MMM YYYY HH:mm')
        return receipt
      })
    },
    hasReceipts: function () {
      return this.receipts.length > 0
    },
    scrollableTableHeight: function () {
      if (this.displayedItems === 0) {
        return 'auto'
      }
      const requiredHeight = this.receipts.length * 48
      const maxHeight = this.displayedItems * 48
      let height = Math.min(requiredHeight, maxHeight)
      if (requiredHeight > maxHeight) {
        height += 24 // lägger till en halv rad så man tydligt ser att det finns fler kvitton att scrolla ner till
      }
      return `${height}px`
    }
  },
  methods: {
    openReceipt: function (receipt) {
      this.$store.commit('posReceipt/SET_SHOW_DIALOG', false) // stänger dialog först, om dialogen redan skulle vara öppen i bakgrunden så flyttas den inte till förgrunden annars
      this.$nextTick(() => {
        this.$store.dispatch('posReceipt/openDialogById', { receiptId: receipt.id })
      })
    }
  }
}
</script>
