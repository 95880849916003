<template>
  <div class="full-width">
    <v-slide-x-transition hide-on-leave>
      <div v-show="!inputModeAmount" :class="{ 'd-flex flex-grow-1': !inputModeAmount }">
        <v-btn
          fab
          small
          color="secondary"
          text
          @click="refundPercent = 0"
        >
          0%
        </v-btn>
        <v-slider
          v-model="refundPercentRounded"
          color="secondary"
          class="refund-slider my-auto"
          hide-details
          step="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ value }}%
          </template>
        </v-slider>
        <v-btn
          fab
          small
          color="secondary"
          text
          @click="refundPercent = 100"
        >
          100%
        </v-btn>
      </div>
    </v-slide-x-transition>
    <v-slide-x-transition hide-on-leave>
      <div v-show="inputModeAmount" :class="{ 'd-flex flex-grow-1': inputModeAmount }">
        <v-text-field
          v-model="refundAmount"
          type="number"
          outlined
          dense
          hide-details
          append-icon="coi-currency-sek"
        ></v-text-field>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>

export default {
  name: 'RefundInput',
  components: {},
  props: {
    productCategory: Object,
    inputModeAmount: Boolean
  },
  data: () => ({}),
  computed: {
    refundPercentRounded: {
      get: function () {
        return Math.round(this.refundPercent)
      },
      set: function (val) {
        this.refundPercent = val
      }
    },
    refundPercent: {
      get: function () {
        return this.productCategory.refund_percent
      },
      set: function (val) {
        this.$emit('set-refund-percent', { productCategory: this.productCategory, val })
      }
    },
    refundAmount: {
      get: function () {
        return this.productCategory.refund_amount / 100
      },
      set: function (val) {
        val = val * 100
        this.$emit('set-refund-amount', { productCategory: this.productCategory, val })
      }
    }
  },
  methods: {},
  watch: {}
}
</script>
