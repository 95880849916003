<template>
  <div class="invoice-dialog">
    <v-card class="flex-column full-height" tile :loading="isLoading ? 'secondary' : false">
      <v-toolbar
        style="height: auto;"
        elevation="0"
        class="py-3 toolbar-with-subheader flex-grow-0"
        color="white"
      >
        <div class="toolbar-titles-container overflow-hidden">
          <template v-if="isLoading">
            <v-toolbar-title class="pl-5 mt-1">
              <v-skeleton-loader
                type="heading"
                style="width: 200px; max-width: 200px;"
              ></v-skeleton-loader>
            </v-toolbar-title>
            <v-toolbar-title class="toolbar-subheader pl-5 mt-2">
              <v-skeleton-loader
                type="text"
                style="width: 120px; max-width: 120px;"
              ></v-skeleton-loader>
            </v-toolbar-title>
          </template>
          <template v-else>
            <v-toolbar-title class="pl-5">{{ title }}</v-toolbar-title>
            <v-toolbar-title class="pl-5 toolbar-subheader">{{ subtitle }}</v-toolbar-title>
          </template>
        </div>
        <v-spacer></v-spacer>
        <print-button
          v-if="!isMobile"
          button-text="Skriv ut fakturan"
          :print-object="invoice?.print"
          icon
        ></print-button>
        <download-button
          v-if="!isMobile"
          button-text="Ladda ner fakturan"
          :url="invoice?.url"
          icon
        ></download-button>
        <v-btn icon v-if="isMobile" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs
        v-model="tabIndex"
        color="secondary"
        show-arrows
        grow
      >
        <v-tab href="#invoice-information">
          Information
        </v-tab>
        <v-tab href="#sales-items">
          Innehåll
        </v-tab>
        <v-tab href="#payments">
          <!-- Fakturahändelser -->
          Betalningar & Krediteringar
        </v-tab>
        <v-tab href="#emails">
          <v-badge
            :value="emailsCount !== ''"
            color="secondary"
            :content="emailsCount"
            offset-x="-2"
            offset-y="8"
          >
          <span>Email</span>
        </v-badge>
      </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabIndex">
        <v-tab-item value="invoice-information">
          <v-sheet class="flex-grow-1">
            <invoice-data-list
              :is-loading="isLoading"
              :invoice="invoice"
              :hidden-sections="hiddenDataListSections"
              customer-clickable
              booking-clickable
              @close="close"
            ></invoice-data-list>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="sales-items">
          <v-sheet class="flex-grow-1">
            <sales-items-table :sales-items="salesItems"></sales-items-table>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="payments">
          <v-sheet class="flex-grow-1 overflow-x-hidden">
            <payments-timeline
              :payment-applications="paymentApplications"
              :credit-applications="creditApplications"
              :invoice-id="invoice?.id"
              :allow-crediting="!isCreditInvoice"
              :allow-convert-to-customer-credit="allowConvertToCustomerCredit"
              @start-register-payment="startRegisterPayment"
              @start-register-credit="startRegisterCredit"
            ></payments-timeline>
          </v-sheet>
        </v-tab-item>
        <v-tab-item value="emails">
          <v-sheet
            class="overflow-y-auto pa-3 full-height"
          >
            <email-timeline
              :emails="emails"
              :invoice-id="invoice?.id"
              :is-loading="isLoading"
              no-emails-message="Inga email har skickats"
            >
              <template v-slot:timeline-actions>
                <v-timeline-item
                  fill-dot
                  color="success"
                >
                  <template v-slot:icon>
                    <v-icon color="white">mdi-email-fast</v-icon>
                  </template>
                  <v-btn
                    color="success"
                    @click="resendInvoice"
                    :loading="isSendingInvoice"
                    :class="{ 'btn-two-rows': isMobile }"
                  >
                    Skicka fakturakopia
                  </v-btn>
                </v-timeline-item>
              </template>
            </email-timeline>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="mt-auto flex-grow-0 pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="startInvoiceCheckout"
          :disabled="isLoading"
        >
          {{ startPaymentButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- register-payment overlay -->
    <div class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
      <v-fade-transition>
        <div v-show="isShowRegisterPaymentOverlay" @click="closeRegisterPaymentOverlay" style="height: 76px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
      </v-fade-transition>
      <v-scroll-y-reverse-transition>
        <v-card v-show="isShowRegisterPaymentOverlay" class="flex-grow-1" light style="pointer-events: auto; margin-top: -12px;">
          <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
            <div class="d-flex">
              <v-toolbar-title class="headline">{{ registerPaymentTitle }}</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeRegisterPaymentOverlay">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <div class="flex-grow-1 pt-3" style="height: calc(100% - 64px);">
            <register-payment-overlay
              :is-open="isShowRegisterPaymentOverlay"
              :invoice="invoice"
              @close="closeRegisterPaymentOverlay"
            ></register-payment-overlay>
          </div>
        </v-card>
      </v-scroll-y-reverse-transition>
    </div>

    <!-- invoice-checkout overlay -->
    <div class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
      <v-fade-transition>
        <div v-show="isShowInvoiceCheckoutOverlay" @click="closeInvoiceCheckoutOverlay" style="height: 76px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
      </v-fade-transition>
      <v-scroll-y-reverse-transition>
        <v-card v-show="isShowInvoiceCheckoutOverlay" class="flex-grow-1" light style="pointer-events: auto; margin-top: -12px;">
          <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
            <div class="d-flex">
              <v-toolbar-title class="headline">{{ invoiceCheckoutTitle }}</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeInvoiceCheckoutOverlay">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <div class="flex-grow-1 pt-3" style="height: calc(100% - 64px);">
            <invoice-checkout-overlay
              :is-open="isShowInvoiceCheckoutOverlay"
              :invoice="invoice"
              @close="closeInvoiceCheckoutOverlay"
            ></invoice-checkout-overlay>
          </div>
        </v-card>
      </v-scroll-y-reverse-transition>
    </div>

    <!-- register-credit overlay -->
    <div class="d-flex flex-column overflow-hidden" style="position: absolute; height: 100%; width: 100%; top: 0; left: 0; pointer-events: none; z-index: 2;">
      <v-fade-transition>
        <div v-show="isShowRegisterCreditOverlay" @click="closeRegisterCreditOverlay" style="height: 76px; opacity: 0.23; background-color: rgb(33, 33, 33); pointer-events: auto;"></div>
      </v-fade-transition>
      <v-scroll-y-reverse-transition>
        <v-card v-show="isShowRegisterCreditOverlay" class="flex-grow-1" light style="pointer-events: auto; margin-top: -12px;">
          <v-toolbar class="flex-shrink-1 flex-grow-0" flat>
            <div class="d-flex">
              <v-toolbar-title class="headline">Kreditera Faktura</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeRegisterCreditOverlay">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <div class="flex-grow-1" style="height: calc(100% - 64px);">
            <register-credit-overlay
              :is-open="isShowRegisterCreditOverlay"
              :original-invoice="invoice"
              @close="closeRegisterCreditOverlay"
            ></register-credit-overlay>
          </div>
        </v-card>
      </v-scroll-y-reverse-transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import InvoiceDataList from './DataList.vue'
import PaymentsTimeline from './PaymentsTimeline.vue'
import RegisterPaymentOverlay from '@/components/admin/RegisterPayment.vue'
import RegisterCreditOverlay from './RegisterCredit.vue'
import InvoiceCheckoutOverlay from './Checkout.vue'
import SalesItemsTable from '@/components/product/SalesItemsTable.vue'
import DownloadButton from '@/components/common/DownloadButton.vue'
import PrintButton from '@/components/common/PrintButton.vue'
import EmailTimeline from '@/components/email/Timeline.vue'

export default {
  name: 'InvoiceDialogContent',
  components: {
    InvoiceDataList,
    PaymentsTimeline,
    RegisterPaymentOverlay,
    RegisterCreditOverlay,
    InvoiceCheckoutOverlay,
    SalesItemsTable,
    DownloadButton,
    PrintButton,
    EmailTimeline
  },
  props: {
    invoice: Object,
    isLoading: Boolean,
    show: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    tabIndex: 'invoice-information',
    isShowRegisterPaymentOverlay: false,
    isShowInvoiceCheckoutOverlay: false,
    isShowRegisterCreditOverlay: false
  }),
  computed: {
    ...mapState(
      {
        isSendingInvoiceIds: state => state.invoice.isSendingInvoiceIds
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isCreditInvoice: function () {
      return this.invoice && this.invoice.is_credit_invoice
    },
    allowConvertToCustomerCredit: function () {
      return this.isCreditInvoice && this.invoice.remaining_amount < 0
    },
    title: function () {
      if (this.isCreditInvoice) {
        return `Kreditfaktura ${this.invoice.invoice_number}`
      } else {
        // fakturor eller kontantfaktura
        return `Faktura ${this.invoice.invoice_number}`
      }
    },
    subtitle: function () {
      if (!this.invoice) {
        return ''
      }
      const formattedDate = this.invoice.invoice_date.format('YYYY-MM-DD')
      return `Fakturadatum ${formattedDate}`
    },
    startPaymentButtonText: function () {
      if (this.isCreditInvoice) {
        return 'Påbörja återbetalning'
      } else {
        return 'Ta betalt'
      }
    },
    salesItems: function () {
      if (!this.invoice) {
        return []
      }
      return this.invoice.sales_items
    },
    paymentApplications: function () {
      if (!this.invoice) {
        return []
      }
      return this.invoice.payment_applications
    },
    creditApplications: function () {
      if (!this.invoice) {
        return []
      }
      return this.invoice.credit_applications
    },
    registerPaymentTitle: function () {
      if (this.isCreditInvoice) {
        return 'Registrera en utbetalning'
      }
      return 'Registrera en inbetalning'
    },
    invoiceCheckoutTitle: function () {
      if (this.isCreditInvoice) {
        return 'Genomför en återbetalning'
      }
      return 'Ta betalt'
    },
    hiddenDataListSections: function () {
      if (this.isCreditInvoice) {
        return ['due-date']
      }
      return []
    },
    emails: function () {
      if (this.invoice) {
        return this.invoice.emails
      }
      return []
    },
    emailsCount: function () {
      if (!this.emails || this.emails.length === 0) {
        return ''
      }
      if (this.emails.length > 99) {
        return '99+'
      }
      return this.emails.length
    },
    isSendingInvoice: function () {
      return this.invoice && this.isSendingInvoiceIds.includes(this.invoice.id)
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    startRegisterPayment: function () {
      this.isShowRegisterPaymentOverlay = true
    },
    startRegisterCredit: function () {
      this.isShowRegisterCreditOverlay = true
    },
    closeRegisterPaymentOverlay: function () {
      this.isShowRegisterPaymentOverlay = false
    },
    closeRegisterCreditOverlay: function () {
      this.isShowRegisterCreditOverlay = false
    },
    startInvoiceCheckout: function () {
      this.isShowInvoiceCheckoutOverlay = true
    },
    closeInvoiceCheckoutOverlay: function () {
      this.isShowInvoiceCheckoutOverlay = false
    },
    resendInvoice: function () {
      this.$store.dispatch('invoice/resendInvoice', { invoiceId: this.invoice.id })
    }
  },
  watch: {
    show: function () {
      if (!this.show) {
        this.tabIndex = 'invoice-information'
        this.isShowRegisterPaymentOverlay = false
        this.isShowInvoiceCheckoutOverlay = false
        this.isShowRegisterCreditOverlay = false
      }
    }
  }
}
</script>
