import API from './API.js'

export default {
  getRegister (workstationId) {
    return API.httpClient.get('register/find', {
      params: {
        workstation_id: workstationId
      }
    })
  },
  openRegister (registerId, balance) {
    return API.httpClient.post('register/open', {
      register_id: registerId,
      balance
    })
  },
  closeRegister (registerId) {
    return API.httpClient.post('register/close', {
      register_id: registerId
    })
  },
  fetchRegisters () {
    return API.httpClient.get('register')
  },
  prepareCopy ({ registerId, receiptId }) {
    console.log('prepareCopy', registerId, receiptId)
    return API.httpClient.post('receipts/preparecopy', {
      register_id: registerId,
      receipt_id: receiptId
    })
  },
  getReport (registerId, reportId) {
    return API.httpClient.get('register/report', {
      params: {
        register_id: registerId,
        report_id: reportId
      }
    })
  },
  getJournal (registerId) {
    return API.httpClient.get('register/journal', {
      params: {
        register_id: registerId
      }
    })
  },
  setHost (registerId, workstationId) {
    return API.httpClient.post('register/sethost', {
      register_id: registerId,
      workstation_id: workstationId
    })
  },
  getAccountingCalendarData (registerId, month) {
    return API.httpClient.get('register/accountingcalendardata', {
      params: {
        register_id: registerId,
        month
      }
    })
  },
  setReconciliationCountedTotals (reconciliationId, countedTotals) {
    return API.httpClient.post('reconciliation/setcountedtotals', {
      reconciliation_id: reconciliationId,
      counted_totals: countedTotals
    })
  },
  finishReconciliation (reconciliationId, comment, transaction) {
    return API.httpClient.post('reconciliation/finish', {
      reconciliation_id: reconciliationId,
      comment,
      transaction
    })
  },
  openCashDrawer (registerId) {
    return API.httpClient.post('register/opencashdrawer', {
      register_id: registerId
    })
  }
}
