<template>
  <v-dialog v-model="show" max-width="680px" :fullscreen="isMobile">
    <invoice-dialog-content
      :invoice="invoice"
      :is-loading="isLoading"
      :show="show"
      @close="show = false"
    ></invoice-dialog-content>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

import InvoiceDialogContent from './DialogContent.vue'

export default {
  name: 'InvoiceDialog',
  components: {
    InvoiceDialogContent
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        invoice: state => state.invoice.dialog.invoice,
        isLoading: state => state.invoice.dialog.isLoading,
        showDialog: state => state.invoice.dialog.show
      }
    ),
    show: {
      get () {
        return this.showDialog
      },
      set (value) {
        this.$store.commit('invoice/SET_SHOW_DIALOG', value)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {}
}
</script>
