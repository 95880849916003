import CountryService from '@/services/CountryService.js'

export const namespaced = true

export const state = {
  countries: [],
  isFetchingCountries: false
}

export const mutations = {
  SET_COUNTRIES (state, countries) {
    state.countries = countries
  },
  SET_IS_FETCHING_COUNTRIES (state, val) {
    state.isFetchingCountries = val
  }
}

export const actions = {
  fetchCountries ({ commit }) {
    commit('SET_IS_FETCHING_COUNTRIES', true)
    return CountryService.getCountries()
      .then(({ data }) => {
        commit('SET_COUNTRIES', data.data.countries)
        commit('SET_IS_FETCHING_COUNTRIES', false)
      })
  },
  enrichCountries ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichCountry(state.countries)
      resolve(true)
    })
  }
}

export const getters = {
  defaultCountry: function (state) {
    if (state.countries.length === 0) {
      return null
    }
    return state.countries.find(c => c.id === 752) // 752 = Sverige
  }
}
